import React from 'react';
import { useParams } from "react-router-dom";

import Loader from 'components/app/Loader';
import { useActions, useAppState } from 'state';
import PageHeader from 'components/layout/page/PageHeader';
import ModuleErrors from 'components/ui/ModuleErrors';
import { TenantVehicleInfo } from 'components/tenant/vehicle/TenantVehicleInfo';
import { TabDivider } from 'components/ui/Tabs';
import PageLayout from 'components/layout/page/PageLayout';
import { TenantVehicleDrawerAction } from 'components/tenant/vehicle/TenantVehicleDrawer';
import { TenantVehicleInsuranceInfo } from 'components/tenant/vehicle/TenantVehicleInsuranceInfo';
import { Box, Stack } from '@mantine/core';


const Vehicle: React.FC = () => {
    
    const { id } = useParams()
    const tenant = useAppState().tenant
    const { loading, currentItem, errors } = useAppState().vehicles
    const { subscribeToItem, unsubscribeFromItem } = useActions().vehicles

    React.useEffect(() => {
        subscribeToItem({ id });
        return () => {
            unsubscribeFromItem()
        }
    }, [id, subscribeToItem, unsubscribeFromItem])

    if (loading || !currentItem) return <Loader />

    return (
        <PageLayout
            header={<PageHeader
                title={`${currentItem.make} ${currentItem.model}`}
                renderAction={<TenantVehicleDrawerAction />}
            />}
        >

            <ModuleErrors errors={errors} />

            <Stack>
                <Box>
                    <TabDivider label="Vehicle Details" />
                    <TenantVehicleInfo vehicle={currentItem} />
                </Box>

                {tenant.info&&tenant.info.vehicle_policy_enabled?(
                    <Box>
                        <TabDivider label="Vehicle Insurance Policy" />
                        <TenantVehicleInsuranceInfo vehicle={currentItem} />
                    </Box>
                ):null}

            </Stack>

        </PageLayout>
    )
}

export default Vehicle;
import { Loader, Select } from "@mantine/core";
import React from "react";
import { useActions, useAppState } from "state";
import { Order_By, Vehicles_Status_Enum } from "state/gql/_types";
import { GenericFieldProps } from "utils/forms";
import { capitalizeWords } from "utils/string";

const VehicleSelect: React.FC<GenericFieldProps> = ({ id, name, label, onChange, onBlur, value, error, required, description, disabled, classNames }) => {

    const tenant_id = useAppState(state => state.profile?.tenant_id)
    const { itemsList, loading } = useAppState(state => state.vehicles)
    const { getAllItems } = useActions().vehicles

    React.useEffect(() => {

        let controller: AbortController | null = new AbortController();

        const loadItems = async () => {

            if (controller) {
                try {
                    await getAllItems({
                        where: {
                            tenant_id: {
                                _eq: tenant_id
                            },
                            status: {
                                _eq: Vehicles_Status_Enum.Available
                            }
                        },
                        order_by: {
                            year: Order_By.Asc,
                            make: Order_By.Asc,
                            model: Order_By.Asc,
                            color: Order_By.Asc,
                        }
                    })
                    controller = null;
                } catch (e: any) {
                    if (e.name !== 'AbortError') {
                        console.error(e)
                    }
                }
            }

        }
        
        if (tenant_id) {
            loadItems()
        }

        return () => controller?.abort()

    }, [tenant_id, getAllItems])

    return (
        <Select
            label={label}
            id={id}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={error}
            required={required}
            disabled={disabled}
            description={description}
            rightSection={loading ? <Loader size="xs" /> : null}
            placeholder="Select..."
            searchable
            clearable
            data={itemsList.map(vehicle=>({
                label: capitalizeWords(`${vehicle.year} ${vehicle.make} ${vehicle.model} - ${vehicle.color} [${vehicle.plate.toUpperCase()}]`),
                value: vehicle.id
            }))}
            selectOnBlur
            autoComplete="off"
            classNames={classNames}
        />
    )

}

export default VehicleSelect;
export const createTenantSubscription = async (tenant_id: string): Promise<boolean> => {

    try {

        let res = await fetch(`${process.env.REACT_APP_OPS_API_URL||''}/tenant/create-subscription`,{
            method:'POST',
            body: JSON.stringify({tenant_id}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        let data = await res.json()
        return data.success

    } catch (e) {
        console.error(e)
        return false;
    }
    
}
import React from 'react';
import { Text, Button, MantineColor, MantineSize, ButtonVariant } from '@mantine/core';
import Icons from 'components/ui/Icons';
import { Icon } from '@iconify/react';

export type OrderProcessActionProps = {
    title: string;
    icon?: string;
    color?: MantineColor;
    variant?: ButtonVariant;
    size?: MantineSize;
    iconColor?: string;
    disabled: boolean;
    onClick(): void;
}

const OrderProcessButton: React.FC<OrderProcessActionProps> = ({
    title,
    icon = Icons.add,
    color = "blue",
    iconColor = "white",
    size = "sm",
    variant = "filled",
    disabled = false,
    onClick
}) =>{

    return (
        <Button
            onClick={onClick}
            rightIcon={<Icon icon={icon} color={iconColor} width={18} />}
            color={color}
            variant={variant}
            size={size}
            disabled={disabled}
        >
            <Text>{title}</Text>
        </Button>
    );
}

export default OrderProcessButton;
import { AsyncAction } from "state/_types";
import logger from 'utils/logger';

export const initializeSubscriptions: AsyncAction = async ({state,effects}) => {
    if ( state.auth.user_id && state.auth.session_id && state.auth.session_role ) {
        effects.subscriptions.initialize({auth:state.auth,tenant:state.tenant});
        state.online = true
    } else {
        logger('No Session For Subscription API')
    }    
}
import React from "react";
import { Box, Button, Text, Modal, ScrollArea } from "@mantine/core";
import { useActions, useAppState } from "state";
import { TermsContent } from "./TermsContent";
import { useMediaQuery } from "@mantine/hooks";

export const TenantTermsModal = () => {

    const profile = useAppState(state => state.profile)
    const { info } = useAppState(state => state.tenant)
    const acceptLatestTenantTerms = useActions().tenants.acceptLatestTenantTerms
    const [loading, setLoading] = React.useState(false);
    const [open, setOpened] = React.useState(true);
    const isMobile = useMediaQuery('(max-width: 1000px)');   

    const handleAcceptTerms = async () => {
        setLoading(true)
        await acceptLatestTenantTerms()
        setLoading(false)
        setOpened(false)
    }

    if (!profile||profile.role!=='manager') return null
    if (!info||info.latest_terms_accepted) return null

    return (
        <React.Fragment>
            <Modal
                opened={open}
                onClose={() => setOpened(false)}
                title={<Text color="red" weight={600}>Please Review & Accept Before Continuing</Text>}
                withCloseButton={false}
                centered
                size={isMobile ? "100%" : "60%"}
                closeOnClickOutside={false}
                closeOnEscape={false}
                overflow="inside"
                trapFocus={false}
            >

                <Box sx={{ position: 'relative', height: '60vh', overflow: 'hidden' }}>

                    <ScrollArea
                        style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0
                        }}
                    >
                        <TermsContent />

                        <Button
                            fullWidth
                            size="xl"
                            onClick={handleAcceptTerms}
                            loading={loading}
                        >
                            Accept Terms
                        </Button>

                    </ScrollArea>

                </Box>


            </Modal>
        </React.Fragment>
    );

}
import { AsyncAction } from "state/_types";
import { PortalLinkMode } from "../effects";

export const getBillingPortalLink: AsyncAction<PortalLinkMode,string|null> = async ({ state,effects }, mode = 'default') => {
    let session
    if (mode==='subscription_cancel'&&state.tenant.info?.subscription_id) {
        session = await effects.getBillingPortalLink(mode,state.tenant.info.subscription_id)
    } else {
        session = await effects.getBillingPortalLink(mode)
    }
    return session&&session.url ? session.url : null
}
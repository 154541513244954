import React from 'react';
import { Box, Container, ScrollArea } from '@mantine/core';
import { motion } from 'framer-motion'

type PublicPageLayoutProps = {
    header?: React.ReactElement;
}

const PublicPageLayout: React.FC<PublicPageLayoutProps> = ({ header = null, children }) => {

    return (

        <Box
            id="bob"
            sx={theme => ({
                height: '100%',
                display: 'grid',
                [theme.fn.smallerThan('md')]: {
                    gridTemplateRows: 'auto 1fr',
                },
                [theme.fn.largerThan('md')]: {
                    gridTemplateRows: 'auto 1fr',
                },
            })}
        >
            <motion.div
                variants={{
                    enter: { y: 0, opacity: 0, transition: { duration: 0.25 } },
                    target: { y: 0, opacity: 1, transition: { duration: 0.25 } },
                    exit: { y: 0, opacity: 0, transition: { duration: 0.25 } }
                }}
                initial="enter"
                animate="target"
                exit="exit"
                style={{ height: '100%', position: 'relative' }}
            >
                {header}
            </motion.div>

            <Box sx={{ position: 'relative', height: '100%', overflow: 'hidden' }}>

                <motion.div
                    variants={{
                        enter: { y: -100, opacity: 0, transition: { duration: 0.25 } },
                        target: { y: 0, opacity: 1, transition: { duration: 0.25 } },
                        exit: { y: 0, opacity: 0, transition: { duration: 0.25 } }
                    }}
                    initial="enter"
                    animate="target"
                    exit="exit"
                    style={{ height: '100%', position: 'relative' }}
                >

                    <ScrollArea
                        style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0
                        }}
                    >

                        <Box sx={(theme) => ({
                            padding: theme.spacing.md,
                            paddingTop: 0
                        })}>
                            <Container
                                sx={(theme) => ({
                                    height: "100%",
                                    [theme.fn.smallerThan('md')]: {
                                        padding: 0,
                                    }
                                })}
                            >
                                {children}
                            </Container>

                        </Box>

                    </ScrollArea>

                </motion.div>
            </Box>

        </Box>

    )
}

export default PublicPageLayout;
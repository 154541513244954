import React from 'react';
import { NavLink, useParams } from "react-router-dom";

import { useActions, useAppState } from 'state';

import Loader from 'components/app/Loader';
import PageHeader from 'components/layout/page/PageHeader';
import ModuleErrors from 'components/ui/ModuleErrors';
import PageLayout from 'components/layout/page/PageLayout';
import { Badge, Button, Text, Grid, MediaQuery } from '@mantine/core';
import { TabDividerSpacing } from 'components/ui/Tabs';
import { parseOrderStepColor } from 'components/tenant/order/OrderUtils';
import InfoItem from 'components/ui/InfoItem';
import { capitalizeWord, formatSnakeCase } from 'utils/string';
import Icons from 'components/ui/Icons';
import { Icon } from '@iconify/react';
import { formatDate, formatDuration, formatHour } from 'utils/dates';
import TenantOrderTabs from 'components/tenant/order/TenantOrderTabs';
import VehicleColorBadge from 'components/tenant/vehicle/VehicleColorBadge';
import { decimalToPercentage } from 'utils/numbers';

const TenantOrderDetails: React.FC = () => {
    const { id } = useParams()
    const { loading, currentItem, errors } = useAppState().orders
    const { subscribeToItem, unsubscribeFromItem, resetModule } = useActions().orders

    React.useEffect(() => {
        subscribeToItem({ id });
        return () => {
            resetModule()
            unsubscribeFromItem()
        }
    }, [id, subscribeToItem, unsubscribeFromItem, resetModule])

    if (loading || !currentItem) return <Loader />

    return (
        <PageLayout
            showFooterNav={false}
            header={
                <PageHeader
                    title={`Order #${currentItem.order_number} - ${currentItem.client?.first_name} ${currentItem.client?.last_name}`}
                    renderAction={
                        <React.Fragment>
                            {currentItem.status === 'pending' || currentItem.status === 'open' ? (
                                <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                                    <Button
                                        to={`/orders/${currentItem.id}/process`}
                                        component={NavLink}
                                        rightIcon={<Icon icon={Icons.forward} color="white" width={18} />}
                                        color="blue"
                                        size="sm"
                                    >
                                        <Text>Continue Rental Process</Text>
                                    </Button>
                                </MediaQuery>
                            ) : null}
                        </React.Fragment>
                    }
                />
            }
        >
            <ModuleErrors errors={errors} />

            <TenantOrderTabs />

            <TabDividerSpacing label="Summary" />

            <Grid grow gutter="xs" >

                <Grid.Col md={6}>
                    <InfoItem label="Status">
                        <Badge size="lg" variant="outline" color={parseOrderStepColor(currentItem.step)}>{formatSnakeCase(currentItem.step)}</Badge>
                    </InfoItem>
                </Grid.Col>

                <Grid.Col md={6}>
                    <InfoItem label="Step">
                        <Badge size="lg" variant="outline" color="gray">{formatSnakeCase(currentItem.state)}</Badge>
                    </InfoItem>
                </Grid.Col>


                <Grid.Col md={6}>
                    <InfoItem label="Agent" value={`${currentItem.agent?.first_name} ${currentItem.agent?.last_name}`} />
                </Grid.Col>

            </Grid>

            <TabDividerSpacing label="Vehicle" />

            <Grid grow gutter="xs">

                <Grid.Col xs={6} lg={3}>
                    <InfoItem label="Make" value={currentItem.vehicle?.make} />
                </Grid.Col>

                <Grid.Col xs={6} lg={3}>
                    <InfoItem label="Model" value={capitalizeWord(currentItem.vehicle?.model || 'NA')} />
                </Grid.Col>

                <Grid.Col xs={6} lg={3}>
                    <InfoItem label="Year" value={currentItem.vehicle?.year} />
                </Grid.Col>

                <Grid.Col xs={6} lg={3}>
                    <InfoItem label="Color">
                        <VehicleColorBadge color={currentItem.vehicle?.color || 'gray'} />
                    </InfoItem>
                </Grid.Col>

                <Grid.Col xs={12} lg={6}>
                    <InfoItem label="Vin" value={currentItem.vehicle?.vin} />
                </Grid.Col>

                <Grid.Col xs={6} lg={3}>
                    <InfoItem label="Plate" value={currentItem.vehicle?.plate} />
                </Grid.Col>

                <Grid.Col xs={6} lg={3}>
                    <InfoItem label="Miles" value={currentItem.vehicle?.miles} />
                </Grid.Col>

            </Grid>

            <TabDividerSpacing label="Client" />

            <Grid grow gutter="xs">

                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="Name" value={`${currentItem.client?.first_name} ${currentItem.client?.last_name}`} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="Email" value={currentItem.client?.email} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="Phone" value={currentItem.client?.phone} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItem label="Address" value={currentItem.client?.address} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="Country" value={currentItem.client?.country} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="City" value={currentItem.client?.city} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="State/Region" value={currentItem.client?.state} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="Zip" value={currentItem.client?.zip} />
                </Grid.Col>

            </Grid>

            <TabDividerSpacing label="License" />

            <Grid grow gutter="xs">

                <Grid.Col span={12}>
                    <InfoItem label="License Number" value={currentItem.client?.license_number} />
                </Grid.Col>
                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="Location Issued" value={currentItem.client?.license_state} />
                </Grid.Col>
                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="Date of Birth" value={formatDate(currentItem.client?.dob)} />
                </Grid.Col>
                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="Expiration Date" value={formatDate(currentItem.client?.license_exp_date)} />
                </Grid.Col>
                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="License Photo Front" value={currentItem.client?.license_image_front ? 'Uploaded' : 'Missing'} />
                </Grid.Col>
                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="License Photo Back" value={currentItem.client?.license_image_front ? 'Uploaded' : 'Missing'} />
                </Grid.Col>

            </Grid>

            <TabDividerSpacing label="Insurance" />

            <Grid grow gutter="xs">

                <Grid.Col md={4} >
                    <InfoItem label="Policy Type" value={capitalizeWord(currentItem.policy_type)} />
                </Grid.Col>

                <Grid.Col md={4}>
                    <InfoItem label="Policy Provider" value={currentItem.client?.insurance_name} />
                </Grid.Col>

                <Grid.Col md={4}>
                    <InfoItem label="Policy Number" value={currentItem.client?.insurance_number} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="Effective Date" value={formatDate(currentItem.client?.insurance_start_date)} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="Expiration Date" value={formatDate(currentItem.client?.insurance_end_date)} />
                </Grid.Col>

                {currentItem.policy_type === 'renter' ? (
                    <Grid.Col xs={6} lg={4}>
                        <InfoItem label="Photo of Insurance" value={currentItem.client?.insurance_image ? 'Uploaded' : 'Missing'} />
                    </Grid.Col>
                ) : null}

            </Grid>

            <TabDividerSpacing label="Rental Dates" />

            <Grid grow gutter="xs">

                <Grid.Col sm={6}>
                    <InfoItem label="Start Date" value={formatDate(currentItem.start_date)} />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="End Date" value={formatDate(currentItem.end_date)} />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="Start Time" value={formatHour(currentItem.start_time)} />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="End Time" value={formatHour(currentItem.end_time)} />
                </Grid.Col>



            </Grid>

            <TabDividerSpacing label="Rental Terms" />

            <Grid grow gutter="xs">

                <Grid.Col sm={6}>
                    <InfoItem label="Rental Period" value={formatDuration(currentItem.rental_period)} />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="Daily Rate" value={currentItem.day_rate} type="currency" />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="Sub Total" value={currentItem.sub_total} type="currency" />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="Other Fees" value={currentItem.other_fees} type="currency" />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="Discount" value={currentItem.discount} type="currency" />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="Sales Tax" value={decimalToPercentage(currentItem.sales_tax)} />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="Deposit" value={currentItem.deposit} type="currency" />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="Total" value={currentItem.order_total} type="currency" color="green" />
                </Grid.Col>

            </Grid>

            <TabDividerSpacing label="Delivery Details" />

            <Grid grow gutter="xs">

                <Grid.Col span={12}>
                    <InfoItem label="Location" value={currentItem.delivery_geo} />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="Fuel" value={currentItem.fuel_out} />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="Miles" value={currentItem.miles_out} type="number" />
                </Grid.Col>

                <Grid.Col sm={12}>
                    <InfoItem label="Images" value={currentItem.delivery_images?.split(',').length} />
                </Grid.Col>

                <Grid.Col sm={12}>
                    <InfoItem label="Video" value={currentItem.delivery_videos?.split(',').length} />
                </Grid.Col>

            </Grid>

            <TabDividerSpacing label="Contract Details" />

            <Grid grow gutter="xs">

                <Grid.Col md={4} >
                    <InfoItem label="Contract" value={currentItem.contract_id ? 'Generated' : 'Not Generated'} />
                </Grid.Col>

                <Grid.Col md={4}>
                    <InfoItem label="Link" value={currentItem.contract_link ? 'Generated' : 'Not Generated'} />
                </Grid.Col>

                <Grid.Col md={4}>
                    <InfoItem label="Signed" value={currentItem.contract_signed ? 'Yes' : 'No'} />
                </Grid.Col>

            </Grid>

            <TabDividerSpacing label="PickUp Details" />

            <Grid grow gutter="xs" mb={100}>

                <Grid.Col span={12}>
                    <InfoItem label="Location" value={currentItem.pickup_geo} />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="Fuel" value={currentItem.fuel_in} />
                </Grid.Col>

                <Grid.Col sm={6}>
                    <InfoItem label="Miles" value={currentItem.miles_in} type="number" />
                </Grid.Col>

                <Grid.Col sm={12}>
                    <InfoItem label="Images" value={currentItem.pickup_images?.split(',').length} />
                </Grid.Col>

                <Grid.Col sm={12}>
                    <InfoItem label="Video" value={currentItem.pickup_videos?.split(',').length} />
                </Grid.Col>

            </Grid>

        </PageLayout>
    )
}

export default TenantOrderDetails;

import React from 'react';
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useAppState, useReaction } from 'state';

import Loader from 'components/app/Loader';
import AppShellLayout from 'components/layout/AppShellLayout';
import NavContext, { NavContextType } from 'components/navigation/NavContext';

import rootRoutes from 'routes/Root'
import guestRoutes from 'routes/Guest'
import tenantRoutes from 'routes/Tenant'
import agentRoutes from 'routes/Agent';
import PrivacyPolicy from 'screens/global/PrivacyPolicy';
import TermsAndConditions from 'screens/global/TermsAndConditions';
import GuestCreateReservation from 'screens/guest/reservations/GuestCreateReservation';

const Wrapper = () => {

    const { loading, profile } = useAppState(({ loading, profile }) => ({ loading, profile }))

    if (loading) return <Loader />

    const renderAppRoutes = () => {
        switch (profile?.role) {
            case 'admin':
            case 'manager':
            case 'agent':
                return <Route path="*" element={<AppShellLayout />} />
            default:
                return (
                    <React.Fragment>
                        <Route path="reservations" element={<GuestCreateReservation />} /> 
                        <Route path="*" element={<AppShellLayout />} />
                    </React.Fragment>
                )
        }
    }

    return (
        <Routes>
            {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"))}
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-and-conditions" element={<TermsAndConditions />} />
            {renderAppRoutes()} 
        </Routes>
    ) 
}

const App: React.FC = () => {

    let [routes, setRoutes] = React.useState<NavContextType | null>(guestRoutes)
    const reaction = useReaction()

    React.useEffect(() => reaction(
        (state) => state.profile?.role,
        (role) => {
            switch (role) {
                case 'admin':
                    setRoutes(rootRoutes);
                    break;
                case 'manager':
                    setRoutes(tenantRoutes);
                    break;
                case 'agent':
                    setRoutes(agentRoutes);
                    break
                default:
                    setRoutes(guestRoutes);
                    break;
            }
        }, { immediate: true }
    ))

    if (!routes) return <Loader />

    return (
        <NavContext.Provider value={routes}>
            <BrowserRouter>
                <Wrapper />
            </BrowserRouter>
        </NavContext.Provider>
    );
}

export default App;
import Layout from "components/navigation/Layout";

import NotFound from "screens/global/NotFound";
import GuestCreateReservation from "screens/guest/reservations/GuestCreateReservation";

let guestRoutes = {
    routes: [
        {
            path: '/reservations',
            element: <Layout />,
            breadcrumb: 'Layout',
            children: [
                { index: true, element: <GuestCreateReservation />, breadcrumb: 'Reservations' },
                { path: "*", element: <NotFound />, breadcrumb: 'Page Not Found' }
            ]
        }
    ],
    menu: [
        
    ],
    mobile: [
        
    ]
} 

export default guestRoutes;
import * as Types from '../gql/_types';

import { TenantModule } from "./init";
import { mapArrayToStateObject } from "utils/state";
import { Action, AsyncAction, GraphQLSubscriptionResultsPayload } from 'state/_types';
import { subscriptions } from 'state/gql/_operations';
import { handleGraphqQLErrorResponse, handleGraphqQLSubscriptionErrorResponse } from 'state/gql/utils';
import { SetMetaInput } from 'state/Module';

const Module = TenantModule;

export const state = Module;

export const getItems: AsyncAction<Types.GetTenantsQueryVariables> = async ({state, effects}, payload) => {
    let finalPayload = Module.mergeQueryPayload(payload);
    state.tenants.errors = [];
    state.tenants.loading = true;
    try {
        let { limit, offset, sortKey, sortOrder } = state.tenants.meta;
        let originalPayload = { limit, offset, order_by: { [sortKey]: sortOrder } };
        let { meta, items } = await effects.gql.queries.GetTenants({...originalPayload, ...finalPayload})
        state.tenants.meta.total = meta.aggregate?.count || 0;
        state.tenants.items = mapArrayToStateObject(items, 'id');
    } catch (e: any) {
        state.tenants.errors = handleGraphqQLErrorResponse(e);
    }
    state.tenants.loading = false;
}

export const resetState: Action = ({state}) => {
    state.tenants.errors = []
    state.tenants.loading = false
    state.tenants.items = {}
    state.tenants.currentItemId = null
}

export const resetMeta: Action = ({state}) => {
    state.tenants.meta = {
        limit: Module.defaultLimit,
        sortOrder: Module.defaultSortOrder,
        sortKey: Module.defaultSortKey,
        page: 1,
        total: 0,
        offset: 0,
        orderBy: {}
    }
}

export const resetModule: AsyncAction = async ({actions}) => {
    actions.tenants.resetMeta()
    actions.tenants.resetState()
}

export const getMoreItems: AsyncAction = async ({state, effects}) => {
    let finalPayload = Module.mergeQueryPayload({});
    state.tenants.errors = [];
    state.tenants.loadingMore = true;
    try {
        let { limit, sortKey, sortOrder, page } = state.tenants.meta;
        let newPage = ++page;
        let newOffset = ( limit * newPage ) - limit
        let originalPayload = { limit, offset: newOffset, order_by: { [sortKey]: sortOrder } };
        let { meta, items } = await effects.gql.queries.GetTenants({...originalPayload, ...finalPayload})
        state.tenants.meta.total = meta.aggregate?.count || 0;
        state.tenants.meta.page = newPage;
        state.tenants.meta.offset = newOffset;
        state.tenants.items = { ...state.tenants.items, ...mapArrayToStateObject(items, 'id') };
    } catch (e: any) {
        state.tenants.errors = handleGraphqQLErrorResponse(e);
    }
    state.tenants.loadingMore = false;
}

export const getAllItems: AsyncAction<Types.GetTenantsQueryVariables> = async ({state, effects}, payload) => {
    state.tenants.errors = [];
    state.tenants.loading = true;
    try {
        let { meta, items } = await effects.gql.queries.GetTenants(payload)
        state.tenants.meta.total = meta.aggregate?.count || 0;
        state.tenants.items = mapArrayToStateObject(items, 'id');
    } catch (e: any) {
        state.tenants.errors = handleGraphqQLErrorResponse(e);
    }
    state.tenants.loading = false;
}

export const getItem: AsyncAction<Types.GetTenantQueryVariables,Types.GetTenantQuery['item']> = async ({state, effects}, payload) => {
    try {
        let { item } = await effects.gql.queries.GetTenant(payload);
        return item;
    } catch (e: any) {
        state.tenants.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const loadItem: AsyncAction<Types.GetTenantQueryVariables> = async ({state, actions}, payload) => {
    state.tenants.loading = true;
    try {
        let item = await actions.tenants.getItem(payload);
        if (item) {
            state.tenants.currentItemId = item.id;
            state.tenants.items[item.id] = item;
        }
    } catch (e: any) {
        state.tenants.errors = handleGraphqQLErrorResponse(e);
    }
    state.tenants.loading = false;
}

export const selectItem: Action<string> = async ({state}, payload) => {
    state.tenants.currentItemId = payload
}

export const setMeta: Action<SetMetaInput<Types.TenantFragment>> = async ({state}, meta) => {
    state.tenants.meta = {
        ...state.tenants.meta,
        ...meta
    }
}


export const createItem: AsyncAction<Types.CreateTenantMutationVariables['object'],Types.CreateTenantMutation['item']> = async ({state, effects}, payload) => {
    let finalPayload = Module.mergeCreatePayload<Types.CreateTenantMutationVariables['object']>(payload);
    state.tenants.errors = [];
    try {
        let { item } = await effects.gql.mutations.CreateTenant({object:finalPayload});
        return item;
    } catch (e: any) {
        state.tenants.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const updateItem: AsyncAction<Types.UpdateTenantMutationVariables,Types.UpdateTenantMutation['item']> = async ({state, effects}, payload) => {
    state.tenants.errors = [];
    try {
        let { item } = await effects.gql.mutations.UpdateTenant(payload);
        return item;
    } catch (e: any) {
        state.tenants.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const deleteItem: AsyncAction<Types.DeleteTenantMutationVariables,Types.DeleteTenantMutation['item']> = async ({state, effects}, payload) => {
    state.tenants.errors = [];
    try {
        let { item } = await effects.gql.mutations.DeleteTenant(payload);
        return item;
    } catch (e: any) {
        state.tenants.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const syncGetItemsMeta: Action<Types.SyncGetTenantsMetaSubscriptionVariables> = async ({state, actions, effects}, {where}) => {
    effects.subscriptions.subscribe<Types.SyncGetTenantsMetaSubscription, Types.SyncGetTenantsMetaSubscriptionVariables>(
        'syncGetTenantsMeta', 
        subscriptions.SyncGetTenantsMeta, 
        { where }, 
        actions.tenants.onItemsMeta
    );
}

export const unsubItemsMeta: Action = ({effects}) => {
    effects.subscriptions.unsubscribe('syncGetTenantsMeta');
}

export const onItemsMeta: Action<GraphQLSubscriptionResultsPayload<Types.SyncGetTenantsMetaSubscription>> = async ({state}, payload) => {
    if (payload.error) {
        state.tenants.errors = [ ...state.tenants.errors, ...handleGraphqQLSubscriptionErrorResponse(payload.error) ];
    }
    if (payload.data) {
        state.tenants.meta.total = payload.data.meta.aggregate?.count || 0;
    }    
}

export const syncGetItems: Action<Types.SyncGetTenantsSubscriptionVariables> = async ({state, actions, effects}, payload) => {
    let { limit, offset, sortKey, sortOrder } = state.tenants.meta;
    let originalPayload = { limit, offset, order_by: { [sortKey]: sortOrder } };
    state.tenants.loading = true;
    effects.subscriptions.subscribe<Types.SyncGetTenantsSubscription, Types.SyncGetTenantsSubscriptionVariables>(
        'syncGetTenants', 
        subscriptions.SyncGetTenants, 
        {...originalPayload, ...payload}, 
        actions.tenants.onItemsData,
        () => { state.tenants.loading = false }
    );
}

export const unsubItemsData: Action = ({state,effects}) => {
    effects.subscriptions.unsubscribe('syncGetTenants');
    state.tenants.items = {};
}

export const onItemsData: Action<GraphQLSubscriptionResultsPayload<Types.SyncGetTenantsSubscription>> = async ({state}, payload) => {
    if (payload.error) {
        state.tenants.errors = [ ...state.tenants.errors, ...handleGraphqQLSubscriptionErrorResponse(payload.error) ];
    }
    if (payload.data&&payload.data.items) {
        state.tenants.items = mapArrayToStateObject(payload.data.items, 'id');
    }  
}

export const subscribeToItems: Action<Types.SyncGetTenantsSubscriptionVariables> = async ({actions}, payload) => {
    let finalPayload = Module.mergeQueryPayload(payload);
    actions.tenants.syncGetItems(finalPayload);
    actions.tenants.syncGetItemsMeta(finalPayload);
}

export const unsubscribeFromItems: Action = ({actions}) => {
    actions.tenants.unsubItemsData();
    actions.tenants.unsubItemsMeta();
}

export const syncGetItem: Action<Types.SyncGetTenantSubscriptionVariables> = async ({state, actions, effects}, payload) => {
    state.tenants.loading = true;
    effects.subscriptions.subscribe<Types.SyncGetTenantSubscription, Types.SyncGetTenantSubscriptionVariables>(
        'syncGetTenant', 
        subscriptions.SyncGetTenant, 
        payload, 
        actions.tenants.onItemData,
        () => { state.tenants.loading = false }
    );
}

export const unsubItemData: Action = ({state,effects}) => {
    effects.subscriptions.unsubscribe('syncGetTenant');
    state.tenants.currentItemId = null;
}

export const onItemData: Action<GraphQLSubscriptionResultsPayload<Types.SyncGetTenantSubscription>> = async ({state}, payload) => {
    if (payload.error) {
        state.tenants.errors = [ ...state.tenants.errors, ...handleGraphqQLSubscriptionErrorResponse(payload.error) ];
    }
    if (payload.data&&payload.data.item) {
        state.tenants.currentItemId = payload.data.item.id;
        state.tenants.items[payload.data.item.id] = payload.data.item;
    }
}

export const subscribeToItem: Action<Types.SyncGetTenantSubscriptionVariables> = async ({actions}, payload) => {
    actions.tenants.syncGetItem(payload);
}

export const unsubscribeFromItem: Action = ({actions}) => {
    actions.tenants.unsubItemData();
}

export const actions = {
    getItems,
    getAllItems,
    resetState,
    resetMeta,
    getMoreItems,
    resetModule,
    getItem,
    loadItem,
    selectItem,
    setMeta,
    createItem,
    updateItem,
    deleteItem,
    syncGetItemsMeta,
    unsubItemsMeta,
    onItemsMeta,
    syncGetItems,
    unsubItemsData,
    onItemsData,
    subscribeToItems,
    unsubscribeFromItems,
    syncGetItem,
    unsubItemData,
    onItemData,
    subscribeToItem,
    unsubscribeFromItem
};
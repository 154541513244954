import { AppState, AsyncAction, Context } from "state/_types";
import { pipe, debounce } from 'overmind'
import { getDurationDaysNumber } from "utils/dates";
import dayjs from "dayjs";
import { GetTenantVehiclesQueryVariables } from "state/gql/_types";
import { mapArrayToStateObject } from "utils/state";
import { handleGraphqQLErrorResponse } from "state/gql/utils";
import { BillingUsageRecordSummary } from "state/app/effects/getBillingUsage";

export const checkSubdomain: AsyncAction<string | undefined, boolean> = pipe(
    debounce(250),
    async ({ effects }: Context, slug) => {
        if (!slug) return true;
        let { subdomain } = await effects.gql.queries.CheckSubdomain({ slug })
        return subdomain ? !subdomain.taken : true
    }
)

export const getTenantInfo: AsyncAction = async ({ state, effects }: Context) => {
    let { item } = await effects.gql.queries.GetTenant({ id: state.tenant.id })
    if (item) {
        state.tenant.info = item
    }
}

export const acceptLatestTenantTerms: AsyncAction = async ({ state, actions }: Context) => {
    if (state.profile && state.profile.tenant_id) {
        let tenant = await actions.tenants.updateItem({
            id: state.profile.tenant_id,
            _set: {
                latest_terms_accepted: true
            }
        })
        if (tenant) {
            state.tenant.info = tenant
        }
    }

}

export type GetTenantBillingFragment = Pick<AppState['tenant'], 'billing' | 'hasSubscription' | 'subStatus' | 'hasPaymentMethod' | 'cardExpires' | 'cardExpirationDays' | 'hasTrial' | 'trialDays'>

export const getTenantBilling: AsyncAction<string | undefined | null, GetTenantBillingFragment | null> = async ({ effects }: Context, id) => {
    if (id) {
        let { item } = await effects.gql.queries.GetTenantBilling({ id })

        if (item && item.billing) {

            let tenantBilling: { [index: string]: any } = {}

            tenantBilling.billing = item.billing

            let subscription = item.billing.subscriptions.data[0] || false

            if (subscription) {

                tenantBilling.hasSubscription = true
                tenantBilling.subStatus = subscription.status as AppState['tenant']['subStatus']

                let hasPaymentMethod = !!item.billing.paymentMethods.data.length
                tenantBilling.hasPaymentMethod = hasPaymentMethod
                if (hasPaymentMethod) {
                    let paymentMethod = item.billing.paymentMethods.data[0]
                    if (paymentMethod.card) {
                        let expMonth = paymentMethod.card.expMonth
                        let expYear = paymentMethod.card.expYear
                        let expDate = new Date()
                        expDate.setDate(1)
                        expDate.setMonth(expMonth)
                        expDate.setFullYear(expYear)
                        expDate.setHours(0, 0, 0)
                        let expDays = dayjs(expDate).diff(new Date(), 'day')
                        tenantBilling.cardExpires = expDays < 30
                        tenantBilling.cardExpirationDays = expDays
                    }
                }

                if (subscription && subscription.status === 'trialing' && subscription.trialStart && subscription.trialEnd) {
                    tenantBilling.hasTrial = true
                    tenantBilling.trialDays = getDurationDaysNumber(new Date().toISOString(), new Date(subscription.trialEnd * 1000).toISOString())
                }

                return tenantBilling as GetTenantBillingFragment

            }

        }
    }
    return null
}

export const loadTenantBilling: AsyncAction = async ({ state, effects }: Context) => {
    if (state.tenant.id && state.tenant.type === 'dealer') {

        let { item } = await effects.gql.queries.GetTenantBilling({ id: state.tenant.id })

        if (item && item.billing) {

            state.tenant.billing = item.billing

            let subscription = item.billing.subscriptions.data[0] || false

            if (subscription) {

                state.tenant.hasSubscription = true
                state.tenant.subStatus = subscription.status as AppState['tenant']['subStatus']

                let hasPaymentMethod = !!item.billing.paymentMethods.data.length
                state.tenant.hasPaymentMethod = hasPaymentMethod
                if (hasPaymentMethod) {
                    let paymentMethod = item.billing.paymentMethods.data[0]
                    if (paymentMethod.card) {
                        let expMonth = paymentMethod.card.expMonth
                        let expYear = paymentMethod.card.expYear
                        let expDate = new Date()
                        expDate.setDate(1)
                        expDate.setMonth(expMonth)
                        expDate.setFullYear(expYear)
                        expDate.setHours(0, 0, 0)
                        let expDays = dayjs(expDate).diff(new Date(), 'day')
                        state.tenant.cardExpires = expDays < 30
                        state.tenant.cardExpirationDays = expDays
                    }
                }

                if (subscription && subscription.status === 'trialing' && subscription.trialStart && subscription.trialEnd) {
                    state.tenant.hasTrial = true
                    state.tenant.trialDays = getDurationDaysNumber(new Date().toISOString(), new Date(subscription.trialEnd * 1000).toISOString())
                }

            }

        }

    }
}

export const createTenantSubscription: AsyncAction<string | undefined | null, boolean> = async ({ state, effects }: Context, tenant_id) => {
    tenant_id = !!tenant_id ? tenant_id : state.tenant.id
    if (tenant_id) {
        return await effects.createTenantSubscription(tenant_id)
    }
    return false
}

export const getBillingUsage: AsyncAction<string, BillingUsageRecordSummary[]> = async ({ effects }: Context, sub_item_id) => {
    return await effects.getBillingUsage(sub_item_id)
}

export const uncancelTenantSubscription: AsyncAction<string | undefined | null, boolean> = async ({ state, effects }: Context, tenant_id) => {
    tenant_id = !!tenant_id ? tenant_id : state.tenant.id
    if (tenant_id) {
        return await effects.uncancelTenantSubscription(tenant_id)
    }
    return false
}

export const getTenantVehicles: AsyncAction<GetTenantVehiclesQueryVariables> = async ({ state, effects }, payload) => {
    state.vehicles.errors = [];
    state.vehicles.loading = true;
    try {
        let { sortKey, sortOrder } = state.vehicles.meta;
        let { items } = await effects.gql.queries.GetTenantVehicles({ ...payload, order_by: { [sortKey]: sortOrder } })
        state.vehicles.items = mapArrayToStateObject(items, 'id');
    } catch (e: any) {
        state.vehicles.errors = handleGraphqQLErrorResponse(e);
    }
    state.vehicles.loading = false;
}
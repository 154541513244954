import React from 'react';


import { useActions, useAppState } from 'state';
import { OrderFragment } from 'state/gql/_types';

import { extractCurrentItemData } from "utils/forms";
import FormDrawer from 'components/forms/FormDrawer';

import { DrawerActionProps } from 'components/layout/page/PageDrawerAction';

import FormSchema from 'components/tenant/order/schemas/select_rental_terms.json';
import Icons from 'components/ui/Icons';
import OrderProcessAction from '../components/OrderProcessAction';
import { OrderActionProps } from './_types';

type FormPayloadType = Pick<OrderFragment,
    | 'rental_period'
    | 'free_miles'
    | 'day_rate'
    | 'mile_rate'
    | 'sub_total'
    | 'discount'
    | 'sales_tax'
    | 'order_total'
>

const initialState: FormPayloadType = {
    rental_period: 0,
    free_miles: 0,
    day_rate: 0,
    mile_rate: 0,
    sub_total: 0,
    discount: 0,
    sales_tax: 0,
    order_total: 0
};

const SelectRentalTermsDrawer: React.FC<DrawerActionProps<OrderFragment>> = ({ currentItem, onClose }) => {

    const { updateItem } = useActions().orders;
    const { loading, errors } = useAppState().orders;

    let currentItemId = null;
    let currentItemData = null;
    if (currentItem) {
        currentItemId = currentItem.id;
        currentItemData = extractCurrentItemData<FormPayloadType>(initialState, currentItem);
    }

    return (
        <>
           <FormDrawer<FormPayloadType, OrderFragment, string>
                autoOpen={false}
                formSchema={FormSchema}
                updateAction={updateItem}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
            />
        </>
    )
}

export const SelectRentalTermsAction: React.FC<OrderActionProps> = ({isValid}) => {

    const currentItem = useAppState(state=>state.orders.currentItem)

    if (!currentItem) return null;

    return (
        <OrderProcessAction<DrawerActionProps<OrderFragment>>
            title='Select Rental Terms'
            action={SelectRentalTermsDrawer}
            icon={Icons.edit}
            props={{currentItem}}
            variant={isValid?"outline":"filled"}
            color={isValid?"gray":"blue"}
        />
    )
}
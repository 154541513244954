import { UserFragment } from "state/gql/_types";
import { Badge, Grid} from '@mantine/core';
import InfoItem from "components/ui/InfoItem";
import { parseUserRoleColor, parseUserStatusColor } from "./UserUtils";
import { formatDate } from "utils/dates";
import { formatAddress } from "utils/string";

export const UserInfo: React.FC<{ user: UserFragment }> = ({ user }) => {
    return (
        <Grid grow gutter="xs">

            <Grid.Col span={6}>
                <InfoItem label="Status">
                    <Badge size="lg" variant="outline" color={parseUserStatusColor(user.status)}>{user.status}</Badge>
                </InfoItem>
            </Grid.Col>

            <Grid.Col span={6}>
                <InfoItem label="Role">
                    <Badge size="lg" variant="outline" color={parseUserRoleColor(user.role)}>{user.role}</Badge>
                </InfoItem>
            </Grid.Col>

            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Name" value={`${user.first_name} ${user.last_name}`} />
            </Grid.Col>

            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Email" value={user.email} />
            </Grid.Col>

            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Phone" value={user.phone} />
            </Grid.Col>

            <Grid.Col span={12}>
                <InfoItem label="Address" value={formatAddress(user.address, user.city, user.state, user.zip)} />
            </Grid.Col>

            <Grid.Col xs={6}>
                <InfoItem label="Created" value={formatDate(user.created_at)} />
            </Grid.Col>
            <Grid.Col xs={6}>
                <InfoItem label="Updated" value={formatDate(user.updated_at)} />
            </Grid.Col>

        </Grid>
    )
}
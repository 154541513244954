import React from 'react';
import { useAppState } from 'state';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

const DynamicVehicleBreadCrumb: React.FC<BreadcrumbComponentProps> = () => {
    const { currentItem } = useAppState().vehicles
    if (!currentItem) return <span>Vehicle</span>;
    return (
        <span>{currentItem.make} {currentItem.model}</span>
    );
}

export default DynamicVehicleBreadCrumb;
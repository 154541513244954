import React from 'react';

import PageDrawerAction, { DrawerActionProps } from 'components/layout/page/PageDrawerAction';

import { extractCurrentItemData } from "utils/forms";

import FormSchema from './NewTenantFormSchema.json';
import { CreateTenantInput, TenantFragment } from 'state/gql/_types';
import FormDrawer from 'components/forms/FormDrawer';
import { useActions, useAppState } from 'state'; 
import Icons from 'components/ui/Icons';

type FormPayloadType = Omit<TenantFragment,
    | 'id'
    | 'created_at'
    | 'updated_at'
    | 'type'
    | 'status'
>
 
const initialState: FormPayloadType = {
    name: '',
    slug: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    vehicle_policy_enabled: false
};

const NewTenantDrawer: React.FC<DrawerActionProps<TenantFragment>> = ({ currentItem, onClose }) => {

    const tenant_id = useAppState(state=>state.profile?.tenant_id)
    const { createItem, updateItem, deleteItem, checkSubdomain } = useActions().tenants;
    const { loading, errors } = useAppState().tenants;

    let currentItemId = null;
    let currentItemData = null;
    if (currentItem) {
        currentItemId = currentItem.id;
        currentItemData = extractCurrentItemData<FormPayloadType>(initialState, currentItem);
    }
    
    const handleCreate = async (payload: CreateTenantInput) => {
        if (tenant_id) {
            return createItem({ ...payload, parent_id: tenant_id })
        }
        return Promise.reject('No Active Tenant Loaded')
    }

    return (
        <>
            <FormDrawer<FormPayloadType, TenantFragment, string>
                autoOpen={false}
                formSchema={FormSchema}
                validators={{
                    domain_available: checkSubdomain
                }}
                createAction={handleCreate}
                updateAction={updateItem}
                deleteAction={deleteItem}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
                redirectPath="/tenants"
                deleteRedirect={true}
            />

        </>
    )
}

const NewTenantAction = () => {
    return (
        <PageDrawerAction<TenantFragment>
            title='New Tenant'
            action={NewTenantDrawer}
            props={{}}
        />
    )
}

export const EditTenantAction = () => {
    const { currentItem } = useAppState().tenants
    return (
        <PageDrawerAction<TenantFragment>
            title='Edit Tenant'
            action={NewTenantDrawer}
            icon={Icons.edit}
            props={{ currentItem }}
            color="dark"
        />
    )
}

export default NewTenantAction;
import { Module } from "state/Module"
import * as Types from "state/gql/_types"

export const ClientModule = new Module<Types.ClientFragment, Types.GetUsersQueryVariables, Types.Users_Insert_Input>({
    sortKey: 'first_name',
    filter: {
        where: {
            role: {
                _eq: Types.User_Roles_Enum.Client
            }
        }
    },
    default: {
        role: Types.User_Roles_Enum.Client
    }
})

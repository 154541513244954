import React from 'react';

import { useActions, useAppState } from 'state';
import { VehicleFragment, Vehicles_Insert_Input } from 'state/gql/_types';

import { DrawerActionProps } from 'components/layout/page/PageDrawerAction';
import FormDrawer from 'components/forms/FormDrawer';

import FormSchema from './schemas/edit-vehicle-details.json';
import { VehicleDrawerActions } from '../TenantVehicleDrawer';

type FormPayloadType = Omit<VehicleFragment,
    | 'id'
    | 'tenant_id'
    | 'created_at'
    | 'updated_at'
    | 'status'
    | 'picture'
    | 'open_orders'
    | 'completed_orders'
    | 'open_reservations'
>

const initialState: FormPayloadType = {
    make: '',
    model: '',
    color: '',
    year: '',
    plate: '',
    vin: '',
    miles: 0,
    allow_reservations: false,
    day_rate: 0,
    mile_rate: 0,
    rate_type: 'day'
};

const CreateVehicleAction: React.FC<DrawerActionProps<VehicleFragment,VehicleDrawerActions>> = ({ onClose }) => {

    const tenant_id = useAppState(state=>state.profile?.tenant_id)
    const { createItem } = useActions().vehicles;
    const { loading, errors } = useAppState().vehicles;

    let currentItemId = null;
    let currentItemData = null;

    const handleCreate = (payload: Vehicles_Insert_Input) => {
        if (tenant_id) {
            return createItem({ ...payload, tenant_id: tenant_id })
        }
        return Promise.reject('No Active Tenant Loaded')
    }

    return (
        <>
            <FormDrawer<FormPayloadType, VehicleFragment,string>
                autoOpen={false}
                formSchema={FormSchema}
                createAction={handleCreate}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
                redirectPath="/vehicles"
                hideDelete={true}
            />

        </>
    )
}

export default CreateVehicleAction
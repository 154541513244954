import React from 'react';
import { useParams } from "react-router-dom";

import Loader from 'components/app/Loader';
import { useActions, useAppState } from 'state';
import PageHeader from 'components/layout/page/PageHeader';
import ModuleErrors from 'components/ui/ModuleErrors';
import { UserInfo } from 'components/root/user/RootUserInfo';
import { EditRootUserAction } from 'components/root/user/RootUserActions';
import { TabDivider } from 'components/ui/Tabs';
import PageLayout from 'components/layout/page/PageLayout';

const User: React.FC = () => {
    const { id } = useParams()
    const { loading, currentItem, errors } = useAppState().users
    const { subscribeToItem, unsubscribeFromItem } = useActions().users

    React.useEffect(() => {
        subscribeToItem({ id });
        return () => {
            unsubscribeFromItem()
        }
    }, [id, subscribeToItem, unsubscribeFromItem])

    if (loading || !currentItem) return <Loader />

    return (
        <PageLayout
            header={<PageHeader
                title={`${currentItem.first_name} ${currentItem.last_name}`}
                renderAction={<EditRootUserAction />}
            />}
        >

            <ModuleErrors errors={errors} />
            <TabDivider label="User Details" />
            <UserInfo user={currentItem} />

        </PageLayout>
    )
}

export default User;
import { Icon, IconProps } from '@iconify/react';
import { Table, createStyles } from '@mantine/core';
import React from 'react';

const useStyles = createStyles((theme, _params) => ({
    table: {
        borderSpacing: '0 4px',
        borderCollapse: 'separate',
        [`& tbody tr td`]: {
            borderBottom: 0,
            padding: 10
        },
        [`& thead tr th`]: {
            borderBottom: 0,
            padding: 10
        }
    },
    thead: {
        [`& tr`]: {
            backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[9],
            borderRadius: theme.radius.sm,
            outline: `1px solid ${theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[9]}`
        }
    },
    tbody: {
        [`& tr`]: {
            backgroundColor: theme.colorScheme === 'light' ? theme.white : theme.colors.dark[6],
            borderRadius: theme.radius.sm
        }
    },
    tr: {
        [`& td:first-of-type`]: {
            borderRadius: '4px 0 0 4px'
        },
        [`& td:last-child`]: {
            borderRadius: '0 4px 4px 0'
        },
        [`& th:first-of-type`]: {
            borderRadius: '4px 0 0 4px'
        },
        [`& th:last-of-type`]: {
            borderRadius: '0 4px 4px 0'
        },
        [`&.selected`]: {
            outline: `1px solid ${theme.colors.green[5]}`
        }
    },
    th: {
        textTransform: 'capitalize',
        [`& a`]: {
            fontSize: theme.fontSizes.xs,
            fontWeight:500
        }
    },
    td: {

    },
    a: {
        color: theme.colorScheme === 'light' ? theme.colors.gray[6] : theme.colors.dark[3],
        lineHeight: 'initial',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        [`&.sorted`]: {
            color: theme.colorScheme === 'light' ? theme.colors.gray[7] : theme.colors.gray[5],
        },
        [`& .sort-icon`]: {
            marginLeft: 3,
            color: theme.colorScheme === 'light' ? theme.colors.gray[4] : theme.colors.dark[6],
        },
        [`&.sorted .sort-icon`]: {
            color: theme.colorScheme === 'light' ? theme.colors.gray[7] : theme.colors.gray[5],
        },
    },
    pagination: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: theme.fontSizes.xs,
        paddingTop: theme.spacing.md
    },
}));

export const DataTableComponent: React.FC = ({ children, ...props }) => {
    const { classes } = useStyles();
    return <Table
        {...props}
        className={classes.table}
        sx={(theme) => ({
            '& tbody tr:has(:hover)': {
                backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[8],
                cursor: 'pointer'
            },
        })}
    >
        {children}
    </Table>
}

export const TableHead: React.FC<React.ComponentPropsWithoutRef<'thead'>> = ({ children, ...props }) => {
    const { classes } = useStyles();
    return <thead {...props} className={classes.thead}>{children}</thead>
}

export const TableBody: React.FC<React.ComponentPropsWithoutRef<'tbody'>> = ({ children, ...props }) => {
    const { classes } = useStyles();
    return <tbody {...props} className={classes.tbody}>{children}</tbody>
}

export const TableRow: React.FC<React.ComponentPropsWithoutRef<'tr'>> = ({ className, children, ...props }) => {
    const { classes } = useStyles();
    return <tr {...props} className={[classes.tr, className].join(' ')}>{children}</tr>
}

export const TableCell: React.FC<React.ComponentPropsWithoutRef<'td'>> = ({ children, ...props }) => {
    const { classes } = useStyles();
    return <td {...props} className={classes.tr}>{children}</td>
}

export const TableHeading: React.FC<React.ComponentPropsWithoutRef<'th'>> = ({ children, ...props }) => {
    const { classes } = useStyles();
    return <th {...props} className={classes.th}>{children}</th>
}

export const SortIconButton: React.FC<React.ComponentPropsWithoutRef<'a'>> = ({ className, children, ...props }) => {
    const { classes } = useStyles();
    return <a {...props} className={[classes.a, className].join(' ')}>{children}</a>
}

export const PaginationContainer: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({ children, ...props }) => {
    const { classes } = useStyles();
    return <div {...props} className={classes.pagination}>{children}</div>
}

export const TableHeaderIcon: React.FC<IconProps> = (props) => {
    return (
        <Icon className="sort-icon" {...props} />
    )
}
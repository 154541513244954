import React from 'react';
import PageHeader from 'components/layout/page/PageHeader';
import PageLayout from 'components/layout/page/PageLayout';
import CreateReservationAction from 'components/tenant/reservations/components/CreateReservationAction';


const TenantReservationCreate: React.FC = () => {

    return (
        <PageLayout
            header={<PageHeader
                title={`New Reservation`}
                //renderAction={<EditTenantUserAction />}
            />}
        >

            <CreateReservationAction /> 

        </PageLayout>
    )
}

export default TenantReservationCreate;
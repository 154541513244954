import React from 'react';
import { PrivacyPolicyContent } from 'components/app/PrivacyPolicyContent';
import PublicShellLayout from 'components/layout/PublicShellLayout';
import PublicPageLayout from 'components/layout/page/PublicPageLayout';

const PrivacyPolicy: React.FC = () => {
    return (
        <PublicShellLayout>

            <PublicPageLayout>
                <PrivacyPolicyContent />
            </PublicPageLayout>

        </PublicShellLayout>
    )
}

export default PrivacyPolicy; 
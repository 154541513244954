import React from "react";
import { NumberInput } from "@mantine/core";
import { GenericFieldProps } from "utils/forms";

export const OrderSubTotalCalculator: React.FC<GenericFieldProps<number>> = ({ id, name, label, onChange, onBlur, value: initialValue, error, required, description, getValues, classNames }) => {

    const [calculatedValue,setValue] = React.useState(0)

    React.useEffect(() => {
        if (!!getValues) {
            let sub_total = parseFloat(getValues.rental_period ) * parseFloat(getValues.day_rate);
            setValue(sub_total)
        } else {
            setValue(0)
        }
    }, [getValues])

    React.useEffect(()=>{
        if((initialValue!==undefined&&initialValue!==null)&&calculatedValue!==initialValue) {
            onChange(calculatedValue)
        }
    },[calculatedValue,initialValue,onChange])

    return (
        <NumberInput
            label={label}
            id={id}
            name={name}
            onBlur={onBlur}
            value={calculatedValue}
            error={error}
            required={required}
            disabled={true}
            description={description}
            classNames={classNames}
            parser={(value) => !!value ? value.replace(/\$\s?|(,*)/g, '') : ''}
            formatter={(value) => !!value && !Number.isNaN(parseFloat(value)) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ '}
            sx={theme=>({
                input: {
                    '&:disabled': {
                        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                        opacity: 1
                    }
                }
            })}
        />
    )

}

export const OrderTotalCalculator: React.FC<GenericFieldProps<number>> = ({ id, name, label, onChange, onBlur, value: initialValue, error, required, description, getValues, classNames }) => {

    const [calculatedValue,setValue] = React.useState(0)

    React.useEffect(() => {        
        if (!!getValues) {
            let sub_total = parseFloat(getValues.sub_total || 0) + parseFloat(getValues.other_fees || 0) - parseFloat(getValues.discount || 0)
            let tax_total = sub_total * parseFloat(getValues.sales_tax);
            let order_total = sub_total + tax_total;
            setValue(order_total)
        } else {
            setValue(0)
        }
    }, [getValues])

    React.useEffect(()=>{        
        if((initialValue!==undefined&&initialValue!==null)&&calculatedValue!==initialValue) {
            onChange(calculatedValue)
        }
    },[calculatedValue,initialValue,onChange])

    return (
        <NumberInput
            label={label}
            id={id}
            name={name}
            onBlur={onBlur}
            value={calculatedValue}
            error={error}
            required={required}
            disabled={true}
            precision={2}
            description={description}
            classNames={classNames}
            parser={(value) => !!value ? value.replace(/\$\s?|(,*)/g, '') : ''}
            formatter={(value) => !!value && !Number.isNaN(parseFloat(value)) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ '}
            sx={theme=>({
                input: {
                    '&:disabled': {
                        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                        opacity: 1
                    }
                }
            })}
        />
    )

}

export const OrderBalanceCalculator: React.FC<GenericFieldProps<number>> = ({ id, name, label, onChange, onBlur, value: initialValue, error, required, description, getValues, classNames }) => {

    const [calculatedValue,setValue] = React.useState(0)

    React.useEffect(() => {      
        console.log(getValues)  
        if (!!getValues) {
            let raw_balance = parseFloat(getValues.total || 0) - parseFloat(getValues.deposit || 0)
            let balance = raw_balance >= 0 ? raw_balance : 0
            setValue(balance)
        } else {
            setValue(0)
        }
    }, [getValues])

    return (
        <NumberInput
            label={label}
            id={id}
            name={name}
            onBlur={onBlur}
            value={calculatedValue}
            error={error}
            required={required}
            disabled={true}
            precision={2}
            description={description}
            classNames={classNames}
            parser={(value) => !!value ? value.replace(/\$\s?|(,*)/g, '') : ''}
            formatter={(value) => !!value && !Number.isNaN(parseFloat(value)) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ '}
            sx={theme=>({
                input: {
                    '&:disabled': {
                        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                        opacity: 1
                    }
                }
            })}
        />
    )

}
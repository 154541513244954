import React from 'react';
import { TermsContent } from 'components/app/TermsContent';
import PublicPageLayout from 'components/layout/page/PublicPageLayout';
import PublicShellLayout from 'components/layout/PublicShellLayout';

const TermsAndConditions: React.FC = () => {
    return (
        <PublicShellLayout>

            <PublicPageLayout>
                <TermsContent />
            </PublicPageLayout>

        </PublicShellLayout>
    )
}

export default TermsAndConditions;
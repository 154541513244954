import { SessionInfoPayload } from "./fetchSession"

export const refreshTokenPayload = async (): Promise<SessionInfoPayload|false> => {

    try {
        let res = await fetch(`${process.env.REACT_APP_AUTH_API_URL||''}/refresh-token`)
        let data = await res.json()
        return data

    } catch (e) {
        console.error(e)
        return false;
    }
    
}
import React from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { FilePondErrorDescription, FilePondFile, FilePondServerConfigProps } from 'filepond';
import FilePondImagePreview from 'filepond-plugin-image-preview';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { InputWrapper } from '@mantine/core';
import { GenericFieldProps } from 'utils/forms';
import { useAppState } from 'state';
import { AppState } from 'state/_types';

registerPlugin(FilePondImagePreview);

const UPLOAD_API_URL = process.env.REACT_APP_UPLOAD_API_URL || ''

const parseHeaders = (headers: Headers): string => {
    let headerStrings: string[] = []
    for (var pair of headers.entries()) {
        headerStrings.push(pair[0] + ': ' + pair[1]);
    }
    return headerStrings.join('\r\n')
}

const generateServerConfig = (schemaId: string, auth: AppState['auth'], tenant: AppState['tenant'], type?: GenericFieldProps['type'], parentId?: string | null): FilePondServerConfigProps['server'] => {

    let assetPath = [
        tenant.parent.slug,
        tenant.slug,
        schemaId,
        parentId,
        type,
    ].reduce<string[]>((paths,path)=>{
        if (path&&path.length) {
            return [...paths, path]
        }
        return paths
    },[])

    return {
        process: (fieldName, file, metadata, load, error, progress, abort) => {

            const formData = new FormData();
            formData.append(fieldName, file, file.name);
            formData.append("user_id", auth.user_id || '');
            formData.append("tenant_id", tenant.id || '');
            formData.append("asset_path", assetPath.join('/'));

            const request = new XMLHttpRequest();
            request.open('POST', `${UPLOAD_API_URL}/process`);

            request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
            };

            request.onload = function () {
                if (request.status >= 200 && request.status < 300) {
                    load(request.responseText);
                    let resultData = JSON.parse(request.responseText);
                    load(resultData.url)
                } else {
                    error('Error Processing Upload');
                }
            };

            request.send(formData);

            return {
                abort: () => {
                    request.abort();
                    abort();
                },
            };

        },
        revert: (source, load, error) => {

            const requestPayload = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    url: source,
                    user_id: auth.user_id,
                    tenant_id: tenant.id,
                    tenant_slug: tenant.slug
                }),
            }

            fetch(`${UPLOAD_API_URL}/revert`, requestPayload)
                .then(() => {
                    load();
                })
                .catch((e) => {
                    error(e);
                });

        },
        load: (source, load, error, progress, abort, headers) => {

            let controller = new AbortController();

            let requestPayload = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    url: source
                }),
                signal: controller.signal
            }

            fetch(`${UPLOAD_API_URL}/load`, requestPayload)
                .then(response => {
                    if (response.body) {

                        let headersString = parseHeaders(response.headers)
                        headers(headersString)

                        const reader = response.body.getReader();

                        const contentLength = parseInt(response.headers.get('Content-Length') || '0')
                        let receivedLength = 0;
                        let chunks: BlobPart[] = []

                        reader.read().then(function processText({ done, value }): any {

                            if (done) {
                                let blob = new Blob(chunks, { type: response.headers.get('Content-Type') || '' });
                                load(blob)
                                return;
                            }

                            if (value) {
                                receivedLength += value.length;
                                progress(true, receivedLength, contentLength);
                                chunks.push(value);
                            }

                            return reader.read().then(processText).catch(e => { error(e) });

                        }).catch(e => { error(e) })

                    } else {
                        error('Error Loading Upload');
                    }
                })
                .catch(e => error(e))

            return {
                abort: () => {
                    controller.abort()
                    abort();
                },
            };

        },
    }
}

type AssetUploadProps = GenericFieldProps & {
    schemaId: string;
    parentId?: string | null;
    allowMultiple?:boolean;
    maxFiles?: number;
}

const AssetUpload: React.FC<AssetUploadProps> = ({ id, schemaId, parentId, type, label, onChange, value, error, required, description, allowMultiple = false, maxFiles = 1 }) => {

    const auth = useAppState(state => state.auth)
    const tenant = useAppState(state => state.tenant)
    const [urls, setUrls] = React.useState<string[]>([])

    const [files, setFiles] = React.useState<any[]>([])

    const initialValueSet = React.useRef(false)

    React.useEffect(() => {
        if (!initialValueSet.current) {
            if (typeof value === 'string' && value.length) {
                if (allowMultiple) {
                    setFiles(value.split(',').map(item=>{
                        return {
                            source: item,
                            options: {
                                type: 'local'
                            }
                        }
                    }))
                    setUrls(value.split(',').map(item=>item))
                } else {
                    setFiles([
                        {
                            source: value,
                            options: {
                                type: 'local'
                            }
                        }
                    ])
                }                
                initialValueSet.current = true;
            }
        }
    }, [value,allowMultiple])

    const onUploaded = (error: FilePondErrorDescription | null, file: FilePondFile) => {
        if (error) {
            console.error('onUploaded', error);
        }
        else if (file.serverId) {
            if (allowMultiple) {
                let newUrls = [...urls,file.serverId]
                setUrls(newUrls)
                onChange(newUrls.join(','))
            } else {
                onChange(file.serverId)
            }
        }
    }

    const onUpdatedFiles = (updatedFiles: FilePondFile[]) => {
        const files = updatedFiles.map((fileItem) => fileItem.file)
        setFiles(files)
        if (!files.length) {
            onChange(null)
            if (allowMultiple) {
                setUrls([])
            }
        }
    }

    let serverConfig = generateServerConfig(schemaId, auth, tenant, type, parentId)

    return (
        <InputWrapper
            id={id}
            label={label}
            description={description}
            error={error}
            required={required}
            sx={(theme) => ({
                marginBottom: '20px',
                '& .filepond--credits': {
                    display: 'none',
                },
                '& .filepond--root': {
                    fontFamily: theme.fontFamily,
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    borderColor: !!error ? theme.colors.red[6] : theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[4],
                    borderWidth: 1,
                    borderStyle: 'dashed',
                    borderRadius: theme.radius.sm,
                    marginBottom: 0

                },
                '& .filepond--drop-label': {
                    display: 'block',
                    padding: '16px 16px 0',
                    height: '68px',
                    minHeight: '68px !important',

                },
                '& .filepond--drop-label.filepond--drop-label label': {
                    padding: 0
                },
                '& .filepond--label-action': {
                    textDecorationColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[6]
                },
                '.filepond--panel-root': {
                    backgroundColor: 'transparent'
                },
                [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                    '& .filepond--label-action-mobile': {
                        border: `1px solid ${theme.colorScheme === 'light' ? theme.colors.gray[4] : theme.colors.dark[5]}`,
                        backgroundColor: theme.colorScheme === 'light' ? theme.white : theme.colors.dark[5],
                        color: theme.colorScheme === 'light' ? theme.black : theme.white,
                        height: '36px',
                        padding: '0 18px',
                        borderRadius: '4px',
                        fontWeight: '600',
                        lineHeight: '32px',
                        fontSize: '14px',
                        display: 'block'
                    },
                    '& .filepond--label-action-desktop': {
                        display: 'none'
                    }
                },
                [`@media (min-width: ${theme.breakpoints.md}px)`]: {
                    '& .filepond--label-action-mobile': {
                        display: 'none'
                    }
                }
            })}
            styles={theme => ({
                error: {
                    fontSize: theme.fontSizes.xs,
                    lineHeight: `${theme.fontSizes.xs + 8}px`,
                    marginTop: 1
                }
            })}
        >
            <FilePond
                name="file"
                allowMultiple={allowMultiple}
                maxFiles={maxFiles}
                server={serverConfig}
                imagePreviewTransparencyIndicator="grid"
                onprocessfile={onUploaded}
                onupdatefiles={onUpdatedFiles}
                allowImagePreview={!allowMultiple}
                files={files}
                labelIdle='<span class="filepond--label-action-desktop">Drag & Drop your files or <span class="filepond--label-action">Browse</span></span> <span class="filepond--label-action-mobile">Select from Device</span></span>'
            />
        </InputWrapper>

    )

}

export default AssetUpload;
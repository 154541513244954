import React from 'react'; 

import { useActions, useAppState } from 'state';
import { ClientFragment, Users_Insert_Input } from 'state/gql/_types';

import { DrawerActionProps } from 'components/layout/page/PageDrawerAction';
import FormDrawer from 'components/forms/FormDrawer';

import FormSchema from './schemas/edit-client-details.json';
import { ClientDrawerActions } from '../TenantClientDrawer';

type FormPayloadType = Omit<ClientFragment,
    | 'id'
    | 'role'
    | 'tenant_id'
    | 'created_at'
    | 'updated_at'
    | 'status'
>

const initialState: FormPayloadType = {
    email: '',
    first_name: '',
    last_name: '',
    address: '',
    country: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    dob: '' 
};

const CreateClientAction: React.FC<DrawerActionProps<ClientFragment,ClientDrawerActions>> = ({ onClose }) => {

    const tenant_id = useAppState(state=>state.profile?.tenant_id)
    const { createItem } = useActions().clients;
    const { checkUserEmail } = useActions().users;
    const { loading, errors } = useAppState().clients;

    let currentItemId = null;
    let currentItemData = null;

    const handleCreate = (payload: Users_Insert_Input) => {
        if (tenant_id) {
            return createItem({ ...payload, tenant_id: tenant_id })
        }
        return Promise.reject('No Active Tenant Loaded')
    }

    return (
        <>
            <FormDrawer<FormPayloadType, ClientFragment, ClientFragment['id']>
                autoOpen={false}
                formSchema={FormSchema}
                validators={{
                    email_available: checkUserEmail
                }}
                createAction={handleCreate}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
                hideDelete={true}
            />

        </>
    )
}

export default CreateClientAction
import React from 'react';
import { NavLink, useParams } from "react-router-dom";

import { useActions, useAppState } from 'state';

import Loader from 'components/app/Loader';
import PageHeader from 'components/layout/page/PageHeader';
import ModuleErrors from 'components/ui/ModuleErrors';
import PageLayout from 'components/layout/page/PageLayout';
import { Button, Card, Container, Text, Stack, MediaQuery, Box } from '@mantine/core';
import Icons from 'components/ui/Icons';
import { Icon } from '@iconify/react';
import TenantOrderTabs from 'components/tenant/order/TenantOrderTabs';
import TenantOrderSummary from 'components/tenant/order/TenantOrderSummary';
import TenantOrderStatus from 'components/tenant/order/TenantOrderStatus';
import { TabDivider } from 'components/ui/Tabs';

const TenantOrderOverview: React.FC = () => {
    const { id } = useParams()
    const { loading, currentItem, errors } = useAppState().orders
    const { subscribeToItem, unsubscribeFromItem, resetModule } = useActions().orders

    React.useEffect(() => {
        subscribeToItem({ id });
        return () => {
            unsubscribeFromItem()
        }
    }, [id, subscribeToItem, unsubscribeFromItem, resetModule])

    if (loading || !currentItem) return <Loader />

    return (
        <PageLayout
            showFooterNav={false}
            header={
                <PageHeader
                    title={`Order #${currentItem.order_number} - ${currentItem.client?.first_name} ${currentItem.client?.last_name}`}
                    renderAction={
                        <React.Fragment>
                            {currentItem.status === 'pending' || currentItem.status === 'open' ? (
                                <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                                    <Button
                                        to={`/orders/${currentItem.id}/process`}
                                        component={NavLink}
                                        rightIcon={<Icon icon={Icons.forward} color="white" width={18} />}
                                        color="blue"
                                        size="sm"
                                    >
                                        <Text>Continue Rental Process</Text>
                                    </Button>
                                </MediaQuery>
                            ) : null}
                        </React.Fragment>
                    }
                />
            }
        >
            <ModuleErrors errors={errors} />

            <TenantOrderTabs />

            <TenantOrderStatus currentItem={currentItem} />
            {!!currentItem.memo && currentItem.memo.length && (
                <Box mt="md">
                    <TabDivider label="Memo / Notes" />
                    <Card
                        shadow="sm"
                        p="sm">
                            {currentItem.memo}
                    </Card>

                </Box>
            )}
            <TenantOrderSummary currentItem={currentItem} />

            {currentItem.status === 'pending' || currentItem.status === 'open' ? (
                <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                    <Card
                        sx={theme => ({
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: theme.colorScheme === 'light' ? theme.white : theme.colors.dark[9],
                            [theme.fn.smallerThan('sm')]: {
                                borderRadius: 0,
                            }
                        })}
                    >
                        <Container
                            size="xs"
                            px={0}
                            sx={theme => ({
                                [theme.fn.smallerThan('sm')]: {
                                    maxWidth: '100%'
                                }
                            })}
                        >
                            <Stack spacing="xs">
                                <Button
                                    to={`/orders/${currentItem.id}/process`}
                                    component={NavLink}
                                    rightIcon={<Icon icon={Icons.forward} color="white" width={18} />}
                                    color="blue"
                                    size="xl"
                                >
                                    <Text>Continue Rental Process</Text>
                                </Button>
                            </Stack>
                        </Container>
                    </Card>
                </MediaQuery>
            ) : null}

        </PageLayout>
    )
}

export default TenantOrderOverview;
import { TextInput } from "@mantine/core";
import React from "react";
import { getDurationDays, getDurationDaysNumber } from "utils/dates";
import { GenericFieldProps } from "utils/forms";

const RentalPeriod: React.FC<GenericFieldProps<number>> = ({ id, name, label, onChange, onBlur, value: initialValue = 0, error, required, description, getValues, setValues, setValueKeys, classNames }) => {

    const [value,setValue] = React.useState(0)
    const [valueDisplay,setValueDisplay] = React.useState(`${initialValue} days`)

    React.useEffect(() => {
        if (!!getValues && !!getValues.start_date && !!getValues.end_date) {
            let duration = getDurationDaysNumber(getValues.start_date, getValues.end_date)
            let durationDisplay = getDurationDays(getValues.start_date, getValues.end_date, true)
            if (duration) {
                setValue(duration)
                setValueDisplay(durationDisplay)
            }
            
        } else {
            if (valueDisplay!=='0 days') {                
                setValueDisplay('0 days')
            }
        }
    }, [valueDisplay,getValues])

    React.useEffect(()=>{
        if(value!==initialValue) {
            onChange(value)
        }
    },[value,initialValue,onChange])

    return (
        <TextInput
            type="text"
            label={label}
            id={id}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={valueDisplay||'0'}
            error={error}
            required={required}
            disabled={true}
            description={description}
            classNames={classNames}
            sx={theme=>({
                input: {
                    '&:disabled': {
                        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                        opacity: 1
                    }
                }
            })}
        />
    )

}

export default RentalPeriod
import React from "react";
import { Icon } from "@iconify/react";
import { Button, Text } from "@mantine/core";
import Icons from "components/ui/Icons";
import { useActions, useAppState } from "state";
import { OrderActionProps } from "./_types";
import { useSearchParams } from "react-router-dom";

export const SignContractAction: React.FC<OrderActionProps> = ({ isValid }) => {

    let [searchParams, setSearchParams] = useSearchParams();
    const currentItem = useAppState(state => state.orders.currentItem)
    const { generateContractLink } = useActions().orders
    const [loadingLink, setLoadingLink] = React.useState(false)
    const [loadingCallback, setLoadingCallback] = React.useState(false)
    const [linkExpired, setLinkExpired] = React.useState(false)

    const handleGenerateClick = async () => {
        if (currentItem) {
            generateContractLink({
                object: {
                    order_id: currentItem.id
                }
            })
            setLoadingLink(true)
        }
    }

    React.useEffect(() => {
        if (loadingLink && currentItem?.contract_link) {
            setTimeout(()=>{
                setLoadingLink(false)
                setLinkExpired(false)
            },2000)
        }
    }, [loadingLink, currentItem?.contract_link])

    React.useEffect(() => {
        if (loadingCallback && currentItem?.contract_signed) {
            setLoadingCallback(false)
        }
    }, [loadingCallback, loadingLink, currentItem?.contract_signed])

    React.useEffect(() => {
        if (currentItem) {
            let searchParamEvent = searchParams.get('document_id')
            if (searchParamEvent) {
                // switch (searchParamEvent) {
                //     case 'access_code_failed':
                //     case 'cancel':
                //     case 'decline':
                //     case 'exception':
                //     case 'session_timeout':
                //     case 'ttl_expired':
                //         setLinkExpired(true)
                //         break;
                //     case 'document_id':
                //         setLoadingCallback(true)
                //         break;
                // }
                setLoadingCallback(true)
                setSearchParams([])
            }
        }
    }, [currentItem, searchParams, setSearchParams])

    if (!currentItem) return null;

    if (!currentItem.contract_link||linkExpired) {
        return (
            <Button
                onClick={handleGenerateClick}
                rightIcon={!loadingLink?<Icon className="action-icon" icon={Icons.edit} color={isValid ? "gray" : "blue"} width={18} />:null}
                variant={isValid ? "outline" : "filled"}
                color={isValid ? "gray" : "blue"}
                loading={loadingLink}
                sx={theme => ({
                    '& .action-icon': {
                        color: `${isValid ? theme.colors[isValid ? "gray" : "blue"][7] : "white"} !important`
                    }
                })}
            >
                <Text>{loadingLink ? 'Generating Signing Link...' : 'Generate Signing Link'}</Text>
            </Button>
        )
    }

    return (
        <Button
            component="a"
            disabled={currentItem.contract_signed === true}
            href={currentItem.contract_signed ? '#' : currentItem.contract_link}
            rightIcon={!loadingCallback?<Icon className="action-icon" icon={Icons.edit} color={isValid ? "gray" : "blue"} width={18} />:null}
            variant={isValid ? "outline" : "filled"}
            color={isValid ? "gray" : "blue"}
            loading={loadingCallback}
            sx={theme => ({
                '& .action-icon': {
                    color: `${isValid ? theme.colors[isValid ? "gray" : "blue"][7] : "white"} !important`
                }
            })}
        >
            <Text>{loadingCallback ? 'Verifying Signature...' : 'Sign Contract'}</Text>
        </Button>
    )

}
import React from 'react';


import { useActions, useAppState } from 'state';
import { ClientFragment } from 'state/gql/_types';

import { extractCurrentItemData } from "utils/forms";
import FormDrawer from 'components/forms/FormDrawer';

import { DrawerActionProps } from 'components/layout/page/PageDrawerAction';

import FormSchema from 'components/tenant/order/schemas/verify_license.json';
import Icons from 'components/ui/Icons';
import OrderProcessAction from '../components/OrderProcessAction';
import { OrderActionProps } from './_types';

type FormPayloadType = Pick<ClientFragment,
    | 'license_number'
    | 'dob'
    | 'license_state'
    | 'license_exp_date'
    | 'license_image_front'
    | 'license_image_back'

>

const initialState: FormPayloadType = {
    license_number: '',
    dob: '',
    license_exp_date: '',
    license_state: '',
    license_image_front: '',
    license_image_back: ''
};

const VerifyLicenseDrawer: React.FC<DrawerActionProps<ClientFragment>> = ({ currentItem, onClose }) => {

    const { updateItem } = useActions().clients;
    const { loading, errors } = useAppState().orders;

    let currentItemId = null;
    let currentItemData = null;
    if (currentItem) {
        currentItemId = currentItem.id;
        currentItemData = extractCurrentItemData<FormPayloadType>(initialState, currentItem);
    }

    return (
        <>
            <FormDrawer<FormPayloadType, ClientFragment, string>
                autoOpen={false}
                formSchema={FormSchema}
                updateAction={updateItem}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
            />
        </>
    )
}

export const VerifyLicenseAction: React.FC<OrderActionProps> = ({isValid}) => {

    const currentOrder = useAppState(state=>state.orders.currentItem)
    // const currentClient = useAppState(state=>state.clients.currentItem)
    // const { loadItem } = useActions().clients

    // React.useEffect(()=>{
    //     if (currentOrder?.client_id) {
    //         loadItem({id: currentOrder.client_id})
    //     }
    // },[loadItem, currentOrder])

    if (!currentOrder||!currentOrder.client) return null;

    return (
        <OrderProcessAction<DrawerActionProps<ClientFragment>>
            title='Update Client License'
            action={VerifyLicenseDrawer}
            icon={Icons.edit}
            props={{currentItem:currentOrder.client}}
            variant={isValid?"outline":"filled"}
            color={isValid?"gray":"blue"}
        />
    )
}
import { createMachine, Event } from 'xstate';

interface MachineContext {
    step: string;
}

type MachineEvents =
    | { type: 'select-dates' }
    | { type: 'select-vehicle' }
    | { type: 'client-details' }
    | { type: 'accept-terms' }
    | { type: 'review' }
    | { type: 'complete' }
    | { type: 'error' }
    | { type: 'next' }

export type MachineEvent = Event<MachineEvents>

const ReservationMachine = createMachine<MachineContext, MachineEvents>({
    id: "reservation",
    initial: "select-dates",
    context: {
      step: "select-dates",
    },
    states: {
      "select-dates": {
        on: {
          "next": "select-vehicle",
          "select-vehicle": "select-vehicle",
          "client-details": "client-details",
          "accept-terms": "accept-terms",
          "review": "review",
        },
      },
      "select-vehicle": {
        on: {
          "select-dates": "select-dates",
          "next": "client-details",
          "client-details": "client-details",
          "accept-terms": "accept-terms",
          "review": "review",
        },
      },
      "client-details": {
        on: {
          "select-dates": "select-dates",
          "select-vehicle": "select-vehicle",
          "next": "accept-terms",
          "accept-terms": "accept-terms",
          "review": "review",
        },
      },
      "accept-terms": {
        on: {
          "select-dates": "select-dates",
          "select-vehicle": "select-vehicle",
          "client-details": "client-details",
          "review": "review",
          "next": "review"
        },
      },
      "review": {
        on: {
          "select-dates": "select-dates",
          "select-vehicle": "select-vehicle",
          "client-details": "client-details",
          "accept-terms": "accept-terms",
          "next": "complete",
          "complete": "complete",
          "error": "error",
        },
      },
      "complete": {
        "type": "final",
      },
      "error": {},
    },
  })

export default ReservationMachine
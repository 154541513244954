import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Card, Grid, Text, Badge, Group, MediaQuery, Button } from '@mantine/core';

import { useActions, useAppState } from 'state';
import { Reservations_Bool_Exp, ReservationFragment, Reservation_Status_Enum } from 'state/gql/_types';

import PageHeader from 'components/layout/page/PageHeader';
import ModuleErrors from 'components/ui/ModuleErrors';
import { PageFilters, usePageFilters } from 'components/ui/PageFilters';
import PageLayout from 'components/layout/page/PageLayout';
import { EmptyPageComponent } from 'components/ui/Misc';
import LoadMoreButton from 'components/ui/LoadMoreButton';
import { parseReservationStatusColor } from 'components/tenant/reservations/ReservationUtils';
import DataTable from 'components/ui/DataTable';
import { SetMetaInput } from 'state/Module';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'utils/dates';
import { Icon } from '@iconify/react';
import Icons from 'components/ui/Icons';
import ReservationsTimeLine from 'components/tenant/reservations/components/ReservationsTimeLine';

const TenantReservations: React.FC = () => {

    const tenant_id = useAppState(state => state.profile?.tenant_id)
    const navigation = useNavigate()
    const { itemsList, errors, meta, loadingMore, loading, meta: { limit, offset, sortKey, sortOrder } } = useAppState(state => state.reservations)
    const { subscribeToItems, unsubscribeFromItems, getMoreItems, resetModule, setMeta } = useActions().reservations
    const { view } = useAppState(state => state.ui)

    const {
        filters,
        filterParams
    } = usePageFilters<Reservations_Bool_Exp['_and'], ReservationFragment>({
        baseFilters: [{ tenant_id: { _eq: tenant_id } }],
        clickFilters: [
            {
                _or: [
                    { status: { _eq: Reservation_Status_Enum.New } },
                    { status: { _eq: Reservation_Status_Enum.Confirmed } },
                    { status: { _eq: Reservation_Status_Enum.Active } }
                ]
            }
        ],
        searchFilterFields: ['email', 'first_name', 'last_name', 'phone', 'address', 'city'],
        searchFilterRelations: {
            tenant: ['name'],
            vehicle: ['year', 'make', 'model', 'plate', 'color']
        },
        clickFilterField: 'status',
        clickFiltersDisableDefaultAll: true,
        clickFilterValues: {
            new: { label: 'New', filter: [{ status: { _eq: Reservation_Status_Enum.New } }] },
            all: { label: 'All', filter: [] }
        }
    })

    React.useEffect(() => {
        subscribeToItems(filters);
        return () => {
            unsubscribeFromItems()
        }
    }, [filters, limit, offset, sortKey, sortOrder, subscribeToItems, unsubscribeFromItems, resetModule])

    const columnHelper = createColumnHelper<ReservationFragment>()

    const columns = React.useMemo(() => [
        columnHelper.accessor('id', {
            header: 'ID'
        }),
        columnHelper.accessor('reservation_number', {
            header: '#'
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: row => <Badge variant="outline" color={parseReservationStatusColor(row.getValue())}>{row.getValue()}</Badge>
        }),
        columnHelper.accessor(row => row.vehicle.year, {
            header: 'Year'
        }),
        columnHelper.accessor(row => row.vehicle.make, {
            header: 'Make'
        }),
        columnHelper.accessor(row => row.vehicle.model, {
            header: 'Model'
        }),
        columnHelper.accessor(row => row.vehicle.color, {
            header: 'Color'
        }),
        columnHelper.accessor('first_name', {
            header: 'First Name'
        }),
        columnHelper.accessor('last_name', {
            header: 'Last Name'
        }),
        columnHelper.accessor('start_date', {
            header: 'Start Date',
            cell: row => formatDate(row.getValue(), 'MM/DD/YY')
        }),
        columnHelper.accessor('end_date', {
            header: 'End Date',
            cell: row => formatDate(row.getValue(), 'MM/DD/YY')
        }),
    ], [columnHelper])

    const initialState = {
        columnVisibility: {
            id: false
        }
    }

    const handleMetaChange = (meta: SetMetaInput<ReservationFragment>) => {
        setMeta(meta)
    }

    const handleSelect = (id: string) => {
        navigation(`/reservations/${id}`)
    }

    const renderList = () => {
        return (
            <DataTable<ReservationFragment>
                columns={columns}
                data={itemsList}
                loading={loading}
                meta={meta}
                onMetaChange={handleMetaChange}
                initialState={initialState}
                onSelectItem={handleSelect}
            />
        )
    }

    const renderCards = () => {
        return (
            <React.Fragment>
                <Grid>
                    {itemsList.map((i, k) => {
                        return (
                            <Grid.Col md={6} key={k}>
                                <Card
                                    component={Link}
                                    to={`/users/${i.id}`}
                                    shadow="xs"
                                    sx={(theme) => ({
                                        '&:hover': {
                                            backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[9],
                                        },
                                    })}
                                >
                                    <Group position='apart'>
                                        <Text weight={500}>#{i.reservation_number}</Text>
                                        <Badge variant="outline" color={parseReservationStatusColor(i.status)}>{i.status}</Badge>
                                    </Group>
                                    <Text size="sm">{i.email}</Text>
                                </Card>
                            </Grid.Col>
                        )
                    })}
                </Grid>
                <LoadMoreButton meta={meta} onLoadMore={getMoreItems} loading={loadingMore} />
            </React.Fragment>
        )
    }

    return (
        <PageLayout
            header={<PageHeader
                title='Reservations'
                renderAction={(
                    <React.Fragment>
                        <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
                            <Button
                                onClick={() => navigation(`/reservations/new`)}
                                rightIcon={<Icon icon={Icons.add} color="white" width={18} />}
                                color="blue"
                                variant="filled"
                                size="sm"
                            >
                                <Text>New Reservation</Text>
                            </Button>
                        </MediaQuery>

                        <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                            <Button
                                onClick={() => navigation(`/reservations/new`)}
                                color="blue"
                                variant="filled"
                                size="sm"
                                px={8}
                            >
                                <Icon icon={Icons.add} color="white" width={19} />
                            </Button>
                        </MediaQuery>
                    </React.Fragment>
                )}
            />}
        >

            <ReservationsTimeLine reservations={itemsList} />

            <PageFilters options={filterParams} />

            <ModuleErrors errors={errors} />

            {itemsList.length ? (<> {view === 'list' ? renderList() : renderCards()} </>) : (<EmptyPageComponent label='Reservations' />)}

        </PageLayout>
    )
}

export default TenantReservations;
import { gql } from 'overmind-graphql';
import { Query } from 'overmind-graphql';
import {
    GetClientsQuery,
    GetClientsQueryVariables,
    GetClientQuery,
    GetClientQueryVariables,
    CreateClientMutation,
    CreateClientMutationVariables,
    UpdateClientMutation,
    UpdateClientMutationVariables,
    DeleteClientMutation,
    DeleteClientMutationVariables,
    SyncGetClientsMetaSubscription,
    SyncGetClientsMetaSubscriptionVariables,
    SyncGetClientsSubscription,
    SyncGetClientsSubscriptionVariables,
    SyncGetClientSubscription,
    SyncGetClientSubscriptionVariables,
    GetOrderStatusQuery,
    GetOrderStatusQueryVariables,
    GetOrdersQuery,
    GetOrdersQueryVariables,
    GetOrderQuery,
    GetOrderQueryVariables,
    TriggerOrderEventMutation,
    TriggerOrderEventMutationVariables,
    GenerateContractMutation,
    GenerateContractMutationVariables,
    GenerateContractLinkMutation,
    GenerateContractLinkMutationVariables,
    CreateOrderMutation,
    CreateOrderMutationVariables,
    UpdateOrderMutation,
    UpdateOrderMutationVariables,
    DeleteOrderMutation,
    DeleteOrderMutationVariables,
    SyncGetOrdersMetaSubscription,
    SyncGetOrdersMetaSubscriptionVariables,
    SyncGetOrdersSubscription,
    SyncGetOrdersSubscriptionVariables,
    SyncGetOrderSubscription,
    SyncGetOrderSubscriptionVariables,
    GetReservationsQuery,
    GetReservationsQueryVariables,
    CheckReservationEmailQuery,
    CheckReservationEmailQueryVariables,
    GetReservationQuery,
    GetReservationQueryVariables,
    CreateReservationMutation,
    CreateReservationMutationVariables,
    UpdateReservationMutation,
    UpdateReservationMutationVariables,
    DeleteReservationMutation,
    DeleteReservationMutationVariables,
    SyncGetReservationsMetaSubscription,
    SyncGetReservationsMetaSubscriptionVariables,
    SyncGetReservationsSubscription,
    SyncGetReservationsSubscriptionVariables,
    SyncGetReservationSubscription,
    SyncGetReservationSubscriptionVariables,
    CheckSubdomainQuery,
    CheckSubdomainQueryVariables,
    GetTenantsQuery,
    GetTenantsQueryVariables,
    GetTenantBySlugQuery,
    GetTenantBySlugQueryVariables,
    GetTenantQuery,
    GetTenantQueryVariables,
    CreateTenantMutation,
    CreateTenantMutationVariables,
    UpdateTenantMutation,
    UpdateTenantMutationVariables,
    DeleteTenantMutation,
    DeleteTenantMutationVariables,
    SyncGetTenantsMetaSubscription,
    SyncGetTenantsMetaSubscriptionVariables,
    SyncGetTenantsSubscription,
    SyncGetTenantsSubscriptionVariables,
    SyncGetTenantSubscription,
    SyncGetTenantSubscriptionVariables,
    GetTenantBillingQuery,
    GetTenantBillingQueryVariables,
    GetTenantVehiclesQuery,
    GetTenantVehiclesQueryVariables,
    GetUsersQuery,
    GetUsersQueryVariables,
    CheckUserEmailQuery,
    CheckUserEmailQueryVariables,
    GetUserQuery,
    GetUserQueryVariables,
    CreateUserMutation,
    CreateUserMutationVariables,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    SyncGetUsersMetaSubscription,
    SyncGetUsersMetaSubscriptionVariables,
    SyncGetUsersSubscription,
    SyncGetUsersSubscriptionVariables,
    SyncGetUserSubscription,
    SyncGetUserSubscriptionVariables,
    GetVehiclesQuery,
    GetVehiclesQueryVariables,
    GetVehicleQuery,
    GetVehicleQueryVariables,
    CreateVehicleMutation,
    CreateVehicleMutationVariables,
    UpdateVehicleMutation,
    UpdateVehicleMutationVariables,
    DeleteVehicleMutation,
    DeleteVehicleMutationVariables,
    SyncGetVehiclesMetaSubscription,
    SyncGetVehiclesMetaSubscriptionVariables,
    SyncGetVehiclesSubscription,
    SyncGetVehiclesSubscriptionVariables,
    SyncGetVehicleSubscription,
    SyncGetVehicleSubscriptionVariables
} from './_types';

/* Fragments */
const Client = gql` fragment Client on users { id tenant_id status role email first_name last_name address city state zip phone address country city state zip phone picture dob license_number license_state license_exp_date license_image_front license_image_back insurance_name insurance_number insurance_start_date insurance_end_date insurance_image created_at updated_at }`;
const Order = gql` fragment Order on orders { id order_number tenant_id agent_id client_id vehicle_id state status step created_at updated_at start_date end_date start_time end_time extended_end_date contract_signed rental_period free_miles miles_in miles_out day_rate discount mile_rate sales_tax sub_total order_total fuel_in fuel_out contract_id contract_link contract_preview delivery_geo delivery_images delivery_notes delivery_videos pickup_geo pickup_images pickup_notes pickup_videos policy_type terms_accepted insurance_image license_images memo other_fees deposit tenant { name } agent { email first_name last_name } client { id tenant_id status role email first_name last_name address country city state zip phone picture dob license_number license_state license_exp_date license_image_front license_image_back insurance_name insurance_number insurance_start_date insurance_end_date insurance_image created_at updated_at } vehicle { id tenant_id status make model year color plate vin miles picture insurance_end_date insurance_name insurance_number insurance_start_date created_at updated_at allow_reservations day_rate mile_rate rate_type } }`;
const Reservation = gql` fragment Reservation on reservations { id status tenant_id vehicle_id client_id agent_id order_id email reservation_number first_name last_name phone dob address city state zip country start_date end_date start_time end_time rental_period free_miles day_rate mile_rate discount sales_tax sub_total total terms_accepted created_at updated_at memo other_fees deposit tenant { id slug name address city state zip phone } client { id tenant_id status role email first_name last_name address country city state zip phone picture dob license_number license_state license_exp_date license_image_front license_image_back insurance_name insurance_number insurance_start_date insurance_end_date insurance_image created_at updated_at } agent { id tenant_id status role email first_name last_name } vehicle { id tenant_id status make model year color plate vin miles picture insurance_end_date insurance_name insurance_number insurance_start_date created_at updated_at allow_reservations day_rate mile_rate rate_type } }`;
const Tenant = gql` fragment Tenant on tenants { id parent_id type status name slug address city state zip phone vehicle_policy_enabled latest_terms_accepted stripe_id subscription_id billing_email created_at updated_at stats { dealers admins managers agents clients total_vehicles unavailabe_vehicles available_vehicles rented_vehicles total_orders pending_orders open_orders completed_orders } }`;
const TenantBilling = gql` fragment TenantBilling on tenants { billing { id email balance delinquent subscriptions { data { id description billingCycleAnchor billingThresholds { amountGte resetBillingCycleAnchor } canceledAt cancelAt cancelAtPeriodEnd currentPeriodEnd currentPeriodStart daysUntilDue defaultPaymentMethod { id } endedAt items { data { created id quantity price { active billingScheme created id nickname type unitAmount unitAmountDecimal product { active description id images name type unitLabel } } plan { usageType } } } startDate status trialEnd trialStart } } paymentMethods { data { id type card { brand expMonth expYear last4 } } } } }`;
const User = gql` fragment User on users { id tenant_id status role email first_name last_name address city state zip phone created_at updated_at }`;
const Vehicle = gql` fragment Vehicle on vehicles { id tenant_id status make model year color plate vin miles picture insurance_end_date insurance_name insurance_number insurance_start_date created_at updated_at allow_reservations day_rate mile_rate rate_type open_reservations: reservations( where: {_or: [{status: {_eq: new}}, {status: {_eq: unconfirmed}}, {status: {_eq: confirmed}}, {status: {_eq: active}}]} ) { id status tenant_id vehicle_id client_id agent_id order_id email reservation_number first_name last_name start_date end_date start_time end_time rental_period created_at updated_at memo tenant { id slug name address city state zip phone } client { id tenant_id status email first_name last_name country city state phone } agent { id tenant_id status role email first_name last_name } } open_orders: orders( where: {_or: [{status: {_eq: pending}}, {status: {_eq: open}}]} ) { id status state step order_number rental_period start_date end_date created_at client { id first_name last_name email } } completed_orders: orders( where: {_or: [{status: {_eq: completed}}, {status: {_eq: archived}}]} ) { id status state step order_number rental_period start_date end_date created_at client { id first_name last_name email } } }`;

/* Queries & Mutations */
const GetClients: Query<GetClientsQuery, GetClientsQueryVariables> = gql` query getClients($distinct_on: [users_select_column!], $limit: Int, $offset: Int, $order_by: [users_order_by!], $where: users_bool_exp) { meta: users_aggregate(where: $where) { aggregate { count } } items: users( distinct_on: $distinct_on limit: $limit offset: $offset order_by: $order_by where: $where ) { ...Client } } ${Client}`;
const GetClient: Query<GetClientQuery, GetClientQueryVariables> = gql` query getClient($id: uuid!) { item: users_by_pk(id: $id) { ...Client } } ${Client}`;
const CreateClient: Query<CreateClientMutation, CreateClientMutationVariables> = gql` mutation createClient($object: users_insert_input!) { item: insert_users_one(object: $object) { id } }`;
const UpdateClient: Query<UpdateClientMutation, UpdateClientMutationVariables> = gql` mutation updateClient($id: uuid!, $_set: users_set_input!) { item: update_users_by_pk(pk_columns: {id: $id}, _set: $_set) { ...Client } } ${Client}`;
const DeleteClient: Query<DeleteClientMutation, DeleteClientMutationVariables> = gql` mutation deleteClient($id: uuid!) { item: delete_users_by_pk(id: $id) { id } }`;
const SyncGetClientsMeta: Query<SyncGetClientsMetaSubscription, SyncGetClientsMetaSubscriptionVariables> = gql` subscription syncGetClientsMeta($where: users_bool_exp) { meta: users_aggregate(where: $where) { aggregate { count } } }`;
const SyncGetClients: Query<SyncGetClientsSubscription, SyncGetClientsSubscriptionVariables> = gql` subscription syncGetClients($distinct_on: [users_select_column!], $limit: Int, $offset: Int, $order_by: [users_order_by!], $where: users_bool_exp) { items: users( distinct_on: $distinct_on limit: $limit offset: $offset order_by: $order_by where: $where ) { ...Client } } ${Client}`;
const SyncGetClient: Query<SyncGetClientSubscription, SyncGetClientSubscriptionVariables> = gql` subscription syncGetClient($id: uuid!) { item: users_by_pk(id: $id) { ...Client } } ${Client}`;
const GetOrderStatus: Query<GetOrderStatusQuery, GetOrderStatusQueryVariables> = gql` query getOrderStatus($id: String!) { status: get_order_status(id: $id) { state } }`;
const GetOrders: Query<GetOrdersQuery, GetOrdersQueryVariables> = gql` query getOrders($distinct_on: [orders_select_column!], $limit: Int, $offset: Int, $order_by: [orders_order_by!], $where: orders_bool_exp) { meta: orders_aggregate(where: $where) { aggregate { count } } items: orders( distinct_on: $distinct_on limit: $limit offset: $offset order_by: $order_by where: $where ) { ...Order } } ${Order}`;
const GetOrder: Query<GetOrderQuery, GetOrderQueryVariables> = gql` query getOrder($id: uuid!) { item: orders_by_pk(id: $id) { ...Order } } ${Order}`;
const TriggerOrderEvent: Query<TriggerOrderEventMutation, TriggerOrderEventMutationVariables> = gql` mutation triggerOrderEvent($event: String!, $order_id: String!) { order: trigger_order_event(object: {event: $event, order_id: $order_id}) { id } }`;
const GenerateContract: Query<GenerateContractMutation, GenerateContractMutationVariables> = gql` mutation generateContract($object: GenerateContractInput!) { order: generate_contract(object: $object) { id } }`;
const GenerateContractLink: Query<GenerateContractLinkMutation, GenerateContractLinkMutationVariables> = gql` mutation generateContractLink($object: GenerateContractInput!) { order: generate_contract_link(object: $object) { id } }`;
const CreateOrder: Query<CreateOrderMutation, CreateOrderMutationVariables> = gql` mutation createOrder($object: orders_insert_input!) { item: insert_orders_one(object: $object) { id } }`;
const UpdateOrder: Query<UpdateOrderMutation, UpdateOrderMutationVariables> = gql` mutation updateOrder($id: uuid!, $_set: orders_set_input!) { item: update_orders_by_pk(pk_columns: {id: $id}, _set: $_set) { ...Order } } ${Order}`;
const DeleteOrder: Query<DeleteOrderMutation, DeleteOrderMutationVariables> = gql` mutation deleteOrder($id: uuid!) { item: delete_orders_by_pk(id: $id) { id } }`;
const SyncGetOrdersMeta: Query<SyncGetOrdersMetaSubscription, SyncGetOrdersMetaSubscriptionVariables> = gql` subscription syncGetOrdersMeta($where: orders_bool_exp) { meta: orders_aggregate(where: $where) { aggregate { count } } }`;
const SyncGetOrders: Query<SyncGetOrdersSubscription, SyncGetOrdersSubscriptionVariables> = gql` subscription syncGetOrders($distinct_on: [orders_select_column!], $limit: Int, $offset: Int, $order_by: [orders_order_by!], $where: orders_bool_exp) { items: orders( distinct_on: $distinct_on limit: $limit offset: $offset order_by: $order_by where: $where ) { ...Order } } ${Order}`;
const SyncGetOrder: Query<SyncGetOrderSubscription, SyncGetOrderSubscriptionVariables> = gql` subscription syncGetOrder($id: uuid!) { item: orders_by_pk(id: $id) { ...Order } } ${Order}`;
const GetReservations: Query<GetReservationsQuery, GetReservationsQueryVariables> = gql` query getReservations($distinct_on: [reservations_select_column!], $limit: Int, $offset: Int, $order_by: [reservations_order_by!], $where: reservations_bool_exp) { meta: reservations_aggregate(where: $where) { aggregate { count } } items: reservations( distinct_on: $distinct_on limit: $limit offset: $offset order_by: $order_by where: $where ) { ...Reservation } } ${Reservation}`;
const CheckReservationEmail: Query<CheckReservationEmailQuery, CheckReservationEmailQueryVariables> = gql` query checkReservationEmail($email: String!) { items: reservations(where: {email: {_eq: $email}}) { ...Reservation } } ${Reservation}`;
const GetReservation: Query<GetReservationQuery, GetReservationQueryVariables> = gql` query getReservation($id: uuid!) { item: reservations_by_pk(id: $id) { ...Reservation } } ${Reservation}`;
const CreateReservation: Query<CreateReservationMutation, CreateReservationMutationVariables> = gql` mutation createReservation($object: reservations_insert_input!) { item: insert_reservations_one(object: $object) { id } }`;
const UpdateReservation: Query<UpdateReservationMutation, UpdateReservationMutationVariables> = gql` mutation updateReservation($id: uuid!, $_set: reservations_set_input!) { item: update_reservations_by_pk(pk_columns: {id: $id}, _set: $_set) { ...Reservation } } ${Reservation}`;
const DeleteReservation: Query<DeleteReservationMutation, DeleteReservationMutationVariables> = gql` mutation deleteReservation($id: uuid!) { item: delete_reservations_by_pk(id: $id) { id } }`;
const SyncGetReservationsMeta: Query<SyncGetReservationsMetaSubscription, SyncGetReservationsMetaSubscriptionVariables> = gql` subscription syncGetReservationsMeta($where: reservations_bool_exp) { meta: reservations_aggregate(where: $where) { aggregate { count } } }`;
const SyncGetReservations: Query<SyncGetReservationsSubscription, SyncGetReservationsSubscriptionVariables> = gql` subscription syncGetReservations($distinct_on: [reservations_select_column!], $limit: Int, $offset: Int, $order_by: [reservations_order_by!], $where: reservations_bool_exp) { items: reservations( distinct_on: $distinct_on limit: $limit offset: $offset order_by: $order_by where: $where ) { ...Reservation } } ${Reservation}`;
const SyncGetReservation: Query<SyncGetReservationSubscription, SyncGetReservationSubscriptionVariables> = gql` subscription syncGetReservation($id: uuid!) { item: reservations_by_pk(id: $id) { ...Reservation } } ${Reservation}`;
const CheckSubdomain: Query<CheckSubdomainQuery, CheckSubdomainQueryVariables> = gql` query checkSubdomain($slug: String!) { subdomain: check_subdomain_by_slug(slug: $slug) { taken } }`;
const GetTenants: Query<GetTenantsQuery, GetTenantsQueryVariables> = gql` query getTenants($distinct_on: [tenants_select_column!], $limit: Int, $offset: Int, $order_by: [tenants_order_by!], $where: tenants_bool_exp) { meta: tenants_aggregate(where: $where) { aggregate { count } } items: tenants( distinct_on: $distinct_on limit: $limit offset: $offset order_by: $order_by where: $where ) { ...Tenant } } ${Tenant}`;
const GetTenantBySlug: Query<GetTenantBySlugQuery, GetTenantBySlugQueryVariables> = gql` query getTenantBySlug($tenant_slug: String!) { tenant: tenant_by_slug(args: {tenant_slug: $tenant_slug}) { id type name slug parent { id type name slug } } }`;
const GetTenant: Query<GetTenantQuery, GetTenantQueryVariables> = gql` query getTenant($id: uuid!) { item: tenants_by_pk(id: $id) { ...Tenant } } ${Tenant}`;
const CreateTenant: Query<CreateTenantMutation, CreateTenantMutationVariables> = gql` mutation createTenant($object: CreateTenantInput!) { item: create_tenant(object: $object) { id } }`;
const UpdateTenant: Query<UpdateTenantMutation, UpdateTenantMutationVariables> = gql` mutation updateTenant($id: uuid!, $_set: tenants_set_input!) { item: update_tenants_by_pk(pk_columns: {id: $id}, _set: $_set) { ...Tenant } } ${Tenant}`;
const DeleteTenant: Query<DeleteTenantMutation, DeleteTenantMutationVariables> = gql` mutation deleteTenant($id: uuid!) { item: delete_tenant(id: $id) { id } }`;
const SyncGetTenantsMeta: Query<SyncGetTenantsMetaSubscription, SyncGetTenantsMetaSubscriptionVariables> = gql` subscription syncGetTenantsMeta($where: tenants_bool_exp) { meta: tenants_aggregate(where: $where) { aggregate { count } } }`;
const SyncGetTenants: Query<SyncGetTenantsSubscription, SyncGetTenantsSubscriptionVariables> = gql` subscription syncGetTenants($distinct_on: [tenants_select_column!], $limit: Int, $offset: Int, $order_by: [tenants_order_by!], $where: tenants_bool_exp) { items: tenants( distinct_on: $distinct_on limit: $limit offset: $offset order_by: $order_by where: $where ) { ...Tenant } } ${Tenant}`;
const SyncGetTenant: Query<SyncGetTenantSubscription, SyncGetTenantSubscriptionVariables> = gql` subscription syncGetTenant($id: uuid!) { item: tenants_by_pk(id: $id) { ...Tenant } } ${Tenant}`;
const GetTenantBilling: Query<GetTenantBillingQuery, GetTenantBillingQueryVariables> = gql` query getTenantBilling($id: uuid!) { item: tenants_by_pk(id: $id) { ...TenantBilling } } ${TenantBilling}`;
const GetTenantVehicles: Query<GetTenantVehiclesQuery, GetTenantVehiclesQueryVariables> = gql` query getTenantVehicles($order_by: [vehicles_order_by!], $where: vehicles_bool_exp) { items: vehicles(order_by: $order_by, where: $where) { id color make model picture status tenant_id year day_rate mile_rate rate_type } }`;
const GetUsers: Query<GetUsersQuery, GetUsersQueryVariables> = gql` query getUsers($distinct_on: [users_select_column!], $limit: Int, $offset: Int, $order_by: [users_order_by!], $where: users_bool_exp) { meta: users_aggregate(where: $where) { aggregate { count } } items: users( distinct_on: $distinct_on limit: $limit offset: $offset order_by: $order_by where: $where ) { ...User } } ${User}`;
const CheckUserEmail: Query<CheckUserEmailQuery, CheckUserEmailQueryVariables> = gql` query checkUserEmail($email: String!) { items: users(where: {email: {_eq: $email}}) { ...User } } ${User}`;
const GetUser: Query<GetUserQuery, GetUserQueryVariables> = gql` query getUser($id: uuid!) { item: users_by_pk(id: $id) { ...User } } ${User}`;
const CreateUser: Query<CreateUserMutation, CreateUserMutationVariables> = gql` mutation createUser($object: users_insert_input!) { item: insert_users_one(object: $object) { id } }`;
const UpdateUser: Query<UpdateUserMutation, UpdateUserMutationVariables> = gql` mutation updateUser($id: uuid!, $_set: users_set_input!) { item: update_users_by_pk(pk_columns: {id: $id}, _set: $_set) { ...User } } ${User}`;
const DeleteUser: Query<DeleteUserMutation, DeleteUserMutationVariables> = gql` mutation deleteUser($id: uuid!) { item: delete_users_by_pk(id: $id) { id } }`;
const SyncGetUsersMeta: Query<SyncGetUsersMetaSubscription, SyncGetUsersMetaSubscriptionVariables> = gql` subscription syncGetUsersMeta($where: users_bool_exp) { meta: users_aggregate(where: $where) { aggregate { count } } }`;
const SyncGetUsers: Query<SyncGetUsersSubscription, SyncGetUsersSubscriptionVariables> = gql` subscription syncGetUsers($distinct_on: [users_select_column!], $limit: Int, $offset: Int, $order_by: [users_order_by!], $where: users_bool_exp) { items: users( distinct_on: $distinct_on limit: $limit offset: $offset order_by: $order_by where: $where ) { ...User } } ${User}`;
const SyncGetUser: Query<SyncGetUserSubscription, SyncGetUserSubscriptionVariables> = gql` subscription syncGetUser($id: uuid!) { item: users_by_pk(id: $id) { ...User } } ${User}`;
const GetVehicles: Query<GetVehiclesQuery, GetVehiclesQueryVariables> = gql` query getVehicles($distinct_on: [vehicles_select_column!], $limit: Int, $offset: Int, $order_by: [vehicles_order_by!], $where: vehicles_bool_exp) { meta: vehicles_aggregate(where: $where) { aggregate { count } } items: vehicles( distinct_on: $distinct_on limit: $limit offset: $offset order_by: $order_by where: $where ) { ...Vehicle } } ${Vehicle}`;
const GetVehicle: Query<GetVehicleQuery, GetVehicleQueryVariables> = gql` query getVehicle($id: uuid!) { item: vehicles_by_pk(id: $id) { ...Vehicle } } ${Vehicle}`;
const CreateVehicle: Query<CreateVehicleMutation, CreateVehicleMutationVariables> = gql` mutation createVehicle($object: vehicles_insert_input!) { item: insert_vehicles_one(object: $object) { id } }`;
const UpdateVehicle: Query<UpdateVehicleMutation, UpdateVehicleMutationVariables> = gql` mutation updateVehicle($id: uuid!, $_set: vehicles_set_input!) { item: update_vehicles_by_pk(pk_columns: {id: $id}, _set: $_set) { ...Vehicle } } ${Vehicle}`;
const DeleteVehicle: Query<DeleteVehicleMutation, DeleteVehicleMutationVariables> = gql` mutation deleteVehicle($id: uuid!) { item: delete_vehicles_by_pk(id: $id) { id } }`;
const SyncGetVehiclesMeta: Query<SyncGetVehiclesMetaSubscription, SyncGetVehiclesMetaSubscriptionVariables> = gql` subscription syncGetVehiclesMeta($where: vehicles_bool_exp) { meta: vehicles_aggregate(where: $where) { aggregate { count } } }`;
const SyncGetVehicles: Query<SyncGetVehiclesSubscription, SyncGetVehiclesSubscriptionVariables> = gql` subscription syncGetVehicles($distinct_on: [vehicles_select_column!], $limit: Int, $offset: Int, $order_by: [vehicles_order_by!], $where: vehicles_bool_exp) { items: vehicles( distinct_on: $distinct_on limit: $limit offset: $offset order_by: $order_by where: $where ) { ...Vehicle } } ${Vehicle}`;
const SyncGetVehicle: Query<SyncGetVehicleSubscription, SyncGetVehicleSubscriptionVariables> = gql` subscription syncGetVehicle($id: uuid!) { item: vehicles_by_pk(id: $id) { ...Vehicle } } ${Vehicle}`;

/* Exports */
export const queries = {
    GetClients,
    GetClient,
    GetOrderStatus,
    GetOrders,
    GetOrder,
    GetReservations,
    CheckReservationEmail,
    GetReservation,
    CheckSubdomain,
    GetTenants,
    GetTenantBySlug,
    GetTenant,
    GetTenantBilling,
    GetTenantVehicles,
    GetUsers,
    CheckUserEmail,
    GetUser,
    GetVehicles,
    GetVehicle
};
export const mutations = {
    CreateClient,
    UpdateClient,
    DeleteClient,
    TriggerOrderEvent,
    GenerateContract,
    GenerateContractLink,
    CreateOrder,
    UpdateOrder,
    DeleteOrder,
    CreateReservation,
    UpdateReservation,
    DeleteReservation,
    CreateTenant,
    UpdateTenant,
    DeleteTenant,
    CreateUser,
    UpdateUser,
    DeleteUser,
    CreateVehicle,
    UpdateVehicle,
    DeleteVehicle
};
export const subscriptions = {
    SyncGetClientsMeta,
    SyncGetClients,
    SyncGetClient,
    SyncGetOrdersMeta,
    SyncGetOrders,
    SyncGetOrder,
    SyncGetReservationsMeta,
    SyncGetReservations,
    SyncGetReservation,
    SyncGetTenantsMeta,
    SyncGetTenants,
    SyncGetTenant,
    SyncGetUsersMeta,
    SyncGetUsers,
    SyncGetUser,
    SyncGetVehiclesMeta,
    SyncGetVehicles,
    SyncGetVehicle
};
import { Box, Grid } from "@mantine/core";
import { OrderInfoViewProps } from "./_types";
import { InfoItemPlain } from "components/ui/InfoItem";
import { formatDate } from "utils/dates";
import { checkItemValidity } from "./_utils";

const ClientLicenseInfoView: React.FC<OrderInfoViewProps> = ({ order, invalidFields }) => {
    if (!order.client) return null;
    let i = order.client
    return (
        <Box>

            <Grid grow gutter="xs">

                <Grid.Col span={12}>
                    <InfoItemPlain label="Number" value={i.license_number} validation={checkItemValidity('license_number', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Location" value={i.license_state} validation={checkItemValidity('license_state', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Expires" value={formatDate(i.license_exp_date,'M/DD/YY')} validation={checkItemValidity('license_exp_date', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Date of Birth" value={formatDate(i.dob,'M/DD/YY')} validation={checkItemValidity('dob', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="License Photo Front" value={i.license_image_front?'Uploaded':'Missing'} validation={checkItemValidity('license_image_front', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="License Photo Back" value={i.license_image_back?'Uploaded':'Missing'} validation={checkItemValidity('license_image_back', invalidFields)} />
                </Grid.Col>

            </Grid>

        </Box>
    )
}

export default ClientLicenseInfoView;
import React from 'react';

import { useActions, useAppState } from 'state';
import { ClientFragment } from 'state/gql/_types';

import { DrawerActionProps } from 'components/layout/page/PageDrawerAction';
import FormDrawer from 'components/forms/FormDrawer';

import FormSchema from './schemas/edit-client-details.json';
import { extractCurrentItemData } from 'utils/forms';
import { ClientDrawerActions } from '../TenantClientDrawer';

type FormPayloadType = Omit<ClientFragment,
    | 'id'
    | 'role'
    | 'tenant_id'
    | 'created_at'
    | 'updated_at'
    | 'status'
>

const initialState: FormPayloadType = {
    email: '',
    first_name: '',
    last_name: '',
    address: '',
    country: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    dob: ''
};

const EditClientAction: React.FC<DrawerActionProps<ClientFragment,ClientDrawerActions>> = ({ currentItem, onClose }) => {

    const { updateItem } = useActions().clients;
    const { loading, errors } = useAppState().clients;

    let currentItemId = null;
    let currentItemData = null;
    if (currentItem) {
        currentItemId = currentItem.id;
        currentItemData = extractCurrentItemData<FormPayloadType>(initialState, currentItem);
    }

    return (
        <>
            <FormDrawer<FormPayloadType, ClientFragment, ClientFragment['id']>
                autoOpen={false}
                formSchema={FormSchema}
                updateAction={updateItem}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
                hideDelete={true}
            />

        </>
    )
}

export default EditClientAction
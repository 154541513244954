import React from 'react';
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
    getSortedRowModel,
    PaginationState
} from '@tanstack/react-table'
import type { Table, Row, TableOptions, HeaderGroup, SortingState } from '@tanstack/react-table'
import { ModelMeta, SetMetaInput } from 'state/Module';
import { Box, Button, Group, Select } from '@mantine/core'
import * as S from './DataTableStyles'
import { Icon } from '@iconify/react'
import { useAppState } from 'state'
import cx from 'classnames'

export interface TableProperties<T extends Record<string, unknown>> extends Pick<TableOptions<T>, 'columns' | 'data' | 'initialState'> {
    loading: boolean;
    meta: ModelMeta<T>;
    onMetaChange: (meta: SetMetaInput<T>) => void;
    onAdd?: (instance: Table<T>) => React.MouseEventHandler;
    onDelete?: (instance: Table<T>) => React.MouseEventHandler;
    onEdit?: (instance: Table<T>) => React.MouseEventHandler;
    onClick?: (row: Row<T>) => void;
    onSelectItem?: (id: any) => any;
    columActions?: ColumnActions
    selectedId?: string | null | undefined;
}

type ColumnAction = {
    label: string,
    action?: (id: string | number) => void,
    actions?: ColumnActions
}

export type ColumnActions = {
    [index: string]: ColumnAction
}

export type TableComponentProps<T extends Record<string, unknown>> = {
    onEditAction?(id: T['id']): any
    currentFilter?: any;
}

interface CustomHeaderGroup<T> extends HeaderGroup<T> {
    relationId: string;
}

function DataTable<T extends Record<string, unknown>>(props: React.PropsWithChildren<TableProperties<T>>): React.ReactElement {

    useAppState();

    const { columns, onSelectItem = () => {}, selectedId, meta: { page: currentPage, total, limit, sortKey, sortOrder } } = props;
    const { onMetaChange } = props;

    const pageCount = Math.ceil(total / limit);
    const canPreviousPage = currentPage > 1;
    const canNextPage = currentPage < pageCount;

    const pagination = React.useMemo<PaginationState>(
        () => ({
            pageIndex: currentPage - 1,
            pageSize: limit,
        }),
        [currentPage, limit]
    )

    const sorting = React.useMemo<SortingState>(
        () => ([
            {
                id: sortKey as string,
                desc: sortOrder === 'desc'
            }
        ]),
        [sortKey, sortOrder]
    )

    const table = useReactTable<T>(
        {
            ...props,
            columns,
            manualPagination: true,
            manualSorting: true,
            pageCount,
            state: {
                ...props.initialState,
                pagination,
                sorting,
            },
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
        }
    );

    const getSortIcon = (columnId: string) => columnId !== sortKey ? 'fluent:arrow-sort-16-filled' : sortOrder === 'desc' ? 'fluent:arrow-sort-down-16-filled' : 'fluent:arrow-sort-up-16-filled'

    const handlePageSizeChange = (pageSize: number) => {
        let newMeta: SetMetaInput<T> = {};
        if (pageSize !== limit) {
            newMeta.limit = pageSize;
            newMeta.offset = 0;
            newMeta.page = 1;
        }
        if (Object.keys(newMeta).length) {
            onMetaChange(newMeta);
        }
    }

    const handlePageChange = (nextPage: number) => {
        let newMeta: SetMetaInput<T> = {};
        if (nextPage !== currentPage) {
            newMeta.page = nextPage;
            newMeta.offset = nextPage * limit - limit;
        }
        if (Object.keys(newMeta).length) {
            onMetaChange(newMeta);
        }
    }

    const handleSort = (key: keyof T, relationId?: string | undefined) => {
        let newMeta: SetMetaInput<T> = {};
        if (key !== sortKey) {
            newMeta.sortKey = key;
        }
        if (sortOrder === 'asc') {
            newMeta.sortOrder = 'desc';
        } else {
            newMeta.sortOrder = 'asc';
        }
        if (relationId) {
            newMeta.orderBy = {
                [key]: {
                    [relationId]: newMeta.sortOrder
                }
            }
        } else {
            newMeta.orderBy = {
                [key]: newMeta.sortOrder
            }
        }
        if (Object.keys(newMeta).length) {
            onMetaChange(newMeta);
        }
    }

    return (
        <Box>
            <S.DataTableComponent>
                <S.TableHead>
                    {table.getHeaderGroups().map((headerGroup) => {
                        return (
                            <S.TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    const sortIconName = getSortIcon(header.id);
                                    let col = header as unknown as CustomHeaderGroup<T>
                                    return (
                                        <S.TableHeading key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <S.SortIconButton
                                                    onClick={() => handleSort(header.id, col.relationId)}
                                                    title="Toggle SortBy"
                                                    className={cx({ sorted: col.id === sortKey })}
                                                >
                                                    <span>
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                    </span>
                                                    <S.TableHeaderIcon icon={sortIconName} />
                                                </S.SortIconButton>
                                            )}
                                        </S.TableHeading>
                                    )
                                })}
                            </S.TableRow>
                        )
                    })}
                </S.TableHead>
                <S.TableBody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <S.TableRow 
                                key={row.id} 
                                onClick={()=>onSelectItem(row.getValue('id'))}
                                className={cx({ selected: row.getValue('id') === selectedId })}
                            >
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <S.TableCell key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </S.TableCell>
                                    )
                                })}
                            </S.TableRow>
                        )
                    })}
                </S.TableBody>
            </S.DataTableComponent>
            <S.PaginationContainer>

                <Group spacing={6}>
                    <span>Page:</span>
                    <Select
                        size="xs"
                        placeholder="Select..."
                        data={table.getPageOptions().map(i => (i + 1).toString())}
                        onChange={(value) => handlePageChange(Number(value))}
                        value={currentPage.toString()}
                        style={{ width: '55px' }}
                    />
                    <span>of {table.getPageCount()}</span>
                </Group>

                <Group spacing={2}>
                    <Button
                        size="xs"
                        variant="light"
                        color="gray"
                        onClick={() => handlePageChange(1)}
                        disabled={!canPreviousPage}
                        sx={{
                            padding: '0 8px',
                            [`&:not(.__mantine-ref-loading):disabled`]: {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        <Icon icon='ci:chevron-left-duo' style={{ fontSize: '16px' }} />
                    </Button>
                    <Button
                        size="xs"
                        variant="light"
                        color="gray"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={!canPreviousPage}
                        sx={{
                            padding: '0 8px',
                            [`&:not(.__mantine-ref-loading):disabled`]: {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        <Icon icon='ci:chevron-left-md' style={{ fontSize: '16px' }} />
                    </Button>

                    <Button
                        size="xs"
                        variant="light"
                        color="gray"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={!canNextPage}
                        sx={{
                            padding: '0 8px',
                            [`&:not(.__mantine-ref-loading):disabled`]: {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        <Icon icon='ci:chevron-right-md' style={{ fontSize: '16px' }} />
                    </Button>
                    <Button
                        size="xs"
                        variant="light"
                        color="gray"
                        onClick={() => handlePageChange(pageCount)}
                        disabled={!canNextPage}
                        sx={{
                            padding: '0 8px',
                            [`&:not(.__mantine-ref-loading):disabled`]: {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        <Icon icon='ci:chevron-right-duo' style={{ fontSize: '16px' }} />
                    </Button>
                </Group>

                <Group spacing={6}>
                    <span>Items Per Page: </span>
                    <Select
                        size="xs"
                        placeholder="Select..."
                        data={['1','5', '10', '20', '50', '100']}
                        onChange={(value) => handlePageSizeChange(Number(value))}
                        value={limit.toString()}
                        style={{ width: '55px' }}
                    />
                </Group>

            </S.PaginationContainer>
        </Box>
    )

}

export default DataTable;
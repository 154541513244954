import { handleGraphqQLErrorResponse } from 'state/gql/utils';
import { AsyncAction } from 'state/_types';

export const lookUpTenant: AsyncAction = async ({state, effects}) => {
    try {
        if(state.tenant.slug) {
            let { tenant } = await effects.gql.queries.GetTenantBySlug({tenant_slug:state.tenant.slug});
            if (tenant&&tenant.id) {
                state.tenant.id = tenant.id
                state.tenant.name = tenant.name
                state.tenant.type = tenant.type
                if (tenant.parent) {
                    state.tenant.parent.id = tenant.parent.id
                    state.tenant.parent.type = tenant.parent.type
                    state.tenant.parent.name = tenant.parent.name
                    state.tenant.parent.slug = tenant.parent.slug
                }                
            }
        }
    } catch (e: any) {
        state.tenants.errors = handleGraphqQLErrorResponse(e);
    }
}
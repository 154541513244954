import React from 'react';
import PageHeader from 'components/layout/page/PageHeader';
import PageLayout from 'components/layout/page/PageLayout';
import EditReservationAction from 'components/tenant/reservations/components/EditReservationAction';

const TenantReservationEdit: React.FC = () => {

    return (
        <PageLayout
            header={<PageHeader title={`New Reservation`}/>}
        >

            <EditReservationAction /> 

        </PageLayout>
    )
}

export default TenantReservationEdit;
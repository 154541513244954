import React from 'react';
import { useParams } from "react-router-dom";

import { useActions, useAppState } from 'state';

import Loader from 'components/app/Loader';
import PageHeader from 'components/layout/page/PageHeader';
import ModuleErrors from 'components/ui/ModuleErrors';
import { TabDivider } from 'components/ui/Tabs';

import TenantClientTabs from 'components/tenant/client/TenantClientTabs';
import { TenantClientLicenseInfo } from 'components/tenant/client/TenantClientInfo';
import PageLayout from 'components/layout/page/PageLayout';
import { TenantClientDrawerAction } from 'components/tenant/client/TenantClientDrawer';

const TenantClientLicense: React.FC = () => {

    const { id } = useParams()
    const { loading, currentItem, errors } = useAppState().clients
    const { subscribeToItem, unsubscribeFromItem } = useActions().clients

    React.useEffect(() => {
        subscribeToItem({ id });
        return () => {
            unsubscribeFromItem()
        }
    }, [id, subscribeToItem, unsubscribeFromItem])

    if (loading || !currentItem) return <Loader />

    return (
        <PageLayout
            header={<PageHeader
                title={`${currentItem.first_name} ${currentItem.last_name}`}
                renderAction={<TenantClientDrawerAction/>}
            />}
        >

            <ModuleErrors errors={errors} />

            <TenantClientTabs />

            <TabDivider label="Client License Details" />
            <TenantClientLicenseInfo client={currentItem} />

        </PageLayout>
    )
}

export default TenantClientLicense;
import { Badge, Grid, Box } from '@mantine/core';
import { TabDividerSpacing } from 'components/ui/Tabs';
import { parseOrderStepColor } from 'components/tenant/order/OrderUtils';
import InfoItem from 'components/ui/InfoItem';
import { capitalizeWords, formatSnakeCase } from 'utils/string';
import { formatDate } from 'utils/dates';
import { OrderFragment } from 'state/gql/_types';

export const TenantOrderSummary: React.FC<{currentItem: OrderFragment}> = ({currentItem}) => {

    return (
        <Box>

            <TabDividerSpacing label="Order Summary" />
            <Grid grow gutter="xs" mb={100}>

                <Grid.Col md={6}>
                    <InfoItem label="Status">
                        <Badge size="lg" variant="outline" color={parseOrderStepColor(currentItem.step)}>{formatSnakeCase(currentItem.step)}</Badge>
                    </InfoItem>
                </Grid.Col>

                <Grid.Col md={6}>
                    <InfoItem label="Step">
                        <Badge size="lg" variant="outline" color="gray">{formatSnakeCase(currentItem.state)}</Badge>
                    </InfoItem>
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="Client" value={`${currentItem.client?.first_name} ${currentItem.client?.last_name}`} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="Vehicle" value={capitalizeWords(`${currentItem.vehicle?.year} ${currentItem.vehicle?.make} ${currentItem.vehicle?.model}`)} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="Start Date" value={formatDate(currentItem.start_date)} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItem label="End Date" value={formatDate(currentItem.end_date)} />
                </Grid.Col>

                <Grid.Col span={6}>
                    <InfoItem label="Agent" value={`${currentItem.agent?.first_name} ${currentItem.agent?.last_name}`} />
                </Grid.Col>

            </Grid>
        </Box>
    )
    
}

export default TenantOrderSummary

import { Box, Text, DEFAULT_THEME, CSSObject, Group, Anchor } from "@mantine/core"
import { useAppState } from "state"
import { Link } from "react-router-dom";

export const PaletteOverrides = (colorScheme: 'light' | 'dark'): Record<string, string> => {
    return {
        primary: DEFAULT_THEME.colors.green[6],
        background: colorScheme === 'light' ? DEFAULT_THEME.white : DEFAULT_THEME.colors.dark[7],
        error: DEFAULT_THEME.colors.red[9],
        textTitle: colorScheme === 'light' ? DEFAULT_THEME.colors.gray[9] : DEFAULT_THEME.colors.dark[0],
        textLabel: colorScheme === 'light' ? DEFAULT_THEME.colors.gray[9] : DEFAULT_THEME.colors.dark[0],
        textInput: colorScheme === 'light' ? DEFAULT_THEME.black : DEFAULT_THEME.colors.dark[0],
        textPrimary: colorScheme === 'light' ? DEFAULT_THEME.colors.gray[7] : DEFAULT_THEME.colors.dark[0],
        textLink: DEFAULT_THEME.colors.blue[6],
        inputBackground: colorScheme === 'light' ? DEFAULT_THEME.white : DEFAULT_THEME.colors.dark[5],
    }
}

export const StyleOverrides = (colorScheme: 'light' | 'dark'): Record<string, CSSObject> => {
    return {
        container: {
            fontFamily: "Euclid Circular B",
            marginTop: 12,
            marginBottom: 0
        },
        row: {
            paddingTop: 20
        },
        headerSubtitle: {
            marginBottom: 15
        },
        input: {
            fontFamily: "Euclid Circular B",
            paddingLeft: 12,
            paddingRight: 12,
            height: 34,
            borderRadius: 4
        },
        button: {
            fontFamily: "Euclid Circular B"
        },
        showPassword: {
            top: '2px !important ',
            marginRight: 5,
            display: 'flex',
            alignItems: 'center',
        },
        inputWrapper: {
            borderColor: colorScheme === 'light' ? DEFAULT_THEME.colors.gray[4] : DEFAULT_THEME.colors.dark[5],
            height: 36
        },
        divider: {
            borderBottomColor: colorScheme === 'light' ? DEFAULT_THEME.colors.gray[2] : DEFAULT_THEME.colors.dark[5]
        },
        inputContainer: {
            marginTop: 4
        },
        formRow: {
            paddingBottom: 15,
            '&:last-of-type': {
                paddingTop: 15,
            }
        }
    }
}

const PageBody: React.FC = ({ children }) => {
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'url(/clutch360bg.jpg)',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}
        >
            {children}
        </Box>
    )
}

const TenantName: React.FC = () => {
    const { name } = useAppState().tenant
    return (
        <Text
            weight={600}
            sx={(theme) => ({
                color: theme.white,
                fontSize: 22,
                textShadow: '0px 2px 3px #00000070'
            })}
        >
            {name}
        </Text>
    )
}

const FooterLinks = () => {
    return (
        <Group my="md" spacing="sm">
            <Anchor component={Link} to="/privacy-policy" size="sm" sx={{color:'white', textShadow: '0 0 5px #000'}}>Privacy Policy</Anchor>
            <Text color="white" >|</Text>
            <Anchor component={Link} to="/terms-and-conditions" size="sm" sx={{color:'white', textShadow: '0 0 5px #000'}}>Terms & Conditions</Anchor>
        </Group>
    )
}

export const BaseOverrides = {
    EmailPasswordSignIn: ({ DefaultComponent, ...props }: any) => {
        return (
            <PageBody>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Text
                        weight={700}
                        sx={(theme) => ({
                            color: theme.white,
                            fontSize: 50,
                            lineHeight: '50px',
                            textShadow: '0px 2px 3px #00000070'
                        })}
                    >
                        Clutch
                    </Text>
                    <Text
                        weight={700}
                        sx={(theme) => ({
                            color: theme.colors.red[9],
                            fontSize: 50,
                            lineHeight: '50px',
                            textShadow: '0px 2px 3px #00000070'
                        })}
                    >
                        360
                    </Text>
                </div>
                <TenantName />
                <DefaultComponent {...props} />
                <FooterLinks />
            </PageBody>
        );
    },
    EmailPasswordSignUp: ({ DefaultComponent, ...props }: any) => {
        return (
            <PageBody>
                <TenantName />
                <DefaultComponent {...props} />
                <FooterLinks />
            </PageBody>
        );
    },
    EmailPasswordResetPasswordEmail: ({ DefaultComponent, ...props }: any) => {
        return (
            <PageBody>
                <TenantName />
                <DefaultComponent {...props} />
                <FooterLinks />
            </PageBody>
        );
    },
    EmailPasswordSubmitNewPassword: ({ DefaultComponent, ...props }: any) => {
        return (
            <PageBody>
                <TenantName />
                <DefaultComponent {...props} />
                <FooterLinks />
            </PageBody>
        );
    },
}

export const VerificationOverrides = {
    EmailVerificationSendVerifyEmail: ({ DefaultComponent, ...props }: any) => {
        return (
            <PageBody>
                <TenantName />
                <DefaultComponent {...props} />
            </PageBody>
        );
    },
    EmailVerificationVerifyEmailLinkClicked: ({ DefaultComponent, ...props }: any) => {
        return (
            <PageBody>
                <TenantName />
                <DefaultComponent {...props} />
                <FooterLinks />
            </PageBody>
        );
    },
}

import { Module } from "state/Module"
import * as Types from "state/gql/_types"

export const OrderModule = new Module<Types.OrderFragment, Types.GetOrdersQueryVariables, Types.Orders_Insert_Input>({
    sortKey: 'order_number',
    sortOrder: 'desc',
    default: {
        // rental_period: 0,
        // free_miles: 0,
        // mile_rate: 0,
        // discount: 0,
        // sales_tax: 0.07,
        // sub_total: 0,
        // order_total: 0,
        // other_fees: 0,
        // deposit: 0
    }
})
import React from 'react';

import PageDrawerAction, { DrawerActionProps } from 'components/layout/page/PageDrawerAction';

import { extractCurrentItemData } from "utils/forms";

import FormSchema from './TenantUserFormSchema.json';
import { UserFragment, Users_Insert_Input, User_Roles_Enum } from 'state/gql/_types';
import FormDrawer from 'components/forms/FormDrawer';
import { useActions, useAppState } from 'state';
import Icons from 'components/ui/Icons';

type FormPayloadType = Omit<UserFragment,
    | 'id'
    | 'tenant_id'
    | 'created_at'
    | 'updated_at'
    | 'status'
>

const initialState: FormPayloadType = {
    role: User_Roles_Enum.Manager,
    email: '',
    first_name: '',
    last_name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: ''
};

const TenantUserDrawer: React.FC<DrawerActionProps<UserFragment>> = ({ currentItem, onClose }) => {

    const tenant_id = useAppState(state=>state.tenants.currentItemId)
    const { createItem, updateItem, deleteItem, checkUserEmail } = useActions().users;
    const { loading, errors } = useAppState().users;

    let currentItemId = null;
    let currentItemData = null;
    if (currentItem) {
        currentItemId = currentItem.id;
        currentItemData = extractCurrentItemData<FormPayloadType>(initialState, currentItem);
    }

    const handleCreate = (payload: Users_Insert_Input) => {
        if (tenant_id) {
            return createItem({ ...payload, tenant_id: tenant_id })
        }
        return Promise.reject('No Active Tenant Loaded')
    }

    return (
        <>
            <FormDrawer<FormPayloadType, UserFragment, string>
                autoOpen={false}
                formSchema={FormSchema}
                validators={{
                    email_available: checkUserEmail 
                }}
                createAction={handleCreate}
                updateAction={updateItem}
                deleteAction={deleteItem}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
                redirectPath="/users"
                deleteRedirect={true}
            />

        </>
    )
}

export const NewTenantUserAction = () => {
    return (
        <PageDrawerAction<UserFragment>
            title='New User' 
            action={TenantUserDrawer}
            props={{}}
        />
    )
}

export const EditTenantUserAction: React.FC<{ id: string; }> = ({id}) => {

    const { currentItem } = useAppState().users
    const { loadItem } = useActions().users

    React.useEffect(()=>{
        loadItem({id})
    },[id,loadItem])

    if (!currentItem) return null;

    return (
        <PageDrawerAction<UserFragment>
            title='Edit User'
            action={TenantUserDrawer}
            icon={Icons.edit}
            props={{ currentItem }}
            color="dark"
            size="xs"
            variant='outline'
            iconColor="black"
        />
    )
}
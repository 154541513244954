import React from 'react';

import PageLayout from 'components/layout/page/PageLayout';

import PublicShellLayout from 'components/layout/PublicShellLayout';
import CreateReservationAction from 'components/guest/reservations/CreateReservationAction';


const Screen: React.FC = () => {

    return (
        <PublicShellLayout>

            <PageLayout>
                <CreateReservationAction/>
            </PageLayout>

        </PublicShellLayout>
    )
}

export default Screen;
import { VehicleFragment } from "state/gql/_types";
import { Grid } from '@mantine/core';
import InfoItem from "components/ui/InfoItem";
import { formatDate } from "utils/dates";

export const TenantVehicleInsuranceInfo: React.FC<{ vehicle: VehicleFragment }> = ({ vehicle }) => {
    return (
        <Grid grow gutter="xs">

            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Policy Provider" value={vehicle.insurance_name} />
            </Grid.Col>
            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Policy Number" value={vehicle.insurance_number} />
            </Grid.Col>
            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Effective Date" value={formatDate(vehicle.insurance_start_date)} />
            </Grid.Col>
            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Expiration Date" value={formatDate(vehicle.insurance_end_date)} />
            </Grid.Col>

        </Grid>
    )
}
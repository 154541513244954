export const capitalizeWord = (value: string): string => {
    if (typeof value !== 'string') return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export const capitalizeWords = (input: string): string => {
    let oldStrArr = input.split(' ');
    let newStrArr = oldStrArr.map((str)=>{
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    });
    return newStrArr.join(' ');    
}

export const pluralizeWord = (word: string, count: number): string => {
    return count > 1 || count === 0 ? `${word}s` : word;
}

type OptionalField = string | undefined | null

export const formatAddress = (address?: OptionalField, city?: OptionalField, state?: OptionalField, zip?: OptionalField, country?: OptionalField): string => {
    return `${address ? `${address},` : ''} ${city ? `${city},` : ''} ${state ? `${state}` : ''} ${zip ? `${zip}` : ''}${country ? `, ${country}` : ''}`
}

export const formatSnakeCase = (value: string): string => {
    return value  
        .split('_')  
        .map((word) => {  
            return capitalizeWord(word);  
        })  
        .join(' ');
}

export const sanitizeURL= (str: any): string => {
    if (str===null||typeof str === 'undefined') return str as unknown as string
    const regex = /[^a-zA-Z0-9-]+/g; // regex to match all characters except alphanumeric and space
    const trimmedStr = str.trim(); // remove spaces at beginning and end of the string
    const sanitizedStr = trimmedStr.replace(regex, ""); // replace all invalid characters with hyphens
    const singleSpacedStr = sanitizedStr.replace(/\s+/g, ""); // replace multiple spaces with single spaces
    return singleSpacedStr; // return sanitized string
}

export const sanitizeEmail = (str: any): string => {
    if (str===null||typeof str === 'undefined') return str as unknown as string
    const regex = /[^a-zA-Z0-9-_.@]+/g; // regex to match all characters except alphanumeric and space
    const trimmedStr = str.trim(); // remove spaces at beginning and end of the string
    const sanitizedStr = trimmedStr.replace(regex, ""); // replace all invalid characters with hyphens
    const singleSpacedStr = sanitizedStr.replace(/\s+/g, ""); // replace multiple spaces with single spaces
    return singleSpacedStr; // return sanitized string
}

export const sanitizeString = (str: any): string => {
    if (str===null||typeof str === 'undefined') return str as unknown as string
    const regex = /[^a-zA-Z0-9 -.,()&@#!?$='"%]+/g; // regex to match all characters except alphanumeric and space
    const trimmedStr = str.trim(); // remove spaces at beginning and end of the string
    const sanitizedStr = trimmedStr.replace(regex, ""); // replace all invalid characters with hyphens
    const singleSpacedStr = sanitizedStr.replace(/\s+/g, " "); // replace multiple spaces with single spaces
    return singleSpacedStr; // return sanitized string
}

export const sanitizeStringAllowSpace = (str: any): string => {
    if (str===null||typeof str === 'undefined') return str as unknown as string
    const regex = /[^a-zA-Z0-9 -.,()&@#!?$='"%]+/g; // regex to match all characters except alphanumeric and space
    const trimmedStr = str
    const sanitizedStr = trimmedStr.replace(regex, ""); // replace all invalid characters with hyphens
    const singleSpacedStr = sanitizedStr.replace(/\s+/g, " "); // replace multiple spaces with single spaces
    return singleSpacedStr; // return sanitized string
}
import { StripePlan, StripeSubscriptionItem, TenantFragment } from "state/gql/_types";
import { Alert, Badge, Box, Button, Grid, Group, Stack, Tooltip, Text, Portal, Card } from '@mantine/core';
import InfoItem from "components/ui/InfoItem";
import { useActions, useAppState } from "state";
import React from "react";
import { AppState } from "state/_types";
import { capitalizeWord, pluralizeWord } from "utils/string";
import { TabDivider } from "components/ui/Tabs";
import { formatDate } from "utils/dates";
import { useLocation, useNavigate } from "react-router-dom";
import { GetTenantBillingFragment } from "state/tenants/actions";

// {
//     "id": "cus_MLVdtCPU2JyJl3",
//     "email": "admin@ideal.consulting",
//     "balance": 0,
//     "delinquent": false,
//     "subscriptions": {
//         "data": []
//     },
//     "paymentMethods": {
//         "data": []
//     }
// } 

export const TenantBillingActions = () => {

    const navigate = useNavigate()
    const getBillingPortalLink = useActions().getBillingPortalLink
    const createTenantSubscription = useActions().tenants.createTenantSubscription
    const uncancelTenantSubscription = useActions().tenants.uncancelTenantSubscription
    const { cardExpires, hasSubscription, hasPaymentMethod, billing } = useAppState(state => state.tenant)

    const [loadingLink, setLoadingLink] = React.useState({
        default: false,
        payments: false,
        activate: false,
        cancel: false,
    })

    const onBillingPortal = async () => {
        setLoadingLink((state) => ({ ...state, default: !state.default }))
        let url = await getBillingPortalLink('default')
        if (url) {
            window.location.href = url
        } else {
            setLoadingLink((state) => ({ ...state, default: !state.cancel }))
        }
    }

    const onBillingUpdatePaymentPortal = async () => {
        setLoadingLink((state) => ({ ...state, payments: !state.payments }))
        let url = await getBillingPortalLink('payment_method_update')
        if (url) {
            window.location.href = url
        }
    }

    const onCreateSubscription = async () => {
        setLoadingLink((state) => ({ ...state, activate: !state.activate }))
        let success = await createTenantSubscription()
        setLoadingLink((state) => ({ ...state, activate: !state.activate }))
        if (success) {
            navigate(0)
        }
    }

    const onCancelSubscription = async () => {
        setLoadingLink((state) => ({ ...state, cancel: !state.cancel }))
        let url = await getBillingPortalLink('subscription_cancel')
        if (url) {
            window.location.href = url
        } else {
            setLoadingLink((state) => ({ ...state, cancel: !state.cancel }))
        }
    }

    const onUncancelCancelSubscription = async () => {
        setLoadingLink((state) => ({ ...state, cancel: !state.cancel }))
        let success = await uncancelTenantSubscription()
        setLoadingLink((state) => ({ ...state, cancel: !state.cancel }))
        if (success) {
            navigate(0)
        }
    }

    if (!billing) return null

    let subscription = billing.subscriptions.data[0] || false
    let isCancelled = !!subscription?.canceledAt || false
    let willCancel = !!subscription?.cancelAtPeriodEnd || false
    let hadTrial = !!subscription.trialStart && !!subscription.trialStart

    return (
        <Stack spacing="xs">

            {hasSubscription && !hasPaymentMethod && <Button color="blue" size="sm" loading={loadingLink.payments} variant="outline" onClick={onBillingUpdatePaymentPortal}>Update Billing Details</Button>}
            {!hasSubscription && !hasPaymentMethod && <Button color="blue" size="sm" loading={loadingLink.payments} variant="outline" onClick={onBillingUpdatePaymentPortal}>Update Billing Details</Button>}
            {hasSubscription && hasPaymentMethod && cardExpires && <Button color="blue" size="sm" loading={loadingLink.payments} variant="outline" onClick={onBillingUpdatePaymentPortal}>Update Billing Details</Button>}

            {!hasSubscription && hasPaymentMethod && <Button color="dark" size="sm" loading={loadingLink.activate} variant="outline" onClick={onCreateSubscription}>{hadTrial ? 'Reactivate Subscription' : 'Activate Subscription'}</Button>}

            <Group mb="md" grow>

                {hasSubscription && hasPaymentMethod && !isCancelled && <Button color="gray" size="sm" loading={loadingLink.cancel} variant="outline" onClick={onCancelSubscription}>Cancel Subscription</Button>}
                {hasSubscription && hasPaymentMethod && willCancel && <Button color="green" size="sm" loading={loadingLink.cancel} variant="outline" onClick={onUncancelCancelSubscription}>Reactivate Subscription</Button>}
                {hasSubscription && hasPaymentMethod && <Button color="red" size="sm" loading={loadingLink.default} variant="outline" onClick={onBillingPortal}>Billing Portal</Button>}

            </Group>

        </Stack>
    )


}

const tenantBillingStatusColor = (status: AppState['tenant']['subStatus']) => {
    switch (status) {
        case 'incomplete':
        case 'incomplete_expired':
        case 'past_due':
        case 'unpaid':
            return 'red'
        case 'trialing':
        case 'active':
            return 'green'
        case 'canceled':
        default:
            return 'gray'

    }
}

const StripeMeteredSubscriptionItem: React.FC<{ i: StripeSubscriptionItem }> = ({ i }) => {

    const [loading, setLoading] = React.useState(true)
    const [usage, setUsage] = React.useState(0)

    const getBillingUsage = useActions().tenants.getBillingUsage

    React.useEffect(() => {

        let controller: AbortController | null = new AbortController();

        const loadData = async () => {

            if (controller) {
                try {
                    setLoading(true)
                    let usageRecords = await getBillingUsage(i.id)
                    if (usageRecords.length) {
                        let latestCycle = usageRecords[0]
                        let currentUsage = latestCycle.total_usage
                        setUsage(currentUsage)
                    }
                    setLoading(false)
                    controller = null;
                } catch (e: any) {
                    if (e.name !== 'AbortError') {
                        console.error(e)
                    }
                    setLoading(false)
                }
            }

        }

        loadData()

        return () => controller?.abort()

    }, [getBillingUsage, i.id])

    if (loading) {
        return (
            <InfoItem label={i.price.product.name} value={'Loading...'} />
        )
    }

    return (
        <InfoItem label={i.price.product.name} value={`${usage} ${pluralizeWord(i.price.product.unitLabel || 'Item', usage || 0)}`} subvalue={`@ $${(i.price.unitAmount || 0) / 100}/${i.price.product.unitLabel}`} />
    )

}

export const TenantBillingInfoDetails = () => {

    const loadTenantBilling = useActions().tenants.loadTenantBilling
    const { cardExpires, subStatus, hasTrial, trialDays, hasSubscription, hasPaymentMethod, billing } = useAppState(state => state.tenant)

    React.useEffect(() => {
        loadTenantBilling()
    }, [loadTenantBilling])

    if (!billing) return null

    let paymentMethods = billing.paymentMethods.data.map(p => {
        if (p.card) {
            let { brand, expMonth, expYear, last4 } = p.card
            return {
                label: `${capitalizeWord(brand)} ${last4} - ${expMonth}/${expYear}`
            }
        } else {
            return {
                label: capitalizeWord(p.type)
            }
        }
    }) || []

    const getSubStatus = () => {
        if (!hasSubscription) return 'Inactive'
        switch (subStatus) {
            case 'incomplete':
                return 'Incomplete'
            case 'incomplete_expired':
                return 'Expired'
            case 'trialing':
                return 'Trial'
            case 'active':
                return 'Active'
            case 'past_due':
                return 'Past Due'
            case 'canceled':
                return 'Canceled'
            case 'unpaid':
                return 'Unpaid'
            default:
                return 'Inactive'
        }
    }

    let currentStatus = getSubStatus()

    if (hasTrial) {
        currentStatus = `${currentStatus} - ${trialDays} ${pluralizeWord('Day', trialDays)} Left`
    }

    let subscription = hasSubscription ? billing.subscriptions.data[0] : null

    let breakdown: { [index: StripePlan['usageType']]: StripeSubscriptionItem[] } = {
        'licensed': [],
        'metered': []
    }

    if (subscription) {
        subscription.items.data.forEach(i => {
            if (i.plan.usageType === 'licensed') {
                breakdown.licensed.push(i as any)
            }
            if (i.plan.usageType === 'metered') {
                breakdown.metered.push(i as any)
            }
        })
    }

    return (
        <Box>

            <Grid mb="lg">

                <Grid.Col xs={6}>
                    <InfoItem label="Subscription Status">
                        <Badge size="lg" variant="outline" color={tenantBillingStatusColor(subStatus)}>{currentStatus}</Badge>
                    </InfoItem>
                </Grid.Col>

                {hasSubscription && subscription ? (
                    <React.Fragment>
                        <Grid.Col xs={6}>
                            <InfoItem label="Description" value={subscription.description} />
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <InfoItem label="Current Cycle" value={`${formatDate(new Date(subscription.currentPeriodStart * 1000).toDateString())} - ${formatDate(new Date(subscription.currentPeriodEnd * 1000).toDateString())}`} />
                        </Grid.Col>
                    </React.Fragment>
                ) : null}

                {paymentMethods.map((i, k) => {
                    return (
                        <Grid.Col key={k} xs={6}>
                            <InfoItem key={k} label="Payment Method" value={i.label} color={cardExpires ? "red" : "dark"} />
                        </Grid.Col>
                    )
                })}
                {!hasPaymentMethod && (
                    <Grid.Col xs={6}>
                        <InfoItem label="Payment Method" value={'None'} color="red" />
                    </Grid.Col>
                )}

            </Grid>

            {hasSubscription && subscription && breakdown.licensed.length ? (
                <React.Fragment>
                    <TabDivider label="Subscribed Products" />
                    <Stack mb="lg">

                        {breakdown.licensed.map((i, k) => {
                            return (
                                <React.Fragment key={k}>
                                    <InfoItem label={i.price.product.name} value={`${i.quantity} ${pluralizeWord(i.price.product.unitLabel || 'Item', i.quantity || 0)}`} subvalue={`@ $${(i.price.unitAmount || 0) / 100}/${i.price.product.unitLabel}`} />
                                </React.Fragment>
                            )
                        })}

                    </Stack>
                </React.Fragment>
            ) : null}

            {hasSubscription && subscription && breakdown.metered.length ? (
                <React.Fragment>
                    <TabDivider label="Current Cycle Usage" />
                    <Stack mb="lg">

                        {breakdown.metered.map((i, k) => {
                            return (
                                <React.Fragment key={k}>
                                    <StripeMeteredSubscriptionItem i={i} />
                                </React.Fragment>
                            )
                        })}

                    </Stack>
                </React.Fragment>
            ) : null}

        </Box>
    )

}

export const TenantBillingInfoParentDetails: React.FC<{ tenant?: TenantFragment }> = ({ tenant }) => {

    const getTenantBilling = useActions().tenants.getTenantBilling
    const [status, setStatus] = React.useState<GetTenantBillingFragment | null>(null)

    React.useEffect(() => {
        if (tenant) {

            let controller: AbortController | null = new AbortController();

            const loadData = async () => {

                let tenantBilling = await getTenantBilling(tenant.id)
                if (tenantBilling) {
                    setStatus(tenantBilling)
                }

            }

            loadData()

            return () => controller?.abort()


        }
    }, [tenant, getTenantBilling])

    if (!status) return null

    let { cardExpires, subStatus, hasTrial, trialDays, hasSubscription, hasPaymentMethod, billing } = status

    if (!billing) return null

    let paymentMethods = billing.paymentMethods.data.map(p => {
        if (p.card) {
            let { brand, expMonth, expYear, last4 } = p.card
            return {
                label: `${capitalizeWord(brand)} ${last4} - ${expMonth}/${expYear}`
            }
        } else {
            return {
                label: capitalizeWord(p.type)
            }
        }
    }) || []

    const getSubStatus = () => {
        if (!hasSubscription) return 'Inactive'
        switch (subStatus) {
            case 'incomplete':
                return 'Incomplete'
            case 'incomplete_expired':
                return 'Expired'
            case 'trialing':
                return 'Trial'
            case 'active':
                return 'Active'
            case 'past_due':
                return 'Past Due'
            case 'canceled':
                return 'Canceled'
            case 'unpaid':
                return 'Unpaid'
            default:
                return 'Inactive'
        }
    }

    let currentStatus = getSubStatus()

    if (hasTrial) {
        currentStatus = `${currentStatus} - ${trialDays} ${pluralizeWord('Day', trialDays)} Left`
    }

    let subscription = hasSubscription ? billing.subscriptions.data[0] : null

    return (
        <Box>

            <Grid mb="lg">

                <Grid.Col xs={6}>
                    <InfoItem label="Subscription Status">
                        <Badge size="lg" variant="outline" color={tenantBillingStatusColor(subStatus)}>{currentStatus}</Badge>
                    </InfoItem>
                </Grid.Col>

                {hasSubscription && subscription ? (
                    <React.Fragment>
                        <Grid.Col xs={6}>
                            <InfoItem label="Description" value={subscription.description} />
                        </Grid.Col>
                        <Grid.Col xs={6}>
                            <InfoItem label="Current Cycle" value={`${formatDate(new Date(subscription.currentPeriodStart * 1000).toDateString())} - ${formatDate(new Date(subscription.currentPeriodEnd * 1000).toDateString())}`} />
                        </Grid.Col>
                    </React.Fragment>
                ) : null}

                {paymentMethods.map((i, k) => {
                    return (
                        <Grid.Col key={k} xs={6}>
                            <InfoItem key={k} label="Payment Method" value={i.label} color={cardExpires ? "red" : "dark"} />
                        </Grid.Col>
                    )
                })}
                {!hasPaymentMethod && (
                    <Grid.Col xs={6}>
                        <InfoItem label="Payment Method" value={'None'} color="red" />
                    </Grid.Col>
                )}

            </Grid>

            {hasSubscription && subscription ? (
                <React.Fragment>
                    <TabDivider label="Subscribed Products" />
                    <Stack mb="lg">

                        {subscription.items.data.map((i, k) => {
                            return (
                                <React.Fragment key={k}>
                                    <InfoItem label={i.price.product.name} value={`${i.quantity} ${pluralizeWord(i.price.product.unitLabel || 'Item', i.quantity || 0)}`} subvalue={`@ $${(i.price.unitAmount || 0) / 100}/${i.price.product.unitLabel}`} />
                                </React.Fragment>
                            )
                        })}

                    </Stack>
                </React.Fragment>
            ) : null}

        </Box>
    )

}

export const TenantBillingWarnings = ({ showButtons = true }: { showButtons?: boolean }) => {

    const navigate = useNavigate()
    const user_role = useAppState(state => state.auth.session_role)
    const { subStatus, hasTrial, trialDays, hasPaymentMethod, cardExpires, cardExpirationDays } = useAppState(state => state.tenant)

    const handleBillingNavigate = () => {
        if (user_role === 'manager') {
            navigate('/billing')
        }
    }

    let alerts: any[] = []

    if (subStatus === 'none') alerts.push('No Active Subscription')
    if (subStatus === 'past_due' || subStatus === 'unpaid') {
        alerts.push('Subscription is Past Due.')
        if (user_role === 'agent') {
            alerts.push('Please Notify Your Account Admin to Regain Access')
        }
    }
    if (!hasPaymentMethod && !hasTrial) alerts.push('No Payment Method Provided')
    if (!hasPaymentMethod && hasTrial && trialDays < 50) alerts.push('No Payment Method Provided')
    if (cardExpires && cardExpirationDays > 0) alerts.push(`Your Payment Method Will Expire in ${cardExpirationDays} Days`)
    if (cardExpires && cardExpirationDays === 0) alerts.push('You Payment Method Will Expire Today')
    if (cardExpires && cardExpirationDays < 0) alerts.push('Your Payment Method Has Expired')

    const renderAlerts = () => {
        if (alerts.length) {
            return (
                <Alert
                    variant="outline"
                    color="red"
                    mb="md"
                >
                    <Stack align="center" spacing={2}>
                        <Text align="center" size="sm" color="red" weight={600}>Account Subscription Billing Notice</Text>
                        {alerts.map((i, k) => <Text key={k} size="xs">{i}</Text>)}
                        {showButtons && user_role === 'manager' && <Button mt={4} size="xs" onClick={handleBillingNavigate}>Manage Account Billing</Button>}
                    </Stack>
                </Alert>
            )
        }
        return null
    }

    const renderTrial = () => {
        if (hasTrial) {
            return (
                <Alert
                    variant="outline"
                    color="green"
                >
                    <Text align="center" size="sm" color="green" weight={600}>Your Clutch360 Trial Expires in {trialDays} {pluralizeWord('Day', trialDays)}</Text>
                </Alert>
            )
        }
    }

    return (
        <Stack spacing="xs">
            {renderTrial()}
            {renderAlerts()}
        </Stack>
    )

}

export const TenantSubscriptionBadge = () => {

    const navigate = useNavigate()
    const user_role = useAppState(state => state.auth.session_role)
    const { subStatus, hasTrial, trialDays } = useAppState(state => state.tenant)
    const location = useLocation();

    const handleBillingNavigate = () => {
        if (user_role === 'manager') {
            navigate('/billing')
        }
    }

    if (hasTrial) {
        return (
            <Tooltip label={`${trialDays} ${pluralizeWord('Day', trialDays)} Left`} >
                <Badge
                    size="lg"
                    color={trialDays >= 5 ? 'green' : 'red'}
                    onClick={handleBillingNavigate}
                    style={user_role === 'manager' ? { cursor: 'pointer' } : {}}
                >
                    Free Trial
                </Badge>
            </Tooltip>
        )
    }

    if (subStatus === 'past_due' || subStatus === 'unpaid') {
        return (
            <React.Fragment>
                <Tooltip label="Subscription Past Due" >
                    <Badge
                        size="lg"
                        color={tenantBillingStatusColor(subStatus)}
                        onClick={handleBillingNavigate}
                        style={user_role === 'manager' ? { cursor: 'pointer' } : {}}
                    >
                        Past Due!
                    </Badge>
                </Tooltip>
                {location.pathname!=='/billing'?(
                    <Portal zIndex={10} position="fixed">
                        <Box
                            sx={{
                                position: "fixed",
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: '#000000de',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Card pb={0}>
                                <TenantBillingWarnings showButtons={true} />
                            </Card>
                        </Box>
                    </Portal>
                ):null}
            </React.Fragment>
        )
    }

    return null

}
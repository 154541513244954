import { Box, Grid } from "@mantine/core";
import { OrderInfoViewProps } from "./_types";
import { InfoItemPlain } from "components/ui/InfoItem";
import { checkItemValidity } from "./_utils";

const PickUpInspection: React.FC<OrderInfoViewProps> = ({ order, invalidFields }) => {
    return (
        <Box>

            <Grid grow gutter="xs">

                <Grid.Col span={12}>
                    <InfoItemPlain label="Fuel" value={order.fuel_in} validation={checkItemValidity('fuel_in', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Miles" value={order.miles_in} type="number" validation={checkItemValidity('miles_in', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Images" value={order.pickup_images?.split(',').length} validation={checkItemValidity('pickup_images', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Video" value={order.pickup_videos?.split(',').length} validation={checkItemValidity('pickup_videos', invalidFields)} />
                </Grid.Col>

            </Grid>

        </Box>
    )
}

export default PickUpInspection;
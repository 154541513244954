import React from "react"
import { Box, ScrollArea } from "@mantine/core"

import PageHeader from "components/layout/page/PageHeader"

import { orderStateTitleMap } from "./OrderUtils";
import { OrderFragment } from "state/gql/_types";

const TenantOrderPreview: React.FC<{order: OrderFragment}> = ({ order, children }) => {

    return (
        <React.Fragment>

            <Box
                sx={theme => ({
                    height: '100%',
                    display: 'grid',
                    [theme.fn.smallerThan('md')]: {
                        gridTemplateRows: 'auto 1fr auto',
                    },
                    [theme.fn.largerThan('md')]: {
                        gridTemplateRows: 'auto 1fr',
                    },
                })}
            >

                <PageHeader
                    title={`Order #${order.order_number} - ${orderStateTitleMap[order.state]}`}
                />

                <Box sx={{ position: 'relative', height: '100%' }}>

                    <ScrollArea
                        style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0
                        }}
                    >

                        <Box sx={(theme) => ({
                            padding: theme.spacing.md,
                            paddingTop: 0
                        })}>

                            {children}

                        </Box>

                    </ScrollArea>

                </Box>

            </Box>

        </React.Fragment>
    )

}

export default TenantOrderPreview
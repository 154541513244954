import { OrderFragment } from 'state/gql/_types';
import { createMachine, Event } from 'xstate';

type MachineContext = OrderFragment

export type MachineEvents =
    | { type: 'select_vehicle' }
    | { type: 'select_client' }
    | { type: 'verify_client_details' }
    | { type: 'verify_license' }
    | { type: 'verify_insurance' }
    | { type: 'select_rental_dates' }
    | { type: 'select_rental_terms' }
    | { type: 'initiate_delivery' }
    | { type: 'start_delivery_inspection' }
    | { type: 'generate_contract' }
    | { type: 'sign_contract' }
    | { type: 'rental_active' }
    | { type: 'initiate_pickup' }
    | { type: 'start_pickup_inspection' }
    | { type: 'completed' }
    | { type: 'cancelled' }
    | { type: 'finalized' }

export type MachineEvent = Event<MachineEvents>

type OrderTypestate =
    | { value: 'select_vehicle', context: MachineContext }
    | { value: 'select_client', context: MachineContext }
    | { value: 'verify_client_details', context: MachineContext }
    | { value: 'verify_license', context: MachineContext }
    | { value: 'verify_insurance', context: MachineContext }
    | { value: 'select_rental_dates', context: MachineContext }
    | { value: 'select_rental_terms', context: MachineContext }
    | { value: 'initiate_delivery', context: MachineContext }
    | { value: 'start_delivery_inspection', context: MachineContext }
    | { value: 'generate_contract', context: MachineContext }
    | { value: 'sign_contract', context: MachineContext }
    | { value: 'rental_active', context: MachineContext }
    | { value: 'initiate_pickup', context: MachineContext }
    | { value: 'start_pickup_inspection', context: MachineContext }
    | { value: 'completed', context: MachineContext }
    | { value: 'cancelled', context: MachineContext }
    | { value: 'finalized', context: MachineContext }

const OrderMachine = createMachine<MachineContext, MachineEvents, OrderTypestate>(
    {
        /** @xstate-layout N4IgpgJg5mDOIC5QHsBOEyoLQFsCGAxgBYCWAdmAHSxgA2YBALgPoBuYpB9AxDfU8y4kwZRgG0ADAF1EoAA7JYJRiWRlZIAB6IAbAFYATJQMB2AIwSJZgMx6ALDpMBOABwAaEAE9EZgy8qWVpb6Jjouei7WAL5RHmgY2PjE5FR8DCxCIozc7KgkAGaegrTCoswYjHgktLCSMkggCkoqahraCE46RibWTj0Sfoa+Ht4I4ZQR1naWLi52oXN6MXHomLiEpBTUdOnFpdlpAuyc9HUaTcqq6g3t+kamFla2Ds7uXogmJhOBOhJO1lY5k4nMsQPE1klNlRcgUipkyhUqjUcphYcwSgQRDQzg0Li1rqB2jYdDpKDY9BFnGYTHZwtYRohxpNphJZvMwnYlrEwatEhsUpQYYV0SRMWQaCi8sLyLAAK6oPBkTE4+SKS6tG4+MISShOCQ6awuAZ-eYGOwMsZ6CaRFlshac0HgvnJLZCooy+WKzG8HYCVBZPC0cp4RhwFWNNX4tqIAwSaykhyzPpOPR-CQ0i1Mm0zOb2rkrBLrF2pX0sf2iQPB0OwH38MsBoOh1A4WrSc6Rq7RhBm6xfWb2Mx2JzU37096W61THPsxaO3lFqHbOvMcuVRuYFvcciXENgcp0Ei5TzhvGdzXduZmXUuFN6Qd-PTWM2Zq3M6d5ueFyEC7cqXf7koj14SpUBYDBAMwd1xTkdIrhPDsNUJRBrDMWZdXmEwBkcVxhnHLMp1ZXMOXzHkv35LZYBAsCDyPZgZRgpgrm4GAKAVUNBDURgFSYeDmjPJCEHjTDKH0CIXB0MxDCsEw9BfSdbSI2duSdBcBRYzB-wITjuIOEgoDIDjRB03j1QJLREGmexdQ5QjZj+OS30ImcHWU+dvwovSDK0ozCGyVdK18w8wBMqNzzsXsdUk35UzsMxUNZBzsycj9XLI4tKH8oNAvYLcyB3di5FFABrWU5BC-jzIQF47GMaKJAiGxfFkvDXySu1iM-CFyKoX8SH-QqCBKuRgLwUDmAGoa6Og2C1HKxDKtTCQaqCMwDSTaxDUSgj2qUgsuvSyjRpYCbSqm2AGPxbgtJwOR6FDCA5rM9p7GE-sDB0f4Gt7ZrRnwhTnJIlT3Koa7brAe7uHychAxIAAvSBHq7PQaSMfQDWcJx5jsbGtv+lLuTIZAMHgBoge69s+Pm9psesSgNqmTo-m+i0sAsAJAg2j6XDNJaDAMTrnUXQ4WGOUV6Ap0yuyHK96aHX5-hkzNaccnb5gF1SKNLPYsgl0KBJcaljFZWlzBcZwTFmTNSVZbbFJcvbBYFN1tYRcGkRJ1VKaenxVv8BXMMk-VzDCK2Ajau3Abc7rBVRYUMSxMBdYqolDH8Y0+nCuWDXNPDrciO8DExixyRMdXgZjqUoLlBUlUT3EEO9hBUINMkzbCQdwgpbnQ5tvGOtS-aha1zKqzgJOqZjM0r0cTDL0+WKnB78OAbL6PhZXBtmCbFtx8b97scocLQkLo1+imJfbZXgfHa2Xr-3AoLUFGT3JfPAEzCcazO+BYEZPmC++67VIoPAUh0xoP1ovRGaZlTwT0EpnOm0knwRELpJH6jI86XxSg7DWVB1JsT3N5Livld5dkNI4OmvhULAgMPYPmAD3z9xweXJQ+lDLEKYKQsKBhDb6j5iYWMFIUImAYclJhwCb5UBHtlOuL89aVU5O9Sgs8nz1WpPnURqtI5pUXHfAqxVSpcIErScKIlbC9gEXoEkmiI6rwOlRcaBi5BnQuhVWBjdDCxXQp8PQepUH6hsVfZh0dQZ3UgEYhaEkrymFZI4eqBsdCBOwRI3BlAoZkBhvDCAETnriS+M8QufwdDTAzHhGqkQpgAmmCmWw4QYgxCAA */
        id: "order-machine",
        initial: "select_vehicle",
        states: {
            select_vehicle: {
                on: {
                    select_client: {
                        target: "select_client"
                    },
                    cancelled: {
                        target: "cancelled"
                    },
                },
                meta: {
                    settings: [
                        "cancelled"
                    ]
                }
            },
            select_client: {
                on: {
                    verify_client_details: {
                        target: "verify_client_details",
                    },
                    select_vehicle: {
                        target: "select_vehicle",
                    },
                    cancelled: {
                        target: "cancelled",
                    },
                },
                meta: {
                    settings: [
                        "select_vehicle",
                        "cancelled"
                    ]
                }
            },
            verify_client_details: {
                on: {
                    verify_license: {
                        target: "verify_license",
                    },
                    select_vehicle: {
                        target: "select_vehicle",
                    },
                    select_client: {
                        target: "select_client",
                    },
                    cancelled: {
                        target: "cancelled"
                    },
                },
                meta: {
                    settings: [
                        "select_vehicle",
                        "select_client",
                        "cancelled"
                    ]
                }
            },
            verify_license: {
                on: {
                    verify_insurance: {
                        target: "verify_insurance",
                    },
                    select_vehicle: {
                        target: "select_vehicle",
                    },
                    select_client: {
                        target: "select_client",
                    },
                    cancelled: {
                        target: "cancelled",
                    },
                },
                meta: {
                    settings: [
                        "select_vehicle",
                        "select_client",
                        "cancelled"
                    ]
                }
            },
            verify_insurance: {
                on: {
                    select_rental_dates: {
                        target: "select_rental_dates",
                    },
                    select_vehicle: {
                        target: "select_vehicle",
                    },
                    select_client: {
                        target: "select_client",
                    },
                    cancelled: {
                        target: "cancelled"
                    },
                },
                meta: {
                    settings: [
                        "select_vehicle",
                        "select_client",
                        "cancelled"
                    ]
                }
            },
            select_rental_dates: {
                on: {
                    select_rental_terms: {
                        target: "select_rental_terms",
                    },
                    select_vehicle: {
                        target: "select_vehicle",
                    },
                    select_client: {
                        target: "select_client",
                    },
                    cancelled: {
                        target: "cancelled"
                    },
                },
                meta: {
                    settings: [
                        "select_vehicle",
                        "select_client",
                        "cancelled"
                    ]
                }
            },
            select_rental_terms: {
                on: {
                    initiate_delivery: {
                        target: "initiate_delivery",
                    },
                    select_vehicle: {
                        target: "select_vehicle",
                    },
                    select_client: {
                        target: "select_client",
                    },
                    select_rental_dates: {
                        target: "select_rental_dates",
                    },
                    cancelled: {
                        target: "cancelled"
                    },
                },
                meta: {
                    settings: [
                        "select_vehicle",
                        "select_client",
                        "select_rental_dates",
                        "cancelled"
                    ]
                }
            },
            initiate_delivery: {
                on: {
                    start_delivery_inspection: {
                        target: "start_delivery_inspection",
                    },
                    select_vehicle: {
                        target: "select_vehicle",
                    },
                    select_client: {
                        target: "select_client",
                    },
                    select_rental_dates: {
                        target: "select_rental_dates",
                    },
                    select_rental_terms: {
                        target: "select_rental_terms",
                    },
                    cancelled: {
                        target: "cancelled"
                    },
                },
                meta: {
                    settings: [
                        "select_vehicle",
                        "select_client",
                        "select_rental_dates",
                        "select_rental_terms",
                        "cancelled"
                    ]
                }
            },
            start_delivery_inspection: {
                on: {
                    generate_contract: {
                        target: "generate_contract",
                    },
                    cancelled: {
                        target: "cancelled"
                    },
                },
                meta: {
                    settings: [
                        "cancelled"
                    ]
                }
            },
            generate_contract: {
                on: {
                    sign_contract: {
                        target: "sign_contract",
                    },
                    cancelled: {
                        target: "cancelled",
                    },
                },
                meta: {
                    settings: [
                        "cancelled"
                    ]
                }
            },
            sign_contract: {
                on: {
                    rental_active: {
                        target: "rental_active",
                    },
                    cancelled: {
                        target: "cancelled"
                    },
                },
                meta: {
                    settings: [
                        "cancelled"
                    ]
                }
            },
            rental_active: {
                on: {
                    initiate_pickup: {
                        target: "initiate_pickup",
                    },
                    cancelled: {
                        target: "cancelled"
                    },
                },
                meta: {
                    settings: [
                        "cancelled"
                    ]
                }
            },
            initiate_pickup: {
                on: {
                    start_pickup_inspection: {
                        target: "start_pickup_inspection",
                    },
                    cancelled: {
                        target: "cancelled"
                    },
                },
                meta: {
                    settings: [
                        "cancelled"
                    ]
                }
            },
            start_pickup_inspection: {
                on: {
                    completed: {
                        target: "completed",
                    },
                    cancelled: {
                        target: "cancelled"
                    },
                },
                meta: {
                    settings: [
                        "cancelled"
                    ]
                }
            },
            completed: {
                on: {
                    finalized: {
                        target: "finalized"
                    },
                },
            },
            cancelled: {
                on: {
                    finalized: {
                        target: "finalized"
                    },
                },
            },
            finalized: {
                type: "final",
            }
        },
    }
)

export default OrderMachine
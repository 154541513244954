import { Module } from "state/Module"
import * as Types from "state/gql/_types"

export const VehicleModule = new Module<Types.VehicleFragment, Types.GetVehiclesQueryVariables, Types.Vehicles_Insert_Input>({
    sortKey: 'year',
    filter: {
        where: {
            status: {
                _neq: Types.Vehicles_Status_Enum.Deleted
            }
        }
    }
})
import React from 'react';
import { Badge, Group, Text } from '@mantine/core';

import { useActions, useAppState } from 'state';
import { ClientFragment } from 'state/gql/_types';

import { capitalizeWords } from 'utils/string';
import { parseUserStatusColor } from 'components/root/user/UserUtils';

import Icons from 'components/ui/Icons';
import PageDrawerAction, { DrawerActionProps } from 'components/layout/page/PageDrawerAction';

import ActionsDrawer, { ActionsDrawerProps } from 'components/ui/ActionsDrawer';
import EditClientAction from './actions/EditClientAction';
import EditClientLicenseAction from './actions/EditClientLicenseAction';
import CreateClientAction from './actions/CreateClientAction';
import EditClientInsuranceAction from './actions/EditClientInsuranceAction';
import EditClientLicenseImagesAction from './actions/EditClientLicenseImagesAction';
import EditClientInsuranceImageAction from './actions/EditClientInsuranceImageAction';

export type ClientDrawerActions = 'edit' | 'edit_license' | 'edit_insurance' | 'edit_license_images' | 'edit_insurance_image'

const ActionsDrawerComponent: React.FC<DrawerActionProps<ClientFragment,ClientDrawerActions>> = ({ currentItemId, defaultAction, onClose }) => {

    const { currentItem } = useAppState().clients
    const { subscribeToItem, unsubscribeFromItem } = useActions().clients

    let header = () => {
        if (!currentItem) return null;
        return (
            <Group position="apart" align="center" mb="sm">
                <Group spacing="xs">
                    <Text weight="bold" size="lg">{capitalizeWords(`${currentItem.first_name} ${currentItem.last_name}`)}</Text>
                </Group>
                <Badge size="lg" variant="outline" color={parseUserStatusColor(currentItem.status)}>{currentItem.status}</Badge>
            </Group>
        )
    }

    let licenseSet = [currentItem?.license_number, currentItem?.license_state, currentItem?.license_exp_date].every(i=>!!i)
    let licenseImagesSet = [currentItem?.license_image_front, currentItem?.license_image_back].every(i=>!!i)
    let insuraceSet = [currentItem?.insurance_name, currentItem?.insurance_number, currentItem?.insurance_start_date, currentItem?.insurance_end_date].every(i=>!!i)
    let insuraceImagesSet = [currentItem?.insurance_image].every(i=>!!i)

    let actions: ActionsDrawerProps<ClientFragment, ClientDrawerActions>['actions'] = {
        edit: {
            icon: 'clientDetails',
            label: 'Update Client Details',
            valid: true,
            element: EditClientAction
        },
        edit_license: {
            icon: 'clientLicense',
            label: licenseSet ? 'Update License' : 'Set License',
            valid: licenseSet,
            element: EditClientLicenseAction,
            postAction: 'edit_license_images'
        },
        edit_license_images: {
            icon: 'images',
            label: licenseImagesSet ? 'Update License Photos' : 'Set License Photos',
            valid: licenseImagesSet,
            element: EditClientLicenseImagesAction,
            postAction: 'edit_insurance'
        },
        edit_insurance: {
            icon: 'clientInsurance',
            label: insuraceSet ? 'Update Insurance' : 'Set Insurance',
            valid: insuraceSet,
            element: EditClientInsuranceAction,
            postAction: 'edit_insurance_image'
        },
        edit_insurance_image: {
            icon: 'image',
            label: insuraceImagesSet ? 'Update Insurance Photo' : 'Set Insurance Photo',
            valid: insuraceImagesSet,
            element: EditClientInsuranceImageAction
        }
    }  

    let createConfig: ActionsDrawerProps<ClientFragment, ClientDrawerActions>['create'] = {
        element: CreateClientAction,
        postAction: 'edit_license'
    }

    return (
        <ActionsDrawer<ClientFragment, ClientDrawerActions>
            header={header}
            subscribe={subscribeToItem}
            unsubscribe={unsubscribeFromItem}
            create={createConfig}
            actions={actions}
            defaultAction={defaultAction}
            currentItemId={currentItemId}
            currentItem={currentItem}
            onClose={onClose}
        />
    )

}

export const TenantClientDrawerAction: React.FC<{ itemId?: string, defaultAction?: 'none' | ClientDrawerActions }> = ({ itemId, defaultAction }) => {

    return (
        <PageDrawerAction<ClientFragment>
            title='Manage Client'
            action={ActionsDrawerComponent}
            icon={Icons.edit}
            props={{ itemId, defaultAction }}
            color="dark"
        />
    )
}

export const TenantClientsDrawerAction: React.FC<{ }> = () => {

    return (
        <PageDrawerAction<ClientFragment>
            title='Create Client'
            action={ActionsDrawerComponent}
            icon={Icons.add}
            color="dark"
        />
    )
}
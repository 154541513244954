import { PageTabs } from "components/ui/Tabs";

import { useAppState } from "state";

const TenantClientTabs = () => {

    const { currentItemId } = useAppState(state=>state.clients)

    return ( 
        <PageTabs
            parentId={currentItemId}
            urlRoot='/clients'
            tabs={{
                details: {
                    label: 'Details',
                    icon: 'clientDetails',
                    route: ''
                },
                license: {
                    label: 'License',
                    icon: 'clientLicense',
                    route: 'license'
                },
                insurance: {
                    label: 'Insurance',
                    icon: 'clientInsurance',
                    route: 'insurance'
                }
            }}
        />
    )

}

export default TenantClientTabs;
import { Order_Status_Enum, Order_Steps_Enum } from "state/gql/_types";

export const orderStatusIndex = (status: Order_Status_Enum): number => {
    switch (status) {
        case Order_Status_Enum.Pending:
            return 0
        case Order_Status_Enum.Open:
            return 1
        case Order_Status_Enum.Completed:
        case Order_Status_Enum.Cancelled:
        case Order_Status_Enum.Archived:
            return 3
        default:
            return 0;
    }
}

export const mergeMeta = (meta: {[index: string]: any}): {[index: string]: any} => {
    return Object.keys(meta).reduce((acc, key) => {
        const value = meta[key];
        Object.assign(acc, value);
        return acc;
    }, {});
}

export const parseOrderStatusColor = (status: Order_Status_Enum): string => {
    switch (status) {

        case 'pending':
            return 'blue';

        case 'open':
            return 'green';

        default:
            return 'gray'

    }
}

export const orderStateTitleMap: { [index: string]: string } = {
    'select_vehicle': 'Vehicle Selection',
    'select_client': 'Client Selection',
    'verify_client_details': 'Client Details',
    'verify_license': 'Client License',
    'verify_insurance': 'Verify Insurance',
    'select_rental_dates': 'Rental Dates',
    'select_rental_terms': 'Rental Terms',
    'initiate_delivery': 'Start Delivery',
    'start_delivery_inspection': 'Delivery Inspection',
    'generate_contract': 'Contract Generation',
    'sign_contract': 'Contract Approval',
    'rental_active': 'Rental in Progress',
    'extend_rental': 'Rental Extension',
    'report_incident': 'Report Incident',
    'initiate_pickup': 'Start PickUp',
    'start_pickup_inspection': 'PickUp Inspection',
    'completed': 'Rental Completed',
    'cancelled': 'Rental Cancelled',
}

// export const parseOrderStateColor = (status: Order_Status_Enum): string => {
//     switch (status) {

//         select_vehicle
//         select_client
//         verify_client_details
//         verify_license
//         verify_insurance
//         select_rental_dates
//         select_rental_terms
//         initiate_delivery
//         start_delivery_inspection
//         generate_contract
//         sign_contract
//         rental_active
//         extend_rental
//         report_incident
//         initiate_pickup
//         start_pickup_inspection
//         completed

//     }
// }

export const parseOrderStepColor = (status: Order_Steps_Enum): string => {
    switch (status) {

        case 'preparing_order' :
        case 'being_delivered' :
        case 'being_picked_up' :
            return 'blue'
            
        case 'ready_for_delivery' :
        case 'ready_for_pickup' :
            return 'yellow'
        
        case 'delivered' :        
            return 'green'

        default:
            return 'gray'

    }
}
import { Vehicles_Status_Enum } from "state/gql/_types";

export const parseVehicleStatusColor = (status: Vehicles_Status_Enum): string => {
    switch (status) {

        case 'available':
            return 'green';

        case 'unavailable':
            return 'red';

        default:
            return 'gray'

    }
}
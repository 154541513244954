import { Loader, TextInput } from "@mantine/core";
import React from "react";
import { GenericFieldProps } from "utils/forms";
import { capitalizeWords } from "utils/string";

type VinInfo = {
    Make: string;
    Model: string;
    ModelYear: string;
}

type VinLookupResults = {
    Count: number;
    Message: string;
    SearchCriteria: string;
    Results: VinInfo[]
}

const VinLookup: React.FC<GenericFieldProps> = ({ id, name, label, onChange, value: initialValue, error, required, description, disabled, setValues, setValueKeys, classNames }) => {

    const [value,setValue] = React.useState(initialValue)
    const [loading,setLoading] = React.useState(false)

    const handleBlur = async (event: React.FocusEvent<HTMLInputElement, Element>) => {
        onChange(event.target.value)
        setLoading(true)
        if(!event.target.value.length) return;
        let res = await fetch(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValuesExtended/${event.target.value}?format=json`)
        let data: VinLookupResults = await res.json()
        let sucess = data.Results && data.Results.length
        if(sucess) {
            let { Make, Model, ModelYear } = data.Results[0]
            let info: Record<string, string> = {
                make: capitalizeWords(Make.toLocaleLowerCase()),
                model: capitalizeWords(Model.toLocaleLowerCase()),
                year: ModelYear
            }
            if (setValues&&setValueKeys&&setValueKeys.length) {
                setValueKeys.forEach(field=>setValues(field, info[field]))
            }
        }
        setLoading(false)
    }

    const handleChange = (event: React.FocusEvent<HTMLInputElement, Element>) => {
        setValue(event.target.value.toUpperCase())
    }

    return (
        <TextInput
            type="text"
            label={label}
            id={id}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value||''}
            error={error}
            required={required}
            disabled={disabled}
            description={description}
            rightSection={loading?<Loader size="xs" />:null}
            classNames={classNames}
        />
    )

}

export default VinLookup

import { IContext } from 'overmind';
import { createActionsHook, createEffectsHook, createReactionHook, createStateHook } from 'overmind-react';
import { namespaced, merge } from 'overmind/config';

import { state, actions, effects } from 'state/app';

import { gql, subscriptions } from 'state/gql';

import * as tenants from 'state/tenants'
import * as users from 'state/users'
import * as clients from 'state/clients'
import * as vehicles from 'state/vehicles'
import * as orders from 'state/orders'
import * as reservations from 'state/reservations'
import * as utils from 'state/utils'

export const AppModules = {
    tenants,
    users,
    clients,
    vehicles,
    orders,
    reservations,
    utils
} 

export const AppStateConfig = merge(
    {
        state,
        actions,
        effects: {
            ...effects,
            gql,
            subscriptions
        }
    }, 
    namespaced(AppModules)
)

export const useAppState = createStateHook<IContext<typeof AppStateConfig>>()
export const useActions = createActionsHook<IContext<typeof AppStateConfig>>()
export const useEffects = createEffectsHook<IContext<typeof AppStateConfig>>()
export const useReaction = createReactionHook<IContext<typeof AppStateConfig>>()
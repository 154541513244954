import { Box, Grid } from "@mantine/core";
import { capitalizeWords } from "utils/string";
import { OrderInfoViewProps } from "./_types";
import { InfoItemPlain } from "components/ui/InfoItem";

const VehicleInfoView: React.FC<OrderInfoViewProps> = ({ order }) => {
    if (!order.vehicle) return null;
    let v = order.vehicle
    return (
        <Box>

            <Grid grow gutter="xs">

            <Grid.Col span={12}>
                    <InfoItemPlain label="Name" value={capitalizeWords(`${v.make} ${v.model}`)} />
                </Grid.Col>

                <Grid.Col span={6}>
                    <InfoItemPlain label="Year" value={v.year} />
                </Grid.Col>

                <Grid.Col span={6}>
                    <InfoItemPlain label="Miles" value={v.miles} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Plate" value={v.plate} />
                </Grid.Col>

            </Grid>

        </Box>
    )
}

export default VehicleInfoView;
import { Box, Grid } from "@mantine/core";
import { OrderInfoViewProps } from "./_types";
import { InfoItemPlain } from "components/ui/InfoItem";
import { formatDate, formatDuration, formatHour } from "utils/dates";
import { checkItemValidity } from "./_utils";

const RentalDatesInfoView: React.FC<OrderInfoViewProps> = ({ order,invalidFields }) => {
    return (
        <Box>

            <Grid grow gutter="xs">

                <Grid.Col span={12}>
                    <InfoItemPlain label="Start Date" value={formatDate(order.start_date)} validation={checkItemValidity('start_date',invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Start Time" value={formatHour(order.start_time)} validation={checkItemValidity('start_time',invalidFields)}/>
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="End Date" value={formatDate(order.end_date)} validation={checkItemValidity('end_date',invalidFields)}/>
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="End Time" value={formatHour(order.end_time)} validation={checkItemValidity('end_time',invalidFields)}/>
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Rental Period" value={formatDuration(order.rental_period)} validation={checkItemValidity('rental_period',invalidFields)}/>
                </Grid.Col>

            </Grid>

        </Box>
    )
}

export default RentalDatesInfoView; 
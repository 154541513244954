import React from 'react';
import { Badge, Group, Text } from '@mantine/core';

import { useActions, useAppState } from 'state';
import { VehicleFragment, Vehicles_Status_Enum } from 'state/gql/_types';

import { capitalizeWords } from 'utils/string';
import { parseVehicleStatusColor } from './TenantVehicleUtils';

import Icons from 'components/ui/Icons';
import PageDrawerAction, { DrawerActionProps } from 'components/layout/page/PageDrawerAction';

import CreateVehicleAction from './actions/CreateVehicleAction';
import EditVehicleAction from './actions/EditVehicleAction';
import EditVehiclePictureAction from './actions/EditVehiclePicture';
import ActionsDrawer, { ActionsDrawerProps } from 'components/ui/ActionsDrawer';
import EditVehicleInsuranceAction from './actions/EditVehicleInsuranceAction';
import { useNavigate } from 'react-router-dom';

export type VehicleDrawerActions = 'edit' | 'edit_picture'

const TenantVehicleActions: React.FC<DrawerActionProps<VehicleFragment, VehicleDrawerActions>> = ({ currentItemId, defaultAction, onClose }) => {

    const navigate = useNavigate()
    const tenant = useAppState().tenant
    const { currentItem } = useAppState().vehicles
    const { subscribeToItem, unsubscribeFromItem, updateItem } = useActions().vehicles

    const header = () => {
        if (!currentItem) return null;
        return (
            <Group position="apart" align="center" mb="sm">
                <Group spacing="xs">
                    <Text weight="bold" size="lg">{capitalizeWords(`${currentItem.year} ${currentItem.make} ${currentItem.model}`)}</Text>
                </Group>
                <Badge size="lg" variant="outline" color={parseVehicleStatusColor(currentItem.status)}>{currentItem.status}</Badge>
            </Group>
        )
    }

    const disableVehicleTrigger = async () => {
        if (currentItem) {
            await updateItem({
                id: currentItem.id,
                _set: {
                    status: Vehicles_Status_Enum.Disabled
                }
            })
        }
    }

    const enableVehicleTrigger = async () => {
        if (currentItem) {
            await updateItem({
                id: currentItem.id,
                _set: {
                    status: Vehicles_Status_Enum.Available
                }
            })
        }
    }

    const deleteVehicleTrigger = async () => {
        if (currentItem) {
            await updateItem({
                id: currentItem.id,
                _set: {
                    status: Vehicles_Status_Enum.Deleted
                }
            })
            navigate('/vehicles')
        }
    }

    let insuraceSet = [currentItem?.insurance_name, currentItem?.insurance_number, currentItem?.insurance_start_date, currentItem?.insurance_end_date].every(i=>!!i)

    let actions: ActionsDrawerProps<VehicleFragment, VehicleDrawerActions>['actions'] = {
        edit: {
            icon: 'edit',
            label: 'Edit Vehicle Details',
            valid: true,
            element: EditVehicleAction
        },
        edit_picture: {
            icon: 'image',
            label: !!currentItem?.picture ? 'Edit Vehicle Picture' : 'Upload Vehicle Picture',
            valid: !!currentItem?.picture,
            element: EditVehiclePictureAction
        }
    }

    if (tenant.info&&tenant.info.vehicle_policy_enabled) {
        actions.edit_insurance = {
            icon: 'clientInsurance',
            label: insuraceSet ? 'Edit Insurance Policy' : 'Set Insurance Policy',
            valid: insuraceSet,
            element: EditVehicleInsuranceAction
        }
    }

    let createConfig: ActionsDrawerProps<VehicleFragment, VehicleDrawerActions>['create'] = {
        element: CreateVehicleAction,
        postAction: 'edit_picture'
    }

    let triggers: ActionsDrawerProps<VehicleFragment, VehicleDrawerActions>['triggers'] = {}

    if ( currentItem?.status !== Vehicles_Status_Enum.Disabled && currentItem?.status === Vehicles_Status_Enum.Available ) {
        triggers.disable = {
            color: 'red',
            icon: 'disable',
            label: 'Disable Vehicle',
            onSelect: disableVehicleTrigger
        }
    }

    if ( currentItem?.status === Vehicles_Status_Enum.Disabled ) {
        triggers.enable = {
            color: 'green',
            icon: 'enable',
            label: 'Enable Vehicle',
            onSelect: enableVehicleTrigger 
        }
    }

    if ( currentItem?.status === Vehicles_Status_Enum.Disabled ) {
        triggers.delete = {
            color: 'red',
            icon: 'delete',
            label: 'Delete Vehicle',
            onSelect: deleteVehicleTrigger
        }
    }
    return (
        <ActionsDrawer
            header={header}
            subscribe={subscribeToItem}
            unsubscribe={unsubscribeFromItem}
            create={createConfig}
            actions={actions}
            triggers={triggers}
            defaultAction={defaultAction}
            currentItemId={currentItemId}
            currentItem={currentItem}
            onClose={onClose}
        />
    )

}

export const TenantVehicleDrawerAction: React.FC<{ itemId?: string, defaultAction?: 'none' | VehicleDrawerActions }> = ({ itemId, defaultAction }) => {

    return (
        <PageDrawerAction<VehicleFragment>
            title='Manage Vehicle'
            action={TenantVehicleActions}
            icon={Icons.edit}
            props={{ itemId, defaultAction }}
        />
    )
}

export const TenantVehiclesDrawerAction: React.FC<{ }> = () => {

    return (
        <PageDrawerAction<VehicleFragment>
            title='Create Vehicle'
            action={TenantVehicleActions}
            icon={Icons.add}
        />
    )
}
import { graphql, Query } from 'overmind-graphql';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { queries, mutations } from 'state/gql/_operations';
import { AppState, GraphQLError, GraphQLSubscriptionResultsPayload, SubscriptionInitParams, Subscriptions } from 'state/_types';
import logger from 'utils/logger';

export const gql = graphql({
    queries,
    mutations
});

export const subscriptions = (() => {

    let client: SubscriptionClient;
    let subscriptions: Subscriptions = {};
    let auth: { [index: string]: any } = {
        session_id: null,
        user_id: null,
        session_role: null,
        tenant_id: null
    }

    return {
        initialize(params: SubscriptionInitParams) {
            logger('Initializing GraphQL Subscriptions Layer')
            auth = { ...params.auth, tenant_id: params.tenant.id };
            client = new SubscriptionClient(process.env.REACT_APP_GQL_API_WS_URL || '', {
                reconnect: true,
                connectionParams: {
                    headers: {
                        'X-Hasura-Role': auth.session_role,
                        'X-Hasura-User-Id': auth.user_id,
                        'X-Hasura-Session-Id': auth.session_id,
                        'X-Hasura-Tenant-Id': auth.tenant_id
                    }
                }
            });

        },
        updateClientAuth(newAuth: AppState['auth']) {
            let authUpdates: { [index: string]: any } = { ...newAuth };
            let updatedAuth = Object.keys(newAuth).reduce((updates, authKey) => {
                let newSetting = authUpdates[authKey];
                return auth[authKey] === newSetting ? updates : { ...updates, [authKey]: newSetting };
            }, {})
            if (Object.keys(updatedAuth).length) {
                auth = { ...auth, ...updatedAuth };
                this.updateClient()
            }
        },
        updateClient() {
            logger('Updating GraphQL Subscriptions Layer Auth')
            if (client) client.close();
            client = new SubscriptionClient(process.env.REACT_APP_GQL_API_WS_URL || '', {
                reconnect: true,
                connectionParams: {
                    headers: {
                        'X-Hasura-Role': auth.session_role,
                        'X-Hasura-User-Id': auth.user_id,
                        'X-Hasura-Session-Id': auth.session_id,
                        'X-Hasura-Tenant-Id': auth.tenant_id
                    }
                }
            });
        },
        subscribe<P extends any, V extends Object>(subscriptionKey: string, query: Query<P, V>, variables: V, handler: (payload: GraphQLSubscriptionResultsPayload<P>) => void, callback?: () => void) {
            if (client) {
                if (subscriptions[subscriptionKey]) {
                    subscriptions[subscriptionKey].unsubscribe();
                }
                let observable = client.request({ query: query as any, variables });
                subscriptions[subscriptionKey] = observable.subscribe({
                    next: ({data}) => handler({ error: null, data: data as P }),
                    error: (error) => handler({ error: error as unknown as GraphQLError, data: null })
                });
            } else {
                logger('GraphQL Subscriptions Layer Not Initialized', {}, 'error')
            }
            if (callback) return callback()
        },
        unsubscribe(subscriptionKey: string) {
            if (subscriptions.hasOwnProperty('subscriptionKey')) {
                subscriptions[subscriptionKey].unsubscribe();
            }
        }
    }

})()    
type Payload = {
    url: string
}

export type PortalLinkMode = 'default' | 'payment_method_update' | 'subscription_cancel'
 
export const getBillingPortalLink = async (mode:PortalLinkMode, subscription_id?: string | undefined): Promise<Payload|false> => {

    let params = ''

    try {
        if (mode==='subscription_cancel'&&subscription_id&&subscription_id.length) {
            params = params + `&sub=${subscription_id}`
        } else if (mode==='subscription_cancel'&&(!subscription_id||subscription_id?.length)){
            return false
        }
        let res = await fetch(`${process.env.REACT_APP_AUTH_API_URL||''}/get-portal-redirect?flow=${mode}&source=${window.location.href}${params}`)
        let data = await res.json()
        return data

    } catch (e) {
        console.error(e)
        return false;
    }
    
}
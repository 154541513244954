import { Outlet } from "react-router-dom"

import Layout from "components/navigation/Layout";
import DynamicUserBreadCrumb from "components/root/user/DynamicUserBreadCrumb";
import Icons from "components/ui/Icons";

import NotFound from "screens/global/NotFound";
import TenantHome from "screens/tenant/TenantHome";
import TenantVehicle from "screens/tenant/vehicle/TenantVehicle";
import TenantVehicles from "screens/tenant/vehicle/TenantVehicles";
import DynamicVehicleBreadCrumb from "components/tenant/vehicle/DynamicVehicleBreadcrumb";
import Users from "screens/tenant/user/TenantUsers";
import User from "screens/tenant/user/TenantUser";
import TenantClients from "screens/tenant/client/TenantClients";
import TenantClient from "screens/tenant/client/TenantClient";
import DynamicClientBreadCrumb from "components/tenant/client/DynamicClientBreadCrumb";
import TenantClientLicense from "screens/tenant/client/TenantClientLicense";
import TenantClientInsurance from "screens/tenant/client/TenantClientInsurance";
import TenantOrders from "screens/tenant/order/TenantOrders";
import DynamicOrderBreadCrumb from "components/tenant/order/DynamicOrderBreadcrumb";
import TenantOrderProcess from "screens/tenant/order/TenantOrderProcess";
import TenantOrderDetails from "screens/tenant/order/TenantOrderDetails";
import TenantOrderOverview from "screens/tenant/order/TenantOrderOverview";
import { Portal } from "components/layout/AppShellLayout";
import TenantOrderFiles from "screens/tenant/order/TenantOrderFiles";
import TenantBilling from "screens/tenant/TenantBilling";
import TenantReservations from "screens/tenant/reservation/TenantReservations";
import TenantReservationCreate from "screens/tenant/reservation/TenantReservationCreate";
import DynamicReservationBreadCrumb from "components/tenant/reservations/DynamicReservationBreadCrumb";
import TenantReservation from "screens/tenant/reservation/TenantReservation";
import TenantReservationEdit from "screens/tenant/reservation/TenantReservationEdit";

let tenantRoutes = {
    routes: [
        {
            path: '/',
            element: <Layout />,
            breadcrumb: 'Layout',
            children: [
                { index: true, element: <TenantHome />, breadcrumb: 'Home' },
                {
                    path: "users",
                    element: <Portal />,
                    breadcrumb: 'Users',
                    icon: 'users',
                    children: [
                        { index: true, element: <Users /> },
                        { path: ":id", element: <User />, breadcrumb: DynamicUserBreadCrumb }
                    ] 
                },
                {
                    path: "clients",
                    element: <Portal />,
                    breadcrumb: 'Clients',
                    icon: 'clients',
                    children: [
                        { index: true, element: <TenantClients /> },
                        { 
                            path: ":id", 
                            element: <Outlet />,
                            children: [
                                { index: true, element: <TenantClient />, breadcrumb: DynamicClientBreadCrumb },
                                { path: "license", element: <TenantClientLicense />, breadcrumb: 'License' },
                                { path: "insurance", element: <TenantClientInsurance />, breadcrumb: 'Insurance' },
                            ]
                        }
                    ] 
                },
                {
                    path: "vehicles",
                    element: <Portal />,
                    breadcrumb: 'Vehicles',
                    icon: 'vehicles',
                    children: [
                        { index: true, element: <TenantVehicles /> },
                        { path: ":id", element: <TenantVehicle />, breadcrumb: DynamicVehicleBreadCrumb }
                    ]
                },
                {
                    path: "reservations",
                    element: <Portal />,
                    breadcrumb: 'Reservations',
                    icon: 'reservations',
                    children: [
                        { index: true, element: <TenantReservations /> },
                        { path: "new", element: <TenantReservationCreate />, breadcrumb: 'New Reservation' },
                        { 
                            path: ":id", 
                            element: <Portal />,
                            children: [
                                { index: true, element: <TenantReservation />, breadcrumb: DynamicReservationBreadCrumb },
                                { path: "edit", element: <TenantReservationEdit/>, breadcrumb: "Edit Reservation" }
                            ]
                        }
                    ] 
                },
                {
                    path: "orders",
                    element: <Portal />,
                    breadcrumb: 'Orders',
                    icon: 'orders',
                    children: [
                        { index: true, element: <TenantOrders/> },
                        { 
                            path: ":id", 
                            element: <Portal />,
                            children: [
                                { index: true, element: <TenantOrderOverview />, breadcrumb: DynamicOrderBreadCrumb },
                                { path: "details", element: <TenantOrderDetails />, breadcrumb: "Details" },
                                { path: "files", element: <TenantOrderFiles/>, breadcrumb: "Files" },
                                { path: "process", element: <TenantOrderProcess />, breadcrumb: 'Process' },
                            ]
                        }
                    ]
                },
                {
                    path: "billing",
                    element: <Portal />,
                    breadcrumb: 'Billing',
                    icon: 'billing',
                    children: [
                        { index: true, element: <TenantBilling/> }
                    ]
                },
                { path: "*", element: <NotFound />, breadcrumb: 'Page Not Found', }
            ]
        }
    ],
    menu: [
        {
            to: '/',
            label: "Home",
            icon: Icons.home
        },
        {
            to: '/orders',
            label: "Orders",
            icon: Icons.orders
        },
        {
            to: '/reservations',
            label: "Reservations",
            icon: Icons.reservations
        },
        {
            to: '/vehicles',
            label: "Vehicles",
            icon: Icons.vehicles
        },
        {
            to: '/clients',
            label: "Clients",
            icon: Icons.clients
        },
        {
            to: '/users',
            label: "Users",
            icon: Icons.agents
        }
    ],
    mobile: [
        {
            to: '/',
            label: "Home",
            icon: Icons.home
        },
        {
            to: '/orders',
            label: "Orders",
            icon: Icons.orders
        },
        {
            to: '/vehicles',
            label: "Vehicles",
            icon: Icons.vehicles
        },
        {
            to: '/clients',
            label: "Clients",
            icon: Icons.clients
        },
        {
            to: '/users',
            label: "Users",
            icon: Icons.agents
        }
    ]
}

export default tenantRoutes;
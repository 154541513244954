import { Icon } from '@iconify/react';
import { Badge, BadgeVariant, Box, Card, Group, MantineColor, Text, useMantineTheme } from '@mantine/core';
import { ItemValidation } from 'components/tenant/order/views/_utils';
import NumberFormat from 'react-number-format';
import Icons from './Icons';

import ResponsiveView from './ResponsiveView';

type InfoItemProps = {
    label: string;
    value?: any;
    subvalue?: any;
    number?: boolean;
    currency?: boolean;
    color?: MantineColor;
    type?: 'text' | 'number' | 'currency';
    validation?: ItemValidation
}

const InfoItem: React.FC<InfoItemProps> = ({ label, value, type = 'text', color = "dark", children, subvalue = null }) => {
    const theme = useMantineTheme();
    const renderValue = () => {
        switch (type) {
            case 'number':
            case 'currency':
                return (
                    <NumberFormat
                        value={value}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={type === 'currency' ? '$' : ''}
                        renderText={(value) => <Text weight={600} color={color ? color : theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black} sx={theme => ({ [theme.fn.smallerThan('md')]: { fontSize: 15 } })}>{value}</Text>}
                    />
                )
            default:
                return <Text component="span" weight={600} color={color ? color : theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black} sx={theme => ({ [theme.fn.smallerThan('md')]: { fontSize: 15 } })}>{value}</Text>
        }
    }
    return (
        <Card
            p="sm"
            shadow="sm"
        >
            <Group
                position='apart'
                spacing={2}
            >
                <Text
                    size="sm"
                    sx={theme => ({
                        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[6],
                        lineHeight: '24px'
                    })}
                >
                    {label}
                </Text>
                <Group spacing={5} align="center">
                    {children ? children : renderValue()}
                    {subvalue && <Text component="span" weight={200} size="md" color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black} sx={theme => ({ [theme.fn.smallerThan('md')]: { fontSize: 12 } })}>{subvalue}</Text>}
                </Group>
            </Group>
        </Card>
    )
}

export const InfoItemDownload: React.FC<InfoItemProps & { url: string }> = ({ label, url }) => {
    return (
        <Card
            component='a'
            href={`${process.env.REACT_APP_UPLOAD_API_URL}/download?file=${url}`}
            p="sm"
            shadow="sm"
            sx={(theme) => ({
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[9],
                },
            })}
        >
            <Group
                position='apart'
            >
                <Text
                    size="sm"
                    sx={theme => ({
                        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[6],
                        lineHeight: '24px'
                    })}
                >
                    {label}
                </Text>
                <Icon icon={Icons.download} width={20} />
            </Group>
        </Card>
    )
}

export const InfoItemPlain: React.FC<InfoItemProps> = ({ label, value, type = 'text', color = "dark", children, validation }) => {
    const theme = useMantineTheme();
    let isValid = true
    if (validation) {
        if (!validation.valid) {
            isValid = false;
            color = 'red'
            if (validation.message) {
                value = validation.message
            }
        }
    }
    const renderValue = () => {
        switch (type) {
            case 'number':
            case 'currency':
                return (
                    <NumberFormat
                        value={value}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={type === 'currency' ? '$' : ''}
                        renderText={(value) => <Text weight={600} color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black} sx={theme => ({ [theme.fn.smallerThan('md')]: { fontSize: 13 } })}>{value}</Text>}
                    />
                )
            default:
                return <Text weight={600} color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black} sx={theme => ({ [theme.fn.smallerThan('md')]: { fontSize: 13 } })}>{value}</Text>
        }
    }
    return (
        <Card
            p="xs"
            sx={theme => ({
                background: 'transparent',
                border: `1px solid ${!isValid ? theme.colorScheme === 'dark' ? theme.colors.red[9] : theme.colors.red[1] : theme.colorScheme === 'dark' ? theme.colors.teal[9] : theme.colors.teal[1]}`
            })}
        >
            <Group
                position='apart'
            >
                <Text
                    size="xs"
                    sx={theme => ({
                        color: !isValid ? theme.colors.red[8] : theme.colorScheme === 'dark' ? theme.colors.teal[9] : theme.colors.teal[6],
                        lineHeight: '24px'
                    })}
                >
                    {label}
                </Text>
                {children ? children : (
                    <Group spacing={5}>
                        {renderValue()}
                        {isValid ? (
                            <Icon icon={Icons.successCircle} color={theme.colors.teal[9]} />
                        ) : (
                            <Icon icon={Icons.warningCircle} color={theme.colorScheme === 'dark' ? theme.colors.red[9] : theme.colors.red[9]} />
                        )}
                    </Group>
                )}
            </Group>
        </Card>
    )
}

export const InfoItemTable: React.FC<InfoItemProps> = ({ label, value, type = 'text', color = "dark", children, validation }) => {
    const theme = useMantineTheme();
    let isValid = true
    if (validation) {
        if (!validation.valid) {
            isValid = false;
            color = 'red'
            if (validation.message) {
                value = validation.message
            }
        }
    }
    const renderValue = () => {
        switch (type) {
            case 'number':
            case 'currency':
                return (
                    <NumberFormat
                        value={value}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={type === 'currency' ? '$' : ''}
                        renderText={(value) => <Text weight={600} color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black} sx={theme => ({ fontSize: 13, [theme.fn.smallerThan('md')]: { fontSize: 15 } })}>{value}</Text>}
                    />
                )
            default:
                return <Text weight={600} color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black} sx={theme => ({ fontSize: 13, [theme.fn.smallerThan('md')]: { fontSize: 13 } })}>{value}</Text>
        }
    }
    return (
        <Box
            py={4}
            sx={theme => ({
                // borderBottom: `1px solid ${!isValid ? theme.colorScheme === 'dark' ? theme.colors.red[9] : theme.colors.red[1] : theme.colorScheme === 'dark' ? theme.colors.teal[9] : theme.colors.teal[1]}`,
                // [`&:last-of-type`]: {
                //     borderBottom: 0
                // }
            })}
        >
            <Group
                position='apart'
            >
                <Group spacing={5}>
                    {isValid ? (
                        <Icon icon={Icons.successCircle} color={theme.colors.teal[9]} />
                    ) : (
                        <Icon icon={Icons.warningCircle} color={theme.colorScheme === 'dark' ? theme.colors.red[9] : theme.colors.red[9]} />
                    )}
                    <Text
                        size="xs"
                        sx={theme => ({
                            color: !isValid ? theme.colors.red[8] : theme.colorScheme === 'dark' ? theme.colors.teal[9] : theme.colors.teal[6],
                            lineHeight: '24px'
                        })}
                    >
                        {label}
                    </Text>
                </Group>
                {children ? children : renderValue()}
            </Group>
        </Box>
    )
}


export const InfoItemBadge: React.FC<{ variant?: BadgeVariant, color: MantineColor, body: string }> = ({ variant = 'outline', color, body }) => {
    return (
        <ResponsiveView
            breakPoint='xs'
            renderDesktop={
                <Badge size="md" variant={variant} color={color} my={2}>{body}</Badge>
            }
            renderMobile={
                <Badge size="xs" variant={variant} color={color} my={2}>{body}</Badge>
            }
        />
    )
}

export default InfoItem
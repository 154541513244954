import React from "react";
import { Icon } from "@iconify/react";
import { Button, Text } from "@mantine/core";
import Icons from "components/ui/Icons";
import { useActions, useAppState } from "state";
import { OrderActionProps } from "./_types";

export const GenerateContractAction: React.FC<OrderActionProps> = ({ isValid }) => {

    const currentItem = useAppState(state => state.orders.currentItem)
    const { generateContract } = useActions().orders
    const [loading,setLoading] = React.useState(false)

    const handleClick = async () => {
        if (currentItem) {
            setLoading(true)
            generateContract({
                object: {
                    order_id: currentItem.id
                }
            })
        }
    }

    React.useEffect(()=>{
        if (loading&&currentItem?.contract_id) {
            setLoading(false)
        }
    },[loading,currentItem?.contract_id])

    if (!currentItem) return null;

    return (
        <Button
            onClick={handleClick}
            rightIcon={!loading?<Icon className="action-icon" icon={Icons.edit} color={isValid?"gray":"blue"} width={18} />:null}
            variant={isValid?"outline":"filled"}
            color={isValid?"gray":"blue"}
            loading={loading}
            sx={theme => ({
                '& .action-icon': {
                    color: `${isValid ? theme.colors[isValid?"gray":"blue"][7] : "white"} !important`
                }
            })}
        >
            <Text>{loading?'Generating Contract...':'Generate Contract'}</Text>
        </Button>
    )

}
import React from "react";
import dayjs from "dayjs";
import { ReservationFragment } from "state/gql/_types";
import { Box, Card, ScrollArea, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { parseReservationStatusColor } from "../ReservationUtils";

const DEFAULT_DAY_WIDTH = 48; // 2px per hour x 24 hours
const DEFAULT_MONTH_RANGE = 1;
const GRID_STROKE_COLOR = "#EDEDED";
const DEFAULT_CELL_HEIGHT = 35;

function mergeVehicleDates(reservations: ReservationFragment[]) {
    let vehicleReservations: { [index: string]: ReservationFragment[] } = {};
    reservations.forEach((r) => {
        if (!vehicleReservations.hasOwnProperty(r.vehicle_id)) {
            vehicleReservations[r.vehicle_id] = [];
        }
        vehicleReservations[r.vehicle_id].push(r);
    });
    return vehicleReservations;
}

function getDates(startDate: string, stopDate: string) {
    let dateArray = [];
    let currentDate = dayjs(startDate);
    let endDate = dayjs(stopDate);
    while (currentDate <= endDate) {
        dateArray.push(dayjs(currentDate));
        currentDate = dayjs(currentDate).add(1, "day");
    }
    return dateArray;
}

function getRange(reservations: ReservationFragment[]) {
    let startDate = dayjs().startOf('day');
    let endDate = dayjs().endOf('day').add(DEFAULT_MONTH_RANGE, 'month');
    reservations.forEach((r) => {
        let currentStartDate = dayjs(`${r.start_date} ${r.start_time}`);
        let currentEndDate = dayjs(`${r.end_date} ${r.end_time}`);
        if (currentStartDate < startDate) {
            startDate = currentStartDate;
        }
        if (currentEndDate > endDate) {
            endDate = currentEndDate;
        }
    });
    return { startDate: startDate.startOf("day"), endDate: endDate.endOf("day") };
}

type ReservationTimeLineProps = {
    reservations: ReservationFragment[]
}

const ReservationsTimeLine: React.FC<ReservationTimeLineProps> = ({ reservations }) => {

    const navigation = useNavigate()

    let dateRange = getRange(reservations);

    let datesArray = getDates(
        dateRange.startDate.format("YYYY-MM-DD"),
        dateRange.endDate.format("YYYY-MM-DD")
    );

    let allVehicleReservations = mergeVehicleDates(reservations);

    let reservedVehiclesCount = Object.keys(allVehicleReservations).length;

    const GRID_WIDTH = DEFAULT_DAY_WIDTH * datesArray.length;
    const GRID_HEIGHT = DEFAULT_CELL_HEIGHT * reservedVehiclesCount + DEFAULT_CELL_HEIGHT;

    return (
        <Card
            sx={{
                position: 'relative'
            }}
            mb="md"
            pb={4}
        >

            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: '20% 80%'
                }}
            >

                <Box>

                    <Box
                        sx={{
                            height: '35px',
                            borderBottom: '1px dashed #c3c3c3',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}>
                        <Text weight={500} size="sm">Reservations Timeline</Text>
                    </Box>

                    <Box>
                        {Object.keys(allVehicleReservations).map((vehicleId, rowKey) => {

                            let vehicle = allVehicleReservations[vehicleId][0].vehicle;

                            return (
                                <Box
                                    key={vehicle.id}
                                    sx={{
                                        height: '35px',
                                        borderBottom: '1px dashed #c3c3c3',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        '&:last-of-type': {
                                            borderBottom: 'none'
                                        }
                                    }}>
                                    <Text size="sm" sx={{ lineHeight: '21px' }}>{`${vehicle.year} ${vehicle.make} ${vehicle.model} (${vehicle.color})`}</Text>
                                </Box>
                            );

                        })}
                    </Box>

                </Box>

                <Box
                    sx={{
                        position: 'relative'
                    }}
                >

                    <ScrollArea
                    offsetScrollbars

                        style={{
                            height: GRID_HEIGHT + 10,
                        }}
                    >

                        <Box>

                            <svg id="timeline_grid" width={GRID_WIDTH} height={GRID_HEIGHT} fill="#FFFFFF">

                                <g className="day_labels">
                                    {datesArray.map((d, k) => {
                                        let pos = k;
                                        let isToday = dayjs().isSame(d, 'day')
                                        return (
                                            <React.Fragment key={k}>
                                                {isToday && (
                                                    <rect
                                                        x={DEFAULT_DAY_WIDTH * pos}
                                                        y={0}
                                                        width={DEFAULT_DAY_WIDTH}
                                                        height={GRID_HEIGHT}
                                                        fill="#1c7ed633"
                                                    />
                                                )}
                                                {(d.format("ddd") === 'Sat' || d.format("ddd") === 'Sun') && (
                                                    <rect
                                                        x={DEFAULT_DAY_WIDTH * pos}
                                                        y={0}
                                                        width={DEFAULT_DAY_WIDTH}
                                                        height={GRID_HEIGHT}
                                                        fill="#00000008"
                                                    />
                                                )}
                                                <foreignObject
                                                    className="day_label"
                                                    x={DEFAULT_DAY_WIDTH * pos}
                                                    y={0}
                                                    width={DEFAULT_DAY_WIDTH}
                                                    height={DEFAULT_CELL_HEIGHT}
                                                >
                                                    <div
                                                        className="day_label_text"
                                                        style={{
                                                            height: DEFAULT_CELL_HEIGHT,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            flexDirection: 'column',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Text
                                                            size="xs"
                                                            sx={(theme) => ({
                                                                lineHeight: '13px',
                                                                color: isToday ? 'black' : theme.colors.gray[5]
                                                            })}
                                                        >
                                                            {d.format("ddd")}
                                                        </Text>
                                                        <Text
                                                            size="sm"
                                                            sx={(theme) => ({
                                                                lineHeight: '16px',
                                                                color: isToday ? 'black' : theme.colors.gray[7]
                                                            })}
                                                        >
                                                            {d.format("D")}
                                                        </Text>
                                                    </div>
                                                </foreignObject>
                                            </React.Fragment>
                                        );
                                    })}
                                </g>

                                <g className="day_ticks">
                                    {datesArray.map((d, k) => {
                                        let pos = k + 1;
                                        return datesArray.length !== pos ? (
                                            <line
                                                key={k}
                                                className={`${datesArray.length}_${pos}`}
                                                x1={DEFAULT_DAY_WIDTH * pos}
                                                y1={0}
                                                x2={DEFAULT_DAY_WIDTH * pos}
                                                y2={GRID_HEIGHT}
                                                stroke={GRID_STROKE_COLOR}
                                                strokeWidth={1}
                                                shapeRendering="crispEdges"
                                                strokeDasharray="2,2"
                                            />
                                        ) : null
                                    })}
                                </g>

                                <line
                                    className="header_separator"
                                    x1={0}
                                    y1={DEFAULT_CELL_HEIGHT}
                                    x2={GRID_WIDTH}
                                    y2={DEFAULT_CELL_HEIGHT}
                                    stroke={"#c3c3c3"}
                                    strokeWidth={1}
                                    shapeRendering="crispEdges"
                                    strokeDasharray="2,2"
                                />

                                <g className="reservations">

                                    {Object.keys(allVehicleReservations).map((vehicleId, rowKey) => {

                                        let rowIndex = rowKey + 1;
                                        let vehicleReservations = allVehicleReservations[vehicleId];

                                        return vehicleReservations.map((reservation) => {

                                            let currentStartDate = dayjs(`${reservation.start_date} ${reservation.start_time}`);
                                            let currentEndDate = dayjs(`${reservation.end_date} ${reservation.end_time}`);
                                            let rentalDurationHours = currentEndDate.diff(currentStartDate, "hour");
                                            let rentalStartOffsetHours = currentStartDate.diff(dateRange.startDate, "hour");

                                            return (
                                                <React.Fragment key={reservation.id}>

                                                    <foreignObject
                                                        x={rentalStartOffsetHours * 2}
                                                        y={DEFAULT_CELL_HEIGHT * rowIndex}
                                                        width={rentalDurationHours * 2}
                                                        height={DEFAULT_CELL_HEIGHT}
                                                    >
                                                        <Box
                                                            py={3}
                                                            sx={{
                                                                height: DEFAULT_CELL_HEIGHT,
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                flexDirection: 'column'
                                                            }}
                                                            
                                                            onClick={()=>{
                                                                navigation(`/reservations/${reservation.id}`)
                                                            }}
                                                        >
                                                            <Box
                                                                px="xs"
                                                                sx={theme => ({
                                                                    height: DEFAULT_CELL_HEIGHT,
                                                                    backgroundColor: parseReservationStatusColor(reservation.status),
                                                                    border: `1px solid ${theme.colors.dark[4]}`,
                                                                    borderRadius: theme.radius.xs,
                                                                    color: theme.white,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'flex-start',
                                                                    cursor: 'pointer'
                                                                })}
                                                            >
                                                                <Text size="sm">{`#${reservation.reservation_number}`}</Text>
                                                            </Box>
                                                        </Box>
                                                    </foreignObject>

                                                    {reservedVehiclesCount !== rowIndex && (
                                                        <line
                                                            className={`${reservedVehiclesCount}_${rowIndex}`}
                                                            x1={0}
                                                            y1={DEFAULT_CELL_HEIGHT + DEFAULT_CELL_HEIGHT * rowIndex}
                                                            x2={GRID_WIDTH}
                                                            y2={DEFAULT_CELL_HEIGHT + DEFAULT_CELL_HEIGHT * rowIndex}
                                                            stroke={"#c3c3c3"}
                                                            strokeWidth={1}
                                                            shapeRendering="crispEdges"
                                                            strokeDasharray="2,2"
                                                        />
                                                    )}

                                                </React.Fragment>
                                            );
                                        });
                                    })}

                                </g>

                            </svg>

                        </Box>

                    </ScrollArea>

                </Box>

            </Box>

        </Card>
    );
};

export default ReservationsTimeLine

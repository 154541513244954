import React from "react";
import { Badge, createStyles, Divider, Grid, NumberInput, Select, Switch, Textarea, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";

import { Order_Policy_Type_Enum, User_Roles_Enum } from "state/gql/_types";
import { GenericDictionary } from "state/_types";
import { checkIfFieldHasRequiredValidation, FormConfig, statesList } from "utils/forms";

import ClientSelect from "./ClientSelect";
import ModelSelect from "./ModelSelect";
import VehicleSelect from "./VehicleSelect";
import VinLookup from "./VinLookup";
import { formatDuration, generateHours } from "utils/dates";
import RentalPeriod from "./RentalPeriod";
import { OrderBalanceCalculator, OrderSubTotalCalculator, OrderTotalCalculator } from "./TermFields";
import PhoneField from "./PhoneField";
import CurrentLocation from "./CurrentLocation";
import AssetUpload from "./AssetUpload";
import { useAppState } from "state";
import { sanitizeEmail, sanitizeString, sanitizeStringAllowSpace, sanitizeURL } from "utils/string";
import GuestVehicleSelect from "./GuestVehicleSelect";
import { sanitizeDecimal, sanitizeNumber } from "utils/numbers";
import GuestVehicleTable from "./GuestVehicleTable";
import dayjs from "dayjs";

type FieldRendererProps<PayloadType extends GenericDictionary, T extends GenericDictionary> = {
    formConfig: FormConfig<T>;
    currentItemId?: string | null;
    formMode: 'create' | 'update' | null;
    values: FormikState<PayloadType>['values'];
    errors: FormikState<PayloadType>['errors'];
    setFieldValue: FormikHelpers<PayloadType>['setFieldValue'];
    handleBlur: FormikHandlers['handleBlur'];
    handleChange: FormikHandlers['handleChange'];
}

const userFormStyles = createStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    error: {
        fontSize: theme.fontSizes.xs,
        lineHeight: `${theme.fontSizes.xs + 8}px`,
        marginTop: '-20px'
    },
    wrapper: {
        marginBottom: '20px'
    },
    label: {
        fontSize: theme.fontSizes.sm,
        lineHeight: `${theme.fontSizes.sm}px`,
        display: 'inline-block',
        marginBottom: 8
    }
}))

function FieldRenderer<PayloadType extends GenericDictionary, T extends GenericDictionary>({
    currentItemId = null,
    formConfig,
    formMode,
    values,
    errors,
    setFieldValue,
    handleBlur,
    handleChange
}: React.PropsWithChildren<FieldRendererProps<PayloadType, T>>): React.ReactElement | null {

    const profile = useAppState(state => state.profile)
    const { classes: styles } = userFormStyles();
    const isMobile = useMediaQuery('(max-width: 1024px)');

    return (
        <React.Fragment>

            {Object.keys(formConfig.fields).map((fieldId, key) => {

                let fieldSchema = formConfig.fields[fieldId];
                let formFieldId = fieldId as keyof PayloadType;
                let isFieldRequired = checkIfFieldHasRequiredValidation(fieldSchema);
                let isFieldEditable = formMode === 'create' ? true : formConfig.editable.indexOf(fieldId) > -1;

                const renderInput = () => {

                    switch (fieldSchema.type) {

                        case 'boolean':
                            return (
                                <Switch
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    size="md"
                                    name={formFieldId as string}
                                    onChange={(e) => setFieldValue(formFieldId as string, e.currentTarget.checked)}
                                    checked={values[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                />
                            )

                        case 'state-select':
                            return (
                                <Select
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    placeholder="Select..."
                                    data={statesList}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    autoComplete="address-level1"
                                    classNames={styles}
                                />
                            );

                        case 'role-select':
                            let isSameUserDisabled = formMode === 'update' && currentItemId === profile?.id
                            return (
                                <Select
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    placeholder="Select..."
                                    data={[
                                        { label: 'Manager', value: User_Roles_Enum.Manager },
                                        { label: 'Agent', value: User_Roles_Enum.Agent }
                                    ]}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable || isSameUserDisabled}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                />
                            );

                        case 'policy-type-select':
                            return (
                                <Select
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    placeholder="Select..."
                                    data={[
                                        { label: 'Renter\'s Insurance Policy', value: Order_Policy_Type_Enum.Renter },
                                        { label: 'Vehicle Insurance Policy', value: Order_Policy_Type_Enum.Vehicle }
                                    ]}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                />
                            );

                        case 'tenant-domain':
                            return (
                                <TextInput
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue(formFieldId as string, sanitizeURL(e.target.value.toLocaleLowerCase().replaceAll(' ', '-'))) //@TODO Validation
                                    }}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    rightSection={
                                        <Badge color="red" variant="filled">
                                            .clutch360app.com
                                        </Badge>
                                    }
                                    styles={{
                                        rightSection: {
                                            width: 'auto',
                                            marginRight: 5
                                        }
                                    }}
                                    classNames={styles}
                                />
                            )

                        case 'email':
                            return (
                                <TextInput
                                    type="email"
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(formFieldId as string, sanitizeEmail(e.target.value))}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                />
                            )

                        case 'phone':
                            return (
                                <PhoneField
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, sanitizeString(value))}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                />
                            )

                        case 'current-location':
                            return (
                                <CurrentLocation
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                />
                            )

                        case 'image':
                        case 'vehicle-image':
                        case 'license-image-front':
                        case 'license-image-back':
                        case 'insurance-image':
                            return (
                                <AssetUpload
                                    schemaId={formConfig.id}
                                    parentId={currentItemId}
                                    type={fieldSchema.type}
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    onBlur={handleBlur}
                                    classNames={styles}
                                />
                            )

                        case 'images':
                        case 'vehicle-images':
                        case 'delivery-inspection-images':
                        case 'delivery-inspection-videos':
                        case 'pickup-inspection-images':
                        case 'pickup-inspection-videos':
                            return (
                                <AssetUpload
                                    schemaId={formConfig.id}
                                    parentId={currentItemId}
                                    type={fieldSchema.type}
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    onBlur={handleBlur}
                                    classNames={styles}
                                    allowMultiple={true}
                                    maxFiles={5}
                                />
                            )

                        case 'year-select':
                            return (
                                <Select
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    placeholder="Select..."
                                    searchable
                                    data={['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000']}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    selectOnBlur
                                    clearable
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    autoComplete="off"
                                    classNames={styles}
                                />
                            );

                        case 'make-select':
                            return (
                                <Select
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    searchable
                                    clearable
                                    creatable
                                    selectOnBlur
                                    placeholder="Select..."
                                    data={[
                                        'Acura',
                                        'Alfa Romeo',
                                        'Aston Martin',
                                        'Audi',
                                        'Bentley',
                                        'BMW',
                                        'Bugatti',
                                        'Buick',
                                        'Cadillac',
                                        'Chevrolet',
                                        'Chrysler',
                                        'Dodge',
                                        'Ferrari',
                                        'Lamborghini',
                                        'Fiat',
                                        'Ford',
                                        'GMC',
                                        'Honda',
                                        'Hyundai',
                                        'Infiniti',
                                        'Jaguar',
                                        'Jeep',
                                        'Kia',
                                        'Land Rover',
                                        'Lexus',
                                        'Lincoln',
                                        'Maserati',
                                        'Maybach',
                                        'Mazda',
                                        'McLaren',
                                        'Mercedes-Benz',
                                        'Mini',
                                        'Nissan',
                                        'Porsche',
                                        'Rolls Royce',
                                        'Subaru',
                                        'Tesla',
                                        'Toyota',
                                        'Volkswagen',
                                        'Volvo',
                                    ]}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    onSearchChange={(value: string) => {
                                        if (!value.length && value !== values[formFieldId]) {
                                            setFieldValue('model', '')
                                        }
                                    }}
                                    autoComplete="off"
                                    classNames={styles}
                                />
                            );

                        case 'color-select':
                            return (
                                <Select
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    searchable
                                    clearable
                                    selectOnBlur
                                    placeholder="Select..."
                                    data={[
                                        'Beige',
                                        'Black',
                                        'Blue',
                                        'Brown',
                                        'Gold',
                                        'Gray',
                                        'Green',
                                        'Orange',
                                        'Purple',
                                        'Red',
                                        'Silver',
                                        'White',
                                        'Yellow'
                                    ]}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    autoComplete="off"
                                    classNames={styles}
                                />
                            );

                        case 'rate-type-select':
                            return (
                                <Select
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    placeholder="Select..."
                                    data={[
                                        { label: "Per Day", value: "day" },
                                        { label: "Per Mile", value: "mile" }
                                    ]}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    autoComplete="off"
                                    classNames={styles}
                                />
                            );

                        case 'fuel-select':
                            return (
                                <Select
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    placeholder="Select..."
                                    data={[
                                        "Full Tank",
                                        "3/4 Tank",
                                        "Half Tank",
                                        "1/4 Tank",
                                        "Empty Tank"
                                    ]}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    autoComplete="off"
                                    classNames={styles}
                                />
                            );

                        case 'model-lookup-select':
                            return (
                                <ModelSelect
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    getValues={{
                                        year: values['year'] || '',
                                        make: values['make'] || ''
                                    }}
                                    classNames={styles}
                                />
                            );

                        case 'vin-lookup':
                            return (
                                <VinLookup
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    setValues={setFieldValue}
                                    setValueKeys={['make', 'model', 'year']}
                                    onBlur={handleBlur}
                                    classNames={styles}
                                />
                            )

                        case 'number':
                            return (
                                <NumberInput
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, sanitizeNumber(value))}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    min={0}
                                    defaultValue={0}
                                    precision={0}
                                    step={1}
                                    stepHoldDelay={500}
                                    stepHoldInterval={100}
                                    onBlur={handleBlur}
                                    classNames={styles}
                                    parser={(value) => !!value ? value.replace(/\$\s?|(,*)/g, '') : ''}
                                    formatter={(value) => !!value && !Number.isNaN(parseFloat(value)) ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}
                                    onFocus={(event) => event.target.select()}
                                />
                            );

                        case 'zip':
                            return (
                                <NumberInput
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, sanitizeNumber(value).toString())}
                                    value={(typeof values[formFieldId] !== 'undefined' && values[formFieldId] !== null && values[formFieldId].length) ? parseInt(values[formFieldId]) : values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    onBlur={handleBlur}
                                    classNames={styles}
                                    hideControls
                                />
                            );

                        case 'decimal':
                            return (
                                <NumberInput
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, sanitizeDecimal(value))}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    defaultValue={0}
                                    precision={2}
                                    min={0}
                                    step={0.01}
                                    max={1}
                                    stepHoldDelay={500}
                                    stepHoldInterval={100}
                                    onBlur={handleBlur}
                                    classNames={styles}
                                    onFocus={(event) => event.target.select()}
                                />
                            );

                        case 'client-select-reservation':
                            return (
                                <ClientSelect
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                    setValues={setFieldValue}
                                    setValueKeys={['first_name', 'last_name', 'email', 'phone', 'dob', 'address', 'country', 'city', 'state', 'zip']}
                                />
                            );

                        case 'client-select':
                            return (
                                <ClientSelect
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                />
                            );

                        case 'vehicle-select':
                            return (
                                <VehicleSelect
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                />
                            );
                        case 'guest-vehicle-select':
                            return (
                                <GuestVehicleSelect
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                    getValues={{
                                        start_date: values['start_date'],
                                        end_date: values['end_date']
                                    }}
                                />
                            );

                        case 'guest-vehicle-table':
                            return (
                                <GuestVehicleTable
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                    getValues={{
                                        start_date: values['start_date'],
                                        end_date: values['end_date']
                                    }}
                                />
                            );

                        case 'date-picker':
                            let isSet1 = typeof values[formFieldId] === 'string' && !!values[formFieldId].length
                            let rawDate1 = isSet1 ? new Date(Date.parse(values[formFieldId])) : new Date()
                            rawDate1.setMinutes(rawDate1.getMinutes() + rawDate1.getTimezoneOffset())
                            let dateValue1 = isSet1 ? typeof values[formFieldId] === 'object' ? values[formFieldId] : rawDate1 : null
                            return (
                                <DatePicker
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => {
                                        console.log(value)
                                        if (typeof value === 'string') {
                                            setFieldValue(formFieldId as string, value)
                                        }
                                        if (typeof value === 'object') {
                                            let dobj = value as any
                                            setFieldValue(formFieldId as string, dobj.toISOString())
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    value={dateValue1}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                    dropdownType={isMobile ? 'modal' : 'popover'}
                                    dateParser={(dateString) => new Date(Date.parse(dateString))}
                                />
                            );

                        case 'date-picker-future':
                            let isSet2 = typeof values[formFieldId] === 'string' && !!values[formFieldId].length
                            let rawDate2 = isSet2 ? new Date(Date.parse(values[formFieldId])) : new Date()
                            rawDate2.setMinutes(rawDate2.getMinutes() + rawDate2.getTimezoneOffset())
                            let dateValue2 = isSet2 ? typeof values[formFieldId] === 'object' ? values[formFieldId] : rawDate2 : null
                            return (
                                <DatePicker
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => {
                                        console.log(value)
                                        if (typeof value === 'string') {
                                            setFieldValue(formFieldId as string, value)
                                        }
                                        if (typeof value === 'object') {
                                            let dobj = value as any
                                            setFieldValue(formFieldId as string, dobj.toISOString())
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    value={dateValue2}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                    dropdownType={isMobile ? 'modal' : 'popover'}
                                    dateParser={(dateString) => new Date(Date.parse(dateString))}
                                    minDate={dayjs(new Date()).toDate()}
                                />
                            );

                        case 'time-picker':
                            return (
                                <Select
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    searchable
                                    clearable
                                    selectOnBlur
                                    placeholder="Select..."
                                    data={generateHours()}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    autoComplete="off"
                                    classNames={styles}
                                />
                            );

                        case 'rental-period':
                            return (
                                <RentalPeriod
                                    id={formFieldId as string}
                                    label={fieldSchema.label}
                                    name={formFieldId as string}
                                    onChange={(value) => {
                                        setFieldValue(formFieldId as string, value)
                                    }}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    getValues={{
                                        start_date: values['start_date'] || null,
                                        end_date: values['end_date'] || null
                                    }}
                                    classNames={styles}
                                />
                            );

                        case 'rental-period-display':
                            return (
                                <NumberInput
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    defaultValue={0}
                                    min={0}
                                    precision={0}
                                    step={1}
                                    stepHoldDelay={500}
                                    stepHoldInterval={100}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                    formatter={(value) => formatDuration(value, 'day')}
                                    onFocus={(event) => event.target.select()}
                                    sx={theme => ({
                                        input: {
                                            '&:disabled': {
                                                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                                                opacity: 1
                                            }
                                        }
                                    })}
                                />
                            );

                        case 'currency':
                            return (
                                <NumberInput
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    onBlur={handleBlur}
                                    defaultValue={0}
                                    min={0}
                                    precision={0}
                                    step={1}
                                    stepHoldDelay={500}
                                    stepHoldInterval={100}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                    parser={(value) => !!value ? value.replace(/\$\s?|(,*)/g, '') : ''}
                                    formatter={(value) => !!value && !Number.isNaN(parseFloat(value)) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ '}
                                    onFocus={(event) => event.target.select()}
                                />
                            );

                        case 'order-subtotal':
                            return (
                                <OrderSubTotalCalculator
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    onBlur={handleBlur}
                                    classNames={styles}
                                    getValues={{
                                        rental_period: values['rental_period'] || 0,
                                        day_rate: values['day_rate'] || 0
                                    }}
                                />
                            )

                        case 'order-total':
                            return (
                                <OrderTotalCalculator
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(value) => setFieldValue(formFieldId as string, value)}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    onBlur={handleBlur}
                                    classNames={styles}
                                    getValues={{
                                        sub_total: values['sub_total'] || 0,
                                        sales_tax: values['sales_tax'] || 0,
                                        discount: values['discount'] || 0,
                                        other_fees: values['other_fees'] || 0,
                                    }}
                                />
                            )

                        case 'order-balance':
                            return (
                                <Grid gutter="sm" sx={{ margin: 0 }}>
                                    <Grid.Col sm={6}>
                                        <NumberInput
                                            label={fieldSchema.label}
                                            id={formFieldId as string}
                                            name={formFieldId as string}
                                            onChange={(value) => setFieldValue(formFieldId as string, value)}
                                            onBlur={handleBlur}
                                            defaultValue={0}
                                            min={0}
                                            precision={0}
                                            step={1}
                                            stepHoldDelay={500}
                                            stepHoldInterval={100}
                                            value={values[formFieldId]}
                                            error={errors[formFieldId]}
                                            required={isFieldRequired}
                                            disabled={!isFieldEditable}
                                            description={fieldSchema.help}
                                            classNames={styles}
                                            parser={(value) => !!value ? value.replace(/\$\s?|(,*)/g, '') : ''}
                                            formatter={(value) => !!value && !Number.isNaN(parseFloat(value)) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ '}
                                            onFocus={(event) => event.target.select()}
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={6}>
                                        <OrderBalanceCalculator
                                            label={'Balance'}
                                            id={'order-balance'}
                                            name={'order-balance'}
                                            onChange={() => { }}
                                            value={0}
                                            error={null}
                                            required={false}
                                            disabled={true}
                                            description={''}
                                            onBlur={() => { }}
                                            classNames={styles}
                                            getValues={{
                                                total: values['total'] || 0,
                                                deposit: values['deposit'] || 0
                                            }}
                                        />
                                    </Grid.Col>
                                </Grid>
                            )

                        case 'plate':
                            return (
                                <TextInput
                                    type="text"
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue(formFieldId as string, sanitizeString(e.target.value.toUpperCase()))
                                    }}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                />
                            )

                        case 'country':
                            return (
                                <TextInput
                                    type="text"
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue(formFieldId as string, sanitizeStringAllowSpace(e.target.value))
                                    }}
                                    onBlur={handleBlur}
                                    value={values[formFieldId]}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                />
                            )

                        case 'textarea':
                            return (
                                <Textarea
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        setFieldValue(formFieldId as string, e.currentTarget.value)
                                    }}
                                    onBlur={handleBlur}
                                    value={values[formFieldId] || ''}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    autosize
                                    minRows={1}
                                    maxRows={3}
                                    classNames={styles}
                                />
                            );

                        default:
                            return (
                                <TextInput
                                    type="text"
                                    label={fieldSchema.label}
                                    id={formFieldId as string}
                                    name={formFieldId as string}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue(formFieldId as string, sanitizeStringAllowSpace(e.target.value))
                                    }}
                                    onBlur={handleBlur}
                                    value={values[formFieldId] || ''}
                                    error={errors[formFieldId]}
                                    required={isFieldRequired}
                                    disabled={!isFieldEditable}
                                    description={fieldSchema.help}
                                    classNames={styles}
                                />
                            );

                    }

                }

                let gridWidth = fieldSchema.grid ? fieldSchema.grid : 12;

                return (
                    <React.Fragment key={`${formMode}-${formConfig.id}-field-${key}`}>
                        <Grid.Col sm={gridWidth}>
                            {renderInput()}
                        </Grid.Col>
                        {!!fieldSchema.divider&&(
                            <Grid.Col sm={12}>
                                <Divider my="sm" variant="dotted"/>
                            </Grid.Col>
                        )}
                    </React.Fragment>
                )

            })}

        </React.Fragment>
    )

}

export default FieldRenderer;
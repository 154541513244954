import { AppState } from "state/_types";

export const state: AppState = {
    settings: {
        theme: 'light',
    },
    navigating: false,
    loading: true,
    online: false,
    tenant: {
        id: null,
        type: null,
        slug: null,        
        name: null,
        info: null,
        billing: null,
        parent: {
            id: null,
            type: null,
            slug: null,
            name: null 
        },
        subStatus: 'none',
        hasSubscription: false,
        hasPaymentMethod: false,
        cardExpires: false,
        hasTrial: false,
        trialDays: 0,
        cardExpirationDays: 0
    },
    auth: {
        user_id: null,
        session_id: null,
        session_role: 'public'
    },
    profile: null,
    get userName() {
        return this.profile ? `${this.profile.first_name} ${this.profile.last_name}` : 'Guest'
    },
    ui: {
        view: 'cards'
    }
}
import React from 'react';
import { useParams } from "react-router-dom";

import { useActions, useAppState, useReaction } from 'state';

import OrderWizard from 'components/tenant/order/OrderWizard';
import PageLayoutBlank from 'components/layout/page/PageLayoutBlank';
import { OrderFragment } from 'state/gql/_types';

const TenantOrderProcess: React.FC = () => {

    const { id } = useParams()
    const { loading } = useAppState(state=>state.orders)
    const { subscribeToItem, unsubscribeFromItem, resetModule } = useActions().orders
    const [currentItem, setCurrentItem] = React.useState<OrderFragment|null>(null)
    const reaction = useReaction()

    React.useEffect(() => reaction(
        (state) => state.orders.currentItem,
        (currentItem) => {
            setCurrentItem(currentItem)
        },
        {
            immediate: true
        }
    ))

    React.useEffect(() => {    
        subscribeToItem({ id });
        return () => {
            unsubscribeFromItem()
        }
    }, [id, subscribeToItem, unsubscribeFromItem, resetModule])

    if (loading || !currentItem) return <span>Loading...</span>

    return (
        <PageLayoutBlank>
            <OrderWizard order={currentItem} />
        </PageLayoutBlank >
    )
}

export default TenantOrderProcess;
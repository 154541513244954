import React from 'react';

import { useActions, useAppState } from 'state';
import { VehicleFragment } from 'state/gql/_types';

import { DrawerActionProps } from 'components/layout/page/PageDrawerAction';
import FormDrawer from 'components/forms/FormDrawer';

import FormSchema from './schemas/edit-vehicle-insurance.json';
import { extractCurrentItemData } from 'utils/forms'; 
import { VehicleDrawerActions } from '../TenantVehicleDrawer';

type FormPayloadType = Pick<VehicleFragment,
    | 'insurance_name'
    | 'insurance_number'
    | 'insurance_start_date'
    | 'insurance_end_date'
>

const initialState: FormPayloadType = {
    insurance_name: '',
    insurance_number: '',
    insurance_start_date: '',
    insurance_end_date: ''
};

const EditVehicleInsuranceAction: React.FC<DrawerActionProps<VehicleFragment,VehicleDrawerActions>> = ({ currentItem, onClose }) => {

    const { updateItem } = useActions().vehicles;
    const { loading, errors } = useAppState().vehicles;

    let currentItemId = null;
    let currentItemData = null;
    if (currentItem) {
        currentItemId = currentItem.id;
        currentItemData = extractCurrentItemData<FormPayloadType>(initialState, currentItem);
    } 

    return (
        <>
            <FormDrawer<FormPayloadType, VehicleFragment, VehicleFragment['id']>
                autoOpen={false}
                formSchema={FormSchema}
                updateAction={updateItem}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
                hideDelete={true}
            />

        </>
    )
}

export default EditVehicleInsuranceAction
import React from 'react';
import { useAppState } from 'state';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

const DynamicTenantBreadCrumb: React.FC<BreadcrumbComponentProps> = () => {
    const { currentItem } = useAppState().tenants
    if (!currentItem) return <span>User</span>;
    return (
        <span>{currentItem ? currentItem.name : 'Tenant'}</span>
    );
}

export default DynamicTenantBreadCrumb;
import React from "react";
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { useModals } from "@mantine/modals";
import { Alert, Box, Button, Card, Container, Grid, Group, Stepper, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "@mantine/notifications";
import { Icon } from "@iconify/react";

import { buildFormConfig, FormConfig } from "utils/forms";

import { GenericError } from "state/_types";

import Icons from "components/ui/Icons";
import FieldRenderer from "components/forms/FieldRenderer";
import { Maybe } from "state/gql/_types";
import { changeEmptyPropsDeep } from "utils/objects";
import { InfoItemPlain, InfoItemTable } from "components/ui/InfoItem";
import { formatDate, formatDuration } from "utils/dates";
import { decimalToPercentage } from "utils/numbers";

type GenericDictionary = {
    [index: string]: any;
}

type GenericModel = {
    id: string;
    [index: string]: any;
}

type FormDrawerProps<T extends GenericDictionary, M extends GenericModel, I extends any> = {
    actionTitle?: string;
    formSchema: any;
    createAction?(value: any): Promise<Maybe<Pick<M, 'id'>> | undefined>;
    updateAction?(value: any): Promise<Maybe<M> | undefined>;
    deleteAction?(value: any): Promise<Maybe<Pick<M, 'id'>> | undefined>;
    resetAction?(): void;
    onClose?(item?: Pick<M, 'id'>): void;
    isLoading: boolean;
    errors: GenericError[];
    currentItemId: I | null;
    initialData: T;
    currentItemData: T | null
    autoOpen?: boolean;
    hideDelete?: boolean;
    actionColor?: string;
    requiredDirty?: boolean;
    redirectPath?: string;
    createRedirect?: boolean;
    updateRedirect?: boolean;
    deleteRedirect?: boolean;
    cancelRedirect?: boolean;
    cancelRedirectPath?: string;
    validators?: GenericDictionary;
    renderReviewComponent?(data: T): React.ReactElement | null;
}

export type FormComponentProps<T extends GenericDictionary> = {
    actionTitle?: string | undefined;
    currentItem: T | null;
    onFormReset?(): void;
    onFormAction?(): void;
    onClose?(): void;
    preselectKey?: string;
    preselectValue?: any;
    actionColor?: string;
}

function FormInline<PayloadType extends GenericDictionary, ModelType extends GenericModel, IDType extends string>({
    formSchema,
    createAction,
    updateAction,
    deleteAction,
    resetAction,
    onClose,
    errors,
    currentItemId,
    currentItemData,
    initialData,
    hideDelete = false,
    requiredDirty = true,
    redirectPath,
    deleteRedirect = false,
    createRedirect = false,
    updateRedirect = false,
    validators = {},
    cancelRedirect = false,
    cancelRedirectPath = undefined,
    renderReviewComponent = (data: PayloadType) => null
}: React.PropsWithChildren<FormDrawerProps<PayloadType, ModelType, IDType>>): React.ReactElement | null {

    const modals = useModals();
    const navigate = useNavigate();
    const notifications = useNotifications();
    const [formMode, setFormMode] = React.useState<'create' | 'update' | null>(null);
    const [initialState] = React.useState<PayloadType>(currentItemData || initialData);
    const [formConfig, setFormConfig] = React.useState<FormConfig<ModelType> | null>(null)
    const [activeGroup, setActiveGroup] = React.useState(0);

    if (!deleteAction) hideDelete = true

    React.useEffect(() => {

        if (currentItemData && formMode !== 'update') {
            setFormMode('update');
            let config = buildFormConfig<ModelType>(formSchema, validators, 'update')
            setFormConfig(config)
        } else if (!currentItemData && formMode !== 'create') {
            setFormMode('create');
            let config = buildFormConfig<ModelType>(formSchema, validators, 'create')
            setFormConfig(config)
        }
    }, [formMode, formSchema, validators, currentItemData, initialData]);

    const openDeleteModal = () =>
        modals.openConfirmModal({
            title: 'Delete Item?',
            zIndex: 999,
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to delete this item? This action is destructive and cannot be undone.
                </Text>
            ),
            labels: { confirm: 'Delete', cancel: "No, Dont Delete" },
            confirmProps: { color: 'red' },
            onConfirm: () => handleDelete(),
        });

    const handleClose = async () => {
        if (currentItemData && resetAction) {
            resetAction();
        } else {
            if (cancelRedirect && cancelRedirectPath) {
                navigate(cancelRedirectPath)
            }
            if (onClose) {
                onClose()
            }
        }
    }

    const handleDelete = async () => {
        if (formMode === 'update' && currentItemId && deleteAction) {
            let deletedItem = await deleteAction({ id: currentItemId });
            if (deletedItem) {
                notifications.showNotification({
                    icon: <Icon icon={Icons.warningCircle} width={24} />,
                    color: 'green',
                    autoClose: 1000,
                    title: 'Success!',
                    message: `${formConfig?.title || 'Item'} Deleted`
                })
                if (resetAction) resetAction();
                if (onClose) {
                    onClose(deletedItem)
                    if (deleteRedirect && redirectPath) {
                        navigate(redirectPath)
                    }
                }
            } else {
                notifications.showNotification({
                    icon: <Icon icon={Icons.warningCircle} width={24} />,
                    color: 'red',
                    autoClose: 1000,
                    title: 'Opps!',
                    message: `Could Not Delete ${formConfig?.title || 'Item'}`
                })
            }
        }
    }

    const getEditablePayload = (payload: PayloadType): PayloadType => {
        let editablePayload: GenericDictionary = {};
        Object.keys(payload).forEach((payloadKey) => {
            if (formConfig && formConfig.editable.indexOf(payloadKey) > -1) {
                editablePayload[payloadKey] = payload[payloadKey]
            }
        });
        return editablePayload as PayloadType;
    }

    const onSubmit = async (payload: PayloadType, { resetForm }: FormikHelpers<PayloadType>) => {
        let finalPayload = changeEmptyPropsDeep(payload)
        if (formMode === 'create' && createAction) {
            let newItem = await createAction(finalPayload);
            if (newItem) {
                notifications.showNotification({
                    icon: <Icon icon={Icons.warningCircle} width={24} />,
                    color: 'green',
                    autoClose: 1000,
                    title: 'Success!',
                    message: `${formConfig?.title || 'Item'} Created`
                })
                if (createRedirect) {
                    navigate(`${redirectPath}/${newItem.id}`)
                }
            } else {
                notifications.showNotification({
                    icon: <Icon icon={Icons.warningCircle} width={24} />,
                    color: 'red',
                    autoClose: 1000,
                    title: 'Opps!',
                    message: `Could Not Create ${formConfig?.title || 'Item'}`
                })
            }
        }
        if (formMode === 'update' && currentItemId && updateAction) {
            let editablePayload = getEditablePayload(finalPayload as PayloadType);
            let updatedItem = await updateAction({ id: currentItemId, _set: editablePayload });
            if (updatedItem) {
                notifications.showNotification({
                    icon: <Icon icon={Icons.warningCircle} width={24} />,
                    color: 'green',
                    autoClose: 1000,
                    title: 'Success!',
                    message: `${formConfig?.title || 'Item'} Updated`
                })
                if (resetAction) resetAction();
                if (updateRedirect && redirectPath) {
                    navigate(redirectPath)
                }
            } else {
                notifications.showNotification({
                    icon: <Icon icon={Icons.warningCircle} width={24} />,
                    color: 'red',
                    autoClose: 1000,
                    title: 'Opps!',
                    message: `Could Not Update ${formConfig?.title || 'Item'}`
                })
            }
        }
    }

    const displayErrors = () => {
        if (!errors.length) return null;
        return (
            <Alert title="Error" variant="outline" m={7} color="red">
                {errors.map((error, key) => {
                    return (
                        <span key={`error-${key}`}>{error.text}</span>
                    )
                })}
            </Alert>
        )
    }

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    if (!formMode || !formConfig) return null;

    const groupConfigs: {
        [index: string]: any
    } = {}

    if (formConfig.groups && Object.keys(formConfig.groups).length) {

        Object.keys(formConfig.groups).forEach(i => {

            if (formConfig.groups) {

                let group = formConfig.groups[i]

                if (!groupConfigs.hasOwnProperty(i)) {
                    groupConfigs[i] = {
                        id: i,
                        title: group.label,
                        fields: {},
                        editable: formConfig.editable
                    }
                }

            }

        })

    }

    Object.keys(formConfig.fields).forEach(i => {

        let field = formConfig.fields[i]

        if (!!field.group) {

            if (groupConfigs.hasOwnProperty(field.group)) {

                groupConfigs[field.group].fields[i] = field

            } else {

                if (!groupConfigs.hasOwnProperty('default')) {
                    groupConfigs.default = {
                        id: 'default',
                        title: 'Overview',
                        fields: {},
                        editable: formConfig.editable
                    }
                }
                groupConfigs.default.fields[i] = field

            }

        }

    })

    let stepsCount = !!formConfig.groups ? Object.keys(formConfig.groups).length : 0
    let isLastStep = activeGroup === stepsCount
    let isFirstStep = activeGroup === 0
    let currentGroup = Object.values(groupConfigs)[activeGroup]

    const nextStep = () => setActiveGroup((current) => (current < stepsCount ? current + 1 : current));
    const prevStep = () => setActiveGroup((current) => (current > 0 ? current - 1 : current));

    const getCurrentGroupErrors = (errors: FormikErrors<PayloadType>): FormikErrors<PayloadType> | false => {

        var groupErrors: FormikErrors<PayloadType> = {}

        Object.keys(errors).forEach(k => {

            var error = errors[k]

            if (currentGroup.fields.hasOwnProperty(k)) {
                groupErrors[k as keyof PayloadType] = error
            }

        })

        return Object.keys(groupErrors).length ? groupErrors : false

    }

    console.log(formConfig.validationSchema)

    return (
        <React.Fragment>

            <Formik
                onKeyDown={onKeyDown}
                initialValues={initialState}
                onSubmit={onSubmit}
                validationSchema={formConfig.validationSchema}
                validateOnBlur={true}
                validateOnMount={true}
                validateOnChange={true}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, submitForm, isSubmitting, isValid, dirty, setFieldValue }) => {

                    let groupErrors = getCurrentGroupErrors(errors)
                    let isCurrentGroupValid = !!!Object.keys(groupErrors).length
                    
                    return (

                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                        >

                            <Box
                                sx={{ paddingBottom: 99 }}
                            >


                                <Stepper
                                    active={activeGroup}
                                    onStepClick={setActiveGroup}
                                    breakpoint="sm"
                                    radius="sm"
                                    styles={theme => ({
                                        steps: {
                                            padding: theme.spacing.sm,
                                            borderRadius: theme.radius.sm,
                                            backgroundColor: theme.colorScheme === 'light' ? theme.white : theme.colors.dark[5]
                                        },
                                        content: {
                                            paddingTop: theme.spacing.xl
                                        }
                                    })}
                                >

                                    {Object.keys(groupConfigs).map((i, k) => {
                                        let groupConfig = groupConfigs[i] as FormConfig<ModelType>
                                        if (!Object.keys(groupConfig.fields).length) return null
                                        const renderFields = () => (
                                            <Grid gutter="sm" sx={{ margin: 0 }}>

                                                <FieldRenderer
                                                    currentItemId={currentItemId}
                                                    formConfig={groupConfig}
                                                    formMode={formMode}
                                                    values={values}
                                                    errors={errors}
                                                    setFieldValue={setFieldValue}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                />

                                            </Grid>
                                        )
                                        return (
                                            <Stepper.Step
                                                key={k}
                                                label={`Step ${k + 1}`}
                                                description={groupConfig.title}
                                                allowStepSelect={false}
                                            >

                                                {groupConfig.id === 'rental_details' ? (
                                                    <Grid>
                                                        <Grid.Col span={9}>
                                                            <Card>{renderFields()}</Card>
                                                        </Grid.Col>
                                                        <Grid.Col span={3}>
                                                            <Card>
                                                                <InfoItemTable label="Start Date" value={formatDate(values.start_date)} />
                                                                <InfoItemTable label="End Date" value={formatDate(values.end_date)} />
                                                                <InfoItemTable label="Rental Period" value={formatDuration(values.rental_period)} />
                                                                <InfoItemTable label="Daily Rate" value={values.day_rate} type="currency" />
                                                                <InfoItemTable label="Mile Rate" value={values.mile_rate} type="currency" />
                                                                <InfoItemTable label="Free Miles" value={values.free_miles} type="currency" />
                                                                <InfoItemTable label="Sub Total" value={values.sub_total} type="currency" />
                                                                <InfoItemTable label="Sales Tax" value={decimalToPercentage(values.sales_tax)} />
                                                                <InfoItemTable label="Other Fees" value={values.other_fees} type="currency" />
                                                                <InfoItemTable label="Discount" value={values.discount} type="currency" />
                                                                <InfoItemTable label="Order Total" value={values.total} type="currency" />
                                                                <InfoItemTable label="Deposit" value={values.deposit} type="currency" />
                                                                <Box mt="md">
                                                                    <InfoItemPlain label="Balance" value={values.total-values.deposit} type="currency" color="green" />
                                                                </Box>
                                                            </Card>
                                                        </Grid.Col>
                                                    </Grid>
                                                ) : (
                                                    <Card>{renderFields()}</Card>
                                                )}

                                            </Stepper.Step>
                                        )
                                    })}

                                    <Stepper.Step
                                        label={`Step ${stepsCount + 1}`}
                                        description="Review Reservation"
                                        allowStepSelect={false}
                                    >
                                        {renderReviewComponent(values)}
                                    </Stepper.Step>

                                </Stepper >



                                <Box>
                                    {displayErrors()}
                                </Box>

                            </Box>

                            <Box sx={(theme) => ({
                                borderTop: `1px solid ${theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[5]}`,
                                padding: theme.spacing.xl,
                                position: 'fixed',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: theme.colorScheme === 'light' ? theme.white : theme.colors.dark[5]
                            })}>
                                <Container size="lg" >
                                    <Group position="apart">

                                        <Box>
                                            {formMode === 'update' && !hideDelete ? (
                                                <Button
                                                size="lg"
                                                    color="red"
                                                    onClick={openDeleteModal}
                                                >
                                                    Delete
                                                </Button>
                                            ) : null}
                                        </Box>

                                        <Group >

                                            {isFirstStep && (
                                                <Button
                                                    size="lg"
                                                    variant="default"
                                                    onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                            )}

                                            {stepsCount > 1 && activeGroup > 0 && (
                                                <Button
                                                size="lg"
                                                    variant="default"
                                                    onClick={prevStep}
                                                    loading={isSubmitting}
                                                //disabled={requiredDirty ? !dirty || !isCurrentGroupValid : false}
                                                >
                                                    Previous Step
                                                </Button>
                                            )}
                                            
                                            {stepsCount > 1 && !isLastStep && (
                                                <Button
                                                size="lg"
                                                    color="blue"
                                                    onClick={nextStep}
                                                    loading={isSubmitting}
                                                    disabled={requiredDirty ? !dirty || !isCurrentGroupValid : false}
                                                >
                                                    Next Step
                                                </Button>
                                            )}

                                            {isLastStep && (
                                                <Button
                                                size="lg"
                                                    color="green"
                                                    onClick={submitForm}
                                                    loading={isSubmitting}
                                                    disabled={requiredDirty ? !dirty || !isValid : false}
                                                >
                                                    Save Reservation
                                                </Button>
                                            )}
                                        </Group>

                                    </Group>
                                </Container>
                            </Box>

                        </Box>

                    )
                }}

            </Formik>

        </React.Fragment>
    )

}

export default FormInline;
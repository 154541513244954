import { Icon } from "@iconify/react"
import { Button } from "@mantine/core"
import Icons from "./Icons"
import { useActions, useAppState } from "state"

const ViewModeToggle = () => {

    const { view } = useAppState(state=>state.ui)
    const toggleUIViewMode = useActions().toggleUIViewMode

    return (
        <Button
            px={8}
            variant="outline"
            color="gray"
            sx={(theme) => ({
                border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[4]}`,
            })}
            onClick={toggleUIViewMode}
        >
            <Icon icon={view==='cards'?Icons.list:Icons.cards} width={20} />
        </Button>
    )

}

export default ViewModeToggle 
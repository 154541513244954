import { Card, Group, SimpleGrid, useMantineTheme, Text, Button, Badge, Box } from "@mantine/core";
import { createColumnHelper } from "@tanstack/react-table";
import { parseVehicleStatusColor } from "components/tenant/vehicle/TenantVehicleUtils";
import DataTable from "components/ui/DataTable";
import LoadMoreButton from "components/ui/LoadMoreButton";
import { EmptyPageComponent } from "components/ui/Misc";
import { PageFilters, usePageFilters } from "components/ui/PageFilters";
import React from "react";
import NumberFormat from "react-number-format";
import { useActions, useAppState } from "state";
import { SetMetaInput } from "state/Module";
import { VehicleFragment, Vehicles_Bool_Exp, Vehicles_Status_Enum } from "state/gql/_types";
import { formatDate } from "utils/dates";
import { GenericFieldProps } from "utils/forms";
import { capitalizeWords } from "utils/string";

const GuestVehicleTable: React.FC<GenericFieldProps> = ({ id, name, label, onChange, onBlur, getValues, value, error, required, description, disabled, classNames }) => {

    const tenant_id = useAppState(state => state.tenant.id)
    const { currentItem, itemsList, loading, meta, loadingMore, meta: { limit, offset, sortKey, sortOrder } } = useAppState(state => state.vehicles)
    const { getItems, getMoreItems, setMeta, selectItem } = useActions().vehicles
    const { view } = useAppState(state => state.ui)
    const theme = useMantineTheme();
    const [selected, setSelected] = React.useState<string | null>(value as string | null)
    const [startDate, setStartDate] = React.useState<string | null>(null)
    const [endDate, setEndDate] = React.useState<string | null>(null)

    const {
        filters,
        filterParams
    } = usePageFilters<Vehicles_Bool_Exp['_and'], VehicleFragment>({
        baseFilters: [{
            tenant_id: { _eq: tenant_id },
            allow_reservations: { _eq: true }, 
            _or: [
                { status: { _neq: Vehicles_Status_Enum.Deleted } },
                { status: { _neq: Vehicles_Status_Enum.Unavailable } },
                { status: { _neq: Vehicles_Status_Enum.Disabled } }
            ],
        }],
        clickFilters: [{ status: { _eq: Vehicles_Status_Enum.Available } }],
        searchFilterFields: ['make', 'model', 'color', 'plate', 'vin'],
        clickFilterField: 'status',
        clickFilterDefault: 'available',
        clickFiltersDisableDefaultAll: true,
        clickFilterValues: {
            available: { label: 'Available', filter: [{ status: { _eq: Vehicles_Status_Enum.Available } }] },
            rented: { label: 'Rented', filter: [{ status: { _eq: Vehicles_Status_Enum.Rented } }] },
            reserved: { label: 'Reserved', filter: [{ status: { _eq: Vehicles_Status_Enum.Unavailable } }] },
            all: { label: 'All', filter: [] }
        }
    })

    React.useEffect(() => {
        if (!!getValues && !!getValues.start_date && !!getValues.end_date) {
            setStartDate(getValues.start_date)
            setEndDate(getValues.end_date)
        }
    }, [startDate, endDate, getValues])

    React.useEffect(() => {

        let controller: AbortController | null = new AbortController();

        // let query = {
        //     where: {
        //         tenant_id: {
        //             _eq: tenant_id
        //         },
        //         allow_reservations: {
        //             _eq: true
        //         },
        //         _and: [
        //             { status: { _neq: Vehicles_Status_Enum.Deleted } },
        //             { status: { _neq: Vehicles_Status_Enum.Unavailable } },
        //             { status: { _neq: Vehicles_Status_Enum.Disabled } }
        //         ],
        //         // reservations: {
        //         //     _or: [
        //         //         { end_date: { _lt: startDate } },
        //         //         { start_date: { _gt: endDate } }
        //         //     ]
        //         // }
        //     },
        //     order_by: {
        //         year: Order_By.Asc,
        //         make: Order_By.Asc,
        //         model: Order_By.Asc,
        //         color: Order_By.Asc,
        //     }
        // }

        const loadItems = async () => {

            if (controller) {
                try {
                    await getItems(filters)
                    controller = null;
                } catch (e: any) {
                    if (e.name !== 'AbortError') {
                        console.error(e)
                    }
                }
            }

        }

        if (tenant_id && startDate && endDate) {
            loadItems()
        }

        if (tenant_id) {
            loadItems()
        }

        return () => controller?.abort()

    }, [filters, startDate, endDate, tenant_id, limit, offset, sortKey, sortOrder, getItems])

    const columnHelper = createColumnHelper<VehicleFragment>()

    const columns = React.useMemo(() => [
        columnHelper.accessor('id', {
            header: 'ID'
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: row => <Badge variant="outline" color={parseVehicleStatusColor(row.getValue())}>{row.getValue()}</Badge>
        }),
        columnHelper.accessor(row => row.year, {
            header: 'Year'
        }),
        columnHelper.accessor(row => row.make, {
            header: 'Make'
        }),
        columnHelper.accessor(row => row.model, {
            header: 'Model'
        }),
        columnHelper.accessor(row => row.color, {
            header: 'Color'
        }),
        columnHelper.accessor('plate', {
            header: 'Plate'
        }),
        columnHelper.accessor('created_at', {
            header: 'Created',
            cell: row => formatDate(row.getValue(), 'MM/DD/YY')
        }),
    ], [columnHelper])

    const initialState = {
        columnVisibility: {
            id: false
        }
    }

    if (loading) return <div>Loading...</div>

    const handleMetaChange = (meta: SetMetaInput<VehicleFragment>) => {
        setMeta(meta)
    }

    const handleSelect = (id: string) => {
        onChange(id !== value ? id : null)
        selectItem(id)
    }

    const renderList = () => {
        return (
            <React.Fragment>
                <DataTable<VehicleFragment>
                    columns={columns}
                    data={itemsList}
                    loading={loading}
                    meta={meta}
                    onMetaChange={handleMetaChange}
                    initialState={initialState}
                    onSelectItem={handleSelect}
                    selectedId={value}
                />
                {error ? <Text component="span" size="sm" color="red" mb={5}>{error}</Text> : null}
            </React.Fragment>
        )
    }

    const renderCards = () => {
        return (
            <React.Fragment>
                <SimpleGrid
                    cols={3}
                    breakpoints={[
                        { maxWidth: 980, cols: 3, spacing: 'md' },
                        { maxWidth: 755, cols: 2, spacing: 'sm' },
                        { maxWidth: 600, cols: 1, spacing: 'sm' },
                    ]}
                    mb="md"
                >

                    {itemsList.map((v, k) => {
                        let label = capitalizeWords(`${v.make} ${v.model}`)
                        return (
                            <Card shadow="xs" p="lg"
                                key={k}
                                onClick={() => {
                                    setSelected(v.id)
                                    onChange(v.id)
                                }}
                                sx={theme => ({
                                    border: `2px solid ${v.id === selected ? theme.colors.green[6] : theme.white}`
                                })}>
                                <Card.Section
                                    sx={{
                                        height: 150,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center center',
                                        backgroundImage: `url(${`${process.env.REACT_APP_UPLOAD_API_URL}/load?url=${v.picture || '#'}`})`
                                    }}
                                >

                                </Card.Section>

                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                    <Text weight={500}>{label}</Text>
                                    <NumberFormat
                                        value={v.day_rate}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix='$'
                                        suffix={v.rate_type && v.rate_type === 'mile' ? '/mile' : '/day'}
                                        renderText={(value) => <Text weight={600} color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black} sx={theme => ({ [theme.fn.smallerThan('md')]: { fontSize: 15 } })}>{value}</Text>}
                                    />
                                </Group>

                                <Button
                                    fullWidth
                                    style={{ marginTop: 14 }}
                                    onClick={() => {
                                        setSelected(v.id)
                                        onChange(v.id)
                                    }}
                                    variant="outline"
                                    color={selected === v.id ? "green" : "gray"}
                                >
                                    {selected === v.id ? "Selected" : "Book Now"}
                                </Button>
                            </Card>
                        )
                    })}

                </SimpleGrid>
                <LoadMoreButton meta={meta} onLoadMore={getMoreItems} loading={loadingMore} />
            </React.Fragment>
        )
    }

    console.log(currentItem)

    return (
        <Box>

            <Text weight={500} size="sm" mb={2}>Vehicle Selection {required ? <Text component="span" size="sm" color="red">*</Text> : null}</Text>

            

            <PageFilters options={filterParams} />

            {itemsList.length ? (<> {view === 'list' ? renderList() : renderCards()} </>) : (<EmptyPageComponent label='Vehicles' />)}

        </Box>
    )

}

export default GuestVehicleTable;
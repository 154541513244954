import { Loader, TextInput } from "@mantine/core";
import React from "react";
import { GenericFieldProps } from "utils/forms";

const CurrentLocation: React.FC<GenericFieldProps> = ({ id, name, label, onChange, value: initialValue, error, required, description, disabled, setValues, setValueKeys, classNames }) => {

    const [value,setValue] = React.useState(initialValue)
    const [loading,setLoading] = React.useState(false)

    React.useEffect(()=>{
        const getLocation = async () => {
            const getLocationCallback = (position: GeolocationPosition) => {
                setValue([position.coords.latitude,position.coords.longitude].join(','))
                setLoading(false)
            }
            const getLocationErrorCallback = (error: GeolocationPositionError) => {
                setLoading(false)
                console.error(error)
            }
            navigator.geolocation.getCurrentPosition(getLocationCallback, getLocationErrorCallback, { maximumAge: 0, timeout: 15000, enableHighAccuracy:true })
        }
        setLoading(true)
        getLocation()
    },[])

    React.useEffect(()=>{
        if(value&&value!==initialValue) {
            onChange(value)
        }
    },[value,initialValue,onChange])

    return (
        <TextInput
            readOnly
            type="text"
            label={label}
            id={id}
            name={name}
            value={value||''}
            error={error}
            required={required}
            disabled={disabled}
            description={description}
            rightSection={loading?<Loader size="xs" />:null}
            classNames={classNames}
        />
    )

}

export default CurrentLocation
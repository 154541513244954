import {Module} from "state/Module"
import * as Types from "state/gql/_types"

export const TenantModule = new Module<Types.TenantFragment, Types.GetTenantsQueryVariables, Types.Tenants_Insert_Input>({
    sortKey: 'name',
    filter: {
        where: {
            type: {
                _neq: Types.Tenant_Types_Enum.Root
            }
        }
    }
})
import React from 'react';
import { Card, Grid, Text, Badge, Group } from '@mantine/core';

import { useActions, useAppState } from 'state';
import { UserFragment, Users_Bool_Exp, User_Roles_Enum } from 'state/gql/_types';
import { EditTenantUserAction, NewTenantUserAction } from 'components/root/tenant/users/TenantUserActions';
import { PageFilters, usePageFilters } from 'components/ui/PageFilters';
import { parseUserRoleColor } from 'components/root/user/UserUtils';
import { useParams } from 'react-router-dom';
import PageHeader from 'components/layout/page/PageHeader';
import RootTenantTabs from 'components/root/tenant/RootTenantTabs';
import ModuleErrors from 'components/ui/ModuleErrors';
import { TabDivider } from 'components/ui/Tabs';
import Loader from 'components/app/Loader';
import PageLayout from 'components/layout/page/PageLayout';
import LoadMoreButton from 'components/ui/LoadMoreButton';

const TenantUsers: React.FC = () => {

    const { id } = useParams()
    const { itemsList, meta, loadingMore } = useAppState(state => state.users)
    const { subscribeToItems, unsubscribeFromItems, getMoreItems, resetModule: resetUsersModule } = useActions().users
    const { loading, errors, currentItem } = useAppState(state => state.tenants)
    const { subscribeToItem, unsubscribeFromItem, resetModule: resetTenantsModule  } = useActions().tenants

    const {
        filters,
        filterParams
    } = usePageFilters<Users_Bool_Exp['_and'], UserFragment>({
        baseFilters: [{ tenant_id: { _eq: id } }],
        clickFilters: [
            {
                _or: [
                    { role: { _eq: User_Roles_Enum.Manager } },
                    { role: { _eq: User_Roles_Enum.Agent } }
                ]
            }
        ],
        searchFilterFields: ['email', 'first_name', 'last_name', 'phone', 'address', 'city'],
        clickFilterField: 'role',
        clickFilterValues: {
            manager: { label: 'Managers', filter: [{ role: { _eq: User_Roles_Enum.Manager } }] },
            agent: { label: 'Agents', filter: [{ role: { _eq: User_Roles_Enum.Agent } }] }
        }
    })

    React.useEffect(() => {
        subscribeToItems(filters);
        subscribeToItem({ id });
        return () => {
            unsubscribeFromItems()
            unsubscribeFromItem()
            resetTenantsModule()
            resetUsersModule()
        }
    }, [filters, subscribeToItems, unsubscribeFromItems, id, subscribeToItem, unsubscribeFromItem, resetUsersModule, resetTenantsModule])

    if (loading || !currentItem) return <Loader />

    return (
        <PageLayout
            header={<PageHeader
                title={currentItem.name}
                renderAction={<NewTenantUserAction />} />}
        >

            <RootTenantTabs />
            <ModuleErrors errors={errors} />
            <TabDivider label="Tenant Users" />

            <PageFilters options={filterParams} />

            <Grid>
                {itemsList.map((i, k) => {
                    return (
                        <Grid.Col md={6} key={k}>
                            <Card
                                shadow="xs"
                            >
                                <Group position='apart'>
                                    <Text weight={500}>{i.first_name} {i.last_name}</Text>
                                    <Badge variant="outline" color={parseUserRoleColor(i.role)}>{i.role}</Badge>
                                </Group>
                                <Text size="sm" mb={5}>{i.email}</Text>
                                <EditTenantUserAction id={i.id} />
                            </Card>
                        </Grid.Col>
                    )
                })}
            </Grid>

            <LoadMoreButton meta={meta} onLoadMore={getMoreItems} loading={loadingMore} />

        </PageLayout>
    )
}

export default TenantUsers;
import * as Types from '../gql/_types';

import { OrderModule } from "./init";
import { mapArrayToStateObject } from "utils/state";
import { Action, AsyncAction, GraphQLSubscriptionResultsPayload } from 'state/_types';
import { subscriptions } from 'state/gql/_operations';
import { handleGraphqQLErrorResponse, handleGraphqQLSubscriptionErrorResponse } from 'state/gql/utils';
import { SetMetaInput } from 'state/Module';

const Module = OrderModule;

export const state = Module;

export const getItems: AsyncAction<Types.GetOrdersQueryVariables> = async ({state, effects}, payload) => {
    let finalPayload = Module.mergeQueryPayload(payload);
    state.orders.errors = [];
    state.orders.loading = true;
    try {
        let { limit, offset, sortKey, sortOrder } = state.orders.meta;
        let originalPayload = { limit, offset, order_by: { [sortKey]: sortOrder } };
        let { meta, items } = await effects.gql.queries.GetOrders({...originalPayload, ...finalPayload})
        state.orders.meta.total = meta.aggregate?.count || 0;
        state.orders.items = mapArrayToStateObject(items, 'id');
    } catch (e: any) {
        state.orders.errors = handleGraphqQLErrorResponse(e);
    }
    state.orders.loading = false;
}

export const resetState: Action = ({state}) => {
    state.orders.errors = []
    state.orders.loading = false
    state.orders.items = {}
    state.orders.currentItemId = null
}

export const resetMeta: Action = ({state}) => {
    state.orders.meta = {
        limit: Module.defaultLimit,
        sortOrder: Module.defaultSortOrder,
        sortKey: Module.defaultSortKey,
        page: 1,
        total: 0,
        offset: 0,
        orderBy: {}
    }
}

export const resetModule: AsyncAction = async ({actions}) => {
    actions.orders.resetMeta()
    actions.orders.resetState()
}

export const getMoreItems: AsyncAction = async ({state, effects}) => {
    let finalPayload = Module.mergeQueryPayload({});
    state.orders.errors = [];
    state.orders.loadingMore = true;
    try {
        let { limit, sortKey, sortOrder, page } = state.orders.meta;
        let newPage = ++page;
        let newOffset = ( limit * newPage ) - limit
        let originalPayload = { limit, offset: newOffset, order_by: { [sortKey]: sortOrder } };
        let { meta, items } = await effects.gql.queries.GetOrders({...originalPayload, ...finalPayload})
        state.orders.meta.total = meta.aggregate?.count || 0;
        state.orders.meta.page = newPage;
        state.orders.meta.offset = newOffset;
        state.orders.items = { ...state.orders.items, ...mapArrayToStateObject(items, 'id') };
    } catch (e: any) {
        state.orders.errors = handleGraphqQLErrorResponse(e);
    }
    state.orders.loadingMore = false;
}

export const getAllItems: AsyncAction<Types.GetOrdersQueryVariables> = async ({state, effects}, payload) => {
    state.orders.errors = [];
    state.orders.loading = true;
    try {
        let { meta, items } = await effects.gql.queries.GetOrders(payload)
        state.orders.meta.total = meta.aggregate?.count || 0;
        state.orders.items = mapArrayToStateObject(items, 'id');
    } catch (e: any) {
        state.orders.errors = handleGraphqQLErrorResponse(e);
    }
    state.orders.loading = false;
}

export const getItem: AsyncAction<Types.GetOrderQueryVariables,Types.GetOrderQuery['item']> = async ({state, effects}, payload) => {
    try {
        let { item } = await effects.gql.queries.GetOrder(payload);
        return item;
    } catch (e: any) {
        state.orders.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const loadItem: AsyncAction<Types.GetOrderQueryVariables> = async ({state, actions}, payload) => {
    state.orders.loading = true;
    try {
        let item = await actions.orders.getItem(payload);
        if (item) {
            state.orders.currentItemId = item.id;
            state.orders.items[item.id] = item;
        }
    } catch (e: any) {
        state.orders.errors = handleGraphqQLErrorResponse(e);
    }
    state.orders.loading = false;
}

export const selectItem: Action<string> = async ({state}, payload) => {
    state.orders.currentItemId = payload
}

export const setMeta: Action<SetMetaInput<Types.OrderFragment>> = async ({state}, meta) => {
    state.orders.meta = {
        ...state.orders.meta,
        ...meta
    }
}


export const createItem: AsyncAction<Types.CreateOrderMutationVariables['object'],Types.CreateOrderMutation['item']> = async ({state, effects}, payload) => {
    let finalPayload = Module.mergeCreatePayload<Types.CreateOrderMutationVariables['object']>(payload);
    state.orders.errors = [];
    try {
        let { item } = await effects.gql.mutations.CreateOrder({object:finalPayload});
        return item;
    } catch (e: any) {
        state.orders.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const updateItem: AsyncAction<Types.UpdateOrderMutationVariables,Types.UpdateOrderMutation['item']> = async ({state, effects}, payload) => {
    state.orders.errors = [];
    try {
        let { item } = await effects.gql.mutations.UpdateOrder(payload);
        return item;
    } catch (e: any) {
        state.orders.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const deleteItem: AsyncAction<Types.DeleteOrderMutationVariables,Types.DeleteOrderMutation['item']> = async ({state, effects}, payload) => {
    state.orders.errors = [];
    try {
        let { item } = await effects.gql.mutations.DeleteOrder(payload);
        return item;
    } catch (e: any) {
        state.orders.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const syncGetItemsMeta: Action<Types.SyncGetOrdersMetaSubscriptionVariables> = async ({state, actions, effects}, {where}) => {
    effects.subscriptions.subscribe<Types.SyncGetOrdersMetaSubscription, Types.SyncGetOrdersMetaSubscriptionVariables>(
        'syncGetOrdersMeta', 
        subscriptions.SyncGetOrdersMeta, 
        { where }, 
        actions.orders.onItemsMeta
    );
}

export const unsubItemsMeta: Action = ({effects}) => {
    effects.subscriptions.unsubscribe('syncGetOrdersMeta');
}

export const onItemsMeta: Action<GraphQLSubscriptionResultsPayload<Types.SyncGetOrdersMetaSubscription>> = async ({state}, payload) => {
    if (payload.error) {
        state.orders.errors = [ ...state.orders.errors, ...handleGraphqQLSubscriptionErrorResponse(payload.error) ];
    }
    if (payload.data) {
        state.orders.meta.total = payload.data.meta.aggregate?.count || 0;
    }    
}

export const syncGetItems: Action<Types.SyncGetOrdersSubscriptionVariables> = async ({state, actions, effects}, payload) => {
    let { limit, offset, sortKey, sortOrder } = state.orders.meta;
    let originalPayload = { limit, offset, order_by: { [sortKey]: sortOrder } };
    state.orders.loading = true;
    effects.subscriptions.subscribe<Types.SyncGetOrdersSubscription, Types.SyncGetOrdersSubscriptionVariables>(
        'syncGetOrders', 
        subscriptions.SyncGetOrders, 
        {...originalPayload, ...payload}, 
        actions.orders.onItemsData,
        () => { state.orders.loading = false }
    );
}

export const unsubItemsData: Action = ({state,effects}) => {
    effects.subscriptions.unsubscribe('syncGetOrders');
    state.orders.items = {};
}

export const onItemsData: Action<GraphQLSubscriptionResultsPayload<Types.SyncGetOrdersSubscription>> = async ({state}, payload) => {
    if (payload.error) {
        state.orders.errors = [ ...state.orders.errors, ...handleGraphqQLSubscriptionErrorResponse(payload.error) ];
    }
    if (payload.data&&payload.data.items) {
        state.orders.items = mapArrayToStateObject(payload.data.items, 'id');
    }  
}

export const subscribeToItems: Action<Types.SyncGetOrdersSubscriptionVariables> = async ({actions}, payload) => {
    let finalPayload = Module.mergeQueryPayload(payload);
    actions.orders.syncGetItems(finalPayload);
    actions.orders.syncGetItemsMeta(finalPayload);
}

export const unsubscribeFromItems: Action = ({actions}) => {
    actions.orders.unsubItemsData();
    actions.orders.unsubItemsMeta();
}

export const syncGetItem: Action<Types.SyncGetOrderSubscriptionVariables> = async ({state, actions, effects}, payload) => {
    state.orders.loading = true;
    effects.subscriptions.subscribe<Types.SyncGetOrderSubscription, Types.SyncGetOrderSubscriptionVariables>(
        'syncGetOrder', 
        subscriptions.SyncGetOrder, 
        payload, 
        actions.orders.onItemData,
        () => { state.orders.loading = false }
    );
}

export const unsubItemData: Action = ({state,effects}) => {
    effects.subscriptions.unsubscribe('syncGetOrder');
    state.orders.currentItemId = null;
}

export const onItemData: Action<GraphQLSubscriptionResultsPayload<Types.SyncGetOrderSubscription>> = async ({state}, payload) => {
    if (payload.error) {
        state.orders.errors = [ ...state.orders.errors, ...handleGraphqQLSubscriptionErrorResponse(payload.error) ];
    }
    if (payload.data&&payload.data.item) {
        state.orders.currentItemId = payload.data.item.id;
        state.orders.items[payload.data.item.id] = payload.data.item;
    }
}

export const subscribeToItem: Action<Types.SyncGetOrderSubscriptionVariables> = async ({actions}, payload) => {
    actions.orders.syncGetItem(payload);
}

export const unsubscribeFromItem: Action = ({actions}) => {
    actions.orders.unsubItemData();
}

export const actions = {
    getItems,
    getAllItems,
    resetState,
    resetMeta,
    getMoreItems,
    resetModule,
    getItem,
    loadItem,
    selectItem,
    setMeta,
    createItem,
    updateItem,
    deleteItem,
    syncGetItemsMeta,
    unsubItemsMeta,
    onItemsMeta,
    syncGetItems,
    unsubItemsData,
    onItemsData,
    subscribeToItems,
    unsubscribeFromItems,
    syncGetItem,
    unsubItemData,
    onItemData,
    subscribeToItem,
    unsubscribeFromItem
};
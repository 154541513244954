import React from 'react';

import { useActions, useAppState } from 'state';
import { ClientFragment } from 'state/gql/_types';

import { DrawerActionProps } from 'components/layout/page/PageDrawerAction';
import FormDrawer from 'components/forms/FormDrawer';

import FormSchema from './schemas/edit-client-license.json';
import { extractCurrentItemData } from 'utils/forms';
import { ClientDrawerActions } from '../TenantClientDrawer';

type FormPayloadType = Pick<ClientFragment,
    | 'license_number'
    | 'dob'
    | 'license_state'
    | 'license_exp_date'
>

const initialState: FormPayloadType = {
    license_number: '',
    dob: '',
    license_state: '',
    license_exp_date: '',
};

const EditClientLicenseAction: React.FC<DrawerActionProps<ClientFragment,ClientDrawerActions>> = ({ currentItem, onClose }) => {

    const { updateItem } = useActions().clients;
    const { loading, errors } = useAppState().clients;

    let currentItemId = null;
    let currentItemData = null;
    if (currentItem) {
        currentItemId = currentItem.id;
        currentItemData = extractCurrentItemData<FormPayloadType>(initialState, currentItem);
    }

    return (
        <>
            <FormDrawer<FormPayloadType, ClientFragment, ClientFragment['id']>
                autoOpen={false}
                formSchema={FormSchema}
                updateAction={updateItem}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
                hideDelete={true}
            />

        </>
    )
}

export default EditClientLicenseAction
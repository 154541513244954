import React from 'react';

import { useAppState } from 'state';

import { OrderActionProps } from './_types';
import { VerifyClientInsuranceAction } from './VerifyClientInsurance';
import { VerifyVehicleInsuranceAction } from './VerifyVehicleInsurance';
import { SelectPolicyTypeAction } from './SelectPolicyType';

export const VerifyInsuranceAction: React.FC<OrderActionProps> = ({isValid}) => {

    const currentOrder = useAppState(state=>state.orders.currentItem)

    if (!currentOrder) return null;

    if (currentOrder.policy_type==='renter') {
        return <VerifyClientInsuranceAction isValid={isValid}/>
    }

    if (currentOrder.policy_type==='vehicle') {
        return <VerifyVehicleInsuranceAction isValid={isValid}/>
    }

    return <SelectPolicyTypeAction isValid={isValid} />
    

}
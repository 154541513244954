import { Reservation_Status_Enum } from "state/gql/_types";

export const parseReservationStatusColor = (status: Reservation_Status_Enum): string => {
    switch (status) {

        case 'new':
            return 'gray';

        case 'confirmed':
            return 'green';

        case 'active':
            return 'blue';

        default:
            return 'gray'

    }
}
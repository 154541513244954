import PageHeader from 'components/layout/page/PageHeader';
import PageLayout from 'components/layout/page/PageLayout';
import RootDashboard from 'components/root/RootDashboard';
import React from 'react';
import { useAppState } from 'state';

const RootHome: React.FC = () => {
    const { userName } = useAppState()

    return (
        <PageLayout
            header={<PageHeader
                title={`Welcome, ${userName}`}
            />}
        >

            <RootDashboard />

        </PageLayout>
    )
}

export default RootHome;
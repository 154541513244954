import React from 'react';
import { useMachine } from "@xstate/react"
import { z } from "zod";
import { Accordion, AccordionState, Box, Button, Stack, useAccordionState, Text, Alert } from '@mantine/core';

import { useActions, useAppState } from 'state';
import { GenericDictionary, GenericError } from 'state/_types';
import { ReservationFragment } from 'state/gql/_types';
import ReservationMachine, { MachineEvent } from "state/reservations/ReservationMachine"

import WizardStep from 'components/forms/WizardStep';

import ReservationSchemas, { getReservationSchema } from './ReservationSchemas';
import { getDurationDaysNumber } from 'utils/dates';

type FormPayloadType = Omit<ReservationFragment, 'id' | 'status' | 'created_at' | 'updated_at' | 'tenant' | 'client' | 'vehicle' | 'agent' | 'order'>
type SelectDates = Pick<FormPayloadType, 'start_date' | 'end_date'>
type SelectVehicle = Pick<FormPayloadType, 'vehicle_id'>
type UserInfo = Pick<FormPayloadType, 'email' | 'first_name' | 'last_name' | 'phone' | 'dob' | 'address' | 'city' | 'state' | 'zip' | 'country'>
type AcceptTerms = Pick<FormPayloadType, 'terms_accepted'>

const initialState: FormPayloadType = {
    "tenant_id": null,
    "vehicle_id": null,
    "email": "davidmelo@desmdesigns.com",
    "first_name": "David",
    "last_name": "Melo",
    "phone": "7864990590",
    "dob": "07/24/1986",
    "address": "1061 Ok Ave",
    "city": "Miami",
    "state": "Florida",
    "zip": "33186",
    "country": "USA",
    "start_date": "2023-05-5",
    "end_date": "2023-05-15",
    "rental_period": 0,
    "free_miles": 0,
    "day_rate": 100.00,
    "mile_rate": 0,
    "discount": 0,
    "sales_tax": 0.07,
    "sub_total": 0,
    "total": 0,
    "terms_accepted": false
}

// const initialStateDates: SelectDates = {
//     "start_date": "2023-05-5",
//     "end_date": "2023-05-15",
// }

// const initialStateVehicle: SelectVehicle = {
//     "vehicle_id": "5b5448a5-f3ea-49b6-8b08-ef805673cfa7",
// }

// const initialStateInfo: UserInfo = {
//     "email": "davidmelo@desmdesigns.com",
//     "first_name": "David",
//     "last_name": "Melo",
//     "phone": "7864990590",
//     "dob": "07/24/1986",
//     "address": "1061 Ok Ave",
//     "city": "Miami",
//     "state": "Florida",
//     "zip": "33186",
//     "country": "USA",
// }


// const initialStateTerms: AcceptTerms = {
//     "terms_accepted": false
// }

type CreateReservationStepProps = {
    state: string;
    schema: any;
    reservation: FormPayloadType;
    onUpdate(payload: FormPayloadType): void
    loading: boolean;
    errors: GenericError[]
}

// const pickSchema = (state: string) => {
//     switch (state) {
//         case 'select-dates':
//             return initialStateDates
//         case 'select-vehicle':
//             return initialStateVehicle
//         case 'client-details':
//             return initialStateInfo
//         case 'accept-terms':
//             return initialStateTerms
//         default:
//             return initialState
//     }
// }

const CreateReservationStep: React.FC<CreateReservationStepProps> = ({ state, schema, reservation, loading, errors, onUpdate }) => {

    const handleCreate = async (payload: FormPayloadType) => {
        onUpdate(payload)
        return Promise.resolve(true)
    }

    return (
        <>
            <WizardStep<SelectDates | FormPayloadType | SelectVehicle | UserInfo | AcceptTerms>
                formSchema={schema}
                updateAction={handleCreate}
                isLoading={loading}
                errors={errors}
                currentItemData={reservation}
            />
        </>
    )
}

const getValidationObject = (state: keyof typeof ReservationSchemas) => {

    const error = (message: string = 'Missing', alt: boolean = false) => {
        return !alt ? {
            invalid_type_error: message
        } : {
            message: message
        }
    }

    const validators: { [index: string]: any } = {
        "select-dates": z.object({
            start_date: z.string(error()).min(5, error()),
            end_date: z.string(error()).min(5, error()),
        }),
        "select-vehicle": z.object({
            vehicle_id: z.string(error("No Vehicle Selected")).min(5, error()),
        }),
        "client-details": z.object({
            email: z.string(error()).min(5, error()),
            first_name: z.string(error()).min(5, error()),
            last_name: z.string(error()).min(5, error()),
            address: z.string(error()).min(5, error()),
            country: z.string(error()).min(5, error()),
            city: z.string(error()).min(5, error()),
            state: z.string(error()).min(5, error()),
            zip: z.string(error()).min(5, error()),
            phone: z.string(error()).min(5, error()),
            dob: z.string(error()).min(5, error()),
        }),
        "accept-terms": z.object({
            terms_accepted: z.boolean(error()),
        })
    }

    return validators.hasOwnProperty(state) ? validators[state] : false

}

const stepToAccordionIndex = (state: MachineEvent) => {
    switch (state) {
        case 'select-dates':
            return 0
        case 'select-vehicle':
            return 1
        case 'client-details':
            return 2
        case 'accept-terms':
            return 3
        case 'review':
            return 4
        case 'complete':
            return 5
        default:
            return 0
    }
}

const getAccordionStateIndex = (state: AccordionState) => {
    let index = Object.keys(state).find(k => !!state[k])
    return !!index ? index : null
}

const accordionIndexToState = (index: string): MachineEvent => {
    switch (index) {
        case '0':
            return 'select-dates'
        case '1':
            return 'select-vehicle'
        case '2':
            return 'client-details'
        case '3':
            return 'accept-terms'
        case '4':
            return 'review'
        case '5':
            return 'complete'
        default:
            return 'select-dates'
    }
}

const ReservationWizardMachine: React.FC = () => {

    const [accordionState, accordionStateHandlers] = useAccordionState({ total: 5, initialItem: 0 });
    const tenant_id = useAppState(state => state.tenant.id)
    const { currentItem: currentVehicle } = useAppState(state => state.vehicles)
    const { createItem } = useActions().reservations;
    const { selectItem } = useActions().vehicles;
    const { loading, errors } = useAppState().reservations;
    const [success, setSuccess] = React.useState(false)

    const [reservation, setReservation] = React.useState<FormPayloadType>({ ...initialState, tenant_id })
    //const [currentState, setCurrentState] = React.useState<MachineEvent>('select-dates')
    const [currentStateIndex, setCurrentStateIndex] = React.useState<string>('0')

    const ReservationMachineInstance = React.useMemo(
        () => ReservationMachine.withConfig({
            actions: {
                //updateVehicleMeta: (context, event) => { console.log('evento', event) }
            }
        }), [])

    const [state, send] = useMachine(ReservationMachineInstance) 

    const [isStepValid, setStepValid] = React.useState(false)
    const [stepErrors, setStepErrors] = React.useState<string[]>([])
    const [invalidFields, setInvalidFields] = React.useState<Record<string, string[]>>({})

    const handleCreate = async () => {
        if (tenant_id) {
            let success = await createItem(reservation)
            if (success) {
                setSuccess(true)
            }
        }
    }

    console.log(stepErrors, invalidFields)

    const handleUpdate = async (nextStep: MachineEvent, updatedPayload: FormPayloadType) => {
        let nextIndex = stepToAccordionIndex(nextStep)
        setReservation(previousPayload => ({ ...previousPayload, ...updatedPayload }))
        send(nextStep)
        //setCurrentState(nextStep)
        setCurrentStateIndex(nextIndex.toString())
        accordionStateHandlers.toggle(nextIndex)
        if (updatedPayload.hasOwnProperty('vehicle_id') && !!updatedPayload.vehicle_id) {
            selectItem(updatedPayload.vehicle_id)
        }
        if (updatedPayload.hasOwnProperty('start_date') && updatedPayload.hasOwnProperty('end_date') && !!updatedPayload.start_date && !!updatedPayload.end_date) {
            let duration = getDurationDaysNumber(updatedPayload.start_date, updatedPayload.end_date)
            //let durationDisplay = getDurationDays(updatedPayload.start_date, updatedPayload.end_date, true)
            setReservation(previousPayload => ({ ...previousPayload, rental_period: duration }))
            //setValueDisplay(durationDisplay)
        }
    }

    React.useEffect(() => {
        if (currentVehicle && reservation.rental_period) {
            let sub_total = reservation.rental_period * reservation.day_rate
            let tax_total = sub_total * parseFloat(reservation.sales_tax);
            let total = (sub_total - reservation.discount) + tax_total;
            setReservation(previousPayload => ({ ...previousPayload, sub_total, total }))
        }
    }, [currentVehicle, reservation.day_rate, reservation.discount, reservation.rental_period, reservation.sales_tax])

    React.useEffect(() => {
        const validateStep = async () => {

            let validator = getValidationObject(state.value as keyof typeof ReservationSchemas)

            if (!validator) {
                setStepErrors([])
                return;
            }

            let result = await validator.safeParse(reservation)

            let invalidFieldsObj: Record<string, string[]> = {}

            if (!result.success) {

                let allErrors: GenericDictionary = result.error.format()

                let stateErrors = Object.keys(allErrors).reduce<string[]>((errors, key) => {

                    let errorObject = allErrors[key];

                    if (key === '_errors') {
                        return [...errors]
                    }

                    let stateSubErrors = Object.keys(errorObject).reduce<string[]>((subErrors, subKey) => {

                        let subErrorObject = errorObject[subKey];

                        let subCriticalErrors: string[] = []

                        if (subKey === '_errors') {

                            subErrorObject.forEach((error: string) => {

                                if (error !== 'Missing') {
                                    subCriticalErrors.push(error)
                                }
                                if (errors.indexOf('Missing Required Information') < 0 || errors.indexOf('Missing Required Information') < 0) {
                                    subCriticalErrors.push('Missing Required Information')
                                }
                                if (!invalidFieldsObj.hasOwnProperty(key)) {
                                    invalidFieldsObj[key] = [error]
                                } else {
                                    invalidFieldsObj[key].push(error)
                                }
                            })

                            return [...subErrors, ...subCriticalErrors]
                        }
                        if (subErrorObject._errors && Array.isArray(subErrorObject._errors)) {

                            subErrorObject._errors.forEach((error: string) => {
                                if (error !== 'Missing') {
                                    subCriticalErrors.push(error)
                                }
                                if (subErrors.indexOf('Missing Required Information') < 0) {
                                    subCriticalErrors.push('Missing Required Information')
                                }
                                if (!invalidFieldsObj.hasOwnProperty(subKey)) {
                                    invalidFieldsObj[subKey] = [error]
                                } else {
                                    invalidFieldsObj[subKey].push(error)
                                }
                            })

                            return [...subErrors, ...subCriticalErrors]
                        }

                        return subErrors;

                    }, [])

                    if (stateSubErrors.length) {
                        return [...errors, ...stateSubErrors]
                    }

                    return errors;

                }, [])

                setInvalidFields(invalidFieldsObj)
                setStepErrors(stateErrors)
                setStepValid(false)

            } else {
                setInvalidFields({})
                setStepErrors([])
                setStepValid(true)
            }
        }
        validateStep()
    }, [reservation, state.value])

    const handleAccordionChange = (changedState: AccordionState) => {
        let oldIndex = getAccordionStateIndex(accordionState)
        let newIndex = getAccordionStateIndex(changedState)
        if (newIndex) {
            let currentIndex = parseInt(currentStateIndex, 10)
            let destinationIndex = parseInt(newIndex, 10)
            if (destinationIndex <= currentIndex) {
                let newState = accordionIndexToState(newIndex)
                if (oldIndex !== newIndex) {
                    accordionStateHandlers.setState(changedState)
                    send(newState)
                }
            }
        }
    }

    return (
        <Box
            mt={16}
            sx={theme => ({

            })}
        >

            <Accordion
                iconPosition="right"
                state={accordionState}
                onChange={handleAccordionChange}
                order={6}
            >
                <Accordion.Item label="Select Rental Dates">
                    <Stack spacing="xs">
                        {state.matches('select-dates') && (
                            <CreateReservationStep
                                schema={getReservationSchema(state.value as string)}
                                state={state.value as string}
                                reservation={reservation}
                                loading={loading}
                                errors={errors}
                                onUpdate={payload => handleUpdate('select-vehicle', payload)}
                            />
                        )}
                    </Stack>
                </Accordion.Item>

                <Accordion.Item label="Select Vehicle">
                    <Stack spacing="xs">
                        {state.matches('select-vehicle') && (
                            <CreateReservationStep
                                schema={getReservationSchema(state.value as string)}
                                state={state.value as string}
                                reservation={reservation}
                                loading={loading}
                                errors={errors}
                                onUpdate={payload => handleUpdate('client-details', payload)}
                            />
                        )}
                    </Stack>
                </Accordion.Item>

                <Accordion.Item label="Provide Your Information">
                    <Stack spacing="xs">
                        {state.matches('client-details') && (
                            <CreateReservationStep
                                schema={getReservationSchema(state.value as string)}
                                state={state.value as string}
                                reservation={reservation}
                                loading={loading}
                                errors={errors}
                                onUpdate={payload => handleUpdate('accept-terms', payload)}
                            />
                        )}
                    </Stack>
                </Accordion.Item>

                <Accordion.Item label="Accept Terms">
                    <Stack spacing="xs">
                        {state.matches('accept-terms') && (
                            <CreateReservationStep
                                schema={getReservationSchema(state.value as string)}
                                state={state.value as string}
                                reservation={reservation}
                                loading={loading}
                                errors={errors}
                                onUpdate={payload => handleUpdate('review', payload)}
                            />
                        )}
                    </Stack>
                </Accordion.Item>

                <Accordion.Item label="Review">
                    <Stack spacing="xs">

                        {success ? (
                            <Alert title="Success!" color="green" variant="outline">
                                You have successfully booked this vehicle.
                            </Alert>
                        ) : (
                            <Button
                                color="blue"
                                variant="filled"
                                size="lg"
                                onClick={handleCreate}
                                disabled={!isStepValid}
                            >
                                <Text>Complete Booking</Text>
                            </Button>
                        )}





                    </Stack>
                </Accordion.Item>

            </Accordion>

            {/* <Box
                sx={theme => ({
                    height: '100%',
                    display: 'grid',
                    [theme.fn.smallerThan('md')]: {
                        gridTemplateRows: 'auto 1fr auto',
                    },
                    [theme.fn.largerThan('md')]: {
                        gridTemplateRows: 'auto 1fr',
                    },
                })}
            >

                <PageHeader
                    title={`Order #${order.order_number} - ${orderStateTitleMap[order.state]}`}
                    renderAction={
                        <Group spacing="xs">
                            {renderSettingsMenu()}
                            <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                                <Box>
                                    {renderProcessButton('sm')}
                                </Box>
                            </MediaQuery>
                        </Group>
                    }
                />

                <Box sx={{ position: 'relative', height: '100%' }}>

                    <Box sx={(theme) => ({
                        padding: theme.spacing.md,
                        paddingTop: 0
                    })}>

                        <Stack>

                            <Card shadow="sm">

                                <Stack>

                                    {stepErrors.length ? (
                                        <Alert icon={<Icon icon={Icons.warningCircle} width="24" />} color="red">
                                            <strong>
                                                {stepErrors.join(', ')}
                                            </strong>
                                        </Alert>
                                    ) : null}

                                    <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                                        <Container
                                            size="xs"
                                            px={0}
                                        >
                                            <Stack spacing="xs">
                                                {renderAction()}
                                            </Stack>
                                        </Container>
                                    </MediaQuery>

                                </Stack>

                            </Card>

                        </Stack>

                    </Box>

                </Box>

                <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                    <Card
                        sx={theme => ({
                            backgroundColor: theme.colorScheme === 'light' ? theme.white : theme.colors.dark[9],
                            borderRadius: 0
                        })}
                    >
                        <Container
                            size="xs"
                            px={0}
                            sx={theme => ({
                                [theme.fn.smallerThan('sm')]: {
                                    maxWidth: '100%'
                                }
                            })}
                        >
                            <Stack spacing="xs">
                                {renderAction()}
                                {renderProcessButton('xl')}
                            </Stack>
                        </Container>
                    </Card>
                </MediaQuery>

            </Box> */}

        </Box>
    )

}

const CreateReservationAction: React.FC = () => {



    return (
        <>
            <ReservationWizardMachine />
        </>
    )
}

export default CreateReservationAction 
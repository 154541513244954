import React from 'react';
import { Text, Drawer, Button, MantineColor, MantineSize, ButtonVariant } from '@mantine/core';
import Icons from 'components/ui/Icons';
import { Icon } from '@iconify/react';

export type OrderProcessActionProps<T extends DrawerActionProps> = {
    title: string;
    icon?: string;
    action: React.FC<any>;
    props: Omit<T, "onClose">;
    color?: MantineColor;
    variant?: ButtonVariant;
    size?: MantineSize;
    iconColor?: string;
}

export type DrawerActionProps = {
    onClose: () => void;
}

function OrderProcessAction<T extends DrawerActionProps>({
    title,
    icon = Icons.add,
    action,
    props,
    color = "blue",
    iconColor = "white",
    size = "sm",
    variant = "filled"
}: React.PropsWithChildren<OrderProcessActionProps<T>>
): React.ReactElement {

    const PageAction = action
    const [opened, setOpened] = React.useState(false);

    return (
        <React.Fragment>

            <Drawer
                position="right"
                opened={opened}
                onClose={() => setOpened(false)}
                title={title}
                padding={0}
                size="xl"
                styles={(theme) => ({
                    header: {
                        borderBottom: `1px solid ${theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[5]}`,
                        marginBottom: 0,
                        padding: theme.spacing.xl
                    },
                    drawer: { 
                        height: '100%',
                        minHeight: '100%',
                        maxHeight: '100%',
                    },
                })}
            >
                <PageAction {...props} onClose={() => setOpened(false)} />
            </Drawer>


            <Button
                onClick={() => setOpened(true)}
                rightIcon={<Icon className="action-icon" icon={icon} color={iconColor} width={18} />}
                color={color}
                variant={variant}
                size={size}
                sx={theme=>({
                    '& .action-icon': {
                        color: `${variant === 'outline' ? theme.colors[color][7] : iconColor} !important`
                    }
                })}
            >
                <Text>{title}</Text>
            </Button>

        </React.Fragment>
    );
}

export default OrderProcessAction;
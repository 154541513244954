import { Card, Group, SimpleGrid, useMantineTheme, Text, Button } from "@mantine/core";
import React from "react";
import NumberFormat from "react-number-format";
import { useActions, useAppState } from "state";
import { Order_By, Vehicles_Status_Enum } from "state/gql/_types";
import { GenericFieldProps } from "utils/forms";
import { capitalizeWords } from "utils/string";

const GuestVehicleSelect: React.FC<GenericFieldProps> = ({ id, name, label, onChange, onBlur, getValues, value, error, required, description, disabled, classNames }) => {

    const tenant_id = useAppState(state => state.tenant.id)
    const { itemsList, loading } = useAppState(state => state.vehicles)
    const { getTenantVehicles } = useActions().tenants
    const theme = useMantineTheme();
    const [selected, setSelected] = React.useState<string | null>(value as string | null)
    const [startDate, setStartDate] = React.useState<string | null>(null)
    const [endDate, setEndDate] = React.useState<string | null>(null)

    React.useEffect(() => {
        if (!!getValues && !!getValues.start_date && !!getValues.end_date) {
            setStartDate(getValues.start_date)
            setEndDate(getValues.end_date)
        }
    }, [startDate, endDate, getValues])

    React.useEffect(() => {

        let controller: AbortController | null = new AbortController();

        let query = {
            where: {
                tenant_id: {
                    _eq: tenant_id
                },
                allow_reservations: {
                    _eq: true
                },
                _and: [
                    { status: { _neq: Vehicles_Status_Enum.Deleted } },
                    { status: { _neq: Vehicles_Status_Enum.Unavailable } },
                    { status: { _neq: Vehicles_Status_Enum.Disabled } }
                ],
                // reservations: {
                //     _or: [
                //         { end_date: { _lt: startDate } },
                //         { start_date: { _gt: endDate } }
                //     ]
                // }
            },
            order_by: {
                year: Order_By.Asc,
                make: Order_By.Asc,
                model: Order_By.Asc,
                color: Order_By.Asc,
            }
        }

        const loadItems = async () => {

            if (controller) {
                try {
                    await getTenantVehicles(query)
                    controller = null;
                } catch (e: any) {
                    if (e.name !== 'AbortError') {
                        console.error(e)
                    }
                }
            }

        }

        if (tenant_id && startDate && endDate) {
            loadItems()
        }

        if (tenant_id) {
            loadItems()
        }

        return () => controller?.abort()

    }, [startDate, endDate, tenant_id, getTenantVehicles])


    if (loading) return <div>Loading...</div>

    return (
        <React.Fragment>

            <SimpleGrid
                cols={3}
                breakpoints={[
                    { maxWidth: 980, cols: 3, spacing: 'md' },
                    { maxWidth: 755, cols: 2, spacing: 'sm' },
                    { maxWidth: 600, cols: 1, spacing: 'sm' },
                ]}
                mb="md"
            >

                {itemsList.map((v, k) => {
                    let label = capitalizeWords(`${v.make} ${v.model}`)
                    return (
                        <Card shadow="xs" p="lg"
                            key={k}
                            onClick={() => {
                                setSelected(v.id)
                                onChange(v.id)
                            }}
                            sx={theme => ({
                                border: `2px solid ${v.id === selected ? theme.colors.green[6] : theme.white}`
                            })}>
                            <Card.Section
                                sx={{
                                    height: 150,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center',
                                    backgroundImage: `url(${`${process.env.REACT_APP_UPLOAD_API_URL}/load?url=${v.picture || '#'}`})`
                                }}
                            >

                            </Card.Section>

                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                <Text weight={500}>{label}</Text>
                                <NumberFormat
                                    value={v.day_rate}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix='$'
                                    suffix={v.rate_type&&v.rate_type==='mile'?'/mile':'/day'}
                                    renderText={(value) => <Text weight={600} color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black} sx={theme => ({ [theme.fn.smallerThan('md')]: { fontSize: 15 } })}>{value}</Text>}
                                />
                            </Group>

                            <Button
                                fullWidth
                                style={{ marginTop: 14 }}
                                onClick={() => {
                                    setSelected(v.id)
                                    onChange(v.id)
                                }}
                                variant="outline"
                                color={selected === v.id ? "green" : "gray"}
                            >
                                {selected === v.id ? "Selected" : "Book Now"}
                            </Button>
                        </Card>
                    )
                })}

            </SimpleGrid>

        </React.Fragment>
    )

}

export default GuestVehicleSelect;
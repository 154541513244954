import { PageTabs } from "components/ui/Tabs";

import { useAppState } from "state";

const TenantOrderTabs = () => {

    const { currentItemId } = useAppState(state=>state.orders)

    return ( 
        <PageTabs
            parentId={currentItemId}
            urlRoot='/orders'
            tabs={{
                status: {
                    label: 'Status',
                    icon: 'clientDetails',
                    route: ''
                },
                details: {
                    label: 'Details',
                    icon: 'clientLicense',
                    route: 'details'
                },
                files: {
                    label: 'Files',
                    icon: 'files',
                    route: 'files'
                }
            }}
        />
    )

}

export default TenantOrderTabs;
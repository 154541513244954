import * as Types from '../gql/_types';

import { ClientModule } from "./init";
import { mapArrayToStateObject } from "utils/state";
import { Action, AsyncAction, GraphQLSubscriptionResultsPayload } from 'state/_types';
import { subscriptions } from 'state/gql/_operations';
import { handleGraphqQLErrorResponse, handleGraphqQLSubscriptionErrorResponse } from 'state/gql/utils';
import { SetMetaInput } from 'state/Module';

const Module = ClientModule;

export const state = Module;

export const getItems: AsyncAction<Types.GetClientsQueryVariables> = async ({state, effects}, payload) => {
    let finalPayload = Module.mergeQueryPayload(payload);
    state.clients.errors = [];
    state.clients.loading = true;
    try {
        let { limit, offset, sortKey, sortOrder } = state.clients.meta;
        let originalPayload = { limit, offset, order_by: { [sortKey]: sortOrder } };
        let { meta, items } = await effects.gql.queries.GetClients({...originalPayload, ...finalPayload})
        state.clients.meta.total = meta.aggregate?.count || 0;
        state.clients.items = mapArrayToStateObject(items, 'id');
    } catch (e: any) {
        state.clients.errors = handleGraphqQLErrorResponse(e);
    }
    state.clients.loading = false;
}

export const resetState: Action = ({state}) => {
    state.clients.errors = []
    state.clients.loading = false
    state.clients.items = {}
    state.clients.currentItemId = null
}

export const resetMeta: Action = ({state}) => {
    state.clients.meta = {
        limit: Module.defaultLimit,
        sortOrder: Module.defaultSortOrder,
        sortKey: Module.defaultSortKey,
        page: 1,
        total: 0,
        offset: 0,
        orderBy: {}
    }
}

export const resetModule: AsyncAction = async ({actions}) => {
    actions.clients.resetMeta()
    actions.clients.resetState()
}

export const getMoreItems: AsyncAction = async ({state, effects}) => {
    let finalPayload = Module.mergeQueryPayload({});
    state.clients.errors = [];
    state.clients.loadingMore = true;
    try {
        let { limit, sortKey, sortOrder, page } = state.clients.meta;
        let newPage = ++page;
        let newOffset = ( limit * newPage ) - limit
        let originalPayload = { limit, offset: newOffset, order_by: { [sortKey]: sortOrder } };
        let { meta, items } = await effects.gql.queries.GetClients({...originalPayload, ...finalPayload})
        state.clients.meta.total = meta.aggregate?.count || 0;
        state.clients.meta.page = newPage;
        state.clients.meta.offset = newOffset;
        state.clients.items = { ...state.clients.items, ...mapArrayToStateObject(items, 'id') };
    } catch (e: any) {
        state.clients.errors = handleGraphqQLErrorResponse(e);
    }
    state.clients.loadingMore = false;
}

export const getAllItems: AsyncAction<Types.GetClientsQueryVariables> = async ({state, effects}, payload) => {
    state.clients.errors = [];
    state.clients.loading = true;
    try {
        let { meta, items } = await effects.gql.queries.GetClients(payload)
        state.clients.meta.total = meta.aggregate?.count || 0;
        state.clients.items = mapArrayToStateObject(items, 'id');
    } catch (e: any) {
        state.clients.errors = handleGraphqQLErrorResponse(e);
    }
    state.clients.loading = false;
}

export const getItem: AsyncAction<Types.GetClientQueryVariables,Types.GetClientQuery['item']> = async ({state, effects}, payload) => {
    try {
        let { item } = await effects.gql.queries.GetClient(payload);
        return item;
    } catch (e: any) {
        state.clients.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const loadItem: AsyncAction<Types.GetClientQueryVariables> = async ({state, actions}, payload) => {
    state.clients.loading = true;
    try {
        let item = await actions.clients.getItem(payload);
        if (item) {
            state.clients.currentItemId = item.id;
            state.clients.items[item.id] = item;
        }
    } catch (e: any) {
        state.clients.errors = handleGraphqQLErrorResponse(e);
    }
    state.clients.loading = false;
}

export const selectItem: Action<string> = async ({state}, payload) => {
    state.clients.currentItemId = payload
}

export const setMeta: Action<SetMetaInput<Types.ClientFragment>> = async ({state}, meta) => {
    state.clients.meta = {
        ...state.clients.meta,
        ...meta
    }
}


export const createItem: AsyncAction<Types.CreateClientMutationVariables['object'],Types.CreateClientMutation['item']> = async ({state, effects}, payload) => {
    let finalPayload = Module.mergeCreatePayload<Types.CreateClientMutationVariables['object']>(payload);
    state.clients.errors = [];
    try {
        let { item } = await effects.gql.mutations.CreateClient({object:finalPayload});
        return item;
    } catch (e: any) {
        state.clients.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const updateItem: AsyncAction<Types.UpdateClientMutationVariables,Types.UpdateClientMutation['item']> = async ({state, effects}, payload) => {
    state.clients.errors = [];
    try {
        let { item } = await effects.gql.mutations.UpdateClient(payload);
        return item;
    } catch (e: any) {
        state.clients.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const deleteItem: AsyncAction<Types.DeleteClientMutationVariables,Types.DeleteClientMutation['item']> = async ({state, effects}, payload) => {
    state.clients.errors = [];
    try {
        let { item } = await effects.gql.mutations.DeleteClient(payload);
        return item;
    } catch (e: any) {
        state.clients.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const syncGetItemsMeta: Action<Types.SyncGetClientsMetaSubscriptionVariables> = async ({state, actions, effects}, {where}) => {
    effects.subscriptions.subscribe<Types.SyncGetClientsMetaSubscription, Types.SyncGetClientsMetaSubscriptionVariables>(
        'syncGetClientsMeta', 
        subscriptions.SyncGetClientsMeta, 
        { where }, 
        actions.clients.onItemsMeta
    );
}

export const unsubItemsMeta: Action = ({effects}) => {
    effects.subscriptions.unsubscribe('syncGetClientsMeta');
}

export const onItemsMeta: Action<GraphQLSubscriptionResultsPayload<Types.SyncGetClientsMetaSubscription>> = async ({state}, payload) => {
    if (payload.error) {
        state.clients.errors = [ ...state.clients.errors, ...handleGraphqQLSubscriptionErrorResponse(payload.error) ];
    }
    if (payload.data) {
        state.clients.meta.total = payload.data.meta.aggregate?.count || 0;
    }    
}

export const syncGetItems: Action<Types.SyncGetClientsSubscriptionVariables> = async ({state, actions, effects}, payload) => {
    let { limit, offset, sortKey, sortOrder } = state.clients.meta;
    let originalPayload = { limit, offset, order_by: { [sortKey]: sortOrder } };
    state.clients.loading = true;
    effects.subscriptions.subscribe<Types.SyncGetClientsSubscription, Types.SyncGetClientsSubscriptionVariables>(
        'syncGetClients', 
        subscriptions.SyncGetClients, 
        {...originalPayload, ...payload}, 
        actions.clients.onItemsData,
        () => { state.clients.loading = false }
    );
}

export const unsubItemsData: Action = ({state,effects}) => {
    effects.subscriptions.unsubscribe('syncGetClients');
    state.clients.items = {};
}

export const onItemsData: Action<GraphQLSubscriptionResultsPayload<Types.SyncGetClientsSubscription>> = async ({state}, payload) => {
    if (payload.error) {
        state.clients.errors = [ ...state.clients.errors, ...handleGraphqQLSubscriptionErrorResponse(payload.error) ];
    }
    if (payload.data&&payload.data.items) {
        state.clients.items = mapArrayToStateObject(payload.data.items, 'id');
    }  
}

export const subscribeToItems: Action<Types.SyncGetClientsSubscriptionVariables> = async ({actions}, payload) => {
    let finalPayload = Module.mergeQueryPayload(payload);
    actions.clients.syncGetItems(finalPayload);
    actions.clients.syncGetItemsMeta(finalPayload);
}

export const unsubscribeFromItems: Action = ({actions}) => {
    actions.clients.unsubItemsData();
    actions.clients.unsubItemsMeta();
}

export const syncGetItem: Action<Types.SyncGetClientSubscriptionVariables> = async ({state, actions, effects}, payload) => {
    state.clients.loading = true;
    effects.subscriptions.subscribe<Types.SyncGetClientSubscription, Types.SyncGetClientSubscriptionVariables>(
        'syncGetClient', 
        subscriptions.SyncGetClient, 
        payload, 
        actions.clients.onItemData,
        () => { state.clients.loading = false }
    );
}

export const unsubItemData: Action = ({state,effects}) => {
    effects.subscriptions.unsubscribe('syncGetClient');
    state.clients.currentItemId = null;
}

export const onItemData: Action<GraphQLSubscriptionResultsPayload<Types.SyncGetClientSubscription>> = async ({state}, payload) => {
    if (payload.error) {
        state.clients.errors = [ ...state.clients.errors, ...handleGraphqQLSubscriptionErrorResponse(payload.error) ];
    }
    if (payload.data&&payload.data.item) {
        state.clients.currentItemId = payload.data.item.id;
        state.clients.items[payload.data.item.id] = payload.data.item;
    }
}

export const subscribeToItem: Action<Types.SyncGetClientSubscriptionVariables> = async ({actions}, payload) => {
    actions.clients.syncGetItem(payload);
}

export const unsubscribeFromItem: Action = ({actions}) => {
    actions.clients.unsubItemData();
}

export const actions = {
    getItems,
    getAllItems,
    resetState,
    resetMeta,
    getMoreItems,
    resetModule,
    getItem,
    loadItem,
    selectItem,
    setMeta,
    createItem,
    updateItem,
    deleteItem,
    syncGetItemsMeta,
    unsubItemsMeta,
    onItemsMeta,
    syncGetItems,
    unsubItemsData,
    onItemsData,
    subscribeToItems,
    unsubscribeFromItems,
    syncGetItem,
    unsubItemData,
    onItemData,
    subscribeToItem,
    unsubscribeFromItem
};
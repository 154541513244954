import { Box, Grid, Image } from "@mantine/core";
import { OrderInfoViewProps } from "./_types";
import { InfoItemPlain } from "components/ui/InfoItem";
import { checkItemValidity } from "./_utils";
import ResponsiveView from "components/ui/ResponsiveView";

const GetPickUpLocation: React.FC<OrderInfoViewProps> = ({ order, invalidFields }) => {
    return (
        <Box>

            <Grid grow gutter="xs">

                <Grid.Col span={12}>
                    <InfoItemPlain label="Location" value={order.pickup_geo} validation={checkItemValidity('pickup_geo', invalidFields)} />
                </Grid.Col>

                {order.pickup_geo?(
                    <Grid.Col span={12}>
                        <ResponsiveView
                            renderDesktop={
                                <Image
                                    radius="md"
                                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${order.pickup_geo}&zoom=11&size=900x200&key=AIzaSyCpm1d5SwHD8Zb8fLx8c0EujWR0ylx3FFU&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C${order.pickup_geo}`}
                                    alt={order.pickup_geo||''} />
                            }
                            renderMobile={
                                <Image
                                    radius="md"
                                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${order.pickup_geo}&zoom=11&size=400x300&key=AIzaSyCpm1d5SwHD8Zb8fLx8c0EujWR0ylx3FFU&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C${order.pickup_geo}`}
                                    alt={order.pickup_geo||''} />
                            }
                        />
                    </Grid.Col>
                ):null}

            </Grid>

        </Box>
    )
}

export default GetPickUpLocation;
import React from 'react';
import { Text, Box, Drawer, Button, MediaQuery, MantineColor, MantineSize, ButtonVariant } from '@mantine/core';
import Icons from 'components/ui/Icons';
import { Icon } from '@iconify/react';
import { GenericDictionary } from 'state/_types';
import { GenericModel } from 'state/Module';

export type PageDrawerActionProps<M extends GenericModel> = {
    title: string;
    icon?: string;
    action: React.FC<any>;
    props?: Omit<DrawerActionProps<M>, "onClose"> | GenericDictionary;
    color?: MantineColor;
    variant?: ButtonVariant;
    size?: MantineSize;  
    iconColor?: string;
}

export type DrawerActionProps<M extends GenericModel, A extends string = 'none'> = {
    defaultAction?: A;
    currentItemId?: string;
    currentItem?: M | null;
    onClose: (item?: Pick<M,'id'>) => void;
}
 
function PageDrawerAction<M extends GenericModel>({
    title,
    icon = Icons.add,
    action,
    props = {},
    color = "blue",
    iconColor = "white",
    size = "sm",
    variant = "filled"
}: React.PropsWithChildren<PageDrawerActionProps<M>>
): React.ReactElement {

    const PageAction = action
    const [opened, setOpened] = React.useState(false);

    return (
        <Box>

            <Drawer
                position="right"
                opened={opened}
                onClose={() => setOpened(false)}
                title={title}
                padding={0}
                size="xl"
                styles={(theme)=>({
                    header: {
                        borderBottom: `1px solid ${theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[5]}`,
                        marginBottom: 0,
                        padding: theme.spacing.xl
                    },
                    drawer: { 
                        height: '100%',
                        minHeight: '100%',
                        maxHeight: '100%',
                    },
                })}
            >
                <PageAction {...props} onClose={() => setOpened(false)} />
            </Drawer>

            <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
                <Button
                    onClick={() => setOpened(true)}
                    rightIcon={<Icon icon={icon} color={iconColor} width={18} />}
                    color={color}
                    variant={variant}
                    size={size}
                >
                    <Text>{title}</Text>
                </Button>
            </MediaQuery>

            <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                <Box>
                    <Button 
                        onClick={() => setOpened(true)}
                        color={color}
                        variant={variant}
                        size="sm"
                        px={8}
                    >
                        <Icon icon={icon} color={iconColor} width={19} />
                    </Button>
                </Box>
            </MediaQuery>

        </Box>
    );
}

export default PageDrawerAction;
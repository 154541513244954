import React from 'react';
import { Link } from "react-router-dom";
import { Card, Grid, Text, Badge, Group } from '@mantine/core';

import { useActions, useAppState } from 'state';
import PageHeader from 'components/layout/page/PageHeader';
import { NewRootUserAction } from 'components/root/user/RootUserActions';
import ModuleErrors from 'components/ui/ModuleErrors';
import { PageFilters, usePageFilters } from 'components/ui/PageFilters';
import { UserFragment, Users_Bool_Exp, User_Status_Enum } from 'state/gql/_types';
import { parseUserRoleColor } from 'components/root/user/UserUtils';
import PageLayout from 'components/layout/page/PageLayout';
import { EmptyPageComponent } from 'components/ui/Misc';
import LoadMoreButton from 'components/ui/LoadMoreButton';

const Users: React.FC = () => {

    const tenant_id = useAppState(state => state.profile?.tenant_id)
    const { itemsList, errors, meta, loadingMore } = useAppState(state => state.users)
    const { subscribeToItems, unsubscribeFromItems, getMoreItems, resetModule } = useActions().users

    const {
        filters,
        filterParams
    } = usePageFilters<Users_Bool_Exp['_and'], UserFragment>({
        baseFilters: [{ tenant_id: { _eq: tenant_id } }],
        clickFilters: [
            {
                _or: [
                    { status: { _eq: User_Status_Enum.Active } },
                    { status: { _eq: User_Status_Enum.Inactive } }
                ]
            }
        ],
        searchFilterFields: ['email', 'first_name', 'last_name', 'phone', 'address', 'city'],
        clickFilterField: 'status',
        clickFilterValues: {
            active: { label: 'Active', filter: [{ status: { _eq: User_Status_Enum.Active } }] },
            inactive: { label: 'Inactive', filter: [{ status: { _eq: User_Status_Enum.Inactive } }] }
        }
    })

    React.useEffect(() => {
        subscribeToItems(filters);
        return () => {
            unsubscribeFromItems()
            resetModule()
        }
    }, [filters, subscribeToItems, unsubscribeFromItems, resetModule])

    return (
        <PageLayout
            header={<PageHeader
                title='Users'
                renderAction={<NewRootUserAction />}
            />}
        >

            <PageFilters options={filterParams} />

            <ModuleErrors errors={errors} />

            {itemsList.length ? (
                <React.Fragment>
                    <Grid>
                        {itemsList.map((i, k) => {
                            return (
                                <Grid.Col md={6} key={k}>
                                    <Card
                                        component={Link}
                                        to={`/users/${i.id}`}
                                        shadow="xs"
                                        sx={(theme) => ({
                                            '&:hover': {
                                                backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[9],
                                            },
                                        })}
                                    >
                                        <Group position='apart'>
                                            <Text weight={500}>{i.first_name} {i.last_name}</Text>
                                            <Badge variant="outline" color={parseUserRoleColor(i.role)}>{i.role}</Badge>
                                        </Group>
                                        <Text size="sm">{i.email}</Text>
                                    </Card>
                                </Grid.Col>
                            )
                        })}
                    </Grid>
                    <LoadMoreButton meta={meta} onLoadMore={getMoreItems} loading={loadingMore} />
                </React.Fragment>
            ) : (
                <EmptyPageComponent label='Users' />
            )}

        </PageLayout>
    )
}

export default Users;
import { MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
    colorScheme: 'light',
    fontFamily: 'Euclid Circular B',
    headings: { fontFamily: 'Euclid Circular B' },
    primaryColor: 'blue',
    black: '#050505',
    breakpoints: {
        xs: 600,
        sm: 900,
        md: 1300,
        lg: 1500,
        xl: 2000,
    },
}

export default theme;
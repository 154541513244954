import { Box, Grid } from "@mantine/core";
import { OrderInfoViewProps } from "./_types";
import { InfoItemPlain } from "components/ui/InfoItem";
import { checkItemValidity } from "./_utils";

const DeliveryInspection: React.FC<OrderInfoViewProps> = ({ order, invalidFields }) => {
    return (
        <Box>

            <Grid grow gutter="xs">

                <Grid.Col span={12}>
                    <InfoItemPlain label="Fuel" value={order.fuel_out} validation={checkItemValidity('fuel_out', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Miles" value={order.miles_out} type="number" validation={checkItemValidity('miles_out', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Images" value={order.delivery_images?.split(',').length} validation={checkItemValidity('delivery_images', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Video" value={order.delivery_videos?.split(',').length} validation={checkItemValidity('delivery_videos', invalidFields)} />
                </Grid.Col>

            </Grid>

        </Box>
    )
}

export default DeliveryInspection;
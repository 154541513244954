import select_dates from './schemas/select-dates.json'
import select_vehicle from './schemas/select-vehicle.json'
import client_details from './schemas/client-details.json'
import accept_terms from './schemas/accept-terms.json'

const ReservationSchemas = {
    select_dates,
    select_vehicle,
    client_details,
    accept_terms,
}

export const getReservationSchema = (index: string) => {
    let key = index.replaceAll('-','_')
    return ReservationSchemas[key as keyof typeof ReservationSchemas] || null
}

export default ReservationSchemas
import React from 'react';
import { NavLink, useParams } from "react-router-dom";

import { useActions, useAppState } from 'state';

import Loader from 'components/app/Loader';
import PageHeader from 'components/layout/page/PageHeader';
import ModuleErrors from 'components/ui/ModuleErrors';
import PageLayout from 'components/layout/page/PageLayout';
import { Button, Text, MediaQuery, Paper, Stack } from '@mantine/core';
import { TabDividerSpacing } from 'components/ui/Tabs';
import { InfoItemDownload } from 'components/ui/InfoItem';
import Icons from 'components/ui/Icons';
import { Icon } from '@iconify/react';
import TenantOrderTabs from 'components/tenant/order/TenantOrderTabs';

const TenantOrderDetails: React.FC = () => {
    const { id } = useParams()
    const { loading, currentItem, errors } = useAppState().orders
    const { subscribeToItem, unsubscribeFromItem, resetModule } = useActions().orders

    React.useEffect(() => {
        subscribeToItem({ id });
        return () => {
            resetModule()
            unsubscribeFromItem()
        }
    }, [id, subscribeToItem, unsubscribeFromItem, resetModule])

    if (loading || !currentItem) return <Loader />

    let contracts = [currentItem.contract_id].filter(c => !!c && c.length)
    let clientLicenseFiles = currentItem.license_images ? currentItem.license_images.split(',').filter(c => !!c && c.length) : []
    let clientInsuranceFiles = [currentItem.insurance_image].filter(c => !!c && c.length)
    let deliveryImages = currentItem.delivery_images ? currentItem.delivery_images.split(',').filter(c => !!c && c.length) : []
    let deliveryVideos = currentItem.delivery_videos ? currentItem.delivery_videos.split(',').filter(c => !!c && c.length) : []
    let pickupImages = currentItem.pickup_images ? currentItem.pickup_images.split(',').filter(c => !!c && c.length) : []
    let pickupVideos = currentItem.pickup_videos ? currentItem.pickup_videos.split(',').filter(c => !!c && c.length) : []

    let contractsAvailable = contracts.length
    let clientLicenseFilesAvailable = clientLicenseFiles.length
    let clientInsuranceFilesAvailable = clientInsuranceFiles.length

    let deliveryImagesAvailable = deliveryImages.length
    let deliveryVideosAvailable = deliveryVideos.length
    let pickupImagesAvailable = pickupImages.length
    let pickupVideosAvailable = pickupVideos.length

    return (
        <PageLayout
            showFooterNav={false}
            header={
                <PageHeader
                    title={`Order #${currentItem.order_number} - ${currentItem.client?.first_name} ${currentItem.client?.last_name}`}
                    renderAction={
                        <React.Fragment>
                            {currentItem.status === 'pending' || currentItem.status === 'open' ? (
                                <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                                    <Button
                                        to={`/orders/${currentItem.id}/process`}
                                        component={NavLink}
                                        rightIcon={<Icon icon={Icons.forward} color="white" width={18} />}
                                        color="blue"
                                        size="sm"
                                    >
                                        <Text>Continue Rental Process</Text>
                                    </Button>
                                </MediaQuery>
                            ) : null}
                        </React.Fragment>
                    }
                />
            }
        >
            <ModuleErrors errors={errors} />

            <TenantOrderTabs />

            <TabDividerSpacing label="Contracts" />

            <Stack spacing="xs">
                {contractsAvailable ? (
                    <InfoItemDownload label="Rental Agreement Contract" url={currentItem.contract_preview || ''} />
                ) : (
                    <NotAvailable label='Contracts' />
                )}
            </Stack>

            <TabDividerSpacing label="Client Files" />

            <Stack spacing="xs">
                {clientLicenseFiles.map((url, k) => {
                    return (
                        <InfoItemDownload key={`license-photo-${k}`} label={`Client License Photo ${k + 1}`} url={url || ''} />
                    )
                })}

                {clientInsuranceFiles.map((url, k) => {
                    return (
                        <InfoItemDownload key={`insurance-photo-${k}`} label={`Client Insurance Photo`} url={url || ''} />
                    )
                })}
            </Stack>

            {!clientLicenseFilesAvailable && !clientInsuranceFilesAvailable && <NotAvailable label='Client Files' />}

            <TabDividerSpacing label="Delivery Inspection Files" />

            <Stack spacing="xs">
                {deliveryImages.map((url, k) => {
                    return (
                        <InfoItemDownload key={`delivery-photo-${k}`} label={`Delivery Photo ${k + 1}`} url={url || ''} />
                    )
                })}

                {deliveryVideos.map((url, k) => {
                    return (
                        <InfoItemDownload key={`delivery-video-${k}`} label={`Delivery Video ${k + 1}`} url={url || ''} />
                    )
                })}
            </Stack>

            {!deliveryImagesAvailable && !deliveryVideosAvailable && <NotAvailable label='Delivery Inspection Files' />}

            <TabDividerSpacing label="PickUp Inspection Files" />

            <Stack spacing="xs">
                {pickupImages.map((url, k) => {
                    return (
                        <InfoItemDownload key={`pickup-photo-${k}`} label={`PickUp Photo ${k + 1}`} url={url || ''} />
                    )
                })}

                {!pickupImagesAvailable && !pickupVideosAvailable && <NotAvailable label='PickUp Inspection Files' />}

                {pickupVideos.map((url, k) => {
                    return (
                        <InfoItemDownload key={`pickup-video-${k}`} label={`PickUp Video ${k + 1}`} url={url || ''} />
                    )
                })}
            </Stack>


        </PageLayout>
    )
}

export const NotAvailable: React.FC<{ label: string }> = ({ label }) => {
    return (
        <Paper
            p="md"
            sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[8],
            })}
        >
            <Text
                align='center'
                size='xs'
                sx={(theme) => ({
                    color: theme.colorScheme === 'light' ? theme.colors.gray[5] : theme.colors.dark[4],
                })}
            >
                No {label} Available
            </Text>
        </Paper>
    )
}

export default TenantOrderDetails;
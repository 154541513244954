import { TenantFragment } from "state/gql/_types";
import { Badge, Box, Grid, Text } from '@mantine/core';
import { capitalizeWord, formatAddress } from "utils/string";
import InfoItem from "components/ui/InfoItem";
import { parseTenantStatusColor } from "./TenantUtils";
import { formatDate } from "utils/dates";
import React from "react";

export const TenantInfo: React.FC<{ tenant: TenantFragment }> = ({ tenant }) => {
    return (
        <Grid>

            <Grid.Col xs={6}>
                <InfoItem label="Status">
                    <Badge size="lg" variant="outline" color={parseTenantStatusColor(tenant.status)}>{tenant.status}</Badge>
                </InfoItem>
            </Grid.Col>

            <Grid.Col xs={6}>
                <InfoItem label="Tenant Type" value={capitalizeWord(tenant.type)} />
            </Grid.Col>

            <Grid.Col xs={6}>
                <InfoItem label="Name" value={tenant.name} />
            </Grid.Col>
  
            <Grid.Col xs={6}>
                <InfoItem label="Domain">
                    <Box>
                        <Text component="span" weight={600} sx={theme => ({ color: theme.colors.red[6] })}>{tenant.slug}</Text>
                        <Text component="span" weight={600}>.clutch360app.com</Text>
                    </Box>
                </InfoItem>
            </Grid.Col>

            <Grid.Col xs={12}>
                <InfoItem label="Address" value={formatAddress(tenant.address, tenant.city, tenant.state, tenant.zip)} />
            </Grid.Col>

            <Grid.Col xs={6}>
                <InfoItem label="Phone" value={tenant.phone} />
            </Grid.Col>
            <Grid.Col xs={6}>
                <InfoItem label="Billing Email" value={tenant.billing_email} />
            </Grid.Col>

            <Grid.Col xs={6}>
                <InfoItem label="Created" value={formatDate(tenant.created_at)} />
            </Grid.Col>
            <Grid.Col xs={6}>
                <InfoItem label="Updated" value={formatDate(tenant.updated_at)} />
            </Grid.Col>

        </Grid>
    )
} 

export const TenantSettingsInfo: React.FC<{ tenant: TenantFragment }> = ({ tenant }) => {
    return (
        <Grid>

            <Grid.Col xs={6}>
                <InfoItem label="Vehicle Policies">
                    <Badge size="lg" variant="outline" color={tenant.vehicle_policy_enabled ? 'green' : 'red'}>{tenant.vehicle_policy_enabled ? 'Enabled' : 'Disabled'}</Badge>
                </InfoItem>
            </Grid.Col>

        </Grid>
    )
}
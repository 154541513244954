import PageHeader from 'components/layout/page/PageHeader';
import PageLayout from 'components/layout/page/PageLayout';
import { TenantBillingActions, TenantBillingInfoDetails, TenantBillingWarnings } from 'components/root/tenant/TenantBillingInfo';
import { TabDivider } from 'components/ui/Tabs';
import React from 'react';

const TenantBilling: React.FC = () => {

    return (
        <PageLayout
            header={<PageHeader
                title={`Account Billing`}
            />}
        >

            <TenantBillingWarnings showButtons={false} />
            <TabDivider label="Billing Options" />
            <TenantBillingActions />
            <TabDivider label="Subscription Details" />
            <TenantBillingInfoDetails />

        </PageLayout>
    )
}

export default TenantBilling;
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  bigint: any;
  date: any;
  float8: any;
  numeric: any;
  time: any;
  timestamptz: any;
  uuid: any;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CheckSubDomainOutput = {
  taken: Scalars['Boolean'];
};

export type CreateTenantInput = {
  address: Scalars['String'];
  billing_email: Scalars['String'];
  city: Scalars['String'];
  name: Scalars['String'];
  parent_id: Scalars['uuid'];
  phone?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  state: Scalars['String'];
  vehicle_policy_enabled?: Maybe<Scalars['Boolean']>;
  zip: Scalars['String'];
};

export type CreateTenantOutput = {
  id: Scalars['uuid'];
};

export type DeleteTenantOutput = {
  id: Scalars['uuid'];
};

export type GenerateContractInput = {
  order_id: Scalars['String'];
};

export type GenerateContractOutput = {
  id: Scalars['String'];
};

export type GetOrderStatusOutput = {
  state: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};


export type OrderTriggerEventInput = {
  event: Scalars['String'];
  order_id: Scalars['String'];
};

export type OrderTriggerEventOutput = {
  id: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type Stripe = {
  connectedAccount: StripeConnectedAccount;
  connectedAccounts: StripeConnectedAccounts;
  customer: StripeCustomer;
  customers: StripeCustomers;
};


export type StripeConnectedAccountArgs = {
  id: Scalars['String'];
};


export type StripeCustomerArgs = {
  id: Scalars['String'];
};


export type StripeCustomersArgs = {
  email?: Maybe<Scalars['String']>;
  endingBefore?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  startingAfter?: Maybe<Scalars['String']>;
};

export type StripeAddress = {
  /** City/Ward. */
  city?: Maybe<Scalars['String']>;
  /** Two-letter country code ([ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)). */
  country?: Maybe<Scalars['String']>;
  /** Block/Building number. */
  line1?: Maybe<Scalars['String']>;
  /** Building details. */
  line2?: Maybe<Scalars['String']>;
  /** ZIP or postal code. */
  postalCode?: Maybe<Scalars['String']>;
  /** Prefecture. */
  state?: Maybe<Scalars['String']>;
};

export type StripeBillingPortalSession = {
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Int'];
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: Scalars['Boolean'];
  /** The IETF language tag of the locale Customer Portal is displayed in. If blank or auto, the customer's `preferred_locales` or browser's locale is used. */
  locale?: Maybe<Scalars['String']>;
  /** String representing the object's type. Objects of the same type share the same value. */
  object: Scalars['String'];
  /** The URL to redirect customers to when they click on the portal's link to return to your website. */
  returnUrl?: Maybe<Scalars['String']>;
  /** The short-lived URL of the session that gives customers access to the customer portal. */
  url: Scalars['String'];
};

/** Stripe charge object */
export type StripeCharge = {
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  amountRefunded: Scalars['Int'];
  application?: Maybe<StripeConnectedAccount>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  billingDetails?: Maybe<Scalars['JSON']>;
  calculatedStatementDescriptor?: Maybe<Scalars['String']>;
  captured: Scalars['Boolean'];
  created?: Maybe<Scalars['Int']>;
  currency: Scalars['String'];
  customer: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  disputed: Scalars['Boolean'];
  failureCode?: Maybe<Scalars['String']>;
  fraudDetails?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  invoice?: Maybe<StripeInvoice>;
  livemode: Scalars['Boolean'];
  metadata?: Maybe<Scalars['JSON']>;
  outcome?: Maybe<Scalars['JSON']>;
  paid: Scalars['Boolean'];
  paymentIntent?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentMethodDetails?: Maybe<Scalars['JSON']>;
  receiptEmail?: Maybe<Scalars['String']>;
  receiptNumber?: Maybe<Scalars['String']>;
  receiptUrl?: Maybe<Scalars['String']>;
  refunded: Scalars['Boolean'];
  refunds?: Maybe<Scalars['JSON']>;
  shipping?: Maybe<Scalars['JSON']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  statementDescriptorSuffix?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  transferData?: Maybe<Scalars['JSON']>;
  transferGroup?: Maybe<Scalars['String']>;
};

/** List of Stripe charge objects */
export type StripeCharges = {
  data: Array<StripeCharge>;
  hasMore: Scalars['Boolean'];
  object: Scalars['String'];
  url: Scalars['String'];
};

/** Stripe charge object */
export type StripeConnectedAccount = {
  businessProfile: Scalars['JSON'];
  businessType?: Maybe<Scalars['String']>;
  capabilities: Scalars['JSON'];
  chargesEnabled: Scalars['Boolean'];
  company: Scalars['JSON'];
  controller?: Maybe<Scalars['JSON']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int']>;
  defaultCurrency?: Maybe<Scalars['String']>;
  detailsSubmitted: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  externalAccounts: Scalars['JSON'];
  futureRequirements: Scalars['JSON'];
  id: Scalars['String'];
  individual: Scalars['JSON'];
  metadata: Scalars['JSON'];
  object: Scalars['String'];
  payoutsEnabled: Scalars['Boolean'];
  requirements: Scalars['JSON'];
  settings: Scalars['JSON'];
  tosAcceptance: Scalars['JSON'];
};

/** List of Stripe Connected Account objects */
export type StripeConnectedAccounts = {
  data: Array<StripeConnectedAccount>;
  hasMore: Scalars['Boolean'];
  object: Scalars['String'];
  url: Scalars['String'];
};

/** This object represents a customer of your business. It lets you create recurring charges and track payments that belong to the same customer. */
export type StripeCustomer = {
  /** The customer's address. */
  address?: Maybe<StripeAddress>;
  /** Current balance, if any, being stored on the customer. If negative, the customer has credit to apply to their next invoice. If positive, the customer has an amount owed that will be added to their next invoice. The balance does not refer to any unpaid invoices; it solely takes into account amounts that have yet to be successfully applied to any invoice. This balance is only taken into account as invoices are finalized. */
  balance: Scalars['Int'];
  charges: StripeCharges;
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Int'];
  /** Three-letter [ISO code for the currency](https://stripe.com/docs/currencies) the customer can be charged in for recurring billing purposes. */
  currency?: Maybe<Scalars['String']>;
  /**
   * When the customer's latest invoice is billed by charging automatically, `delinquent` is `true` if the invoice's latest charge failed. When the customer's latest invoice is billed by sending an invoice, `delinquent` is `true` if the invoice isn't paid by its due date.
   *
   * If an invoice is marked uncollectible by [dunning](https://stripe.com/docs/billing/automatic-collection), `delinquent` doesn't get reset to `false`.
   */
  delinquent?: Maybe<Scalars['Boolean']>;
  /** An arbitrary string attached to the object. Often useful for displaying to users. */
  description?: Maybe<Scalars['String']>;
  /** The customer's email address. */
  email?: Maybe<Scalars['String']>;
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** The prefix for the customer used to generate unique invoice numbers. */
  invoicePrefix?: Maybe<Scalars['String']>;
  invoices: StripeInvoices;
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: Scalars['Boolean'];
  /** Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  metadata: Scalars['JSON'];
  /** The customer's full name or business name. */
  name?: Maybe<Scalars['String']>;
  /** The suffix of the customer's next invoice number, e.g., 0001. */
  nextInvoiceSequence?: Maybe<Scalars['Int']>;
  /** String representing the object's type. Objects of the same type share the same value.  */
  object: Scalars['String'];
  paymentIntents: StripePaymentIntents;
  paymentMethods: StripePaymentMethods;
  /** The customer's phone number. */
  phone?: Maybe<Scalars['String']>;
  /** The customer's preferred locales (languages), ordered by preference. */
  preferredLocales?: Maybe<Array<Scalars['String']>>;
  /** Mailing and shipping address for the customer. Appears on invoices emailed to this customer. */
  shipping?: Maybe<StripeCustomerShipping>;
  /** The customer's current subscriptions, if any. */
  subscriptions: StripeSubscriptions;
  tax?: Maybe<StripeCustomerTax>;
};


/** This object represents a customer of your business. It lets you create recurring charges and track payments that belong to the same customer. */
export type StripeCustomerPaymentMethodsArgs = {
  endingBefore?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  startingAfter?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodTypes>;
};

export type StripeCustomerShipping = {
  address?: Maybe<StripeAddress>;
  /** The delivery service that shipped a physical product, such as Fedex, UPS, USPS, etc. */
  carrier?: Maybe<Scalars['String']>;
  /** Recipient name. */
  name?: Maybe<Scalars['String']>;
  /** Recipient phone (including extension). */
  phone?: Maybe<Scalars['String']>;
  /** The tracking number for a physical product, obtained from the delivery service. If multiple tracking numbers were generated for this purchase, please separate them with commas. */
  trackingNumber?: Maybe<Scalars['String']>;
};

export type StripeCustomerTax = {
  /** A recent IP address of the customer used for tax reporting and tax location inference. */
  ipAddress?: Maybe<Scalars['String']>;
  /** The customer's location as identified by Stripe Tax. */
  location?: Maybe<StripeCustomerTaxLocation>;
};

export type StripeCustomerTaxLocation = {
  /** The customer's country as identified by Stripe Tax. */
  country: Scalars['String'];
  /** The customer's state, county, province, or region as identified by Stripe Tax. */
  state?: Maybe<Scalars['String']>;
};

export type StripeCustomers = {
  data: Array<StripeCustomer>;
  /** True if this list has another page of items after this one that can be fetched. */
  hasMore: Scalars['Boolean'];
  object: Scalars['String'];
  /** The URL where this list can be accessed. */
  url: Scalars['String'];
};

export type StripeInvoice = {
  /** The country of the business associated with this invoice, most often the business creating the invoice. */
  accountCountry?: Maybe<Scalars['String']>;
  /** The public name of the business associated with this invoice, most often the business creating the invoice. */
  accountName?: Maybe<Scalars['String']>;
  /** Final amount due at this time for this invoice. If the invoice's total is smaller than the minimum charge amount, for example, or if there is account credit that can be applied to the invoice, the `amount_due` may be 0. If there is a positive `starting_balance` for the invoice (the customer owes money), the `amount_due` will also take that into account. The charge that gets generated for the invoice will be for the amount specified in `amount_due`. */
  amountDue: Scalars['Int'];
  /** The amount, in %s, that was paid. */
  amountPaid: Scalars['Int'];
  /** The difference between amount_due and amount_paid, in %s. */
  amountRemaining: Scalars['Int'];
  application?: Maybe<StripeConnectedAccount>;
  /** The fee in %s that will be applied to the invoice and transferred to the application owner's Stripe account when the invoice is paid. */
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  /** Number of payment attempts made for this invoice, from the perspective of the payment retry schedule. Any payment attempt counts as the first attempt, and subsequently only automatic retries increment the attempt count. In other words, manual payment attempts after the first attempt do not affect the retry schedule. */
  attemptCount: Scalars['Int'];
  /** Whether an attempt has been made to pay the invoice. An invoice is not attempted until 1 hour after the `invoice.created` webhook, for example, so you might not want to display that invoice as unpaid to your users. */
  attempted: Scalars['Boolean'];
  /** Controls whether Stripe will perform [automatic collection](https://stripe.com/docs/billing/invoices/workflow/#auto_advance) of the invoice. When `false`, the invoice's state will not automatically advance without an explicit action. */
  autoAdvance?: Maybe<Scalars['Boolean']>;
  automaticTax: StripeInvoiceAutomaticTax;
  /** Indicates the reason why the invoice was created. `subscription_cycle` indicates an invoice created by a subscription advancing into a new period. `subscription_create` indicates an invoice created due to creating a subscription. `subscription_update` indicates an invoice created due to updating a subscription. `subscription` is set for all old invoices to indicate either a change to a subscription or a period advancement. `manual` is set for all invoices unrelated to a subscription (for example: created via the invoice editor). The `upcoming` value is reserved for simulated invoices per the upcoming invoice endpoint. `subscription_threshold` indicates an invoice created due to a billing threshold being reached. */
  billingReason?: Maybe<Scalars['String']>;
  /** Either `charge_automatically`, or `send_invoice`. When charging automatically, Stripe will attempt to pay this invoice using the default source attached to the customer. When sending an invoice, Stripe will email this invoice to the customer with payment instructions. */
  collectionMethod?: Maybe<Scalars['String']>;
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Int'];
  /** Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase. Must be a [supported currency](https://stripe.com/docs/currencies). */
  currency: Scalars['String'];
  /** The ID of the customer who will be billed. */
  customer: Scalars['String'];
  /** The customer's address. Until the invoice is finalized, this field will equal `customer.address`. Once the invoice is finalized, this field will no longer be updated. */
  customerAddress?: Maybe<StripeAddress>;
  /** The customer's email. Until the invoice is finalized, this field will equal `customer.email`. Once the invoice is finalized, this field will no longer be updated. */
  customerEmail?: Maybe<Scalars['String']>;
  /** The customer's name. Until the invoice is finalized, this field will equal `customer.name`. Once the invoice is finalized, this field will no longer be updated. */
  customerName?: Maybe<Scalars['String']>;
  /** The customer's phone number. Until the invoice is finalized, this field will equal `customer.phone`. Once the invoice is finalized, this field will no longer be updated. */
  customerPhone?: Maybe<Scalars['String']>;
  /** The customer's shipping information. Until the invoice is finalized, this field will equal `customer.shipping`. Once the invoice is finalized, this field will no longer be updated. */
  customerShipping?: Maybe<StripeInvoiceCustomerShipping>;
  /** The customer's tax exempt status. Until the invoice is finalized, this field will equal `customer.tax_exempt`. Once the invoice is finalized, this field will no longer be updated. */
  customerTaxExempt?: Maybe<Scalars['String']>;
  /** The customer's tax IDs. Until the invoice is finalized, this field will contain the same tax IDs as `customer.tax_ids`. Once the invoice is finalized, this field will no longer be updated. */
  customerTaxIds?: Maybe<Array<StripeInvoiceCustomerTaxId>>;
  /** ID of the default payment method for the invoice. It must belong to the customer associated with the invoice. If not set, defaults to the subscription's default payment method, if any, or to the default payment method in the customer's invoice settings. */
  defaultPaymentMethod?: Maybe<StripePaymentMethod>;
  /** An arbitrary string attached to the object. Often useful for displaying to users. Referenced as 'memo' in the Dashboard. */
  description?: Maybe<Scalars['String']>;
  /** The date on which payment for this invoice is due. This value will be `null` for invoices where `collection_method=charge_automatically`. */
  dueDate?: Maybe<Scalars['Int']>;
  /** Ending customer balance after the invoice is finalized. Invoices are finalized approximately an hour after successful webhook delivery or when payment collection is attempted for the invoice. If the invoice has not been finalized yet, this will be null. */
  endingBalance?: Maybe<Scalars['Int']>;
  /** Footer displayed on the invoice. */
  footer?: Maybe<Scalars['String']>;
  /** The URL for the hosted invoice page, which allows customers to view and pay an invoice. If the invoice has not been finalized yet, this will be null. */
  hostedInvoiceUrl?: Maybe<Scalars['String']>;
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** The link to download the PDF for the invoice. If the invoice has not been finalized yet, this will be null. */
  invoicePdf?: Maybe<Scalars['String']>;
  /** The individual line items that make up the invoice. `lines` is sorted as follows: invoice items in reverse chronological order, followed by the subscription, if any. */
  lines: StripeInvoiceLineItems;
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: Scalars['Boolean'];
  /** Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  metadata: Scalars['JSON'];
  /** The time at which payment will next be attempted. This value will be `null` for invoices where `collection_method=send_invoice`. */
  nextPaymentAttempt?: Maybe<Scalars['Int']>;
  /** A unique, identifying string that appears on emails sent to the customer for this invoice. This starts with the customer's unique invoice_prefix if it is specified. */
  number?: Maybe<Scalars['String']>;
  /** String representing the object's type. Objects of the same type share the same value. */
  object: Scalars['String'];
  /** Whether payment was successfully collected for this invoice. An invoice can be paid (most commonly) with a charge or with credit from the customer's account balance. */
  paid: Scalars['Boolean'];
  /** Returns true if the invoice was manually marked paid, returns false if the invoice hasn't been paid yet or was paid on Stripe. */
  paidOutOfBand: Scalars['Boolean'];
  /** End of the usage period during which invoice items were added to this invoice. */
  periodEnd: Scalars['Int'];
  /** Start of the usage period during which invoice items were added to this invoice. */
  periodStart: Scalars['Int'];
  /** Total amount of all post-payment credit notes issued for this invoice. */
  postPaymentCreditNotesAmount: Scalars['Int'];
  /** Total amount of all pre-payment credit notes issued for this invoice. */
  prePaymentCreditNotesAmount: Scalars['Int'];
  /** This is the transaction number that appears on email receipts sent for this invoice. */
  receiptNumber?: Maybe<Scalars['String']>;
  /** Options for invoice PDF rendering. */
  renderingOptions?: Maybe<StripeInvoiceRenderingOptions>;
  /** Starting customer balance before the invoice is finalized. If the invoice has not been finalized yet, this will be the current customer balance. For revision invoices, this also includes any customer balance that was applied to the original invoice. */
  startingBalance: Scalars['Int'];
  /** Extra information about an invoice for the customer's credit card statement. */
  statementDescriptor?: Maybe<Scalars['String']>;
  /** The status of the invoice, one of `draft`, `open`, `paid`, `uncollectible`, or `void`. [Learn more](https://stripe.com/docs/billing/invoices/workflow#workflow-overview) */
  status?: Maybe<Scalars['String']>;
  statusTransition?: Maybe<StripeInvoiceStatusTransitions>;
  /** The subscription that this invoice was prepared for, if any. */
  subscription?: Maybe<StripeSubscription>;
  /** Only set for upcoming invoices that preview prorations. The time used to calculate prorations. */
  subscriptionProrationDate?: Maybe<Scalars['Int']>;
  /** Total of all subscriptions, invoice items, and prorations on the invoice before any invoice level discount or exclusive tax is applied. Item discounts are already incorporated */
  subtotal: Scalars['Int'];
  /** The integer amount in %s representing the subtotal of the invoice before any invoice level discount or tax is applied. Item discounts are already incorporated */
  subtotalExcludingTax?: Maybe<Scalars['Int']>;
  /** The amount of tax on this invoice. This is the sum of all the tax amounts on this invoice. */
  tax?: Maybe<Scalars['Int']>;
  /** Total after discounts and taxes. */
  total: Scalars['Int'];
  /** The integer amount in %s representing the total amount of the invoice including all discounts but excluding all tax. */
  totalExcludingTax?: Maybe<Scalars['Int']>;
  /** Invoices are automatically paid or sent 1 hour after webhooks are delivered, or until all webhook delivery attempts have [been exhausted](https://stripe.com/docs/billing/webhooks#understand). This field tracks the time when webhooks for this invoice were successfully delivered. If the invoice had no webhooks to deliver, this will be set while the invoice is being created. */
  webhooksDeliveredAt?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceAutomaticTax = {
  /** Whether Stripe automatically computes tax on this invoice. Note that incompatible invoice items (invoice items with manually specified [tax rates](https://stripe.com/docs/api/tax_rates), negative amounts, or `tax_behavior=unspecified`) cannot be added to automatic tax invoices. */
  enabled: Scalars['Boolean'];
  /** The status of the most recent automated tax calculation for this invoice. */
  status?: Maybe<Scalars['String']>;
};

export type StripeInvoiceCustomerShipping = {
  address?: Maybe<StripeAddress>;
  /** The delivery service that shipped a physical product, such as Fedex, UPS, USPS, etc. */
  carrier?: Maybe<Scalars['String']>;
  /** Recipient name. */
  name?: Maybe<Scalars['String']>;
  /** Recipient phone (including extension). */
  phone?: Maybe<Scalars['String']>;
  /** The tracking number for a physical product, obtained from the delivery service. If multiple tracking numbers were generated for this purchase, please separate them with commas. */
  trackingNumber?: Maybe<Scalars['String']>;
};

export type StripeInvoiceCustomerTaxId = {
  /** The type of the tax ID, one of `eu_vat`, `br_cnpj`, `br_cpf`, `eu_oss_vat`, `gb_vat`, `nz_gst`, `au_abn`, `au_arn`, `in_gst`, `no_vat`, `za_vat`, `ch_vat`, `mx_rfc`, `sg_uen`, `ru_inn`, `ru_kpp`, `ca_bn`, `hk_br`, `es_cif`, `tw_vat`, `th_vat`, `jp_cn`, `jp_rn`, `li_uid`, `my_itn`, `us_ein`, `kr_brn`, `ca_qst`, `ca_gst_hst`, `ca_pst_bc`, `ca_pst_mb`, `ca_pst_sk`, `my_sst`, `sg_gst`, `ae_trn`, `cl_tin`, `sa_vat`, `id_npwp`, `my_frp`, `il_vat`, `ge_vat`, `ua_vat`, `is_vat`, `bg_uic`, `hu_tin`, `si_tin`, or `unknown` */
  type: Scalars['String'];
  /** The value of the tax ID. */
  value?: Maybe<Scalars['String']>;
};

export type StripeInvoiceLineItem = {
  /** The amount, in %s. */
  amount: Scalars['Int'];
  /** The integer amount in %s representing the amount for this line item, excluding all tax and discounts. */
  amountExcludingTax?: Maybe<Scalars['Int']>;
  /** Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase. Must be a [supported currency](https://stripe.com/docs/currencies). */
  currency: Scalars['String'];
  /** An arbitrary string attached to the object. Often useful for displaying to users. */
  description?: Maybe<Scalars['String']>;
  /** If true, discounts will apply to this line item. Always false for prorations. */
  discountable: Scalars['Boolean'];
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** The ID of the [invoice item](https://stripe.com/docs/api/invoiceitems) associated with this line item if any. */
  invoiceItem?: Maybe<Scalars['String']>;
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: Scalars['Boolean'];
  /** Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. Note that for line items with `type=subscription` this will reflect the metadata of the subscription that caused the line item to be created. */
  metadata: Scalars['JSON'];
  /** String representing the object's type. Objects of the same type share the same value. */
  object: Scalars['String'];
  period: StripeInvoiceLineItemPeriod;
  /** The plan of the subscription, if the line item is a subscription or a proration. */
  plan?: Maybe<StripePlan>;
  /** The price of the line item. */
  price?: Maybe<StripePrice>;
  /** Whether this is a proration. */
  proration: Scalars['Boolean'];
  /** The quantity of the subscription, if the line item is a subscription or a proration. */
  quantity?: Maybe<Scalars['Int']>;
  /** The amount of tax calculated per tax rate for this line item */
  taxAmount?: Maybe<Array<StripeInvoiceLineItemTaxAmount>>;
  /** The tax rates which apply to the line item. */
  taxRates?: Maybe<Array<StripeTaxRate>>;
  /** A string identifying the type of the source of this line item, either an `invoiceitem` or a `subscription`. */
  type: Scalars['String'];
  /** The amount in %s representing the unit amount for this line item, excluding all tax and discounts. */
  unitAmountExcludingTax?: Maybe<Scalars['String']>;
};

export type StripeInvoiceLineItemPeriod = {
  /** The end of the period, which must be greater than or equal to the start. */
  end: Scalars['Int'];
  /** The start of the period. */
  start: Scalars['Int'];
};

export type StripeInvoiceLineItemTaxAmount = {
  /** The amount, in %s, of the tax. */
  amount: Scalars['Int'];
  /** Whether this tax amount is inclusive or exclusive. */
  inclusive: Scalars['Boolean'];
};

export type StripeInvoiceLineItems = {
  data: Array<StripeInvoiceLineItem>;
  /** True if this list has another page of items after this one that can be fetched. */
  hasMore: Scalars['Boolean'];
  object: Scalars['String'];
  /** The URL where this list can be accessed. */
  url: Scalars['String'];
};

export type StripeInvoiceRenderingOptions = {
  /** How line-item prices and amounts will be displayed with respect to tax on invoice PDFs. */
  amountTaxDisplay?: Maybe<Scalars['String']>;
};

export type StripeInvoiceStatusTransitions = {
  /** The time that the invoice draft was finalized. */
  finalizedAt?: Maybe<Scalars['Int']>;
  /** The time that the invoice was marked uncollectible. */
  markedUncollectibleAt?: Maybe<Scalars['Int']>;
  /** The time that the invoice was paid. */
  paidAt?: Maybe<Scalars['Int']>;
  /** The time that the invoice was voided. */
  voidedAt?: Maybe<Scalars['Int']>;
};

export type StripeInvoices = {
  data: Array<StripeInvoice>;
  /** True if this list has another page of items after this one that can be fetched. */
  hasMore: Scalars['Boolean'];
  object: Scalars['String'];
  /** The URL where this list can be accessed. */
  url: Scalars['String'];
};

export type StripeMutations = {
  createBillingPortalSession: StripeBillingPortalSession;
};


export type StripeMutationsCreateBillingPortalSessionArgs = {
  configuration?: Maybe<Scalars['String']>;
  customer: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  returnUrl?: Maybe<Scalars['String']>;
};

/** Payment intents */
export type StripePaymentIntent = {
  amount: Scalars['Int'];
  amountCapturable: Scalars['Int'];
  amountDetails?: Maybe<Scalars['JSON']>;
  amountReceived: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  canceledAt?: Maybe<Scalars['Int']>;
  cancellationReason?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int']>;
  currency: Scalars['String'];
  customer: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoice?: Maybe<StripeInvoice>;
  metadata?: Maybe<Scalars['JSON']>;
  object: Scalars['String'];
  paymentMethodTypes: Array<Scalars['String']>;
  receiptEmail?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  statementDescriptorSuffix?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  transferGroup?: Maybe<Scalars['String']>;
};

export type StripePaymentIntents = {
  data: Array<StripePaymentIntent>;
  hasMore: Scalars['Boolean'];
  object: Scalars['String'];
  url: Scalars['String'];
};

export type StripePaymentMethod = {
  billingDetails?: Maybe<StripePaymentMethodBillingDetails>;
  card?: Maybe<StripePaymentMethodCard>;
  /** The ID of the Customer to which this PaymentMethod is saved. This will not be set when the PaymentMethod has not been saved to a Customer. */
  created: Scalars['Int'];
  customer?: Maybe<Scalars['String']>;
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: Scalars['Boolean'];
  /** Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  metadata: Scalars['JSON'];
  /** String representing the object's type. Objects of the same type share the same value. */
  object: Scalars['String'];
  /** The type of the PaymentMethod. An additional hash is included on the PaymentMethod with a name matching this value. It contains additional information specific to the PaymentMethod type. */
  type: StripePaymentMethodTypes;
};

export type StripePaymentMethodBillingDetails = {
  /** Billing address. */
  address?: Maybe<StripeAddress>;
  /** Email address. */
  email?: Maybe<Scalars['String']>;
  /** Full name. */
  name?: Maybe<Scalars['String']>;
  /** Billing phone number (including extension). */
  phone?: Maybe<Scalars['String']>;
};

export type StripePaymentMethodCard = {
  /** Card brand. Can be `amex`, `diners`, `discover`, `jcb`, `mastercard`, `unionpay`, `visa`, or `unknown`. */
  brand: Scalars['String'];
  /** Checks on Card address and CVC if provided. */
  check?: Maybe<StripePaymentMethodCardChecks>;
  /** Two-letter ISO code representing the country of the card. You could use this attribute to get a sense of the international breakdown of cards you've collected. */
  country?: Maybe<Scalars['String']>;
  /** A high-level description of the type of cards issued in this range. (For internal use only and not typically available in standard API requests.) */
  description?: Maybe<Scalars['String']>;
  /** Two-digit number representing the card's expiration month. */
  expMonth: Scalars['Int'];
  /** Four-digit number representing the card's expiration year. */
  expYear: Scalars['Int'];
  /**
   * Uniquely identifies this particular card number. You can use this attribute to check whether two customers who've signed up with you are using the same card number, for example. For payment methods that tokenize card information (Apple Pay, Google Pay), the tokenized number might be provided instead of the underlying card number.
   *
   * *Starting May 1, 2021, card fingerprint in India for Connect will change to allow two fingerprints for the same card --- one for India and one for the rest of the world.*
   */
  fingerprint?: Maybe<Scalars['String']>;
  /** Card funding type. Can be `credit`, `debit`, `prepaid`, or `unknown`. */
  funding: Scalars['String'];
  /** Issuer identification number of the card. (For internal use only and not typically available in standard API requests.) */
  iin?: Maybe<Scalars['String']>;
  /** The name of the card's issuing bank. (For internal use only and not typically available in standard API requests.) */
  issuer?: Maybe<Scalars['String']>;
  /** The last four digits of the card. */
  last4: Scalars['String'];
  /** Contains information about card networks that can be used to process the payment. */
  networks?: Maybe<StripePaymentMethodCardNetworks>;
  /** Contains details on how this Card maybe be used for 3D Secure authentication. */
  threeDSecureUsage?: Maybe<StripePaymentMethodCardThreeDSecureUsage>;
  /** If this Card is part of a card wallet, this contains the details of the card wallet. */
  wallet?: Maybe<StripePaymentMethodCardWallet>;
};

export type StripePaymentMethodCardChecks = {
  /** If a address line1 was provided, results of the check, one of `pass`, `fail`, `unavailable`, or `unchecked`. */
  addressLine1Check?: Maybe<Scalars['String']>;
  /** If a address postal code was provided, results of the check, one of `pass`, `fail`, `unavailable`, or `unchecked`. */
  addressPostalCodeCheck?: Maybe<Scalars['String']>;
  /** If a CVC was provided, results of the check, one of `pass`, `fail`, `unavailable`, or `unchecked`. */
  cvcCheck?: Maybe<Scalars['String']>;
};

export type StripePaymentMethodCardNetworks = {
  /** All available networks for the card. */
  available: Array<Scalars['String']>;
  /** The preferred network for the card. */
  preferred?: Maybe<Scalars['String']>;
};

export type StripePaymentMethodCardThreeDSecureUsage = {
  /** Whether 3D Secure is supported on this card. */
  supported: Scalars['Boolean'];
};

export type StripePaymentMethodCardWallet = {
  /** (For tokenized numbers only.) The last four digits of the device account number. */
  dynamicLast4?: Maybe<Scalars['String']>;
  masterpass?: Maybe<StripePaymentMethodCardWalletMasterpass>;
  /** The type of the card wallet, one of `amex_express_checkout`, `apple_pay`, `google_pay`, `masterpass`, `samsung_pay`, or `visa_checkout`. An additional hash is included on the Wallet subhash with a name matching this value. It contains additional information specific to the card wallet type. */
  type: StripePaymentMethodCardWalletType;
  visaCheckout?: Maybe<StripePaymentMethodCardWalletVisaCheckout>;
};

export type StripePaymentMethodCardWalletMasterpass = {
  /** Owner's verified billing address. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  billingAddress?: Maybe<StripeAddress>;
  /** Owner's verified email. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  email?: Maybe<Scalars['String']>;
  /** Owner's verified full name. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  name?: Maybe<Scalars['String']>;
  /** Owner's verified shipping address. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  shippinAddress?: Maybe<StripeAddress>;
};

export enum StripePaymentMethodCardWalletType {
  AmexExpressCheckout = 'amex_express_checkout',
  ApplePay = 'apple_pay',
  GooglePay = 'google_pay',
  Masterpass = 'masterpass',
  SamsungPay = 'samsung_pay',
  VisaCheckout = 'visa_checkout'
}

export type StripePaymentMethodCardWalletVisaCheckout = {
  /** Owner's verified billing address. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  billingAddress?: Maybe<StripeAddress>;
  /** Owner's verified email. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  email?: Maybe<Scalars['String']>;
  /** Owner's verified full name. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  name?: Maybe<Scalars['String']>;
  /** Owner's verified shipping address. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  shippinAddress?: Maybe<StripeAddress>;
};

export enum StripePaymentMethodTypes {
  AcssDebit = 'acss_debit',
  Affirm = 'affirm',
  AfterpayClearpay = 'afterpay_clearpay',
  Alipay = 'alipay',
  AuBecsDebit = 'au_becs_debit',
  BacsDebit = 'bacs_debit',
  Bancontact = 'bancontact',
  Blik = 'blik',
  Boleto = 'boleto',
  Card = 'card',
  CardPresent = 'card_present',
  Cashapp = 'cashapp',
  CustomerBalance = 'customer_balance',
  Eps = 'eps',
  Fpx = 'fpx',
  Giropay = 'giropay',
  Grabpay = 'grabpay',
  Ideal = 'ideal',
  Klarna = 'klarna',
  Konbini = 'konbini',
  Link = 'link',
  Oxxo = 'oxxo',
  P24 = 'p24',
  Paynow = 'paynow',
  Pix = 'pix',
  Promptpay = 'promptpay',
  SepaDebit = 'sepa_debit',
  Sofort = 'sofort',
  UsBankAccount = 'us_bank_account',
  WechatPay = 'wechat_pay'
}

export type StripePaymentMethods = {
  data: Array<StripePaymentMethod>;
  /** True if this list has another page of items after this one that can be fetched. */
  hasMore: Scalars['Boolean'];
  object: Scalars['String'];
  /** The URL where this list can be accessed. */
  url: Scalars['String'];
};

export type StripePlan = {
  /** Whether the plan can be used for new purchases. */
  active: Scalars['Boolean'];
  /** Specifies a usage aggregation strategy for plans of `usage_type=metered`. Allowed values are `sum` for summing up all usage during a period, `last_during_period` for using the last usage record reported within a period, `last_ever` for using the last usage record ever (across period bounds) or `max` which uses the usage record with the maximum reported usage during a period. Defaults to `sum`. */
  aggregateUsage?: Maybe<Scalars['String']>;
  /** The unit amount in %s to be charged, represented as a whole integer if possible. Only set if `billing_scheme=per_unit`. */
  amount?: Maybe<Scalars['Int']>;
  /** The unit amount in %s to be charged, represented as a decimal string with at most 12 decimal places. Only set if `billing_scheme=per_unit`. */
  amountDecimal?: Maybe<Scalars['String']>;
  /** Describes how to compute the price per period. Either `per_unit` or `tiered`. `per_unit` indicates that the fixed amount (specified in `amount`) will be charged per unit in `quantity` (for plans with `usage_type=licensed`), or per unit of total usage (for plans with `usage_type=metered`). `tiered` indicates that the unit pricing will be computed using a tiering strategy as defined using the `tiers` and `tiers_mode` attributes. */
  billingScheme: Scalars['String'];
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Int'];
  /** Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase. Must be a [supported currency](https://stripe.com/docs/currencies). */
  currency: Scalars['String'];
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** The frequency at which a subscription is billed. One of `day`, `week`, `month` or `year`. */
  interval: Scalars['String'];
  /** The number of intervals (specified in the `interval` attribute) between subscription billings. For example, `interval=month` and `interval_count=3` bills every 3 months. */
  intervalCount: Scalars['Int'];
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: Scalars['Boolean'];
  /** Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  metadata?: Maybe<Scalars['JSON']>;
  /** A brief description of the plan, hidden from customers. */
  nickname?: Maybe<Scalars['String']>;
  /** String representing the object's type. Objects of the same type share the same value. */
  object: Scalars['String'];
  /** The product whose pricing this plan determines. */
  product?: Maybe<StripeProduct>;
  /** Defines if the tiering price should be `graduated` or `volume` based. In `volume`-based tiering, the maximum quantity within a period determines the per unit price. In `graduated` tiering, pricing can change as the quantity grows. */
  tiersMode?: Maybe<Scalars['String']>;
  /** Apply a transformation to the reported usage or set quantity before computing the amount billed. Cannot be combined with `tiers`. */
  transformUsage?: Maybe<StripePlanTransformUsage>;
  /** Default number of trial days when subscribing a customer to this plan using [`trial_from_plan=true`](https://stripe.com/docs/api#create_subscription-trial_from_plan). */
  trialPeriodDays?: Maybe<Scalars['Int']>;
  /** Configures how the quantity per period should be determined. Can be either `metered` or `licensed`. `licensed` automatically bills the `quantity` set when adding it to a subscription. `metered` aggregates the total usage based on usage records. Defaults to `licensed`. */
  usageType: Scalars['String'];
};

export type StripePlanTransformUsage = {
  /** Divide usage by this number. */
  divideBy: Scalars['Int'];
  /** After division, either round the result `up` or `down`. */
  round: Scalars['String'];
};

export type StripePrice = {
  /** Whether the price can be used for new purchases. */
  active: Scalars['Boolean'];
  /** Describes how to compute the price per period. Either `per_unit` or `tiered`. `per_unit` indicates that the fixed amount (specified in `unit_amount` or `unit_amount_decimal`) will be charged per unit in `quantity` (for prices with `usage_type=licensed`), or per unit of total usage (for prices with `usage_type=metered`). `tiered` indicates that the unit pricing will be computed using a tiering strategy as defined using the `tiers` and `tiers_mode` attributes. */
  billingScheme: Scalars['String'];
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Int'];
  /** Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase. Must be a [supported currency](https://stripe.com/docs/currencies). */
  currency: Scalars['String'];
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: Scalars['Boolean'];
  /** A lookup key used to retrieve prices dynamically from a static string. This may be up to 200 characters. */
  lookupKey?: Maybe<Scalars['String']>;
  /** Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  metadata?: Maybe<Scalars['JSON']>;
  /** A brief description of the price, hidden from customers. */
  nickname?: Maybe<Scalars['String']>;
  /** String representing the object's type. Objects of the same type share the same value. */
  object: Scalars['String'];
  /** The ID of the product this price is associated with. */
  product: StripeProduct;
  /** Defines if the tiering price should be `graduated` or `volume` based. In `volume`-based tiering, the maximum quantity within a period determines the per unit price. In `graduated` tiering, pricing can change as the quantity grows. */
  tiersMode?: Maybe<Scalars['String']>;
  /** One of `one_time` or `recurring` depending on whether the price is for a one-time purchase or a recurring (subscription) purchase. */
  type: Scalars['String'];
  /** The unit amount in %s to be charged, represented as a whole integer if possible. Only set if `billing_scheme=per_unit`. */
  unitAmount?: Maybe<Scalars['Int']>;
  /** The unit amount in %s to be charged, represented as a decimal string with at most 12 decimal places. Only set if `billing_scheme=per_unit`. */
  unitAmountDecimal?: Maybe<Scalars['String']>;
};

export type StripeProduct = {
  /** Whether the product is currently available for purchase. */
  active: Scalars['Boolean'];
  /** A list of up to 5 attributes that each SKU can provide values for (e.g., `["color", "size"]`). */
  attributes?: Maybe<Array<Scalars['String']>>;
  /** A short one-line description of the product, meant to be displayable to the customer. Only applicable to products of `type=good`. */
  caption?: Maybe<Scalars['String']>;
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Int'];
  /** An array of connect application identifiers that cannot purchase this product. Only applicable to products of `type=good`. */
  deactivateOn?: Maybe<Array<Scalars['String']>>;
  /** The ID of the [Price](https://stripe.com/docs/api/prices) object that is the default price for this product. */
  defaultPrice?: Maybe<StripePrice>;
  /** The product's description, meant to be displayable to the customer. Use this field to optionally store a long form explanation of the product being sold for your own rendering purposes. */
  description?: Maybe<Scalars['String']>;
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** A list of up to 8 URLs of images for this product, meant to be displayable to the customer. */
  images?: Maybe<Array<Scalars['String']>>;
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: Scalars['Boolean'];
  /** Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  metadata?: Maybe<Scalars['JSON']>;
  /** The product's name, meant to be displayable to the customer. */
  name: Scalars['String'];
  /** String representing the object's type. Objects of the same type share the same value. */
  object: Scalars['String'];
  /** Whether this product is shipped (i.e., physical goods). */
  sippable?: Maybe<Scalars['Boolean']>;
  /** Extra information about a product which will appear on your customer's credit card statement. In the case that multiple products are billed at once, the first statement descriptor will be used. */
  statementDescriptor?: Maybe<Scalars['String']>;
  /** The type of the product. The product is either of type `good`, which is eligible for use with Orders and SKUs, or `service`, which is eligible for use with Subscriptions and Plans. */
  type: Scalars['String'];
  /** A label that represents units of this product in Stripe and on customers' receipts and invoices. When set, this will be included in associated invoice line item descriptions. */
  unitLabel?: Maybe<Scalars['String']>;
  /** Time at which the object was last updated. Measured in seconds since the Unix epoch. */
  updated: Scalars['Int'];
  /** A URL of a publicly-accessible webpage for this product. */
  url?: Maybe<Scalars['String']>;
};

export type StripeSubscription = {
  /** A non-negative decimal between 0 and 100, with at most two decimal places. This represents the percentage of the subscription invoice subtotal that will be transferred to the application owner's Stripe account. */
  applicationFeePercent?: Maybe<Scalars['Float']>;
  automaticTax: StripeSubscriptionAutomaticTax;
  /** Determines the date of the first full invoice, and, for plans with `month` or `year` intervals, the day of the month for subsequent invoices. The timestamp is in UTC format. */
  billingCycleAnchor: Scalars['Int'];
  /** Define thresholds at which an invoice will be sent, and the subscription advanced to a new billing period */
  billingThresholds?: Maybe<StripeSubscriptionBillingThresholds>;
  /** A date in the future at which the subscription will automatically get canceled */
  cancelAt?: Maybe<Scalars['Int']>;
  /** If the subscription has been canceled with the `at_period_end` flag set to `true`, `cancel_at_period_end` on the subscription will be true. You can use this attribute to determine whether a subscription that has a status of active is scheduled to be canceled at the end of the current period. */
  cancelAtPeriodEnd: Scalars['Boolean'];
  /** If the subscription has been canceled, the date of that cancellation. If the subscription was canceled with `cancel_at_period_end`, `canceled_at` will reflect the time of the most recent update request, not the end of the subscription period when the subscription is automatically moved to a canceled state. */
  canceledAt?: Maybe<Scalars['Int']>;
  /** Either `charge_automatically`, or `send_invoice`. When charging automatically, Stripe will attempt to pay this subscription at the end of the cycle using the default source attached to the customer. When sending an invoice, Stripe will email your customer an invoice with payment instructions and mark the subscription as `active`. */
  collectionMethods: Scalars['String'];
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Int'];
  /** Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase. Must be a [supported currency](https://stripe.com/docs/currencies). */
  currency: Scalars['String'];
  /** End of the current period that the subscription has been invoiced for. At the end of this period, a new invoice will be created. */
  currentPeriodEnd: Scalars['Int'];
  /** Start of the current period that the subscription has been invoiced for. */
  currentPeriodStart: Scalars['Int'];
  /** ID of the customer who owns the subscription. */
  customer: Scalars['String'];
  /** Number of days a customer has to pay invoices generated by this subscription. This value will be `null` for subscriptions where `collection_method=charge_automatically`. */
  daysUntilDue?: Maybe<Scalars['Int']>;
  /** ID of the default payment method for the subscription. It must belong to the customer associated with the subscription. This takes precedence over `default_source`. If neither are set, invoices will use the customer's [invoice_settings.default_payment_method](https://stripe.com/docs/api/customers/object#customer_object-invoice_settings-default_payment_method) or [default_source](https://stripe.com/docs/api/customers/object#customer_object-default_source). */
  defaultPaymentMethod?: Maybe<StripePaymentMethod>;
  /** The tax rates that will apply to any subscription item that does not have `tax_rates` set. Invoices created will have their `default_tax_rates` populated from the subscription. */
  defaultTaxRates?: Maybe<Array<StripeTaxRate>>;
  /** The subscription's description, meant to be displayable to the customer. Use this field to optionally store an explanation of the subscription for rendering in Stripe surfaces. */
  description?: Maybe<Scalars['String']>;
  /** If the subscription has ended, the date the subscription ended. */
  endedAt?: Maybe<Scalars['Int']>;
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** List of subscription items, each with an attached price. */
  items: StripeSubscriptionItems;
  /** The most recent invoice this subscription has generated. */
  latestInvoice?: Maybe<StripeInvoice>;
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: Scalars['Boolean'];
  /** Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  metadata: Scalars['JSON'];
  /** Specifies the approximate timestamp on which any pending invoice items will be billed according to the schedule provided at `pending_invoice_item_interval`. */
  nextPendingInvoiceItemInvoice?: Maybe<Scalars['Int']>;
  /** String representing the object's type. Objects of the same type share the same value. */
  object: Scalars['String'];
  /** If specified, payment collection for this subscription will be paused. */
  pauseCollection?: Maybe<StripeSubscriptionPauseCollection>;
  /** Date when the subscription was first created. The date might differ from the `created` date due to backdating. */
  startDate: Scalars['Int'];
  /**
   * Possible values are `incomplete`, `incomplete_expired`, `trialing`, `active`, `past_due`, `canceled`, or `unpaid`.
   *
   * For `collection_method=charge_automatically` a subscription moves into `incomplete` if the initial payment attempt fails. A subscription in this state can only have metadata and default_source updated. Once the first invoice is paid, the subscription moves into an `active` state. If the first invoice is not paid within 23 hours, the subscription transitions to `incomplete_expired`. This is a terminal state, the open invoice will be voided and no further invoices will be generated.
   *
   * A subscription that is currently in a trial period is `trialing` and moves to `active` when the trial period is over.
   *
   * If subscription `collection_method=charge_automatically` it becomes `past_due` when payment to renew it fails and `canceled` or `unpaid` (depending on your subscriptions settings) when Stripe has exhausted all payment retry attempts.
   *
   * If subscription `collection_method=send_invoice` it becomes `past_due` when its invoice is not paid by the due date, and `canceled` or `unpaid` if it is still not paid by an additional deadline after that. Note that when a subscription has a status of `unpaid`, no subsequent invoices will be attempted (invoices will be created, but then immediately automatically closed). After receiving updated payment information from a customer, you may choose to reopen and pay their closed invoices.
   */
  status: Scalars['String'];
  /** ID of the test clock this subscription belongs to. */
  testClock?: Maybe<StripeTestClock>;
  /** If the subscription has a trial, the end of that trial. */
  trialEnd?: Maybe<Scalars['Int']>;
  /** If the subscription has a trial, the beginning of that trial. */
  trialStart?: Maybe<Scalars['Int']>;
};

export type StripeSubscriptionAutomaticTax = {
  /** Whether Stripe automatically computes tax on this subscription. */
  enabled: Scalars['Boolean'];
};

export type StripeSubscriptionBillingThresholds = {
  /** Monetary threshold that triggers the subscription to create an invoice */
  amountGte?: Maybe<Scalars['Int']>;
  /** Indicates if the `billing_cycle_anchor` should be reset when a threshold is reached. If true, `billing_cycle_anchor` will be updated to the date/time the threshold was last reached; otherwise, the value will remain unchanged. This value may not be `true` if the subscription contains items with plans that have `aggregate_usage=last_ever`. */
  resetBillingCycleAnchor?: Maybe<Scalars['Boolean']>;
};

export type StripeSubscriptionItem = {
  /** Define thresholds at which an invoice will be sent, and the related subscription advanced to a new billing period */
  billingThresholds?: Maybe<StripeSubscriptionItemBillingThresholds>;
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Int'];
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  metadata: Scalars['JSON'];
  /** String representing the object's type. Objects of the same type share the same value. */
  object: Scalars['String'];
  /**
   * You can now model subscriptions more flexibly using the [Prices API](https://stripe.com/docs/api#prices). It replaces the Plans API and is backwards compatible to simplify your migration.
   *
   * Plans define the base price, currency, and billing cycle for recurring purchases of products.
   *
   * [Products](https://stripe.com/docs/api#products) help you track inventory or provisioning, and plans help you track pricing. Different physical goods or levels of service should be represented by products, and pricing options should be represented by plans. This approach lets you change prices without having to change your provisioning scheme.
   *
   * For example, you might have a single "gold" product that has plans for $10/month, $100/year, €9/month, and €90/year.
   *
   * Related guides: [Set up a subscription](https://stripe.com/docs/billing/subscriptions/set-up-subscription) and more about [products and prices](https://stripe.com/docs/products-prices/overview).
   */
  plan: StripePlan;
  /**
   * Prices define the unit cost, currency, and (optional) billing cycle for both recurring and one-time purchases of products.
   *
   * [Products](https://stripe.com/docs/api#products) help you track inventory or provisioning, and prices help you track payment terms. Different physical goods or levels of service should be represented by products, and pricing options should be represented by prices. This approach lets you change prices without having to change your provisioning scheme.
   *
   * For example, you might have a single "gold" product that has prices for $10/month, $100/year, and €9 once.
   *
   * Related guides: [Set up a subscription](https://stripe.com/docs/billing/subscriptions/set-up-subscription), [create an invoice](https://stripe.com/docs/billing/invoices/create), and more about [products and prices](https://stripe.com/docs/products-prices/overview).
   */
  price: StripePrice;
  /** The [quantity](https://stripe.com/docs/subscriptions/quantities) of the plan to which the customer should be subscribed. */
  quantity?: Maybe<Scalars['Int']>;
  /** The `subscription` this `subscription_item` belongs to. */
  subscription: Scalars['String'];
};

export type StripeSubscriptionItemBillingThresholds = {
  /** Usage threshold that triggers the subscription to create an invoice */
  usageGte?: Maybe<Scalars['Int']>;
};

export type StripeSubscriptionItems = {
  data: Array<StripeSubscriptionItem>;
  /** True if this list has another page of items after this one that can be fetched. */
  hasMore: Scalars['Boolean'];
  object: Scalars['String'];
  /** The URL where this list can be accessed. */
  url: Scalars['String'];
};

export type StripeSubscriptionPauseCollection = {
  /** The payment collection behavior for this subscription while paused. One of `keep_as_draft`, `mark_uncollectible`, or `void`. */
  behavior: Scalars['String'];
  /** The time after which the subscription will resume collecting payments. */
  resumesAt?: Maybe<Scalars['Int']>;
};

export type StripeSubscriptions = {
  data: Array<StripeSubscription>;
  /** True if this list has another page of items after this one that can be fetched. */
  hasMore: Scalars['Boolean'];
  object: Scalars['String'];
  /** The URL where this list can be accessed. */
  url: Scalars['String'];
};

/** Tax rates can be applied to [invoices](https://stripe.com/docs/billing/invoices/tax-rates), [subscriptions](https://stripe.com/docs/billing/subscriptions/taxes) and [Checkout Sessions](https://stripe.com/docs/payments/checkout/set-up-a-subscription#tax-rates) to collect tax. */
export type StripeTaxRate = {
  /** Defaults to `true`. When set to `false`, this tax rate cannot be used with new applications or Checkout Sessions, but will still work for subscriptions and invoices that already have it set. */
  active: Scalars['Boolean'];
  /** Two-letter country code ([ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)). */
  country?: Maybe<Scalars['String']>;
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Int'];
  /** An arbitrary string attached to the tax rate for your internal use only. It will not be visible to your customers. */
  description?: Maybe<Scalars['String']>;
  /** The display name of the tax rates as it will appear to your customer on their receipt email, PDF, and the hosted invoice page. */
  displayName: Scalars['String'];
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** This specifies if the tax rate is inclusive or exclusive. */
  inclusive: Scalars['Boolean'];
  /** The jurisdiction for the tax rate. You can use this label field for tax reporting purposes. It also appears on your customer’s invoice. */
  jurisdiction?: Maybe<Scalars['String']>;
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: Scalars['Boolean'];
  /** Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  metadata?: Maybe<Scalars['JSON']>;
  /** String representing the object's type. Objects of the same type share the same value. */
  object: Scalars['String'];
  /** This represents the tax rate percent out of 100. */
  percentage: Scalars['Float'];
  /** [ISO 3166-2 subdivision code](https://en.wikipedia.org/wiki/ISO_3166-2:US), without country prefix. For example, "NY" for New York, United States. */
  state?: Maybe<Scalars['String']>;
  /** The high-level tax type, such as `vat` or `sales_tax`. */
  taxType?: Maybe<Scalars['String']>;
};

export type StripeTestClock = {
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Int'];
  /** Time at which this clock is scheduled to auto delete. */
  deletesAfter: Scalars['Int'];
  /** Time at which all objects belonging to this clock are frozen. */
  frozenTime: Scalars['Int'];
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode: Scalars['Boolean'];
  /** The custom name supplied at creation. */
  name?: Maybe<Scalars['String']>;
  /** String representing the object's type. Objects of the same type share the same value. */
  object: Scalars['String'];
  /** The status of the Test Clock. */
  status: Scalars['String'];
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

/** mutation root */
export type Mutation_Root = {
  create_tenant?: Maybe<CreateTenantOutput>;
  /** delete data from the table: "order_policy_type" */
  delete_order_policy_type?: Maybe<Order_Policy_Type_Mutation_Response>;
  /** delete single row from the table: "order_policy_type" */
  delete_order_policy_type_by_pk?: Maybe<Order_Policy_Type>;
  /** delete data from the table: "order_states" */
  delete_order_states?: Maybe<Order_States_Mutation_Response>;
  /** delete single row from the table: "order_states" */
  delete_order_states_by_pk?: Maybe<Order_States>;
  /** delete data from the table: "order_status" */
  delete_order_status?: Maybe<Order_Status_Mutation_Response>;
  /** delete single row from the table: "order_status" */
  delete_order_status_by_pk?: Maybe<Order_Status>;
  /** delete data from the table: "order_steps" */
  delete_order_steps?: Maybe<Order_Steps_Mutation_Response>;
  /** delete single row from the table: "order_steps" */
  delete_order_steps_by_pk?: Maybe<Order_Steps>;
  /** delete data from the table: "orders" */
  delete_orders?: Maybe<Orders_Mutation_Response>;
  /** delete single row from the table: "orders" */
  delete_orders_by_pk?: Maybe<Orders>;
  /** delete data from the table: "reservation_status" */
  delete_reservation_status?: Maybe<Reservation_Status_Mutation_Response>;
  /** delete single row from the table: "reservation_status" */
  delete_reservation_status_by_pk?: Maybe<Reservation_Status>;
  /** delete data from the table: "reservations" */
  delete_reservations?: Maybe<Reservations_Mutation_Response>;
  /** delete single row from the table: "reservations" */
  delete_reservations_by_pk?: Maybe<Reservations>;
  delete_tenant?: Maybe<DeleteTenantOutput>;
  /** delete data from the table: "tenant_stats" */
  delete_tenant_stats?: Maybe<Tenant_Stats_Mutation_Response>;
  /** delete data from the table: "tenant_status" */
  delete_tenant_status?: Maybe<Tenant_Status_Mutation_Response>;
  /** delete single row from the table: "tenant_status" */
  delete_tenant_status_by_pk?: Maybe<Tenant_Status>;
  /** delete data from the table: "tenant_types" */
  delete_tenant_types?: Maybe<Tenant_Types_Mutation_Response>;
  /** delete single row from the table: "tenant_types" */
  delete_tenant_types_by_pk?: Maybe<Tenant_Types>;
  /** delete data from the table: "tenants" */
  delete_tenants?: Maybe<Tenants_Mutation_Response>;
  /** delete single row from the table: "tenants" */
  delete_tenants_by_pk?: Maybe<Tenants>;
  /** delete data from the table: "user_roles" */
  delete_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** delete single row from the table: "user_roles" */
  delete_user_roles_by_pk?: Maybe<User_Roles>;
  /** delete data from the table: "user_status" */
  delete_user_status?: Maybe<User_Status_Mutation_Response>;
  /** delete single row from the table: "user_status" */
  delete_user_status_by_pk?: Maybe<User_Status>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "vehicles" */
  delete_vehicles?: Maybe<Vehicles_Mutation_Response>;
  /** delete single row from the table: "vehicles" */
  delete_vehicles_by_pk?: Maybe<Vehicles>;
  /** delete data from the table: "vehicles_status" */
  delete_vehicles_status?: Maybe<Vehicles_Status_Mutation_Response>;
  /** delete single row from the table: "vehicles_status" */
  delete_vehicles_status_by_pk?: Maybe<Vehicles_Status>;
  generate_contract?: Maybe<GenerateContractOutput>;
  generate_contract_link?: Maybe<GenerateContractOutput>;
  /** insert data into the table: "order_policy_type" */
  insert_order_policy_type?: Maybe<Order_Policy_Type_Mutation_Response>;
  /** insert a single row into the table: "order_policy_type" */
  insert_order_policy_type_one?: Maybe<Order_Policy_Type>;
  /** insert data into the table: "order_states" */
  insert_order_states?: Maybe<Order_States_Mutation_Response>;
  /** insert a single row into the table: "order_states" */
  insert_order_states_one?: Maybe<Order_States>;
  /** insert data into the table: "order_status" */
  insert_order_status?: Maybe<Order_Status_Mutation_Response>;
  /** insert a single row into the table: "order_status" */
  insert_order_status_one?: Maybe<Order_Status>;
  /** insert data into the table: "order_steps" */
  insert_order_steps?: Maybe<Order_Steps_Mutation_Response>;
  /** insert a single row into the table: "order_steps" */
  insert_order_steps_one?: Maybe<Order_Steps>;
  /** insert data into the table: "orders" */
  insert_orders?: Maybe<Orders_Mutation_Response>;
  /** insert a single row into the table: "orders" */
  insert_orders_one?: Maybe<Orders>;
  /** insert data into the table: "reservation_status" */
  insert_reservation_status?: Maybe<Reservation_Status_Mutation_Response>;
  /** insert a single row into the table: "reservation_status" */
  insert_reservation_status_one?: Maybe<Reservation_Status>;
  /** insert data into the table: "reservations" */
  insert_reservations?: Maybe<Reservations_Mutation_Response>;
  /** insert a single row into the table: "reservations" */
  insert_reservations_one?: Maybe<Reservations>;
  /** insert data into the table: "tenant_stats" */
  insert_tenant_stats?: Maybe<Tenant_Stats_Mutation_Response>;
  /** insert a single row into the table: "tenant_stats" */
  insert_tenant_stats_one?: Maybe<Tenant_Stats>;
  /** insert data into the table: "tenant_status" */
  insert_tenant_status?: Maybe<Tenant_Status_Mutation_Response>;
  /** insert a single row into the table: "tenant_status" */
  insert_tenant_status_one?: Maybe<Tenant_Status>;
  /** insert data into the table: "tenant_types" */
  insert_tenant_types?: Maybe<Tenant_Types_Mutation_Response>;
  /** insert a single row into the table: "tenant_types" */
  insert_tenant_types_one?: Maybe<Tenant_Types>;
  /** insert data into the table: "tenants" */
  insert_tenants?: Maybe<Tenants_Mutation_Response>;
  /** insert a single row into the table: "tenants" */
  insert_tenants_one?: Maybe<Tenants>;
  /** insert data into the table: "user_roles" */
  insert_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** insert a single row into the table: "user_roles" */
  insert_user_roles_one?: Maybe<User_Roles>;
  /** insert data into the table: "user_status" */
  insert_user_status?: Maybe<User_Status_Mutation_Response>;
  /** insert a single row into the table: "user_status" */
  insert_user_status_one?: Maybe<User_Status>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "vehicles" */
  insert_vehicles?: Maybe<Vehicles_Mutation_Response>;
  /** insert a single row into the table: "vehicles" */
  insert_vehicles_one?: Maybe<Vehicles>;
  /** insert data into the table: "vehicles_status" */
  insert_vehicles_status?: Maybe<Vehicles_Status_Mutation_Response>;
  /** insert a single row into the table: "vehicles_status" */
  insert_vehicles_status_one?: Maybe<Vehicles_Status>;
  stripe: StripeMutations;
  trigger_order_event?: Maybe<OrderTriggerEventOutput>;
  /** update data of the table: "order_policy_type" */
  update_order_policy_type?: Maybe<Order_Policy_Type_Mutation_Response>;
  /** update single row of the table: "order_policy_type" */
  update_order_policy_type_by_pk?: Maybe<Order_Policy_Type>;
  /** update multiples rows of table: "order_policy_type" */
  update_order_policy_type_many?: Maybe<Array<Maybe<Order_Policy_Type_Mutation_Response>>>;
  /** update data of the table: "order_states" */
  update_order_states?: Maybe<Order_States_Mutation_Response>;
  /** update single row of the table: "order_states" */
  update_order_states_by_pk?: Maybe<Order_States>;
  /** update multiples rows of table: "order_states" */
  update_order_states_many?: Maybe<Array<Maybe<Order_States_Mutation_Response>>>;
  /** update data of the table: "order_status" */
  update_order_status?: Maybe<Order_Status_Mutation_Response>;
  /** update single row of the table: "order_status" */
  update_order_status_by_pk?: Maybe<Order_Status>;
  /** update multiples rows of table: "order_status" */
  update_order_status_many?: Maybe<Array<Maybe<Order_Status_Mutation_Response>>>;
  /** update data of the table: "order_steps" */
  update_order_steps?: Maybe<Order_Steps_Mutation_Response>;
  /** update single row of the table: "order_steps" */
  update_order_steps_by_pk?: Maybe<Order_Steps>;
  /** update multiples rows of table: "order_steps" */
  update_order_steps_many?: Maybe<Array<Maybe<Order_Steps_Mutation_Response>>>;
  /** update data of the table: "orders" */
  update_orders?: Maybe<Orders_Mutation_Response>;
  /** update single row of the table: "orders" */
  update_orders_by_pk?: Maybe<Orders>;
  /** update multiples rows of table: "orders" */
  update_orders_many?: Maybe<Array<Maybe<Orders_Mutation_Response>>>;
  /** update data of the table: "reservation_status" */
  update_reservation_status?: Maybe<Reservation_Status_Mutation_Response>;
  /** update single row of the table: "reservation_status" */
  update_reservation_status_by_pk?: Maybe<Reservation_Status>;
  /** update multiples rows of table: "reservation_status" */
  update_reservation_status_many?: Maybe<Array<Maybe<Reservation_Status_Mutation_Response>>>;
  /** update data of the table: "reservations" */
  update_reservations?: Maybe<Reservations_Mutation_Response>;
  /** update single row of the table: "reservations" */
  update_reservations_by_pk?: Maybe<Reservations>;
  /** update multiples rows of table: "reservations" */
  update_reservations_many?: Maybe<Array<Maybe<Reservations_Mutation_Response>>>;
  /** update data of the table: "tenant_stats" */
  update_tenant_stats?: Maybe<Tenant_Stats_Mutation_Response>;
  /** update multiples rows of table: "tenant_stats" */
  update_tenant_stats_many?: Maybe<Array<Maybe<Tenant_Stats_Mutation_Response>>>;
  /** update data of the table: "tenant_status" */
  update_tenant_status?: Maybe<Tenant_Status_Mutation_Response>;
  /** update single row of the table: "tenant_status" */
  update_tenant_status_by_pk?: Maybe<Tenant_Status>;
  /** update multiples rows of table: "tenant_status" */
  update_tenant_status_many?: Maybe<Array<Maybe<Tenant_Status_Mutation_Response>>>;
  /** update data of the table: "tenant_types" */
  update_tenant_types?: Maybe<Tenant_Types_Mutation_Response>;
  /** update single row of the table: "tenant_types" */
  update_tenant_types_by_pk?: Maybe<Tenant_Types>;
  /** update multiples rows of table: "tenant_types" */
  update_tenant_types_many?: Maybe<Array<Maybe<Tenant_Types_Mutation_Response>>>;
  /** update data of the table: "tenants" */
  update_tenants?: Maybe<Tenants_Mutation_Response>;
  /** update single row of the table: "tenants" */
  update_tenants_by_pk?: Maybe<Tenants>;
  /** update multiples rows of table: "tenants" */
  update_tenants_many?: Maybe<Array<Maybe<Tenants_Mutation_Response>>>;
  /** update data of the table: "user_roles" */
  update_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** update single row of the table: "user_roles" */
  update_user_roles_by_pk?: Maybe<User_Roles>;
  /** update multiples rows of table: "user_roles" */
  update_user_roles_many?: Maybe<Array<Maybe<User_Roles_Mutation_Response>>>;
  /** update data of the table: "user_status" */
  update_user_status?: Maybe<User_Status_Mutation_Response>;
  /** update single row of the table: "user_status" */
  update_user_status_by_pk?: Maybe<User_Status>;
  /** update multiples rows of table: "user_status" */
  update_user_status_many?: Maybe<Array<Maybe<User_Status_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "vehicles" */
  update_vehicles?: Maybe<Vehicles_Mutation_Response>;
  /** update single row of the table: "vehicles" */
  update_vehicles_by_pk?: Maybe<Vehicles>;
  /** update multiples rows of table: "vehicles" */
  update_vehicles_many?: Maybe<Array<Maybe<Vehicles_Mutation_Response>>>;
  /** update data of the table: "vehicles_status" */
  update_vehicles_status?: Maybe<Vehicles_Status_Mutation_Response>;
  /** update single row of the table: "vehicles_status" */
  update_vehicles_status_by_pk?: Maybe<Vehicles_Status>;
  /** update multiples rows of table: "vehicles_status" */
  update_vehicles_status_many?: Maybe<Array<Maybe<Vehicles_Status_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootCreate_TenantArgs = {
  object: CreateTenantInput;
};


/** mutation root */
export type Mutation_RootDelete_Order_Policy_TypeArgs = {
  where: Order_Policy_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Policy_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Order_StatesArgs = {
  where: Order_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_States_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Order_StatusArgs = {
  where: Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Order_StepsArgs = {
  where: Order_Steps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Steps_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_OrdersArgs = {
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Orders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Reservation_StatusArgs = {
  where: Reservation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservation_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ReservationsArgs = {
  where: Reservations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TenantArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Tenant_StatsArgs = {
  where: Tenant_Stats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenant_StatusArgs = {
  where: Tenant_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenant_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Tenant_TypesArgs = {
  where: Tenant_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenant_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TenantsArgs = {
  where: Tenants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenants_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_RolesArgs = {
  where: User_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Roles_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_StatusArgs = {
  where: User_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_VehiclesArgs = {
  where: Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Vehicles_StatusArgs = {
  where: Vehicles_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vehicles_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGenerate_ContractArgs = {
  object: GenerateContractInput;
};


/** mutation root */
export type Mutation_RootGenerate_Contract_LinkArgs = {
  object: GenerateContractInput;
};


/** mutation root */
export type Mutation_RootInsert_Order_Policy_TypeArgs = {
  objects: Array<Order_Policy_Type_Insert_Input>;
  on_conflict?: Maybe<Order_Policy_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Policy_Type_OneArgs = {
  object: Order_Policy_Type_Insert_Input;
  on_conflict?: Maybe<Order_Policy_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_StatesArgs = {
  objects: Array<Order_States_Insert_Input>;
  on_conflict?: Maybe<Order_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_States_OneArgs = {
  object: Order_States_Insert_Input;
  on_conflict?: Maybe<Order_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_StatusArgs = {
  objects: Array<Order_Status_Insert_Input>;
  on_conflict?: Maybe<Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Status_OneArgs = {
  object: Order_Status_Insert_Input;
  on_conflict?: Maybe<Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_StepsArgs = {
  objects: Array<Order_Steps_Insert_Input>;
  on_conflict?: Maybe<Order_Steps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Steps_OneArgs = {
  object: Order_Steps_Insert_Input;
  on_conflict?: Maybe<Order_Steps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrdersArgs = {
  objects: Array<Orders_Insert_Input>;
  on_conflict?: Maybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Orders_OneArgs = {
  object: Orders_Insert_Input;
  on_conflict?: Maybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservation_StatusArgs = {
  objects: Array<Reservation_Status_Insert_Input>;
  on_conflict?: Maybe<Reservation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservation_Status_OneArgs = {
  object: Reservation_Status_Insert_Input;
  on_conflict?: Maybe<Reservation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReservationsArgs = {
  objects: Array<Reservations_Insert_Input>;
  on_conflict?: Maybe<Reservations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservations_OneArgs = {
  object: Reservations_Insert_Input;
  on_conflict?: Maybe<Reservations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenant_StatsArgs = {
  objects: Array<Tenant_Stats_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Tenant_Stats_OneArgs = {
  object: Tenant_Stats_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Tenant_StatusArgs = {
  objects: Array<Tenant_Status_Insert_Input>;
  on_conflict?: Maybe<Tenant_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenant_Status_OneArgs = {
  object: Tenant_Status_Insert_Input;
  on_conflict?: Maybe<Tenant_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenant_TypesArgs = {
  objects: Array<Tenant_Types_Insert_Input>;
  on_conflict?: Maybe<Tenant_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenant_Types_OneArgs = {
  object: Tenant_Types_Insert_Input;
  on_conflict?: Maybe<Tenant_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TenantsArgs = {
  objects: Array<Tenants_Insert_Input>;
  on_conflict?: Maybe<Tenants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenants_OneArgs = {
  object: Tenants_Insert_Input;
  on_conflict?: Maybe<Tenants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_RolesArgs = {
  objects: Array<User_Roles_Insert_Input>;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Roles_OneArgs = {
  object: User_Roles_Insert_Input;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_StatusArgs = {
  objects: Array<User_Status_Insert_Input>;
  on_conflict?: Maybe<User_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Status_OneArgs = {
  object: User_Status_Insert_Input;
  on_conflict?: Maybe<User_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VehiclesArgs = {
  objects: Array<Vehicles_Insert_Input>;
  on_conflict?: Maybe<Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicles_OneArgs = {
  object: Vehicles_Insert_Input;
  on_conflict?: Maybe<Vehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicles_StatusArgs = {
  objects: Array<Vehicles_Status_Insert_Input>;
  on_conflict?: Maybe<Vehicles_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vehicles_Status_OneArgs = {
  object: Vehicles_Status_Insert_Input;
  on_conflict?: Maybe<Vehicles_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootTrigger_Order_EventArgs = {
  object: OrderTriggerEventInput;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Policy_TypeArgs = {
  _set?: Maybe<Order_Policy_Type_Set_Input>;
  where: Order_Policy_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Policy_Type_By_PkArgs = {
  _set?: Maybe<Order_Policy_Type_Set_Input>;
  pk_columns: Order_Policy_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Policy_Type_ManyArgs = {
  updates: Array<Order_Policy_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Order_StatesArgs = {
  _set?: Maybe<Order_States_Set_Input>;
  where: Order_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_States_By_PkArgs = {
  _set?: Maybe<Order_States_Set_Input>;
  pk_columns: Order_States_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_States_ManyArgs = {
  updates: Array<Order_States_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Order_StatusArgs = {
  _set?: Maybe<Order_Status_Set_Input>;
  where: Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Status_By_PkArgs = {
  _set?: Maybe<Order_Status_Set_Input>;
  pk_columns: Order_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Status_ManyArgs = {
  updates: Array<Order_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Order_StepsArgs = {
  _set?: Maybe<Order_Steps_Set_Input>;
  where: Order_Steps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Steps_By_PkArgs = {
  _set?: Maybe<Order_Steps_Set_Input>;
  pk_columns: Order_Steps_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Steps_ManyArgs = {
  updates: Array<Order_Steps_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrdersArgs = {
  _inc?: Maybe<Orders_Inc_Input>;
  _set?: Maybe<Orders_Set_Input>;
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Orders_By_PkArgs = {
  _inc?: Maybe<Orders_Inc_Input>;
  _set?: Maybe<Orders_Set_Input>;
  pk_columns: Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Orders_ManyArgs = {
  updates: Array<Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_StatusArgs = {
  _set?: Maybe<Reservation_Status_Set_Input>;
  where: Reservation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_Status_By_PkArgs = {
  _set?: Maybe<Reservation_Status_Set_Input>;
  pk_columns: Reservation_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_Status_ManyArgs = {
  updates: Array<Reservation_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReservationsArgs = {
  _inc?: Maybe<Reservations_Inc_Input>;
  _set?: Maybe<Reservations_Set_Input>;
  where: Reservations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservations_By_PkArgs = {
  _inc?: Maybe<Reservations_Inc_Input>;
  _set?: Maybe<Reservations_Set_Input>;
  pk_columns: Reservations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reservations_ManyArgs = {
  updates: Array<Reservations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_StatsArgs = {
  _inc?: Maybe<Tenant_Stats_Inc_Input>;
  _set?: Maybe<Tenant_Stats_Set_Input>;
  where: Tenant_Stats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_Stats_ManyArgs = {
  updates: Array<Tenant_Stats_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_StatusArgs = {
  _set?: Maybe<Tenant_Status_Set_Input>;
  where: Tenant_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_Status_By_PkArgs = {
  _set?: Maybe<Tenant_Status_Set_Input>;
  pk_columns: Tenant_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_Status_ManyArgs = {
  updates: Array<Tenant_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_TypesArgs = {
  _set?: Maybe<Tenant_Types_Set_Input>;
  where: Tenant_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_Types_By_PkArgs = {
  _set?: Maybe<Tenant_Types_Set_Input>;
  pk_columns: Tenant_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenant_Types_ManyArgs = {
  updates: Array<Tenant_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TenantsArgs = {
  _set?: Maybe<Tenants_Set_Input>;
  where: Tenants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenants_By_PkArgs = {
  _set?: Maybe<Tenants_Set_Input>;
  pk_columns: Tenants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenants_ManyArgs = {
  updates: Array<Tenants_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_RolesArgs = {
  _set?: Maybe<User_Roles_Set_Input>;
  where: User_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Roles_By_PkArgs = {
  _set?: Maybe<User_Roles_Set_Input>;
  pk_columns: User_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Roles_ManyArgs = {
  updates: Array<User_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_StatusArgs = {
  _set?: Maybe<User_Status_Set_Input>;
  where: User_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Status_By_PkArgs = {
  _set?: Maybe<User_Status_Set_Input>;
  pk_columns: User_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Status_ManyArgs = {
  updates: Array<User_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VehiclesArgs = {
  _inc?: Maybe<Vehicles_Inc_Input>;
  _set?: Maybe<Vehicles_Set_Input>;
  where: Vehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicles_By_PkArgs = {
  _inc?: Maybe<Vehicles_Inc_Input>;
  _set?: Maybe<Vehicles_Set_Input>;
  pk_columns: Vehicles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicles_ManyArgs = {
  updates: Array<Vehicles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicles_StatusArgs = {
  _set?: Maybe<Vehicles_Status_Set_Input>;
  where: Vehicles_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicles_Status_By_PkArgs = {
  _set?: Maybe<Vehicles_Status_Set_Input>;
  pk_columns: Vehicles_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vehicles_Status_ManyArgs = {
  updates: Array<Vehicles_Status_Updates>;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "order_policy_type" */
export type Order_Policy_Type = {
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "order_policy_type" */
export type Order_Policy_TypeOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "order_policy_type" */
export type Order_Policy_TypeOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};

/** aggregated selection of "order_policy_type" */
export type Order_Policy_Type_Aggregate = {
  aggregate?: Maybe<Order_Policy_Type_Aggregate_Fields>;
  nodes: Array<Order_Policy_Type>;
};

/** aggregate fields of "order_policy_type" */
export type Order_Policy_Type_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Order_Policy_Type_Max_Fields>;
  min?: Maybe<Order_Policy_Type_Min_Fields>;
};


/** aggregate fields of "order_policy_type" */
export type Order_Policy_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Policy_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "order_policy_type". All fields are combined with a logical 'AND'. */
export type Order_Policy_Type_Bool_Exp = {
  _and?: Maybe<Array<Order_Policy_Type_Bool_Exp>>;
  _not?: Maybe<Order_Policy_Type_Bool_Exp>;
  _or?: Maybe<Array<Order_Policy_Type_Bool_Exp>>;
  orders?: Maybe<Orders_Bool_Exp>;
  orders_aggregate?: Maybe<Orders_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_policy_type" */
export enum Order_Policy_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  OrderPolicyTypePkey = 'order_policy_type_pkey'
}

export enum Order_Policy_Type_Enum {
  Company = 'company',
  None = 'none',
  Renter = 'renter',
  Vehicle = 'vehicle'
}

/** Boolean expression to compare columns of type "order_policy_type_enum". All fields are combined with logical 'AND'. */
export type Order_Policy_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Order_Policy_Type_Enum>;
  _in?: Maybe<Array<Order_Policy_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Order_Policy_Type_Enum>;
  _nin?: Maybe<Array<Order_Policy_Type_Enum>>;
};

/** input type for inserting data into table "order_policy_type" */
export type Order_Policy_Type_Insert_Input = {
  orders?: Maybe<Orders_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Order_Policy_Type_Max_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Order_Policy_Type_Min_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "order_policy_type" */
export type Order_Policy_Type_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Policy_Type>;
};

/** input type for inserting object relation for remote table "order_policy_type" */
export type Order_Policy_Type_Obj_Rel_Insert_Input = {
  data: Order_Policy_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Order_Policy_Type_On_Conflict>;
};

/** on_conflict condition type for table "order_policy_type" */
export type Order_Policy_Type_On_Conflict = {
  constraint: Order_Policy_Type_Constraint;
  update_columns?: Array<Order_Policy_Type_Update_Column>;
  where?: Maybe<Order_Policy_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "order_policy_type". */
export type Order_Policy_Type_Order_By = {
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: order_policy_type */
export type Order_Policy_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "order_policy_type" */
export enum Order_Policy_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "order_policy_type" */
export type Order_Policy_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "order_policy_type" */
export type Order_Policy_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Policy_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Policy_Type_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "order_policy_type" */
export enum Order_Policy_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type Order_Policy_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Order_Policy_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Policy_Type_Bool_Exp;
};

/** columns and relationships of "order_states" */
export type Order_States = {
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "order_states" */
export type Order_StatesOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "order_states" */
export type Order_StatesOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};

/** aggregated selection of "order_states" */
export type Order_States_Aggregate = {
  aggregate?: Maybe<Order_States_Aggregate_Fields>;
  nodes: Array<Order_States>;
};

/** aggregate fields of "order_states" */
export type Order_States_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Order_States_Max_Fields>;
  min?: Maybe<Order_States_Min_Fields>;
};


/** aggregate fields of "order_states" */
export type Order_States_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_States_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "order_states". All fields are combined with a logical 'AND'. */
export type Order_States_Bool_Exp = {
  _and?: Maybe<Array<Order_States_Bool_Exp>>;
  _not?: Maybe<Order_States_Bool_Exp>;
  _or?: Maybe<Array<Order_States_Bool_Exp>>;
  orders?: Maybe<Orders_Bool_Exp>;
  orders_aggregate?: Maybe<Orders_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_states" */
export enum Order_States_Constraint {
  /** unique or primary key constraint on columns "value" */
  OrderStatesPkey = 'order_states_pkey'
}

export enum Order_States_Enum {
  Cancelled = 'cancelled',
  Completed = 'completed',
  ExtendRental = 'extend_rental',
  GenerateContract = 'generate_contract',
  InitiateDelivery = 'initiate_delivery',
  InitiatePickup = 'initiate_pickup',
  RentalActive = 'rental_active',
  ReportIncident = 'report_incident',
  SelectClient = 'select_client',
  SelectRentalDates = 'select_rental_dates',
  SelectRentalTerms = 'select_rental_terms',
  SelectVehicle = 'select_vehicle',
  SignContract = 'sign_contract',
  StartDeliveryInspection = 'start_delivery_inspection',
  StartPickupInspection = 'start_pickup_inspection',
  VerifyClientDetails = 'verify_client_details',
  VerifyInsurance = 'verify_insurance',
  VerifyLicense = 'verify_license'
}

/** Boolean expression to compare columns of type "order_states_enum". All fields are combined with logical 'AND'. */
export type Order_States_Enum_Comparison_Exp = {
  _eq?: Maybe<Order_States_Enum>;
  _in?: Maybe<Array<Order_States_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Order_States_Enum>;
  _nin?: Maybe<Array<Order_States_Enum>>;
};

/** input type for inserting data into table "order_states" */
export type Order_States_Insert_Input = {
  orders?: Maybe<Orders_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Order_States_Max_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Order_States_Min_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "order_states" */
export type Order_States_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_States>;
};

/** input type for inserting object relation for remote table "order_states" */
export type Order_States_Obj_Rel_Insert_Input = {
  data: Order_States_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Order_States_On_Conflict>;
};

/** on_conflict condition type for table "order_states" */
export type Order_States_On_Conflict = {
  constraint: Order_States_Constraint;
  update_columns?: Array<Order_States_Update_Column>;
  where?: Maybe<Order_States_Bool_Exp>;
};

/** Ordering options when selecting data from "order_states". */
export type Order_States_Order_By = {
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: order_states */
export type Order_States_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "order_states" */
export enum Order_States_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "order_states" */
export type Order_States_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "order_states" */
export type Order_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_States_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "order_states" */
export enum Order_States_Update_Column {
  /** column name */
  Value = 'value'
}

export type Order_States_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Order_States_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_States_Bool_Exp;
};

/** columns and relationships of "order_status" */
export type Order_Status = {
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "order_status" */
export type Order_StatusOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "order_status" */
export type Order_StatusOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};

/** aggregated selection of "order_status" */
export type Order_Status_Aggregate = {
  aggregate?: Maybe<Order_Status_Aggregate_Fields>;
  nodes: Array<Order_Status>;
};

/** aggregate fields of "order_status" */
export type Order_Status_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Order_Status_Max_Fields>;
  min?: Maybe<Order_Status_Min_Fields>;
};


/** aggregate fields of "order_status" */
export type Order_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "order_status". All fields are combined with a logical 'AND'. */
export type Order_Status_Bool_Exp = {
  _and?: Maybe<Array<Order_Status_Bool_Exp>>;
  _not?: Maybe<Order_Status_Bool_Exp>;
  _or?: Maybe<Array<Order_Status_Bool_Exp>>;
  orders?: Maybe<Orders_Bool_Exp>;
  orders_aggregate?: Maybe<Orders_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_status" */
export enum Order_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  OrderStatusPkey = 'order_status_pkey'
}

export enum Order_Status_Enum {
  Archived = 'archived',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Open = 'open',
  Pending = 'pending'
}

/** Boolean expression to compare columns of type "order_status_enum". All fields are combined with logical 'AND'. */
export type Order_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Order_Status_Enum>;
  _in?: Maybe<Array<Order_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Order_Status_Enum>;
  _nin?: Maybe<Array<Order_Status_Enum>>;
};

/** input type for inserting data into table "order_status" */
export type Order_Status_Insert_Input = {
  orders?: Maybe<Orders_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Order_Status_Max_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Order_Status_Min_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "order_status" */
export type Order_Status_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Status>;
};

/** input type for inserting object relation for remote table "order_status" */
export type Order_Status_Obj_Rel_Insert_Input = {
  data: Order_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Order_Status_On_Conflict>;
};

/** on_conflict condition type for table "order_status" */
export type Order_Status_On_Conflict = {
  constraint: Order_Status_Constraint;
  update_columns?: Array<Order_Status_Update_Column>;
  where?: Maybe<Order_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "order_status". */
export type Order_Status_Order_By = {
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: order_status */
export type Order_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "order_status" */
export enum Order_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "order_status" */
export type Order_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "order_status" */
export type Order_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Status_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "order_status" */
export enum Order_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type Order_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Order_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Status_Bool_Exp;
};

/** columns and relationships of "order_steps" */
export type Order_Steps = {
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "order_steps" */
export type Order_StepsOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "order_steps" */
export type Order_StepsOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};

/** aggregated selection of "order_steps" */
export type Order_Steps_Aggregate = {
  aggregate?: Maybe<Order_Steps_Aggregate_Fields>;
  nodes: Array<Order_Steps>;
};

/** aggregate fields of "order_steps" */
export type Order_Steps_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Order_Steps_Max_Fields>;
  min?: Maybe<Order_Steps_Min_Fields>;
};


/** aggregate fields of "order_steps" */
export type Order_Steps_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Steps_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "order_steps". All fields are combined with a logical 'AND'. */
export type Order_Steps_Bool_Exp = {
  _and?: Maybe<Array<Order_Steps_Bool_Exp>>;
  _not?: Maybe<Order_Steps_Bool_Exp>;
  _or?: Maybe<Array<Order_Steps_Bool_Exp>>;
  orders?: Maybe<Orders_Bool_Exp>;
  orders_aggregate?: Maybe<Orders_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_steps" */
export enum Order_Steps_Constraint {
  /** unique or primary key constraint on columns "value" */
  OrderStepsPkey = 'order_steps_pkey'
}

export enum Order_Steps_Enum {
  BeingDelivered = 'being_delivered',
  BeingPickedUp = 'being_picked_up',
  Cancelled = 'cancelled',
  Delivered = 'delivered',
  OrderCompleted = 'order_completed',
  PreparingOrder = 'preparing_order',
  ReadyForDelivery = 'ready_for_delivery',
  ReadyForPickup = 'ready_for_pickup'
}

/** Boolean expression to compare columns of type "order_steps_enum". All fields are combined with logical 'AND'. */
export type Order_Steps_Enum_Comparison_Exp = {
  _eq?: Maybe<Order_Steps_Enum>;
  _in?: Maybe<Array<Order_Steps_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Order_Steps_Enum>;
  _nin?: Maybe<Array<Order_Steps_Enum>>;
};

/** input type for inserting data into table "order_steps" */
export type Order_Steps_Insert_Input = {
  orders?: Maybe<Orders_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Order_Steps_Max_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Order_Steps_Min_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "order_steps" */
export type Order_Steps_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Steps>;
};

/** input type for inserting object relation for remote table "order_steps" */
export type Order_Steps_Obj_Rel_Insert_Input = {
  data: Order_Steps_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Order_Steps_On_Conflict>;
};

/** on_conflict condition type for table "order_steps" */
export type Order_Steps_On_Conflict = {
  constraint: Order_Steps_Constraint;
  update_columns?: Array<Order_Steps_Update_Column>;
  where?: Maybe<Order_Steps_Bool_Exp>;
};

/** Ordering options when selecting data from "order_steps". */
export type Order_Steps_Order_By = {
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: order_steps */
export type Order_Steps_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "order_steps" */
export enum Order_Steps_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "order_steps" */
export type Order_Steps_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "order_steps" */
export type Order_Steps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Steps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Steps_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "order_steps" */
export enum Order_Steps_Update_Column {
  /** column name */
  Value = 'value'
}

export type Order_Steps_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Order_Steps_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Steps_Bool_Exp;
};

/** columns and relationships of "orders" */
export type Orders = {
  /** An object relationship */
  agent: Users;
  agent_id: Scalars['uuid'];
  /** An object relationship */
  client?: Maybe<Users>;
  client_id?: Maybe<Scalars['uuid']>;
  contract_id?: Maybe<Scalars['String']>;
  contract_link?: Maybe<Scalars['String']>;
  contract_preview?: Maybe<Scalars['String']>;
  contract_signed?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  day_rate?: Maybe<Scalars['numeric']>;
  delivery_geo?: Maybe<Scalars['String']>;
  delivery_images?: Maybe<Scalars['String']>;
  delivery_notes?: Maybe<Scalars['String']>;
  delivery_videos?: Maybe<Scalars['String']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['time']>;
  extended_end_date?: Maybe<Scalars['date']>;
  free_miles?: Maybe<Scalars['Int']>;
  fuel_in?: Maybe<Scalars['String']>;
  fuel_out?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  insurance_image?: Maybe<Scalars['String']>;
  license_images?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles_in?: Maybe<Scalars['Int']>;
  miles_out?: Maybe<Scalars['numeric']>;
  order_number?: Maybe<Scalars['Int']>;
  /** An object relationship */
  order_policy_type: Order_Policy_Type;
  /** An object relationship */
  order_state: Order_States;
  /** An object relationship */
  order_status: Order_Status;
  /** An object relationship */
  order_step: Order_Steps;
  order_total?: Maybe<Scalars['numeric']>;
  other_fees?: Maybe<Scalars['numeric']>;
  pickup_geo?: Maybe<Scalars['String']>;
  pickup_images?: Maybe<Scalars['String']>;
  pickup_notes?: Maybe<Scalars['String']>;
  pickup_videos?: Maybe<Scalars['String']>;
  policy_type: Order_Policy_Type_Enum;
  rental_period?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  start_time?: Maybe<Scalars['time']>;
  state: Order_States_Enum;
  status: Order_Status_Enum;
  step: Order_Steps_Enum;
  sub_total?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  tenant: Tenants;
  tenant_id: Scalars['uuid'];
  terms_accepted?: Maybe<Scalars['Boolean']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle?: Maybe<Vehicles>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "orders" */
export type Orders_Aggregate = {
  aggregate?: Maybe<Orders_Aggregate_Fields>;
  nodes: Array<Orders>;
};

export type Orders_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Orders_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Orders_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Orders_Aggregate_Bool_Exp_Count>;
};

export type Orders_Aggregate_Bool_Exp_Bool_And = {
  arguments: Orders_Select_Column_Orders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Orders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Orders_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Orders_Select_Column_Orders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Orders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Orders_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Orders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "orders" */
export type Orders_Aggregate_Fields = {
  avg?: Maybe<Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Orders_Max_Fields>;
  min?: Maybe<Orders_Min_Fields>;
  stddev?: Maybe<Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Orders_Sum_Fields>;
  var_pop?: Maybe<Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Orders_Var_Samp_Fields>;
  variance?: Maybe<Orders_Variance_Fields>;
};


/** aggregate fields of "orders" */
export type Orders_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "orders" */
export type Orders_Aggregate_Order_By = {
  avg?: Maybe<Orders_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Orders_Max_Order_By>;
  min?: Maybe<Orders_Min_Order_By>;
  stddev?: Maybe<Orders_Stddev_Order_By>;
  stddev_pop?: Maybe<Orders_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Orders_Stddev_Samp_Order_By>;
  sum?: Maybe<Orders_Sum_Order_By>;
  var_pop?: Maybe<Orders_Var_Pop_Order_By>;
  var_samp?: Maybe<Orders_Var_Samp_Order_By>;
  variance?: Maybe<Orders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "orders" */
export type Orders_Arr_Rel_Insert_Input = {
  data: Array<Orders_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Orders_Avg_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles_in?: Maybe<Scalars['Float']>;
  miles_out?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  order_total?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "orders" */
export type Orders_Avg_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles_in?: Maybe<Order_By>;
  miles_out?: Maybe<Order_By>;
  order_number?: Maybe<Order_By>;
  order_total?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Orders_Bool_Exp = {
  _and?: Maybe<Array<Orders_Bool_Exp>>;
  _not?: Maybe<Orders_Bool_Exp>;
  _or?: Maybe<Array<Orders_Bool_Exp>>;
  agent?: Maybe<Users_Bool_Exp>;
  agent_id?: Maybe<Uuid_Comparison_Exp>;
  client?: Maybe<Users_Bool_Exp>;
  client_id?: Maybe<Uuid_Comparison_Exp>;
  contract_id?: Maybe<String_Comparison_Exp>;
  contract_link?: Maybe<String_Comparison_Exp>;
  contract_preview?: Maybe<String_Comparison_Exp>;
  contract_signed?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  day_rate?: Maybe<Numeric_Comparison_Exp>;
  delivery_geo?: Maybe<String_Comparison_Exp>;
  delivery_images?: Maybe<String_Comparison_Exp>;
  delivery_notes?: Maybe<String_Comparison_Exp>;
  delivery_videos?: Maybe<String_Comparison_Exp>;
  deposit?: Maybe<Numeric_Comparison_Exp>;
  discount?: Maybe<Numeric_Comparison_Exp>;
  end_date?: Maybe<Date_Comparison_Exp>;
  end_time?: Maybe<Time_Comparison_Exp>;
  extended_end_date?: Maybe<Date_Comparison_Exp>;
  free_miles?: Maybe<Int_Comparison_Exp>;
  fuel_in?: Maybe<String_Comparison_Exp>;
  fuel_out?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  insurance_image?: Maybe<String_Comparison_Exp>;
  license_images?: Maybe<String_Comparison_Exp>;
  memo?: Maybe<String_Comparison_Exp>;
  mile_rate?: Maybe<Numeric_Comparison_Exp>;
  miles_in?: Maybe<Int_Comparison_Exp>;
  miles_out?: Maybe<Numeric_Comparison_Exp>;
  order_number?: Maybe<Int_Comparison_Exp>;
  order_policy_type?: Maybe<Order_Policy_Type_Bool_Exp>;
  order_state?: Maybe<Order_States_Bool_Exp>;
  order_status?: Maybe<Order_Status_Bool_Exp>;
  order_step?: Maybe<Order_Steps_Bool_Exp>;
  order_total?: Maybe<Numeric_Comparison_Exp>;
  other_fees?: Maybe<Numeric_Comparison_Exp>;
  pickup_geo?: Maybe<String_Comparison_Exp>;
  pickup_images?: Maybe<String_Comparison_Exp>;
  pickup_notes?: Maybe<String_Comparison_Exp>;
  pickup_videos?: Maybe<String_Comparison_Exp>;
  policy_type?: Maybe<Order_Policy_Type_Enum_Comparison_Exp>;
  rental_period?: Maybe<Int_Comparison_Exp>;
  sales_tax?: Maybe<Numeric_Comparison_Exp>;
  start_date?: Maybe<Date_Comparison_Exp>;
  start_time?: Maybe<Time_Comparison_Exp>;
  state?: Maybe<Order_States_Enum_Comparison_Exp>;
  status?: Maybe<Order_Status_Enum_Comparison_Exp>;
  step?: Maybe<Order_Steps_Enum_Comparison_Exp>;
  sub_total?: Maybe<Numeric_Comparison_Exp>;
  tenant?: Maybe<Tenants_Bool_Exp>;
  tenant_id?: Maybe<Uuid_Comparison_Exp>;
  terms_accepted?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle?: Maybe<Vehicles_Bool_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "orders" */
export enum Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrdersPkey = 'orders_pkey'
}

/** input type for incrementing numeric columns in table "orders" */
export type Orders_Inc_Input = {
  day_rate?: Maybe<Scalars['numeric']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  free_miles?: Maybe<Scalars['Int']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles_in?: Maybe<Scalars['Int']>;
  miles_out?: Maybe<Scalars['numeric']>;
  order_number?: Maybe<Scalars['Int']>;
  order_total?: Maybe<Scalars['numeric']>;
  other_fees?: Maybe<Scalars['numeric']>;
  rental_period?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  sub_total?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "orders" */
export type Orders_Insert_Input = {
  agent?: Maybe<Users_Obj_Rel_Insert_Input>;
  agent_id?: Maybe<Scalars['uuid']>;
  client?: Maybe<Users_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['uuid']>;
  contract_id?: Maybe<Scalars['String']>;
  contract_link?: Maybe<Scalars['String']>;
  contract_preview?: Maybe<Scalars['String']>;
  contract_signed?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  delivery_geo?: Maybe<Scalars['String']>;
  delivery_images?: Maybe<Scalars['String']>;
  delivery_notes?: Maybe<Scalars['String']>;
  delivery_videos?: Maybe<Scalars['String']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['time']>;
  extended_end_date?: Maybe<Scalars['date']>;
  free_miles?: Maybe<Scalars['Int']>;
  fuel_in?: Maybe<Scalars['String']>;
  fuel_out?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_image?: Maybe<Scalars['String']>;
  license_images?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles_in?: Maybe<Scalars['Int']>;
  miles_out?: Maybe<Scalars['numeric']>;
  order_number?: Maybe<Scalars['Int']>;
  order_policy_type?: Maybe<Order_Policy_Type_Obj_Rel_Insert_Input>;
  order_state?: Maybe<Order_States_Obj_Rel_Insert_Input>;
  order_status?: Maybe<Order_Status_Obj_Rel_Insert_Input>;
  order_step?: Maybe<Order_Steps_Obj_Rel_Insert_Input>;
  order_total?: Maybe<Scalars['numeric']>;
  other_fees?: Maybe<Scalars['numeric']>;
  pickup_geo?: Maybe<Scalars['String']>;
  pickup_images?: Maybe<Scalars['String']>;
  pickup_notes?: Maybe<Scalars['String']>;
  pickup_videos?: Maybe<Scalars['String']>;
  policy_type?: Maybe<Order_Policy_Type_Enum>;
  rental_period?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  start_time?: Maybe<Scalars['time']>;
  state?: Maybe<Order_States_Enum>;
  status?: Maybe<Order_Status_Enum>;
  step?: Maybe<Order_Steps_Enum>;
  sub_total?: Maybe<Scalars['numeric']>;
  tenant?: Maybe<Tenants_Obj_Rel_Insert_Input>;
  tenant_id?: Maybe<Scalars['uuid']>;
  terms_accepted?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle?: Maybe<Vehicles_Obj_Rel_Insert_Input>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Orders_Max_Fields = {
  agent_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  contract_id?: Maybe<Scalars['String']>;
  contract_link?: Maybe<Scalars['String']>;
  contract_preview?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  delivery_geo?: Maybe<Scalars['String']>;
  delivery_images?: Maybe<Scalars['String']>;
  delivery_notes?: Maybe<Scalars['String']>;
  delivery_videos?: Maybe<Scalars['String']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  end_date?: Maybe<Scalars['date']>;
  extended_end_date?: Maybe<Scalars['date']>;
  free_miles?: Maybe<Scalars['Int']>;
  fuel_in?: Maybe<Scalars['String']>;
  fuel_out?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_image?: Maybe<Scalars['String']>;
  license_images?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles_in?: Maybe<Scalars['Int']>;
  miles_out?: Maybe<Scalars['numeric']>;
  order_number?: Maybe<Scalars['Int']>;
  order_total?: Maybe<Scalars['numeric']>;
  other_fees?: Maybe<Scalars['numeric']>;
  pickup_geo?: Maybe<Scalars['String']>;
  pickup_images?: Maybe<Scalars['String']>;
  pickup_notes?: Maybe<Scalars['String']>;
  pickup_videos?: Maybe<Scalars['String']>;
  rental_period?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  sub_total?: Maybe<Scalars['numeric']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "orders" */
export type Orders_Max_Order_By = {
  agent_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  contract_id?: Maybe<Order_By>;
  contract_link?: Maybe<Order_By>;
  contract_preview?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  day_rate?: Maybe<Order_By>;
  delivery_geo?: Maybe<Order_By>;
  delivery_images?: Maybe<Order_By>;
  delivery_notes?: Maybe<Order_By>;
  delivery_videos?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  extended_end_date?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  fuel_in?: Maybe<Order_By>;
  fuel_out?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_image?: Maybe<Order_By>;
  license_images?: Maybe<Order_By>;
  memo?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles_in?: Maybe<Order_By>;
  miles_out?: Maybe<Order_By>;
  order_number?: Maybe<Order_By>;
  order_total?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  pickup_geo?: Maybe<Order_By>;
  pickup_images?: Maybe<Order_By>;
  pickup_notes?: Maybe<Order_By>;
  pickup_videos?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Orders_Min_Fields = {
  agent_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  contract_id?: Maybe<Scalars['String']>;
  contract_link?: Maybe<Scalars['String']>;
  contract_preview?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  delivery_geo?: Maybe<Scalars['String']>;
  delivery_images?: Maybe<Scalars['String']>;
  delivery_notes?: Maybe<Scalars['String']>;
  delivery_videos?: Maybe<Scalars['String']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  end_date?: Maybe<Scalars['date']>;
  extended_end_date?: Maybe<Scalars['date']>;
  free_miles?: Maybe<Scalars['Int']>;
  fuel_in?: Maybe<Scalars['String']>;
  fuel_out?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_image?: Maybe<Scalars['String']>;
  license_images?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles_in?: Maybe<Scalars['Int']>;
  miles_out?: Maybe<Scalars['numeric']>;
  order_number?: Maybe<Scalars['Int']>;
  order_total?: Maybe<Scalars['numeric']>;
  other_fees?: Maybe<Scalars['numeric']>;
  pickup_geo?: Maybe<Scalars['String']>;
  pickup_images?: Maybe<Scalars['String']>;
  pickup_notes?: Maybe<Scalars['String']>;
  pickup_videos?: Maybe<Scalars['String']>;
  rental_period?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  sub_total?: Maybe<Scalars['numeric']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "orders" */
export type Orders_Min_Order_By = {
  agent_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  contract_id?: Maybe<Order_By>;
  contract_link?: Maybe<Order_By>;
  contract_preview?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  day_rate?: Maybe<Order_By>;
  delivery_geo?: Maybe<Order_By>;
  delivery_images?: Maybe<Order_By>;
  delivery_notes?: Maybe<Order_By>;
  delivery_videos?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  extended_end_date?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  fuel_in?: Maybe<Order_By>;
  fuel_out?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_image?: Maybe<Order_By>;
  license_images?: Maybe<Order_By>;
  memo?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles_in?: Maybe<Order_By>;
  miles_out?: Maybe<Order_By>;
  order_number?: Maybe<Order_By>;
  order_total?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  pickup_geo?: Maybe<Order_By>;
  pickup_images?: Maybe<Order_By>;
  pickup_notes?: Maybe<Order_By>;
  pickup_videos?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "orders" */
export type Orders_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Orders>;
};

/** input type for inserting object relation for remote table "orders" */
export type Orders_Obj_Rel_Insert_Input = {
  data: Orders_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Orders_On_Conflict>;
};

/** on_conflict condition type for table "orders" */
export type Orders_On_Conflict = {
  constraint: Orders_Constraint;
  update_columns?: Array<Orders_Update_Column>;
  where?: Maybe<Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "orders". */
export type Orders_Order_By = {
  agent?: Maybe<Users_Order_By>;
  agent_id?: Maybe<Order_By>;
  client?: Maybe<Users_Order_By>;
  client_id?: Maybe<Order_By>;
  contract_id?: Maybe<Order_By>;
  contract_link?: Maybe<Order_By>;
  contract_preview?: Maybe<Order_By>;
  contract_signed?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  day_rate?: Maybe<Order_By>;
  delivery_geo?: Maybe<Order_By>;
  delivery_images?: Maybe<Order_By>;
  delivery_notes?: Maybe<Order_By>;
  delivery_videos?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  extended_end_date?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  fuel_in?: Maybe<Order_By>;
  fuel_out?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_image?: Maybe<Order_By>;
  license_images?: Maybe<Order_By>;
  memo?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles_in?: Maybe<Order_By>;
  miles_out?: Maybe<Order_By>;
  order_number?: Maybe<Order_By>;
  order_policy_type?: Maybe<Order_Policy_Type_Order_By>;
  order_state?: Maybe<Order_States_Order_By>;
  order_status?: Maybe<Order_Status_Order_By>;
  order_step?: Maybe<Order_Steps_Order_By>;
  order_total?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  pickup_geo?: Maybe<Order_By>;
  pickup_images?: Maybe<Order_By>;
  pickup_notes?: Maybe<Order_By>;
  pickup_videos?: Maybe<Order_By>;
  policy_type?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  step?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  tenant?: Maybe<Tenants_Order_By>;
  tenant_id?: Maybe<Order_By>;
  terms_accepted?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle?: Maybe<Vehicles_Order_By>;
  vehicle_id?: Maybe<Order_By>;
};

/** primary key columns input for table: orders */
export type Orders_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "orders" */
export enum Orders_Select_Column {
  /** column name */
  AgentId = 'agent_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  ContractLink = 'contract_link',
  /** column name */
  ContractPreview = 'contract_preview',
  /** column name */
  ContractSigned = 'contract_signed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayRate = 'day_rate',
  /** column name */
  DeliveryGeo = 'delivery_geo',
  /** column name */
  DeliveryImages = 'delivery_images',
  /** column name */
  DeliveryNotes = 'delivery_notes',
  /** column name */
  DeliveryVideos = 'delivery_videos',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Discount = 'discount',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  ExtendedEndDate = 'extended_end_date',
  /** column name */
  FreeMiles = 'free_miles',
  /** column name */
  FuelIn = 'fuel_in',
  /** column name */
  FuelOut = 'fuel_out',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceImage = 'insurance_image',
  /** column name */
  LicenseImages = 'license_images',
  /** column name */
  Memo = 'memo',
  /** column name */
  MileRate = 'mile_rate',
  /** column name */
  MilesIn = 'miles_in',
  /** column name */
  MilesOut = 'miles_out',
  /** column name */
  OrderNumber = 'order_number',
  /** column name */
  OrderTotal = 'order_total',
  /** column name */
  OtherFees = 'other_fees',
  /** column name */
  PickupGeo = 'pickup_geo',
  /** column name */
  PickupImages = 'pickup_images',
  /** column name */
  PickupNotes = 'pickup_notes',
  /** column name */
  PickupVideos = 'pickup_videos',
  /** column name */
  PolicyType = 'policy_type',
  /** column name */
  RentalPeriod = 'rental_period',
  /** column name */
  SalesTax = 'sales_tax',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Step = 'step',
  /** column name */
  SubTotal = 'sub_total',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TermsAccepted = 'terms_accepted',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id'
}

/** select "orders_aggregate_bool_exp_bool_and_arguments_columns" columns of table "orders" */
export enum Orders_Select_Column_Orders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ContractSigned = 'contract_signed',
  /** column name */
  TermsAccepted = 'terms_accepted'
}

/** select "orders_aggregate_bool_exp_bool_or_arguments_columns" columns of table "orders" */
export enum Orders_Select_Column_Orders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ContractSigned = 'contract_signed',
  /** column name */
  TermsAccepted = 'terms_accepted'
}

/** input type for updating data in table "orders" */
export type Orders_Set_Input = {
  agent_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  contract_id?: Maybe<Scalars['String']>;
  contract_link?: Maybe<Scalars['String']>;
  contract_preview?: Maybe<Scalars['String']>;
  contract_signed?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  delivery_geo?: Maybe<Scalars['String']>;
  delivery_images?: Maybe<Scalars['String']>;
  delivery_notes?: Maybe<Scalars['String']>;
  delivery_videos?: Maybe<Scalars['String']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['time']>;
  extended_end_date?: Maybe<Scalars['date']>;
  free_miles?: Maybe<Scalars['Int']>;
  fuel_in?: Maybe<Scalars['String']>;
  fuel_out?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_image?: Maybe<Scalars['String']>;
  license_images?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles_in?: Maybe<Scalars['Int']>;
  miles_out?: Maybe<Scalars['numeric']>;
  order_number?: Maybe<Scalars['Int']>;
  order_total?: Maybe<Scalars['numeric']>;
  other_fees?: Maybe<Scalars['numeric']>;
  pickup_geo?: Maybe<Scalars['String']>;
  pickup_images?: Maybe<Scalars['String']>;
  pickup_notes?: Maybe<Scalars['String']>;
  pickup_videos?: Maybe<Scalars['String']>;
  policy_type?: Maybe<Order_Policy_Type_Enum>;
  rental_period?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  start_time?: Maybe<Scalars['time']>;
  state?: Maybe<Order_States_Enum>;
  status?: Maybe<Order_Status_Enum>;
  step?: Maybe<Order_Steps_Enum>;
  sub_total?: Maybe<Scalars['numeric']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  terms_accepted?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Orders_Stddev_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles_in?: Maybe<Scalars['Float']>;
  miles_out?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  order_total?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "orders" */
export type Orders_Stddev_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles_in?: Maybe<Order_By>;
  miles_out?: Maybe<Order_By>;
  order_number?: Maybe<Order_By>;
  order_total?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Orders_Stddev_Pop_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles_in?: Maybe<Scalars['Float']>;
  miles_out?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  order_total?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "orders" */
export type Orders_Stddev_Pop_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles_in?: Maybe<Order_By>;
  miles_out?: Maybe<Order_By>;
  order_number?: Maybe<Order_By>;
  order_total?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Orders_Stddev_Samp_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles_in?: Maybe<Scalars['Float']>;
  miles_out?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  order_total?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "orders" */
export type Orders_Stddev_Samp_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles_in?: Maybe<Order_By>;
  miles_out?: Maybe<Order_By>;
  order_number?: Maybe<Order_By>;
  order_total?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
};

/** Streaming cursor of the table "orders" */
export type Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Orders_Stream_Cursor_Value_Input = {
  agent_id?: Maybe<Scalars['uuid']>;
  client_id?: Maybe<Scalars['uuid']>;
  contract_id?: Maybe<Scalars['String']>;
  contract_link?: Maybe<Scalars['String']>;
  contract_preview?: Maybe<Scalars['String']>;
  contract_signed?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  delivery_geo?: Maybe<Scalars['String']>;
  delivery_images?: Maybe<Scalars['String']>;
  delivery_notes?: Maybe<Scalars['String']>;
  delivery_videos?: Maybe<Scalars['String']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['time']>;
  extended_end_date?: Maybe<Scalars['date']>;
  free_miles?: Maybe<Scalars['Int']>;
  fuel_in?: Maybe<Scalars['String']>;
  fuel_out?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_image?: Maybe<Scalars['String']>;
  license_images?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles_in?: Maybe<Scalars['Int']>;
  miles_out?: Maybe<Scalars['numeric']>;
  order_number?: Maybe<Scalars['Int']>;
  order_total?: Maybe<Scalars['numeric']>;
  other_fees?: Maybe<Scalars['numeric']>;
  pickup_geo?: Maybe<Scalars['String']>;
  pickup_images?: Maybe<Scalars['String']>;
  pickup_notes?: Maybe<Scalars['String']>;
  pickup_videos?: Maybe<Scalars['String']>;
  policy_type?: Maybe<Order_Policy_Type_Enum>;
  rental_period?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  start_time?: Maybe<Scalars['time']>;
  state?: Maybe<Order_States_Enum>;
  status?: Maybe<Order_Status_Enum>;
  step?: Maybe<Order_Steps_Enum>;
  sub_total?: Maybe<Scalars['numeric']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  terms_accepted?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Orders_Sum_Fields = {
  day_rate?: Maybe<Scalars['numeric']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  free_miles?: Maybe<Scalars['Int']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles_in?: Maybe<Scalars['Int']>;
  miles_out?: Maybe<Scalars['numeric']>;
  order_number?: Maybe<Scalars['Int']>;
  order_total?: Maybe<Scalars['numeric']>;
  other_fees?: Maybe<Scalars['numeric']>;
  rental_period?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  sub_total?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "orders" */
export type Orders_Sum_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles_in?: Maybe<Order_By>;
  miles_out?: Maybe<Order_By>;
  order_number?: Maybe<Order_By>;
  order_total?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
};

/** update columns of table "orders" */
export enum Orders_Update_Column {
  /** column name */
  AgentId = 'agent_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  ContractLink = 'contract_link',
  /** column name */
  ContractPreview = 'contract_preview',
  /** column name */
  ContractSigned = 'contract_signed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayRate = 'day_rate',
  /** column name */
  DeliveryGeo = 'delivery_geo',
  /** column name */
  DeliveryImages = 'delivery_images',
  /** column name */
  DeliveryNotes = 'delivery_notes',
  /** column name */
  DeliveryVideos = 'delivery_videos',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Discount = 'discount',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  ExtendedEndDate = 'extended_end_date',
  /** column name */
  FreeMiles = 'free_miles',
  /** column name */
  FuelIn = 'fuel_in',
  /** column name */
  FuelOut = 'fuel_out',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceImage = 'insurance_image',
  /** column name */
  LicenseImages = 'license_images',
  /** column name */
  Memo = 'memo',
  /** column name */
  MileRate = 'mile_rate',
  /** column name */
  MilesIn = 'miles_in',
  /** column name */
  MilesOut = 'miles_out',
  /** column name */
  OrderNumber = 'order_number',
  /** column name */
  OrderTotal = 'order_total',
  /** column name */
  OtherFees = 'other_fees',
  /** column name */
  PickupGeo = 'pickup_geo',
  /** column name */
  PickupImages = 'pickup_images',
  /** column name */
  PickupNotes = 'pickup_notes',
  /** column name */
  PickupVideos = 'pickup_videos',
  /** column name */
  PolicyType = 'policy_type',
  /** column name */
  RentalPeriod = 'rental_period',
  /** column name */
  SalesTax = 'sales_tax',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Step = 'step',
  /** column name */
  SubTotal = 'sub_total',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TermsAccepted = 'terms_accepted',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id'
}

export type Orders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Orders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Orders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Orders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Orders_Var_Pop_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles_in?: Maybe<Scalars['Float']>;
  miles_out?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  order_total?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "orders" */
export type Orders_Var_Pop_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles_in?: Maybe<Order_By>;
  miles_out?: Maybe<Order_By>;
  order_number?: Maybe<Order_By>;
  order_total?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Orders_Var_Samp_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles_in?: Maybe<Scalars['Float']>;
  miles_out?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  order_total?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "orders" */
export type Orders_Var_Samp_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles_in?: Maybe<Order_By>;
  miles_out?: Maybe<Order_By>;
  order_number?: Maybe<Order_By>;
  order_total?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Orders_Variance_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles_in?: Maybe<Scalars['Float']>;
  miles_out?: Maybe<Scalars['Float']>;
  order_number?: Maybe<Scalars['Float']>;
  order_total?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "orders" */
export type Orders_Variance_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles_in?: Maybe<Order_By>;
  miles_out?: Maybe<Order_By>;
  order_number?: Maybe<Order_By>;
  order_total?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
};

export type Query_Root = {
  check_subdomain_by_slug?: Maybe<CheckSubDomainOutput>;
  get_order_status?: Maybe<GetOrderStatusOutput>;
  /** fetch data from the table: "order_policy_type" */
  order_policy_type: Array<Order_Policy_Type>;
  /** fetch aggregated fields from the table: "order_policy_type" */
  order_policy_type_aggregate: Order_Policy_Type_Aggregate;
  /** fetch data from the table: "order_policy_type" using primary key columns */
  order_policy_type_by_pk?: Maybe<Order_Policy_Type>;
  /** fetch data from the table: "order_states" */
  order_states: Array<Order_States>;
  /** fetch aggregated fields from the table: "order_states" */
  order_states_aggregate: Order_States_Aggregate;
  /** fetch data from the table: "order_states" using primary key columns */
  order_states_by_pk?: Maybe<Order_States>;
  /** fetch data from the table: "order_status" */
  order_status: Array<Order_Status>;
  /** fetch aggregated fields from the table: "order_status" */
  order_status_aggregate: Order_Status_Aggregate;
  /** fetch data from the table: "order_status" using primary key columns */
  order_status_by_pk?: Maybe<Order_Status>;
  /** fetch data from the table: "order_steps" */
  order_steps: Array<Order_Steps>;
  /** fetch aggregated fields from the table: "order_steps" */
  order_steps_aggregate: Order_Steps_Aggregate;
  /** fetch data from the table: "order_steps" using primary key columns */
  order_steps_by_pk?: Maybe<Order_Steps>;
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "reservation_status" */
  reservation_status: Array<Reservation_Status>;
  /** fetch aggregated fields from the table: "reservation_status" */
  reservation_status_aggregate: Reservation_Status_Aggregate;
  /** fetch data from the table: "reservation_status" using primary key columns */
  reservation_status_by_pk?: Maybe<Reservation_Status>;
  /** An array relationship */
  reservations: Array<Reservations>;
  /** An aggregate relationship */
  reservations_aggregate: Reservations_Aggregate;
  /** fetch data from the table: "reservations" using primary key columns */
  reservations_by_pk?: Maybe<Reservations>;
  stripe: Stripe;
  /** execute function "tenant_by_slug" which returns "tenants" */
  tenant_by_slug?: Maybe<Tenants>;
  /** execute function "tenant_by_slug" and query aggregates on result of table type "tenants" */
  tenant_by_slug_aggregate: Tenants_Aggregate;
  /** fetch data from the table: "tenant_stats" */
  tenant_stats: Array<Tenant_Stats>;
  /** fetch aggregated fields from the table: "tenant_stats" */
  tenant_stats_aggregate: Tenant_Stats_Aggregate;
  /** fetch data from the table: "tenant_status" */
  tenant_status: Array<Tenant_Status>;
  /** fetch aggregated fields from the table: "tenant_status" */
  tenant_status_aggregate: Tenant_Status_Aggregate;
  /** fetch data from the table: "tenant_status" using primary key columns */
  tenant_status_by_pk?: Maybe<Tenant_Status>;
  /** fetch data from the table: "tenant_types" */
  tenant_types: Array<Tenant_Types>;
  /** fetch aggregated fields from the table: "tenant_types" */
  tenant_types_aggregate: Tenant_Types_Aggregate;
  /** fetch data from the table: "tenant_types" using primary key columns */
  tenant_types_by_pk?: Maybe<Tenant_Types>;
  /** An array relationship */
  tenants: Array<Tenants>;
  /** An aggregate relationship */
  tenants_aggregate: Tenants_Aggregate;
  /** fetch data from the table: "tenants" using primary key columns */
  tenants_by_pk?: Maybe<Tenants>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table: "user_status" */
  user_status: Array<User_Status>;
  /** fetch aggregated fields from the table: "user_status" */
  user_status_aggregate: User_Status_Aggregate;
  /** fetch data from the table: "user_status" using primary key columns */
  user_status_by_pk?: Maybe<User_Status>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** An array relationship */
  vehicles: Array<Vehicles>;
  /** An aggregate relationship */
  vehicles_aggregate: Vehicles_Aggregate;
  /** fetch data from the table: "vehicles" using primary key columns */
  vehicles_by_pk?: Maybe<Vehicles>;
  /** fetch data from the table: "vehicles_status" */
  vehicles_status: Array<Vehicles_Status>;
  /** fetch aggregated fields from the table: "vehicles_status" */
  vehicles_status_aggregate: Vehicles_Status_Aggregate;
  /** fetch data from the table: "vehicles_status" using primary key columns */
  vehicles_status_by_pk?: Maybe<Vehicles_Status>;
};


export type Query_RootCheck_Subdomain_By_SlugArgs = {
  slug: Scalars['String'];
};


export type Query_RootGet_Order_StatusArgs = {
  id: Scalars['String'];
};


export type Query_RootOrder_Policy_TypeArgs = {
  distinct_on?: Maybe<Array<Order_Policy_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Policy_Type_Order_By>>;
  where?: Maybe<Order_Policy_Type_Bool_Exp>;
};


export type Query_RootOrder_Policy_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Policy_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Policy_Type_Order_By>>;
  where?: Maybe<Order_Policy_Type_Bool_Exp>;
};


export type Query_RootOrder_Policy_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootOrder_StatesArgs = {
  distinct_on?: Maybe<Array<Order_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_States_Order_By>>;
  where?: Maybe<Order_States_Bool_Exp>;
};


export type Query_RootOrder_States_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_States_Order_By>>;
  where?: Maybe<Order_States_Bool_Exp>;
};


export type Query_RootOrder_States_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootOrder_StatusArgs = {
  distinct_on?: Maybe<Array<Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Status_Order_By>>;
  where?: Maybe<Order_Status_Bool_Exp>;
};


export type Query_RootOrder_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Status_Order_By>>;
  where?: Maybe<Order_Status_Bool_Exp>;
};


export type Query_RootOrder_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootOrder_StepsArgs = {
  distinct_on?: Maybe<Array<Order_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Steps_Order_By>>;
  where?: Maybe<Order_Steps_Bool_Exp>;
};


export type Query_RootOrder_Steps_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Steps_Order_By>>;
  where?: Maybe<Order_Steps_Bool_Exp>;
};


export type Query_RootOrder_Steps_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReservation_StatusArgs = {
  distinct_on?: Maybe<Array<Reservation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Status_Order_By>>;
  where?: Maybe<Reservation_Status_Bool_Exp>;
};


export type Query_RootReservation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Status_Order_By>>;
  where?: Maybe<Reservation_Status_Bool_Exp>;
};


export type Query_RootReservation_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootReservationsArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


export type Query_RootReservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


export type Query_RootReservations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTenant_By_SlugArgs = {
  args: Tenant_By_Slug_Args;
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Query_RootTenant_By_Slug_AggregateArgs = {
  args: Tenant_By_Slug_Args;
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Query_RootTenant_StatsArgs = {
  distinct_on?: Maybe<Array<Tenant_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Stats_Order_By>>;
  where?: Maybe<Tenant_Stats_Bool_Exp>;
};


export type Query_RootTenant_Stats_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Stats_Order_By>>;
  where?: Maybe<Tenant_Stats_Bool_Exp>;
};


export type Query_RootTenant_StatusArgs = {
  distinct_on?: Maybe<Array<Tenant_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Status_Order_By>>;
  where?: Maybe<Tenant_Status_Bool_Exp>;
};


export type Query_RootTenant_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Status_Order_By>>;
  where?: Maybe<Tenant_Status_Bool_Exp>;
};


export type Query_RootTenant_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTenant_TypesArgs = {
  distinct_on?: Maybe<Array<Tenant_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Types_Order_By>>;
  where?: Maybe<Tenant_Types_Bool_Exp>;
};


export type Query_RootTenant_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Types_Order_By>>;
  where?: Maybe<Tenant_Types_Bool_Exp>;
};


export type Query_RootTenant_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTenantsArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Query_RootTenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Query_RootTenants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};


export type Query_RootUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};


export type Query_RootUser_Roles_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootUser_StatusArgs = {
  distinct_on?: Maybe<Array<User_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Status_Order_By>>;
  where?: Maybe<User_Status_Bool_Exp>;
};


export type Query_RootUser_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Status_Order_By>>;
  where?: Maybe<User_Status_Bool_Exp>;
};


export type Query_RootUser_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVehiclesArgs = {
  distinct_on?: Maybe<Array<Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Order_By>>;
  where?: Maybe<Vehicles_Bool_Exp>;
};


export type Query_RootVehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Order_By>>;
  where?: Maybe<Vehicles_Bool_Exp>;
};


export type Query_RootVehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVehicles_StatusArgs = {
  distinct_on?: Maybe<Array<Vehicles_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Status_Order_By>>;
  where?: Maybe<Vehicles_Status_Bool_Exp>;
};


export type Query_RootVehicles_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicles_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Status_Order_By>>;
  where?: Maybe<Vehicles_Status_Bool_Exp>;
};


export type Query_RootVehicles_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** columns and relationships of "reservation_status" */
export type Reservation_Status = {
  /** An array relationship */
  reservations: Array<Reservations>;
  /** An aggregate relationship */
  reservations_aggregate: Reservations_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "reservation_status" */
export type Reservation_StatusReservationsArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


/** columns and relationships of "reservation_status" */
export type Reservation_StatusReservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};

/** aggregated selection of "reservation_status" */
export type Reservation_Status_Aggregate = {
  aggregate?: Maybe<Reservation_Status_Aggregate_Fields>;
  nodes: Array<Reservation_Status>;
};

/** aggregate fields of "reservation_status" */
export type Reservation_Status_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Reservation_Status_Max_Fields>;
  min?: Maybe<Reservation_Status_Min_Fields>;
};


/** aggregate fields of "reservation_status" */
export type Reservation_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reservation_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "reservation_status". All fields are combined with a logical 'AND'. */
export type Reservation_Status_Bool_Exp = {
  _and?: Maybe<Array<Reservation_Status_Bool_Exp>>;
  _not?: Maybe<Reservation_Status_Bool_Exp>;
  _or?: Maybe<Array<Reservation_Status_Bool_Exp>>;
  reservations?: Maybe<Reservations_Bool_Exp>;
  reservations_aggregate?: Maybe<Reservations_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reservation_status" */
export enum Reservation_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  ReservationStatusPkey = 'reservation_status_pkey'
}

export enum Reservation_Status_Enum {
  Active = 'active',
  Archived = 'archived',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Confirmed = 'confirmed',
  Deleted = 'deleted',
  New = 'new',
  Unconfirmed = 'unconfirmed'
}

/** Boolean expression to compare columns of type "reservation_status_enum". All fields are combined with logical 'AND'. */
export type Reservation_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Reservation_Status_Enum>;
  _in?: Maybe<Array<Reservation_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Reservation_Status_Enum>;
  _nin?: Maybe<Array<Reservation_Status_Enum>>;
};

/** input type for inserting data into table "reservation_status" */
export type Reservation_Status_Insert_Input = {
  reservations?: Maybe<Reservations_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Reservation_Status_Max_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Reservation_Status_Min_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "reservation_status" */
export type Reservation_Status_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reservation_Status>;
};

/** input type for inserting object relation for remote table "reservation_status" */
export type Reservation_Status_Obj_Rel_Insert_Input = {
  data: Reservation_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Reservation_Status_On_Conflict>;
};

/** on_conflict condition type for table "reservation_status" */
export type Reservation_Status_On_Conflict = {
  constraint: Reservation_Status_Constraint;
  update_columns?: Array<Reservation_Status_Update_Column>;
  where?: Maybe<Reservation_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "reservation_status". */
export type Reservation_Status_Order_By = {
  reservations_aggregate?: Maybe<Reservations_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: reservation_status */
export type Reservation_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "reservation_status" */
export enum Reservation_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "reservation_status" */
export type Reservation_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "reservation_status" */
export type Reservation_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reservation_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reservation_Status_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "reservation_status" */
export enum Reservation_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type Reservation_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Reservation_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reservation_Status_Bool_Exp;
};

/** columns and relationships of "reservations" */
export type Reservations = {
  address?: Maybe<Scalars['String']>;
  /** An object relationship */
  agent?: Maybe<Users>;
  agent_id?: Maybe<Scalars['uuid']>;
  city?: Maybe<Scalars['String']>;
  /** An object relationship */
  client?: Maybe<Users>;
  client_id?: Maybe<Scalars['uuid']>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  day_rate?: Maybe<Scalars['numeric']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  dob?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['time']>;
  first_name: Scalars['String'];
  free_miles?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  order?: Maybe<Orders>;
  order_id?: Maybe<Scalars['uuid']>;
  other_fees?: Maybe<Scalars['numeric']>;
  phone: Scalars['String'];
  rental_period?: Maybe<Scalars['Int']>;
  reservation_number?: Maybe<Scalars['Int']>;
  /** An object relationship */
  reservation_status: Reservation_Status;
  sales_tax?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  start_time?: Maybe<Scalars['time']>;
  state?: Maybe<Scalars['String']>;
  status: Reservation_Status_Enum;
  sub_total?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  tenant: Tenants;
  tenant_id: Scalars['uuid'];
  terms_accepted?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicles;
  vehicle_id: Scalars['uuid'];
  zip?: Maybe<Scalars['String']>;
};

/** aggregated selection of "reservations" */
export type Reservations_Aggregate = {
  aggregate?: Maybe<Reservations_Aggregate_Fields>;
  nodes: Array<Reservations>;
};

export type Reservations_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Reservations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Reservations_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Reservations_Aggregate_Bool_Exp_Count>;
};

export type Reservations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Reservations_Select_Column_Reservations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Reservations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reservations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Reservations_Select_Column_Reservations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Reservations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reservations_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Reservations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Reservations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reservations" */
export type Reservations_Aggregate_Fields = {
  avg?: Maybe<Reservations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Reservations_Max_Fields>;
  min?: Maybe<Reservations_Min_Fields>;
  stddev?: Maybe<Reservations_Stddev_Fields>;
  stddev_pop?: Maybe<Reservations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reservations_Stddev_Samp_Fields>;
  sum?: Maybe<Reservations_Sum_Fields>;
  var_pop?: Maybe<Reservations_Var_Pop_Fields>;
  var_samp?: Maybe<Reservations_Var_Samp_Fields>;
  variance?: Maybe<Reservations_Variance_Fields>;
};


/** aggregate fields of "reservations" */
export type Reservations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reservations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reservations" */
export type Reservations_Aggregate_Order_By = {
  avg?: Maybe<Reservations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Reservations_Max_Order_By>;
  min?: Maybe<Reservations_Min_Order_By>;
  stddev?: Maybe<Reservations_Stddev_Order_By>;
  stddev_pop?: Maybe<Reservations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Reservations_Stddev_Samp_Order_By>;
  sum?: Maybe<Reservations_Sum_Order_By>;
  var_pop?: Maybe<Reservations_Var_Pop_Order_By>;
  var_samp?: Maybe<Reservations_Var_Samp_Order_By>;
  variance?: Maybe<Reservations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reservations" */
export type Reservations_Arr_Rel_Insert_Input = {
  data: Array<Reservations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Reservations_On_Conflict>;
};

/** aggregate avg on columns */
export type Reservations_Avg_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  reservation_number?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "reservations" */
export type Reservations_Avg_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  reservation_number?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reservations". All fields are combined with a logical 'AND'. */
export type Reservations_Bool_Exp = {
  _and?: Maybe<Array<Reservations_Bool_Exp>>;
  _not?: Maybe<Reservations_Bool_Exp>;
  _or?: Maybe<Array<Reservations_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  agent?: Maybe<Users_Bool_Exp>;
  agent_id?: Maybe<Uuid_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  client?: Maybe<Users_Bool_Exp>;
  client_id?: Maybe<Uuid_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  day_rate?: Maybe<Numeric_Comparison_Exp>;
  deposit?: Maybe<Numeric_Comparison_Exp>;
  discount?: Maybe<Numeric_Comparison_Exp>;
  dob?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  end_date?: Maybe<Date_Comparison_Exp>;
  end_time?: Maybe<Time_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  free_miles?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  memo?: Maybe<String_Comparison_Exp>;
  mile_rate?: Maybe<Numeric_Comparison_Exp>;
  order?: Maybe<Orders_Bool_Exp>;
  order_id?: Maybe<Uuid_Comparison_Exp>;
  other_fees?: Maybe<Numeric_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  rental_period?: Maybe<Int_Comparison_Exp>;
  reservation_number?: Maybe<Int_Comparison_Exp>;
  reservation_status?: Maybe<Reservation_Status_Bool_Exp>;
  sales_tax?: Maybe<Numeric_Comparison_Exp>;
  start_date?: Maybe<Date_Comparison_Exp>;
  start_time?: Maybe<Time_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Reservation_Status_Enum_Comparison_Exp>;
  sub_total?: Maybe<Numeric_Comparison_Exp>;
  tenant?: Maybe<Tenants_Bool_Exp>;
  tenant_id?: Maybe<Uuid_Comparison_Exp>;
  terms_accepted?: Maybe<Boolean_Comparison_Exp>;
  total?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicle?: Maybe<Vehicles_Bool_Exp>;
  vehicle_id?: Maybe<Uuid_Comparison_Exp>;
  zip?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reservations" */
export enum Reservations_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReservationsPkey = 'reservations_pkey'
}

/** input type for incrementing numeric columns in table "reservations" */
export type Reservations_Inc_Input = {
  day_rate?: Maybe<Scalars['numeric']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  free_miles?: Maybe<Scalars['Int']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  other_fees?: Maybe<Scalars['numeric']>;
  rental_period?: Maybe<Scalars['Int']>;
  reservation_number?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  sub_total?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "reservations" */
export type Reservations_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  agent?: Maybe<Users_Obj_Rel_Insert_Input>;
  agent_id?: Maybe<Scalars['uuid']>;
  city?: Maybe<Scalars['String']>;
  client?: Maybe<Users_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['uuid']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['time']>;
  first_name?: Maybe<Scalars['String']>;
  free_miles?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  order?: Maybe<Orders_Obj_Rel_Insert_Input>;
  order_id?: Maybe<Scalars['uuid']>;
  other_fees?: Maybe<Scalars['numeric']>;
  phone?: Maybe<Scalars['String']>;
  rental_period?: Maybe<Scalars['Int']>;
  reservation_number?: Maybe<Scalars['Int']>;
  reservation_status?: Maybe<Reservation_Status_Obj_Rel_Insert_Input>;
  sales_tax?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  start_time?: Maybe<Scalars['time']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Reservation_Status_Enum>;
  sub_total?: Maybe<Scalars['numeric']>;
  tenant?: Maybe<Tenants_Obj_Rel_Insert_Input>;
  tenant_id?: Maybe<Scalars['uuid']>;
  terms_accepted?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle?: Maybe<Vehicles_Obj_Rel_Insert_Input>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Reservations_Max_Fields = {
  address?: Maybe<Scalars['String']>;
  agent_id?: Maybe<Scalars['uuid']>;
  city?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['uuid']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  first_name?: Maybe<Scalars['String']>;
  free_miles?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  order_id?: Maybe<Scalars['uuid']>;
  other_fees?: Maybe<Scalars['numeric']>;
  phone?: Maybe<Scalars['String']>;
  rental_period?: Maybe<Scalars['Int']>;
  reservation_number?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  state?: Maybe<Scalars['String']>;
  sub_total?: Maybe<Scalars['numeric']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "reservations" */
export type Reservations_Max_Order_By = {
  address?: Maybe<Order_By>;
  agent_id?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  memo?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  reservation_number?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reservations_Min_Fields = {
  address?: Maybe<Scalars['String']>;
  agent_id?: Maybe<Scalars['uuid']>;
  city?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['uuid']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  first_name?: Maybe<Scalars['String']>;
  free_miles?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  order_id?: Maybe<Scalars['uuid']>;
  other_fees?: Maybe<Scalars['numeric']>;
  phone?: Maybe<Scalars['String']>;
  rental_period?: Maybe<Scalars['Int']>;
  reservation_number?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  state?: Maybe<Scalars['String']>;
  sub_total?: Maybe<Scalars['numeric']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "reservations" */
export type Reservations_Min_Order_By = {
  address?: Maybe<Order_By>;
  agent_id?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  memo?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  reservation_number?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** response of any mutation on the table "reservations" */
export type Reservations_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reservations>;
};

/** on_conflict condition type for table "reservations" */
export type Reservations_On_Conflict = {
  constraint: Reservations_Constraint;
  update_columns?: Array<Reservations_Update_Column>;
  where?: Maybe<Reservations_Bool_Exp>;
};

/** Ordering options when selecting data from "reservations". */
export type Reservations_Order_By = {
  address?: Maybe<Order_By>;
  agent?: Maybe<Users_Order_By>;
  agent_id?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  client?: Maybe<Users_Order_By>;
  client_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  memo?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  order?: Maybe<Orders_Order_By>;
  order_id?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  reservation_number?: Maybe<Order_By>;
  reservation_status?: Maybe<Reservation_Status_Order_By>;
  sales_tax?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  tenant?: Maybe<Tenants_Order_By>;
  tenant_id?: Maybe<Order_By>;
  terms_accepted?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicle?: Maybe<Vehicles_Order_By>;
  vehicle_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** primary key columns input for table: reservations */
export type Reservations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reservations" */
export enum Reservations_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AgentId = 'agent_id',
  /** column name */
  City = 'city',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayRate = 'day_rate',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Discount = 'discount',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FreeMiles = 'free_miles',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Memo = 'memo',
  /** column name */
  MileRate = 'mile_rate',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OtherFees = 'other_fees',
  /** column name */
  Phone = 'phone',
  /** column name */
  RentalPeriod = 'rental_period',
  /** column name */
  ReservationNumber = 'reservation_number',
  /** column name */
  SalesTax = 'sales_tax',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  SubTotal = 'sub_total',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TermsAccepted = 'terms_accepted',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  Zip = 'zip'
}

/** select "reservations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "reservations" */
export enum Reservations_Select_Column_Reservations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  TermsAccepted = 'terms_accepted'
}

/** select "reservations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "reservations" */
export enum Reservations_Select_Column_Reservations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  TermsAccepted = 'terms_accepted'
}

/** input type for updating data in table "reservations" */
export type Reservations_Set_Input = {
  address?: Maybe<Scalars['String']>;
  agent_id?: Maybe<Scalars['uuid']>;
  city?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['uuid']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['time']>;
  first_name?: Maybe<Scalars['String']>;
  free_miles?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  order_id?: Maybe<Scalars['uuid']>;
  other_fees?: Maybe<Scalars['numeric']>;
  phone?: Maybe<Scalars['String']>;
  rental_period?: Maybe<Scalars['Int']>;
  reservation_number?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  start_time?: Maybe<Scalars['time']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Reservation_Status_Enum>;
  sub_total?: Maybe<Scalars['numeric']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  terms_accepted?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Reservations_Stddev_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  reservation_number?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "reservations" */
export type Reservations_Stddev_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  reservation_number?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reservations_Stddev_Pop_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  reservation_number?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "reservations" */
export type Reservations_Stddev_Pop_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  reservation_number?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reservations_Stddev_Samp_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  reservation_number?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "reservations" */
export type Reservations_Stddev_Samp_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  reservation_number?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** Streaming cursor of the table "reservations" */
export type Reservations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reservations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reservations_Stream_Cursor_Value_Input = {
  address?: Maybe<Scalars['String']>;
  agent_id?: Maybe<Scalars['uuid']>;
  city?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['uuid']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['time']>;
  first_name?: Maybe<Scalars['String']>;
  free_miles?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  order_id?: Maybe<Scalars['uuid']>;
  other_fees?: Maybe<Scalars['numeric']>;
  phone?: Maybe<Scalars['String']>;
  rental_period?: Maybe<Scalars['Int']>;
  reservation_number?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  start_time?: Maybe<Scalars['time']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Reservation_Status_Enum>;
  sub_total?: Maybe<Scalars['numeric']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  terms_accepted?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Reservations_Sum_Fields = {
  day_rate?: Maybe<Scalars['numeric']>;
  deposit?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['numeric']>;
  free_miles?: Maybe<Scalars['Int']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  other_fees?: Maybe<Scalars['numeric']>;
  rental_period?: Maybe<Scalars['Int']>;
  reservation_number?: Maybe<Scalars['Int']>;
  sales_tax?: Maybe<Scalars['numeric']>;
  sub_total?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "reservations" */
export type Reservations_Sum_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  reservation_number?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** update columns of table "reservations" */
export enum Reservations_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AgentId = 'agent_id',
  /** column name */
  City = 'city',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayRate = 'day_rate',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Discount = 'discount',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FreeMiles = 'free_miles',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Memo = 'memo',
  /** column name */
  MileRate = 'mile_rate',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OtherFees = 'other_fees',
  /** column name */
  Phone = 'phone',
  /** column name */
  RentalPeriod = 'rental_period',
  /** column name */
  ReservationNumber = 'reservation_number',
  /** column name */
  SalesTax = 'sales_tax',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  SubTotal = 'sub_total',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TermsAccepted = 'terms_accepted',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  Zip = 'zip'
}

export type Reservations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Reservations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Reservations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reservations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reservations_Var_Pop_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  reservation_number?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "reservations" */
export type Reservations_Var_Pop_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  reservation_number?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reservations_Var_Samp_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  reservation_number?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "reservations" */
export type Reservations_Var_Samp_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  reservation_number?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Reservations_Variance_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  free_miles?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  other_fees?: Maybe<Scalars['Float']>;
  rental_period?: Maybe<Scalars['Float']>;
  reservation_number?: Maybe<Scalars['Float']>;
  sales_tax?: Maybe<Scalars['Float']>;
  sub_total?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "reservations" */
export type Reservations_Variance_Order_By = {
  day_rate?: Maybe<Order_By>;
  deposit?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  free_miles?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  other_fees?: Maybe<Order_By>;
  rental_period?: Maybe<Order_By>;
  reservation_number?: Maybe<Order_By>;
  sales_tax?: Maybe<Order_By>;
  sub_total?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

export type Subscription_Root = {
  /** fetch data from the table: "order_policy_type" */
  order_policy_type: Array<Order_Policy_Type>;
  /** fetch aggregated fields from the table: "order_policy_type" */
  order_policy_type_aggregate: Order_Policy_Type_Aggregate;
  /** fetch data from the table: "order_policy_type" using primary key columns */
  order_policy_type_by_pk?: Maybe<Order_Policy_Type>;
  /** fetch data from the table in a streaming manner: "order_policy_type" */
  order_policy_type_stream: Array<Order_Policy_Type>;
  /** fetch data from the table: "order_states" */
  order_states: Array<Order_States>;
  /** fetch aggregated fields from the table: "order_states" */
  order_states_aggregate: Order_States_Aggregate;
  /** fetch data from the table: "order_states" using primary key columns */
  order_states_by_pk?: Maybe<Order_States>;
  /** fetch data from the table in a streaming manner: "order_states" */
  order_states_stream: Array<Order_States>;
  /** fetch data from the table: "order_status" */
  order_status: Array<Order_Status>;
  /** fetch aggregated fields from the table: "order_status" */
  order_status_aggregate: Order_Status_Aggregate;
  /** fetch data from the table: "order_status" using primary key columns */
  order_status_by_pk?: Maybe<Order_Status>;
  /** fetch data from the table in a streaming manner: "order_status" */
  order_status_stream: Array<Order_Status>;
  /** fetch data from the table: "order_steps" */
  order_steps: Array<Order_Steps>;
  /** fetch aggregated fields from the table: "order_steps" */
  order_steps_aggregate: Order_Steps_Aggregate;
  /** fetch data from the table: "order_steps" using primary key columns */
  order_steps_by_pk?: Maybe<Order_Steps>;
  /** fetch data from the table in a streaming manner: "order_steps" */
  order_steps_stream: Array<Order_Steps>;
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table in a streaming manner: "orders" */
  orders_stream: Array<Orders>;
  /** fetch data from the table: "reservation_status" */
  reservation_status: Array<Reservation_Status>;
  /** fetch aggregated fields from the table: "reservation_status" */
  reservation_status_aggregate: Reservation_Status_Aggregate;
  /** fetch data from the table: "reservation_status" using primary key columns */
  reservation_status_by_pk?: Maybe<Reservation_Status>;
  /** fetch data from the table in a streaming manner: "reservation_status" */
  reservation_status_stream: Array<Reservation_Status>;
  /** An array relationship */
  reservations: Array<Reservations>;
  /** An aggregate relationship */
  reservations_aggregate: Reservations_Aggregate;
  /** fetch data from the table: "reservations" using primary key columns */
  reservations_by_pk?: Maybe<Reservations>;
  /** fetch data from the table in a streaming manner: "reservations" */
  reservations_stream: Array<Reservations>;
  /** execute function "tenant_by_slug" which returns "tenants" */
  tenant_by_slug?: Maybe<Tenants>;
  /** execute function "tenant_by_slug" and query aggregates on result of table type "tenants" */
  tenant_by_slug_aggregate: Tenants_Aggregate;
  /** fetch data from the table: "tenant_stats" */
  tenant_stats: Array<Tenant_Stats>;
  /** fetch aggregated fields from the table: "tenant_stats" */
  tenant_stats_aggregate: Tenant_Stats_Aggregate;
  /** fetch data from the table in a streaming manner: "tenant_stats" */
  tenant_stats_stream: Array<Tenant_Stats>;
  /** fetch data from the table: "tenant_status" */
  tenant_status: Array<Tenant_Status>;
  /** fetch aggregated fields from the table: "tenant_status" */
  tenant_status_aggregate: Tenant_Status_Aggregate;
  /** fetch data from the table: "tenant_status" using primary key columns */
  tenant_status_by_pk?: Maybe<Tenant_Status>;
  /** fetch data from the table in a streaming manner: "tenant_status" */
  tenant_status_stream: Array<Tenant_Status>;
  /** fetch data from the table: "tenant_types" */
  tenant_types: Array<Tenant_Types>;
  /** fetch aggregated fields from the table: "tenant_types" */
  tenant_types_aggregate: Tenant_Types_Aggregate;
  /** fetch data from the table: "tenant_types" using primary key columns */
  tenant_types_by_pk?: Maybe<Tenant_Types>;
  /** fetch data from the table in a streaming manner: "tenant_types" */
  tenant_types_stream: Array<Tenant_Types>;
  /** An array relationship */
  tenants: Array<Tenants>;
  /** An aggregate relationship */
  tenants_aggregate: Tenants_Aggregate;
  /** fetch data from the table: "tenants" using primary key columns */
  tenants_by_pk?: Maybe<Tenants>;
  /** fetch data from the table in a streaming manner: "tenants" */
  tenants_stream: Array<Tenants>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table in a streaming manner: "user_roles" */
  user_roles_stream: Array<User_Roles>;
  /** fetch data from the table: "user_status" */
  user_status: Array<User_Status>;
  /** fetch aggregated fields from the table: "user_status" */
  user_status_aggregate: User_Status_Aggregate;
  /** fetch data from the table: "user_status" using primary key columns */
  user_status_by_pk?: Maybe<User_Status>;
  /** fetch data from the table in a streaming manner: "user_status" */
  user_status_stream: Array<User_Status>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** An array relationship */
  vehicles: Array<Vehicles>;
  /** An aggregate relationship */
  vehicles_aggregate: Vehicles_Aggregate;
  /** fetch data from the table: "vehicles" using primary key columns */
  vehicles_by_pk?: Maybe<Vehicles>;
  /** fetch data from the table: "vehicles_status" */
  vehicles_status: Array<Vehicles_Status>;
  /** fetch aggregated fields from the table: "vehicles_status" */
  vehicles_status_aggregate: Vehicles_Status_Aggregate;
  /** fetch data from the table: "vehicles_status" using primary key columns */
  vehicles_status_by_pk?: Maybe<Vehicles_Status>;
  /** fetch data from the table in a streaming manner: "vehicles_status" */
  vehicles_status_stream: Array<Vehicles_Status>;
  /** fetch data from the table in a streaming manner: "vehicles" */
  vehicles_stream: Array<Vehicles>;
};


export type Subscription_RootOrder_Policy_TypeArgs = {
  distinct_on?: Maybe<Array<Order_Policy_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Policy_Type_Order_By>>;
  where?: Maybe<Order_Policy_Type_Bool_Exp>;
};


export type Subscription_RootOrder_Policy_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Policy_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Policy_Type_Order_By>>;
  where?: Maybe<Order_Policy_Type_Bool_Exp>;
};


export type Subscription_RootOrder_Policy_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootOrder_Policy_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Order_Policy_Type_Stream_Cursor_Input>>;
  where?: Maybe<Order_Policy_Type_Bool_Exp>;
};


export type Subscription_RootOrder_StatesArgs = {
  distinct_on?: Maybe<Array<Order_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_States_Order_By>>;
  where?: Maybe<Order_States_Bool_Exp>;
};


export type Subscription_RootOrder_States_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_States_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_States_Order_By>>;
  where?: Maybe<Order_States_Bool_Exp>;
};


export type Subscription_RootOrder_States_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootOrder_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Order_States_Stream_Cursor_Input>>;
  where?: Maybe<Order_States_Bool_Exp>;
};


export type Subscription_RootOrder_StatusArgs = {
  distinct_on?: Maybe<Array<Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Status_Order_By>>;
  where?: Maybe<Order_Status_Bool_Exp>;
};


export type Subscription_RootOrder_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Status_Order_By>>;
  where?: Maybe<Order_Status_Bool_Exp>;
};


export type Subscription_RootOrder_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootOrder_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Order_Status_Stream_Cursor_Input>>;
  where?: Maybe<Order_Status_Bool_Exp>;
};


export type Subscription_RootOrder_StepsArgs = {
  distinct_on?: Maybe<Array<Order_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Steps_Order_By>>;
  where?: Maybe<Order_Steps_Bool_Exp>;
};


export type Subscription_RootOrder_Steps_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Steps_Order_By>>;
  where?: Maybe<Order_Steps_Bool_Exp>;
};


export type Subscription_RootOrder_Steps_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootOrder_Steps_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Order_Steps_Stream_Cursor_Input>>;
  where?: Maybe<Order_Steps_Bool_Exp>;
};


export type Subscription_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Orders_Stream_Cursor_Input>>;
  where?: Maybe<Orders_Bool_Exp>;
};


export type Subscription_RootReservation_StatusArgs = {
  distinct_on?: Maybe<Array<Reservation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Status_Order_By>>;
  where?: Maybe<Reservation_Status_Bool_Exp>;
};


export type Subscription_RootReservation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Status_Order_By>>;
  where?: Maybe<Reservation_Status_Bool_Exp>;
};


export type Subscription_RootReservation_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootReservation_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Reservation_Status_Stream_Cursor_Input>>;
  where?: Maybe<Reservation_Status_Bool_Exp>;
};


export type Subscription_RootReservationsArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


export type Subscription_RootReservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


export type Subscription_RootReservations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReservations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Reservations_Stream_Cursor_Input>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


export type Subscription_RootTenant_By_SlugArgs = {
  args: Tenant_By_Slug_Args;
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Subscription_RootTenant_By_Slug_AggregateArgs = {
  args: Tenant_By_Slug_Args;
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Subscription_RootTenant_StatsArgs = {
  distinct_on?: Maybe<Array<Tenant_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Stats_Order_By>>;
  where?: Maybe<Tenant_Stats_Bool_Exp>;
};


export type Subscription_RootTenant_Stats_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Stats_Order_By>>;
  where?: Maybe<Tenant_Stats_Bool_Exp>;
};


export type Subscription_RootTenant_Stats_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tenant_Stats_Stream_Cursor_Input>>;
  where?: Maybe<Tenant_Stats_Bool_Exp>;
};


export type Subscription_RootTenant_StatusArgs = {
  distinct_on?: Maybe<Array<Tenant_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Status_Order_By>>;
  where?: Maybe<Tenant_Status_Bool_Exp>;
};


export type Subscription_RootTenant_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Status_Order_By>>;
  where?: Maybe<Tenant_Status_Bool_Exp>;
};


export type Subscription_RootTenant_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTenant_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tenant_Status_Stream_Cursor_Input>>;
  where?: Maybe<Tenant_Status_Bool_Exp>;
};


export type Subscription_RootTenant_TypesArgs = {
  distinct_on?: Maybe<Array<Tenant_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Types_Order_By>>;
  where?: Maybe<Tenant_Types_Bool_Exp>;
};


export type Subscription_RootTenant_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenant_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenant_Types_Order_By>>;
  where?: Maybe<Tenant_Types_Bool_Exp>;
};


export type Subscription_RootTenant_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTenant_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tenant_Types_Stream_Cursor_Input>>;
  where?: Maybe<Tenant_Types_Bool_Exp>;
};


export type Subscription_RootTenantsArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Subscription_RootTenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Subscription_RootTenants_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTenants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Tenants_Stream_Cursor_Input>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


export type Subscription_RootUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Roles_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootUser_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<User_Roles_Stream_Cursor_Input>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_StatusArgs = {
  distinct_on?: Maybe<Array<User_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Status_Order_By>>;
  where?: Maybe<User_Status_Bool_Exp>;
};


export type Subscription_RootUser_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Status_Order_By>>;
  where?: Maybe<User_Status_Bool_Exp>;
};


export type Subscription_RootUser_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootUser_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<User_Status_Stream_Cursor_Input>>;
  where?: Maybe<User_Status_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Users_Stream_Cursor_Input>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootVehiclesArgs = {
  distinct_on?: Maybe<Array<Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Order_By>>;
  where?: Maybe<Vehicles_Bool_Exp>;
};


export type Subscription_RootVehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Order_By>>;
  where?: Maybe<Vehicles_Bool_Exp>;
};


export type Subscription_RootVehicles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVehicles_StatusArgs = {
  distinct_on?: Maybe<Array<Vehicles_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Status_Order_By>>;
  where?: Maybe<Vehicles_Status_Bool_Exp>;
};


export type Subscription_RootVehicles_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicles_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Status_Order_By>>;
  where?: Maybe<Vehicles_Status_Bool_Exp>;
};


export type Subscription_RootVehicles_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootVehicles_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vehicles_Status_Stream_Cursor_Input>>;
  where?: Maybe<Vehicles_Status_Bool_Exp>;
};


export type Subscription_RootVehicles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vehicles_Stream_Cursor_Input>>;
  where?: Maybe<Vehicles_Bool_Exp>;
};

export type Tenant_By_Slug_Args = {
  tenant_slug?: Maybe<Scalars['String']>;
};

/** columns and relationships of "tenant_stats" */
export type Tenant_Stats = {
  admins?: Maybe<Scalars['bigint']>;
  agents?: Maybe<Scalars['bigint']>;
  available_vehicles?: Maybe<Scalars['bigint']>;
  clients?: Maybe<Scalars['bigint']>;
  completed_orders?: Maybe<Scalars['bigint']>;
  dealers?: Maybe<Scalars['bigint']>;
  managers?: Maybe<Scalars['bigint']>;
  open_orders?: Maybe<Scalars['bigint']>;
  pending_orders?: Maybe<Scalars['bigint']>;
  rented_vehicles?: Maybe<Scalars['bigint']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  total_orders?: Maybe<Scalars['bigint']>;
  total_vehicles?: Maybe<Scalars['bigint']>;
  unavailabe_vehicles?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "tenant_stats" */
export type Tenant_Stats_Aggregate = {
  aggregate?: Maybe<Tenant_Stats_Aggregate_Fields>;
  nodes: Array<Tenant_Stats>;
};

/** aggregate fields of "tenant_stats" */
export type Tenant_Stats_Aggregate_Fields = {
  avg?: Maybe<Tenant_Stats_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tenant_Stats_Max_Fields>;
  min?: Maybe<Tenant_Stats_Min_Fields>;
  stddev?: Maybe<Tenant_Stats_Stddev_Fields>;
  stddev_pop?: Maybe<Tenant_Stats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tenant_Stats_Stddev_Samp_Fields>;
  sum?: Maybe<Tenant_Stats_Sum_Fields>;
  var_pop?: Maybe<Tenant_Stats_Var_Pop_Fields>;
  var_samp?: Maybe<Tenant_Stats_Var_Samp_Fields>;
  variance?: Maybe<Tenant_Stats_Variance_Fields>;
};


/** aggregate fields of "tenant_stats" */
export type Tenant_Stats_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tenant_Stats_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tenant_Stats_Avg_Fields = {
  admins?: Maybe<Scalars['Float']>;
  agents?: Maybe<Scalars['Float']>;
  available_vehicles?: Maybe<Scalars['Float']>;
  clients?: Maybe<Scalars['Float']>;
  completed_orders?: Maybe<Scalars['Float']>;
  dealers?: Maybe<Scalars['Float']>;
  managers?: Maybe<Scalars['Float']>;
  open_orders?: Maybe<Scalars['Float']>;
  pending_orders?: Maybe<Scalars['Float']>;
  rented_vehicles?: Maybe<Scalars['Float']>;
  total_orders?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  unavailabe_vehicles?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tenant_stats". All fields are combined with a logical 'AND'. */
export type Tenant_Stats_Bool_Exp = {
  _and?: Maybe<Array<Tenant_Stats_Bool_Exp>>;
  _not?: Maybe<Tenant_Stats_Bool_Exp>;
  _or?: Maybe<Array<Tenant_Stats_Bool_Exp>>;
  admins?: Maybe<Bigint_Comparison_Exp>;
  agents?: Maybe<Bigint_Comparison_Exp>;
  available_vehicles?: Maybe<Bigint_Comparison_Exp>;
  clients?: Maybe<Bigint_Comparison_Exp>;
  completed_orders?: Maybe<Bigint_Comparison_Exp>;
  dealers?: Maybe<Bigint_Comparison_Exp>;
  managers?: Maybe<Bigint_Comparison_Exp>;
  open_orders?: Maybe<Bigint_Comparison_Exp>;
  pending_orders?: Maybe<Bigint_Comparison_Exp>;
  rented_vehicles?: Maybe<Bigint_Comparison_Exp>;
  tenant_id?: Maybe<Uuid_Comparison_Exp>;
  total_orders?: Maybe<Bigint_Comparison_Exp>;
  total_vehicles?: Maybe<Bigint_Comparison_Exp>;
  unavailabe_vehicles?: Maybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "tenant_stats" */
export type Tenant_Stats_Inc_Input = {
  admins?: Maybe<Scalars['bigint']>;
  agents?: Maybe<Scalars['bigint']>;
  available_vehicles?: Maybe<Scalars['bigint']>;
  clients?: Maybe<Scalars['bigint']>;
  completed_orders?: Maybe<Scalars['bigint']>;
  dealers?: Maybe<Scalars['bigint']>;
  managers?: Maybe<Scalars['bigint']>;
  open_orders?: Maybe<Scalars['bigint']>;
  pending_orders?: Maybe<Scalars['bigint']>;
  rented_vehicles?: Maybe<Scalars['bigint']>;
  total_orders?: Maybe<Scalars['bigint']>;
  total_vehicles?: Maybe<Scalars['bigint']>;
  unavailabe_vehicles?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "tenant_stats" */
export type Tenant_Stats_Insert_Input = {
  admins?: Maybe<Scalars['bigint']>;
  agents?: Maybe<Scalars['bigint']>;
  available_vehicles?: Maybe<Scalars['bigint']>;
  clients?: Maybe<Scalars['bigint']>;
  completed_orders?: Maybe<Scalars['bigint']>;
  dealers?: Maybe<Scalars['bigint']>;
  managers?: Maybe<Scalars['bigint']>;
  open_orders?: Maybe<Scalars['bigint']>;
  pending_orders?: Maybe<Scalars['bigint']>;
  rented_vehicles?: Maybe<Scalars['bigint']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  total_orders?: Maybe<Scalars['bigint']>;
  total_vehicles?: Maybe<Scalars['bigint']>;
  unavailabe_vehicles?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Tenant_Stats_Max_Fields = {
  admins?: Maybe<Scalars['bigint']>;
  agents?: Maybe<Scalars['bigint']>;
  available_vehicles?: Maybe<Scalars['bigint']>;
  clients?: Maybe<Scalars['bigint']>;
  completed_orders?: Maybe<Scalars['bigint']>;
  dealers?: Maybe<Scalars['bigint']>;
  managers?: Maybe<Scalars['bigint']>;
  open_orders?: Maybe<Scalars['bigint']>;
  pending_orders?: Maybe<Scalars['bigint']>;
  rented_vehicles?: Maybe<Scalars['bigint']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  total_orders?: Maybe<Scalars['bigint']>;
  total_vehicles?: Maybe<Scalars['bigint']>;
  unavailabe_vehicles?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Tenant_Stats_Min_Fields = {
  admins?: Maybe<Scalars['bigint']>;
  agents?: Maybe<Scalars['bigint']>;
  available_vehicles?: Maybe<Scalars['bigint']>;
  clients?: Maybe<Scalars['bigint']>;
  completed_orders?: Maybe<Scalars['bigint']>;
  dealers?: Maybe<Scalars['bigint']>;
  managers?: Maybe<Scalars['bigint']>;
  open_orders?: Maybe<Scalars['bigint']>;
  pending_orders?: Maybe<Scalars['bigint']>;
  rented_vehicles?: Maybe<Scalars['bigint']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  total_orders?: Maybe<Scalars['bigint']>;
  total_vehicles?: Maybe<Scalars['bigint']>;
  unavailabe_vehicles?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "tenant_stats" */
export type Tenant_Stats_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant_Stats>;
};

/** input type for inserting object relation for remote table "tenant_stats" */
export type Tenant_Stats_Obj_Rel_Insert_Input = {
  data: Tenant_Stats_Insert_Input;
};

/** Ordering options when selecting data from "tenant_stats". */
export type Tenant_Stats_Order_By = {
  admins?: Maybe<Order_By>;
  agents?: Maybe<Order_By>;
  available_vehicles?: Maybe<Order_By>;
  clients?: Maybe<Order_By>;
  completed_orders?: Maybe<Order_By>;
  dealers?: Maybe<Order_By>;
  managers?: Maybe<Order_By>;
  open_orders?: Maybe<Order_By>;
  pending_orders?: Maybe<Order_By>;
  rented_vehicles?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  total_orders?: Maybe<Order_By>;
  total_vehicles?: Maybe<Order_By>;
  unavailabe_vehicles?: Maybe<Order_By>;
};

/** select columns of table "tenant_stats" */
export enum Tenant_Stats_Select_Column {
  /** column name */
  Admins = 'admins',
  /** column name */
  Agents = 'agents',
  /** column name */
  AvailableVehicles = 'available_vehicles',
  /** column name */
  Clients = 'clients',
  /** column name */
  CompletedOrders = 'completed_orders',
  /** column name */
  Dealers = 'dealers',
  /** column name */
  Managers = 'managers',
  /** column name */
  OpenOrders = 'open_orders',
  /** column name */
  PendingOrders = 'pending_orders',
  /** column name */
  RentedVehicles = 'rented_vehicles',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TotalOrders = 'total_orders',
  /** column name */
  TotalVehicles = 'total_vehicles',
  /** column name */
  UnavailabeVehicles = 'unavailabe_vehicles'
}

/** input type for updating data in table "tenant_stats" */
export type Tenant_Stats_Set_Input = {
  admins?: Maybe<Scalars['bigint']>;
  agents?: Maybe<Scalars['bigint']>;
  available_vehicles?: Maybe<Scalars['bigint']>;
  clients?: Maybe<Scalars['bigint']>;
  completed_orders?: Maybe<Scalars['bigint']>;
  dealers?: Maybe<Scalars['bigint']>;
  managers?: Maybe<Scalars['bigint']>;
  open_orders?: Maybe<Scalars['bigint']>;
  pending_orders?: Maybe<Scalars['bigint']>;
  rented_vehicles?: Maybe<Scalars['bigint']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  total_orders?: Maybe<Scalars['bigint']>;
  total_vehicles?: Maybe<Scalars['bigint']>;
  unavailabe_vehicles?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Tenant_Stats_Stddev_Fields = {
  admins?: Maybe<Scalars['Float']>;
  agents?: Maybe<Scalars['Float']>;
  available_vehicles?: Maybe<Scalars['Float']>;
  clients?: Maybe<Scalars['Float']>;
  completed_orders?: Maybe<Scalars['Float']>;
  dealers?: Maybe<Scalars['Float']>;
  managers?: Maybe<Scalars['Float']>;
  open_orders?: Maybe<Scalars['Float']>;
  pending_orders?: Maybe<Scalars['Float']>;
  rented_vehicles?: Maybe<Scalars['Float']>;
  total_orders?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  unavailabe_vehicles?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tenant_Stats_Stddev_Pop_Fields = {
  admins?: Maybe<Scalars['Float']>;
  agents?: Maybe<Scalars['Float']>;
  available_vehicles?: Maybe<Scalars['Float']>;
  clients?: Maybe<Scalars['Float']>;
  completed_orders?: Maybe<Scalars['Float']>;
  dealers?: Maybe<Scalars['Float']>;
  managers?: Maybe<Scalars['Float']>;
  open_orders?: Maybe<Scalars['Float']>;
  pending_orders?: Maybe<Scalars['Float']>;
  rented_vehicles?: Maybe<Scalars['Float']>;
  total_orders?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  unavailabe_vehicles?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tenant_Stats_Stddev_Samp_Fields = {
  admins?: Maybe<Scalars['Float']>;
  agents?: Maybe<Scalars['Float']>;
  available_vehicles?: Maybe<Scalars['Float']>;
  clients?: Maybe<Scalars['Float']>;
  completed_orders?: Maybe<Scalars['Float']>;
  dealers?: Maybe<Scalars['Float']>;
  managers?: Maybe<Scalars['Float']>;
  open_orders?: Maybe<Scalars['Float']>;
  pending_orders?: Maybe<Scalars['Float']>;
  rented_vehicles?: Maybe<Scalars['Float']>;
  total_orders?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  unavailabe_vehicles?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tenant_stats" */
export type Tenant_Stats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Stats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Stats_Stream_Cursor_Value_Input = {
  admins?: Maybe<Scalars['bigint']>;
  agents?: Maybe<Scalars['bigint']>;
  available_vehicles?: Maybe<Scalars['bigint']>;
  clients?: Maybe<Scalars['bigint']>;
  completed_orders?: Maybe<Scalars['bigint']>;
  dealers?: Maybe<Scalars['bigint']>;
  managers?: Maybe<Scalars['bigint']>;
  open_orders?: Maybe<Scalars['bigint']>;
  pending_orders?: Maybe<Scalars['bigint']>;
  rented_vehicles?: Maybe<Scalars['bigint']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  total_orders?: Maybe<Scalars['bigint']>;
  total_vehicles?: Maybe<Scalars['bigint']>;
  unavailabe_vehicles?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Tenant_Stats_Sum_Fields = {
  admins?: Maybe<Scalars['bigint']>;
  agents?: Maybe<Scalars['bigint']>;
  available_vehicles?: Maybe<Scalars['bigint']>;
  clients?: Maybe<Scalars['bigint']>;
  completed_orders?: Maybe<Scalars['bigint']>;
  dealers?: Maybe<Scalars['bigint']>;
  managers?: Maybe<Scalars['bigint']>;
  open_orders?: Maybe<Scalars['bigint']>;
  pending_orders?: Maybe<Scalars['bigint']>;
  rented_vehicles?: Maybe<Scalars['bigint']>;
  total_orders?: Maybe<Scalars['bigint']>;
  total_vehicles?: Maybe<Scalars['bigint']>;
  unavailabe_vehicles?: Maybe<Scalars['bigint']>;
};

export type Tenant_Stats_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Tenant_Stats_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tenant_Stats_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenant_Stats_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tenant_Stats_Var_Pop_Fields = {
  admins?: Maybe<Scalars['Float']>;
  agents?: Maybe<Scalars['Float']>;
  available_vehicles?: Maybe<Scalars['Float']>;
  clients?: Maybe<Scalars['Float']>;
  completed_orders?: Maybe<Scalars['Float']>;
  dealers?: Maybe<Scalars['Float']>;
  managers?: Maybe<Scalars['Float']>;
  open_orders?: Maybe<Scalars['Float']>;
  pending_orders?: Maybe<Scalars['Float']>;
  rented_vehicles?: Maybe<Scalars['Float']>;
  total_orders?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  unavailabe_vehicles?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tenant_Stats_Var_Samp_Fields = {
  admins?: Maybe<Scalars['Float']>;
  agents?: Maybe<Scalars['Float']>;
  available_vehicles?: Maybe<Scalars['Float']>;
  clients?: Maybe<Scalars['Float']>;
  completed_orders?: Maybe<Scalars['Float']>;
  dealers?: Maybe<Scalars['Float']>;
  managers?: Maybe<Scalars['Float']>;
  open_orders?: Maybe<Scalars['Float']>;
  pending_orders?: Maybe<Scalars['Float']>;
  rented_vehicles?: Maybe<Scalars['Float']>;
  total_orders?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  unavailabe_vehicles?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tenant_Stats_Variance_Fields = {
  admins?: Maybe<Scalars['Float']>;
  agents?: Maybe<Scalars['Float']>;
  available_vehicles?: Maybe<Scalars['Float']>;
  clients?: Maybe<Scalars['Float']>;
  completed_orders?: Maybe<Scalars['Float']>;
  dealers?: Maybe<Scalars['Float']>;
  managers?: Maybe<Scalars['Float']>;
  open_orders?: Maybe<Scalars['Float']>;
  pending_orders?: Maybe<Scalars['Float']>;
  rented_vehicles?: Maybe<Scalars['Float']>;
  total_orders?: Maybe<Scalars['Float']>;
  total_vehicles?: Maybe<Scalars['Float']>;
  unavailabe_vehicles?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "tenant_status" */
export type Tenant_Status = {
  /** An array relationship */
  tenants: Array<Tenants>;
  /** An aggregate relationship */
  tenants_aggregate: Tenants_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "tenant_status" */
export type Tenant_StatusTenantsArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


/** columns and relationships of "tenant_status" */
export type Tenant_StatusTenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};

/** aggregated selection of "tenant_status" */
export type Tenant_Status_Aggregate = {
  aggregate?: Maybe<Tenant_Status_Aggregate_Fields>;
  nodes: Array<Tenant_Status>;
};

/** aggregate fields of "tenant_status" */
export type Tenant_Status_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Tenant_Status_Max_Fields>;
  min?: Maybe<Tenant_Status_Min_Fields>;
};


/** aggregate fields of "tenant_status" */
export type Tenant_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tenant_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tenant_status". All fields are combined with a logical 'AND'. */
export type Tenant_Status_Bool_Exp = {
  _and?: Maybe<Array<Tenant_Status_Bool_Exp>>;
  _not?: Maybe<Tenant_Status_Bool_Exp>;
  _or?: Maybe<Array<Tenant_Status_Bool_Exp>>;
  tenants?: Maybe<Tenants_Bool_Exp>;
  tenants_aggregate?: Maybe<Tenants_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenant_status" */
export enum Tenant_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  TenantStatusPkey = 'tenant_status_pkey'
}

export enum Tenant_Status_Enum {
  Active = 'active',
  Inactive = 'inactive'
}

/** Boolean expression to compare columns of type "tenant_status_enum". All fields are combined with logical 'AND'. */
export type Tenant_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Tenant_Status_Enum>;
  _in?: Maybe<Array<Tenant_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Tenant_Status_Enum>;
  _nin?: Maybe<Array<Tenant_Status_Enum>>;
};

/** input type for inserting data into table "tenant_status" */
export type Tenant_Status_Insert_Input = {
  tenants?: Maybe<Tenants_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tenant_Status_Max_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tenant_Status_Min_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tenant_status" */
export type Tenant_Status_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant_Status>;
};

/** input type for inserting object relation for remote table "tenant_status" */
export type Tenant_Status_Obj_Rel_Insert_Input = {
  data: Tenant_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Tenant_Status_On_Conflict>;
};

/** on_conflict condition type for table "tenant_status" */
export type Tenant_Status_On_Conflict = {
  constraint: Tenant_Status_Constraint;
  update_columns?: Array<Tenant_Status_Update_Column>;
  where?: Maybe<Tenant_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "tenant_status". */
export type Tenant_Status_Order_By = {
  tenants_aggregate?: Maybe<Tenants_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: tenant_status */
export type Tenant_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "tenant_status" */
export enum Tenant_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "tenant_status" */
export type Tenant_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "tenant_status" */
export type Tenant_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Status_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "tenant_status" */
export enum Tenant_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type Tenant_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tenant_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenant_Status_Bool_Exp;
};

/** columns and relationships of "tenant_types" */
export type Tenant_Types = {
  /** An array relationship */
  tenants: Array<Tenants>;
  /** An aggregate relationship */
  tenants_aggregate: Tenants_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "tenant_types" */
export type Tenant_TypesTenantsArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


/** columns and relationships of "tenant_types" */
export type Tenant_TypesTenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};

/** aggregated selection of "tenant_types" */
export type Tenant_Types_Aggregate = {
  aggregate?: Maybe<Tenant_Types_Aggregate_Fields>;
  nodes: Array<Tenant_Types>;
};

/** aggregate fields of "tenant_types" */
export type Tenant_Types_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Tenant_Types_Max_Fields>;
  min?: Maybe<Tenant_Types_Min_Fields>;
};


/** aggregate fields of "tenant_types" */
export type Tenant_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tenant_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tenant_types". All fields are combined with a logical 'AND'. */
export type Tenant_Types_Bool_Exp = {
  _and?: Maybe<Array<Tenant_Types_Bool_Exp>>;
  _not?: Maybe<Tenant_Types_Bool_Exp>;
  _or?: Maybe<Array<Tenant_Types_Bool_Exp>>;
  tenants?: Maybe<Tenants_Bool_Exp>;
  tenants_aggregate?: Maybe<Tenants_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenant_types" */
export enum Tenant_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  TenantTypesPkey = 'tenant_types_pkey'
}

export enum Tenant_Types_Enum {
  Dealer = 'dealer',
  Root = 'root'
}

/** Boolean expression to compare columns of type "tenant_types_enum". All fields are combined with logical 'AND'. */
export type Tenant_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Tenant_Types_Enum>;
  _in?: Maybe<Array<Tenant_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Tenant_Types_Enum>;
  _nin?: Maybe<Array<Tenant_Types_Enum>>;
};

/** input type for inserting data into table "tenant_types" */
export type Tenant_Types_Insert_Input = {
  tenants?: Maybe<Tenants_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tenant_Types_Max_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tenant_Types_Min_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tenant_types" */
export type Tenant_Types_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant_Types>;
};

/** input type for inserting object relation for remote table "tenant_types" */
export type Tenant_Types_Obj_Rel_Insert_Input = {
  data: Tenant_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Tenant_Types_On_Conflict>;
};

/** on_conflict condition type for table "tenant_types" */
export type Tenant_Types_On_Conflict = {
  constraint: Tenant_Types_Constraint;
  update_columns?: Array<Tenant_Types_Update_Column>;
  where?: Maybe<Tenant_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "tenant_types". */
export type Tenant_Types_Order_By = {
  tenants_aggregate?: Maybe<Tenants_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: tenant_types */
export type Tenant_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "tenant_types" */
export enum Tenant_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "tenant_types" */
export type Tenant_Types_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "tenant_types" */
export type Tenant_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Types_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "tenant_types" */
export enum Tenant_Types_Update_Column {
  /** column name */
  Value = 'value'
}

export type Tenant_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tenant_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenant_Types_Bool_Exp;
};

/** columns and relationships of "tenants" */
export type Tenants = {
  address: Scalars['String'];
  billing: StripeCustomer;
  billing_email?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  latest_terms_accepted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  /** An object relationship */
  parent?: Maybe<Tenants>;
  parent_id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  /** An array relationship */
  reservations: Array<Reservations>;
  /** An aggregate relationship */
  reservations_aggregate: Reservations_Aggregate;
  slug: Scalars['String'];
  state: Scalars['String'];
  /** An object relationship */
  stats?: Maybe<Tenant_Stats>;
  status: Tenant_Status_Enum;
  stripe_id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  tenant_status: Tenant_Status;
  /** An object relationship */
  tenant_type: Tenant_Types;
  type: Tenant_Types_Enum;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  vehicle_policy_enabled?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  vehicles: Array<Vehicles>;
  /** An aggregate relationship */
  vehicles_aggregate: Vehicles_Aggregate;
  zip: Scalars['String'];
};


/** columns and relationships of "tenants" */
export type TenantsOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsReservationsArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsReservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsVehiclesArgs = {
  distinct_on?: Maybe<Array<Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Order_By>>;
  where?: Maybe<Vehicles_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsVehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Order_By>>;
  where?: Maybe<Vehicles_Bool_Exp>;
};

/** aggregated selection of "tenants" */
export type Tenants_Aggregate = {
  aggregate?: Maybe<Tenants_Aggregate_Fields>;
  nodes: Array<Tenants>;
};

export type Tenants_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Tenants_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Tenants_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Tenants_Aggregate_Bool_Exp_Count>;
};

export type Tenants_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tenants_Select_Column_Tenants_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Tenants_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenants_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tenants_Select_Column_Tenants_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Tenants_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenants_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Tenants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Tenants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenants" */
export type Tenants_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Tenants_Max_Fields>;
  min?: Maybe<Tenants_Min_Fields>;
};


/** aggregate fields of "tenants" */
export type Tenants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tenants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenants" */
export type Tenants_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tenants_Max_Order_By>;
  min?: Maybe<Tenants_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenants" */
export type Tenants_Arr_Rel_Insert_Input = {
  data: Array<Tenants_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Tenants_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenants". All fields are combined with a logical 'AND'. */
export type Tenants_Bool_Exp = {
  _and?: Maybe<Array<Tenants_Bool_Exp>>;
  _not?: Maybe<Tenants_Bool_Exp>;
  _or?: Maybe<Array<Tenants_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  billing_email?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  latest_terms_accepted?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  orders?: Maybe<Orders_Bool_Exp>;
  orders_aggregate?: Maybe<Orders_Aggregate_Bool_Exp>;
  parent?: Maybe<Tenants_Bool_Exp>;
  parent_id?: Maybe<Uuid_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  reservations?: Maybe<Reservations_Bool_Exp>;
  reservations_aggregate?: Maybe<Reservations_Aggregate_Bool_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  stats?: Maybe<Tenant_Stats_Bool_Exp>;
  status?: Maybe<Tenant_Status_Enum_Comparison_Exp>;
  stripe_id?: Maybe<String_Comparison_Exp>;
  subscription_id?: Maybe<String_Comparison_Exp>;
  tenant_status?: Maybe<Tenant_Status_Bool_Exp>;
  tenant_type?: Maybe<Tenant_Types_Bool_Exp>;
  type?: Maybe<Tenant_Types_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  users?: Maybe<Users_Bool_Exp>;
  users_aggregate?: Maybe<Users_Aggregate_Bool_Exp>;
  vehicle_policy_enabled?: Maybe<Boolean_Comparison_Exp>;
  vehicles?: Maybe<Vehicles_Bool_Exp>;
  vehicles_aggregate?: Maybe<Vehicles_Aggregate_Bool_Exp>;
  zip?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenants" */
export enum Tenants_Constraint {
  /** unique or primary key constraint on columns "name" */
  TenantsNameKey = 'tenants_name_key',
  /** unique or primary key constraint on columns "id" */
  TenantsPkey = 'tenants_pkey',
  /** unique or primary key constraint on columns "slug" */
  TenantsSlugKey = 'tenants_slug_key'
}

/** input type for inserting data into table "tenants" */
export type Tenants_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  latest_terms_accepted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Orders_Arr_Rel_Insert_Input>;
  parent?: Maybe<Tenants_Obj_Rel_Insert_Input>;
  parent_id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  reservations?: Maybe<Reservations_Arr_Rel_Insert_Input>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stats?: Maybe<Tenant_Stats_Obj_Rel_Insert_Input>;
  status?: Maybe<Tenant_Status_Enum>;
  stripe_id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  tenant_status?: Maybe<Tenant_Status_Obj_Rel_Insert_Input>;
  tenant_type?: Maybe<Tenant_Types_Obj_Rel_Insert_Input>;
  type?: Maybe<Tenant_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
  vehicle_policy_enabled?: Maybe<Scalars['Boolean']>;
  vehicles?: Maybe<Vehicles_Arr_Rel_Insert_Input>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tenants_Max_Fields = {
  address?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripe_id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tenants" */
export type Tenants_Max_Order_By = {
  address?: Maybe<Order_By>;
  billing_email?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  stripe_id?: Maybe<Order_By>;
  subscription_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tenants_Min_Fields = {
  address?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripe_id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tenants" */
export type Tenants_Min_Order_By = {
  address?: Maybe<Order_By>;
  billing_email?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  stripe_id?: Maybe<Order_By>;
  subscription_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** response of any mutation on the table "tenants" */
export type Tenants_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenants>;
};

/** input type for inserting object relation for remote table "tenants" */
export type Tenants_Obj_Rel_Insert_Input = {
  data: Tenants_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Tenants_On_Conflict>;
};

/** on_conflict condition type for table "tenants" */
export type Tenants_On_Conflict = {
  constraint: Tenants_Constraint;
  update_columns?: Array<Tenants_Update_Column>;
  where?: Maybe<Tenants_Bool_Exp>;
};

/** Ordering options when selecting data from "tenants". */
export type Tenants_Order_By = {
  address?: Maybe<Order_By>;
  billing_email?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_terms_accepted?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  parent?: Maybe<Tenants_Order_By>;
  parent_id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  reservations_aggregate?: Maybe<Reservations_Aggregate_Order_By>;
  slug?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  stats?: Maybe<Tenant_Stats_Order_By>;
  status?: Maybe<Order_By>;
  stripe_id?: Maybe<Order_By>;
  subscription_id?: Maybe<Order_By>;
  tenant_status?: Maybe<Tenant_Status_Order_By>;
  tenant_type?: Maybe<Tenant_Types_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
  vehicle_policy_enabled?: Maybe<Order_By>;
  vehicles_aggregate?: Maybe<Vehicles_Aggregate_Order_By>;
  zip?: Maybe<Order_By>;
};

/** primary key columns input for table: tenants */
export type Tenants_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tenants" */
export enum Tenants_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  BillingEmail = 'billing_email',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LatestTermsAccepted = 'latest_terms_accepted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Slug = 'slug',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  StripeId = 'stripe_id',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehiclePolicyEnabled = 'vehicle_policy_enabled',
  /** column name */
  Zip = 'zip'
}

/** select "tenants_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tenants" */
export enum Tenants_Select_Column_Tenants_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  LatestTermsAccepted = 'latest_terms_accepted',
  /** column name */
  VehiclePolicyEnabled = 'vehicle_policy_enabled'
}

/** select "tenants_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tenants" */
export enum Tenants_Select_Column_Tenants_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  LatestTermsAccepted = 'latest_terms_accepted',
  /** column name */
  VehiclePolicyEnabled = 'vehicle_policy_enabled'
}

/** input type for updating data in table "tenants" */
export type Tenants_Set_Input = {
  address?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  latest_terms_accepted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Tenant_Status_Enum>;
  stripe_id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  type?: Maybe<Tenant_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_policy_enabled?: Maybe<Scalars['Boolean']>;
  zip?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "tenants" */
export type Tenants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenants_Stream_Cursor_Value_Input = {
  address?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  latest_terms_accepted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Tenant_Status_Enum>;
  stripe_id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  type?: Maybe<Tenant_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_policy_enabled?: Maybe<Scalars['Boolean']>;
  zip?: Maybe<Scalars['String']>;
};

/** update columns of table "tenants" */
export enum Tenants_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  BillingEmail = 'billing_email',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LatestTermsAccepted = 'latest_terms_accepted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  Slug = 'slug',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  StripeId = 'stripe_id',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehiclePolicyEnabled = 'vehicle_policy_enabled',
  /** column name */
  Zip = 'zip'
}

export type Tenants_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Tenants_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenants_Bool_Exp;
};


/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: Maybe<Scalars['time']>;
  _gt?: Maybe<Scalars['time']>;
  _gte?: Maybe<Scalars['time']>;
  _in?: Maybe<Array<Scalars['time']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['time']>;
  _lte?: Maybe<Scalars['time']>;
  _neq?: Maybe<Scalars['time']>;
  _nin?: Maybe<Array<Scalars['time']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user_roles" */
export type User_Roles = {
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "user_roles" */
export type User_RolesUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "user_roles" */
export type User_RolesUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** aggregated selection of "user_roles" */
export type User_Roles_Aggregate = {
  aggregate?: Maybe<User_Roles_Aggregate_Fields>;
  nodes: Array<User_Roles>;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<User_Roles_Max_Fields>;
  min?: Maybe<User_Roles_Min_Fields>;
};


/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type User_Roles_Bool_Exp = {
  _and?: Maybe<Array<User_Roles_Bool_Exp>>;
  _not?: Maybe<User_Roles_Bool_Exp>;
  _or?: Maybe<Array<User_Roles_Bool_Exp>>;
  users?: Maybe<Users_Bool_Exp>;
  users_aggregate?: Maybe<Users_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_roles" */
export enum User_Roles_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserRolesPkey = 'user_roles_pkey'
}

export enum User_Roles_Enum {
  Admin = 'admin',
  Agent = 'agent',
  Client = 'client',
  Manager = 'manager'
}

/** Boolean expression to compare columns of type "user_roles_enum". All fields are combined with logical 'AND'. */
export type User_Roles_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Roles_Enum>;
  _in?: Maybe<Array<User_Roles_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<User_Roles_Enum>;
  _nin?: Maybe<Array<User_Roles_Enum>>;
};

/** input type for inserting data into table "user_roles" */
export type User_Roles_Insert_Input = {
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Roles_Max_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Roles_Min_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_roles" */
export type User_Roles_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Roles>;
};

/** input type for inserting object relation for remote table "user_roles" */
export type User_Roles_Obj_Rel_Insert_Input = {
  data: User_Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};

/** on_conflict condition type for table "user_roles" */
export type User_Roles_On_Conflict = {
  constraint: User_Roles_Constraint;
  update_columns?: Array<User_Roles_Update_Column>;
  where?: Maybe<User_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "user_roles". */
export type User_Roles_Order_By = {
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: user_roles */
export type User_Roles_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_roles" */
export type User_Roles_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_roles" */
export type User_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Roles_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "user_roles" */
export enum User_Roles_Update_Column {
  /** column name */
  Value = 'value'
}

export type User_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Roles_Bool_Exp;
};

/** columns and relationships of "user_status" */
export type User_Status = {
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "user_status" */
export type User_StatusUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "user_status" */
export type User_StatusUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** aggregated selection of "user_status" */
export type User_Status_Aggregate = {
  aggregate?: Maybe<User_Status_Aggregate_Fields>;
  nodes: Array<User_Status>;
};

/** aggregate fields of "user_status" */
export type User_Status_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<User_Status_Max_Fields>;
  min?: Maybe<User_Status_Min_Fields>;
};


/** aggregate fields of "user_status" */
export type User_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_status". All fields are combined with a logical 'AND'. */
export type User_Status_Bool_Exp = {
  _and?: Maybe<Array<User_Status_Bool_Exp>>;
  _not?: Maybe<User_Status_Bool_Exp>;
  _or?: Maybe<Array<User_Status_Bool_Exp>>;
  users?: Maybe<Users_Bool_Exp>;
  users_aggregate?: Maybe<Users_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_status" */
export enum User_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserStatusPkey = 'user_status_pkey'
}

export enum User_Status_Enum {
  Active = 'active',
  Inactive = 'inactive'
}

/** Boolean expression to compare columns of type "user_status_enum". All fields are combined with logical 'AND'. */
export type User_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Status_Enum>;
  _in?: Maybe<Array<User_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<User_Status_Enum>;
  _nin?: Maybe<Array<User_Status_Enum>>;
};

/** input type for inserting data into table "user_status" */
export type User_Status_Insert_Input = {
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Status_Max_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Status_Min_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_status" */
export type User_Status_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Status>;
};

/** input type for inserting object relation for remote table "user_status" */
export type User_Status_Obj_Rel_Insert_Input = {
  data: User_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<User_Status_On_Conflict>;
};

/** on_conflict condition type for table "user_status" */
export type User_Status_On_Conflict = {
  constraint: User_Status_Constraint;
  update_columns?: Array<User_Status_Update_Column>;
  where?: Maybe<User_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "user_status". */
export type User_Status_Order_By = {
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: user_status */
export type User_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "user_status" */
export enum User_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_status" */
export type User_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_status" */
export type User_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Status_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "user_status" */
export enum User_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type User_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Status_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  dob?: Maybe<Scalars['date']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  insurance_end_date?: Maybe<Scalars['date']>;
  insurance_image?: Maybe<Scalars['String']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurance_number?: Maybe<Scalars['String']>;
  insurance_start_date?: Maybe<Scalars['date']>;
  last_name: Scalars['String'];
  license_exp_date?: Maybe<Scalars['date']>;
  license_image_back?: Maybe<Scalars['String']>;
  license_image_front?: Maybe<Scalars['String']>;
  license_number?: Maybe<Scalars['String']>;
  license_state?: Maybe<Scalars['String']>;
  /** An array relationship */
  orders_agent: Array<Orders>;
  /** An aggregate relationship */
  orders_agent_aggregate: Orders_Aggregate;
  /** An array relationship */
  orders_client: Array<Orders>;
  /** An aggregate relationship */
  orders_client_aggregate: Orders_Aggregate;
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  /** An array relationship */
  reservations: Array<Reservations>;
  /** An aggregate relationship */
  reservations_aggregate: Reservations_Aggregate;
  role: User_Roles_Enum;
  state?: Maybe<Scalars['String']>;
  status: User_Status_Enum;
  /** An object relationship */
  tenant: Tenants;
  tenant_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_role: User_Roles;
  /** An object relationship */
  user_status: User_Status;
  zip?: Maybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type UsersOrders_AgentArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOrders_Agent_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOrders_ClientArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOrders_Client_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReservationsArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  count?: Maybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dob?: Maybe<Date_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  insurance_end_date?: Maybe<Date_Comparison_Exp>;
  insurance_image?: Maybe<String_Comparison_Exp>;
  insurance_name?: Maybe<String_Comparison_Exp>;
  insurance_number?: Maybe<String_Comparison_Exp>;
  insurance_start_date?: Maybe<Date_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  license_exp_date?: Maybe<Date_Comparison_Exp>;
  license_image_back?: Maybe<String_Comparison_Exp>;
  license_image_front?: Maybe<String_Comparison_Exp>;
  license_number?: Maybe<String_Comparison_Exp>;
  license_state?: Maybe<String_Comparison_Exp>;
  orders_agent?: Maybe<Orders_Bool_Exp>;
  orders_agent_aggregate?: Maybe<Orders_Aggregate_Bool_Exp>;
  orders_client?: Maybe<Orders_Bool_Exp>;
  orders_client_aggregate?: Maybe<Orders_Aggregate_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  picture?: Maybe<String_Comparison_Exp>;
  reservations?: Maybe<Reservations_Bool_Exp>;
  reservations_aggregate?: Maybe<Reservations_Aggregate_Bool_Exp>;
  role?: Maybe<User_Roles_Enum_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  status?: Maybe<User_Status_Enum_Comparison_Exp>;
  tenant?: Maybe<Tenants_Bool_Exp>;
  tenant_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_role?: Maybe<User_Roles_Bool_Exp>;
  user_status?: Maybe<User_Status_Bool_Exp>;
  zip?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_end_date?: Maybe<Scalars['date']>;
  insurance_image?: Maybe<Scalars['String']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurance_number?: Maybe<Scalars['String']>;
  insurance_start_date?: Maybe<Scalars['date']>;
  last_name?: Maybe<Scalars['String']>;
  license_exp_date?: Maybe<Scalars['date']>;
  license_image_back?: Maybe<Scalars['String']>;
  license_image_front?: Maybe<Scalars['String']>;
  license_number?: Maybe<Scalars['String']>;
  license_state?: Maybe<Scalars['String']>;
  orders_agent?: Maybe<Orders_Arr_Rel_Insert_Input>;
  orders_client?: Maybe<Orders_Arr_Rel_Insert_Input>;
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  reservations?: Maybe<Reservations_Arr_Rel_Insert_Input>;
  role?: Maybe<User_Roles_Enum>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<User_Status_Enum>;
  tenant?: Maybe<Tenants_Obj_Rel_Insert_Input>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_role?: Maybe<User_Roles_Obj_Rel_Insert_Input>;
  user_status?: Maybe<User_Status_Obj_Rel_Insert_Input>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_end_date?: Maybe<Scalars['date']>;
  insurance_image?: Maybe<Scalars['String']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurance_number?: Maybe<Scalars['String']>;
  insurance_start_date?: Maybe<Scalars['date']>;
  last_name?: Maybe<Scalars['String']>;
  license_exp_date?: Maybe<Scalars['date']>;
  license_image_back?: Maybe<Scalars['String']>;
  license_image_front?: Maybe<Scalars['String']>;
  license_number?: Maybe<Scalars['String']>;
  license_state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  address?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_end_date?: Maybe<Order_By>;
  insurance_image?: Maybe<Order_By>;
  insurance_name?: Maybe<Order_By>;
  insurance_number?: Maybe<Order_By>;
  insurance_start_date?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  license_exp_date?: Maybe<Order_By>;
  license_image_back?: Maybe<Order_By>;
  license_image_front?: Maybe<Order_By>;
  license_number?: Maybe<Order_By>;
  license_state?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  picture?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_end_date?: Maybe<Scalars['date']>;
  insurance_image?: Maybe<Scalars['String']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurance_number?: Maybe<Scalars['String']>;
  insurance_start_date?: Maybe<Scalars['date']>;
  last_name?: Maybe<Scalars['String']>;
  license_exp_date?: Maybe<Scalars['date']>;
  license_image_back?: Maybe<Scalars['String']>;
  license_image_front?: Maybe<Scalars['String']>;
  license_number?: Maybe<Scalars['String']>;
  license_state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  address?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_end_date?: Maybe<Order_By>;
  insurance_image?: Maybe<Order_By>;
  insurance_name?: Maybe<Order_By>;
  insurance_number?: Maybe<Order_By>;
  insurance_start_date?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  license_exp_date?: Maybe<Order_By>;
  license_image_back?: Maybe<Order_By>;
  license_image_front?: Maybe<Order_By>;
  license_number?: Maybe<Order_By>;
  license_state?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  picture?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  address?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_end_date?: Maybe<Order_By>;
  insurance_image?: Maybe<Order_By>;
  insurance_name?: Maybe<Order_By>;
  insurance_number?: Maybe<Order_By>;
  insurance_start_date?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  license_exp_date?: Maybe<Order_By>;
  license_image_back?: Maybe<Order_By>;
  license_image_front?: Maybe<Order_By>;
  license_number?: Maybe<Order_By>;
  license_state?: Maybe<Order_By>;
  orders_agent_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  orders_client_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
  picture?: Maybe<Order_By>;
  reservations_aggregate?: Maybe<Reservations_Aggregate_Order_By>;
  role?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tenant?: Maybe<Tenants_Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_role?: Maybe<User_Roles_Order_By>;
  user_status?: Maybe<User_Status_Order_By>;
  zip?: Maybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceEndDate = 'insurance_end_date',
  /** column name */
  InsuranceImage = 'insurance_image',
  /** column name */
  InsuranceName = 'insurance_name',
  /** column name */
  InsuranceNumber = 'insurance_number',
  /** column name */
  InsuranceStartDate = 'insurance_start_date',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LicenseExpDate = 'license_exp_date',
  /** column name */
  LicenseImageBack = 'license_image_back',
  /** column name */
  LicenseImageFront = 'license_image_front',
  /** column name */
  LicenseNumber = 'license_number',
  /** column name */
  LicenseState = 'license_state',
  /** column name */
  Phone = 'phone',
  /** column name */
  Picture = 'picture',
  /** column name */
  Role = 'role',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zip = 'zip'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_end_date?: Maybe<Scalars['date']>;
  insurance_image?: Maybe<Scalars['String']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurance_number?: Maybe<Scalars['String']>;
  insurance_start_date?: Maybe<Scalars['date']>;
  last_name?: Maybe<Scalars['String']>;
  license_exp_date?: Maybe<Scalars['date']>;
  license_image_back?: Maybe<Scalars['String']>;
  license_image_front?: Maybe<Scalars['String']>;
  license_number?: Maybe<Scalars['String']>;
  license_state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  role?: Maybe<User_Roles_Enum>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<User_Status_Enum>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_end_date?: Maybe<Scalars['date']>;
  insurance_image?: Maybe<Scalars['String']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurance_number?: Maybe<Scalars['String']>;
  insurance_start_date?: Maybe<Scalars['date']>;
  last_name?: Maybe<Scalars['String']>;
  license_exp_date?: Maybe<Scalars['date']>;
  license_image_back?: Maybe<Scalars['String']>;
  license_image_front?: Maybe<Scalars['String']>;
  license_number?: Maybe<Scalars['String']>;
  license_state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  role?: Maybe<User_Roles_Enum>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<User_Status_Enum>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip?: Maybe<Scalars['String']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceEndDate = 'insurance_end_date',
  /** column name */
  InsuranceImage = 'insurance_image',
  /** column name */
  InsuranceName = 'insurance_name',
  /** column name */
  InsuranceNumber = 'insurance_number',
  /** column name */
  InsuranceStartDate = 'insurance_start_date',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LicenseExpDate = 'license_exp_date',
  /** column name */
  LicenseImageBack = 'license_image_back',
  /** column name */
  LicenseImageFront = 'license_image_front',
  /** column name */
  LicenseNumber = 'license_number',
  /** column name */
  LicenseState = 'license_state',
  /** column name */
  Phone = 'phone',
  /** column name */
  Picture = 'picture',
  /** column name */
  Role = 'role',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zip = 'zip'
}

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "vehicles" */
export type Vehicles = {
  allow_reservations: Scalars['Boolean'];
  color: Scalars['String'];
  created_at: Scalars['timestamptz'];
  day_rate?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  insurance_end_date?: Maybe<Scalars['String']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurance_number?: Maybe<Scalars['String']>;
  insurance_start_date?: Maybe<Scalars['String']>;
  make: Scalars['String'];
  mile_rate?: Maybe<Scalars['numeric']>;
  miles?: Maybe<Scalars['float8']>;
  model: Scalars['String'];
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregate relationship */
  orders_aggregate: Orders_Aggregate;
  picture?: Maybe<Scalars['String']>;
  plate: Scalars['String'];
  rate_type: Scalars['String'];
  /** An array relationship */
  reservations: Array<Reservations>;
  /** An aggregate relationship */
  reservations_aggregate: Reservations_Aggregate;
  status: Vehicles_Status_Enum;
  /** An object relationship */
  tenant: Tenants;
  tenant_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicles_status: Vehicles_Status;
  vin?: Maybe<Scalars['String']>;
  year: Scalars['String'];
};


/** columns and relationships of "vehicles" */
export type VehiclesOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "vehicles" */
export type VehiclesOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "vehicles" */
export type VehiclesReservationsArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


/** columns and relationships of "vehicles" */
export type VehiclesReservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};

/** aggregated selection of "vehicles" */
export type Vehicles_Aggregate = {
  aggregate?: Maybe<Vehicles_Aggregate_Fields>;
  nodes: Array<Vehicles>;
};

export type Vehicles_Aggregate_Bool_Exp = {
  avg?: Maybe<Vehicles_Aggregate_Bool_Exp_Avg>;
  bool_and?: Maybe<Vehicles_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Vehicles_Aggregate_Bool_Exp_Bool_Or>;
  corr?: Maybe<Vehicles_Aggregate_Bool_Exp_Corr>;
  count?: Maybe<Vehicles_Aggregate_Bool_Exp_Count>;
  covar_samp?: Maybe<Vehicles_Aggregate_Bool_Exp_Covar_Samp>;
  max?: Maybe<Vehicles_Aggregate_Bool_Exp_Max>;
  min?: Maybe<Vehicles_Aggregate_Bool_Exp_Min>;
  stddev_samp?: Maybe<Vehicles_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: Maybe<Vehicles_Aggregate_Bool_Exp_Sum>;
  var_samp?: Maybe<Vehicles_Aggregate_Bool_Exp_Var_Samp>;
};

export type Vehicles_Aggregate_Bool_Exp_Avg = {
  arguments: Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vehicles_Aggregate_Bool_Exp_Bool_And = {
  arguments: Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vehicles_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vehicles_Aggregate_Bool_Exp_Corr = {
  arguments: Vehicles_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vehicles_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Vehicles_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Vehicles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Vehicles_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Vehicles_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vehicles_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Vehicles_Aggregate_Bool_Exp_Max = {
  arguments: Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vehicles_Aggregate_Bool_Exp_Min = {
  arguments: Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vehicles_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vehicles_Aggregate_Bool_Exp_Sum = {
  arguments: Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vehicles_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Vehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "vehicles" */
export type Vehicles_Aggregate_Fields = {
  avg?: Maybe<Vehicles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vehicles_Max_Fields>;
  min?: Maybe<Vehicles_Min_Fields>;
  stddev?: Maybe<Vehicles_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicles_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicles_Sum_Fields>;
  var_pop?: Maybe<Vehicles_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicles_Var_Samp_Fields>;
  variance?: Maybe<Vehicles_Variance_Fields>;
};


/** aggregate fields of "vehicles" */
export type Vehicles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vehicles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicles" */
export type Vehicles_Aggregate_Order_By = {
  avg?: Maybe<Vehicles_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Vehicles_Max_Order_By>;
  min?: Maybe<Vehicles_Min_Order_By>;
  stddev?: Maybe<Vehicles_Stddev_Order_By>;
  stddev_pop?: Maybe<Vehicles_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Vehicles_Stddev_Samp_Order_By>;
  sum?: Maybe<Vehicles_Sum_Order_By>;
  var_pop?: Maybe<Vehicles_Var_Pop_Order_By>;
  var_samp?: Maybe<Vehicles_Var_Samp_Order_By>;
  variance?: Maybe<Vehicles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vehicles" */
export type Vehicles_Arr_Rel_Insert_Input = {
  data: Array<Vehicles_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Vehicles_On_Conflict>;
};

/** aggregate avg on columns */
export type Vehicles_Avg_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vehicles" */
export type Vehicles_Avg_Order_By = {
  day_rate?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicles". All fields are combined with a logical 'AND'. */
export type Vehicles_Bool_Exp = {
  _and?: Maybe<Array<Vehicles_Bool_Exp>>;
  _not?: Maybe<Vehicles_Bool_Exp>;
  _or?: Maybe<Array<Vehicles_Bool_Exp>>;
  allow_reservations?: Maybe<Boolean_Comparison_Exp>;
  color?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  day_rate?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  insurance_end_date?: Maybe<String_Comparison_Exp>;
  insurance_name?: Maybe<String_Comparison_Exp>;
  insurance_number?: Maybe<String_Comparison_Exp>;
  insurance_start_date?: Maybe<String_Comparison_Exp>;
  make?: Maybe<String_Comparison_Exp>;
  mile_rate?: Maybe<Numeric_Comparison_Exp>;
  miles?: Maybe<Float8_Comparison_Exp>;
  model?: Maybe<String_Comparison_Exp>;
  orders?: Maybe<Orders_Bool_Exp>;
  orders_aggregate?: Maybe<Orders_Aggregate_Bool_Exp>;
  picture?: Maybe<String_Comparison_Exp>;
  plate?: Maybe<String_Comparison_Exp>;
  rate_type?: Maybe<String_Comparison_Exp>;
  reservations?: Maybe<Reservations_Bool_Exp>;
  reservations_aggregate?: Maybe<Reservations_Aggregate_Bool_Exp>;
  status?: Maybe<Vehicles_Status_Enum_Comparison_Exp>;
  tenant?: Maybe<Tenants_Bool_Exp>;
  tenant_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vehicles_status?: Maybe<Vehicles_Status_Bool_Exp>;
  vin?: Maybe<String_Comparison_Exp>;
  year?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicles" */
export enum Vehicles_Constraint {
  /** unique or primary key constraint on columns "id" */
  VehiclesPkey = 'vehicles_pkey'
}

/** input type for incrementing numeric columns in table "vehicles" */
export type Vehicles_Inc_Input = {
  day_rate?: Maybe<Scalars['numeric']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "vehicles" */
export type Vehicles_Insert_Input = {
  allow_reservations?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_end_date?: Maybe<Scalars['String']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurance_number?: Maybe<Scalars['String']>;
  insurance_start_date?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles?: Maybe<Scalars['float8']>;
  model?: Maybe<Scalars['String']>;
  orders?: Maybe<Orders_Arr_Rel_Insert_Input>;
  picture?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  rate_type?: Maybe<Scalars['String']>;
  reservations?: Maybe<Reservations_Arr_Rel_Insert_Input>;
  status?: Maybe<Vehicles_Status_Enum>;
  tenant?: Maybe<Tenants_Obj_Rel_Insert_Input>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicles_status?: Maybe<Vehicles_Status_Obj_Rel_Insert_Input>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Vehicles_Max_Fields = {
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_end_date?: Maybe<Scalars['String']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurance_number?: Maybe<Scalars['String']>;
  insurance_start_date?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles?: Maybe<Scalars['float8']>;
  model?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  rate_type?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "vehicles" */
export type Vehicles_Max_Order_By = {
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  day_rate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_end_date?: Maybe<Order_By>;
  insurance_name?: Maybe<Order_By>;
  insurance_number?: Maybe<Order_By>;
  insurance_start_date?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  picture?: Maybe<Order_By>;
  plate?: Maybe<Order_By>;
  rate_type?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicles_Min_Fields = {
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_end_date?: Maybe<Scalars['String']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurance_number?: Maybe<Scalars['String']>;
  insurance_start_date?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles?: Maybe<Scalars['float8']>;
  model?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  rate_type?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "vehicles" */
export type Vehicles_Min_Order_By = {
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  day_rate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_end_date?: Maybe<Order_By>;
  insurance_name?: Maybe<Order_By>;
  insurance_number?: Maybe<Order_By>;
  insurance_start_date?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  picture?: Maybe<Order_By>;
  plate?: Maybe<Order_By>;
  rate_type?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vin?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "vehicles" */
export type Vehicles_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicles>;
};

/** input type for inserting object relation for remote table "vehicles" */
export type Vehicles_Obj_Rel_Insert_Input = {
  data: Vehicles_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Vehicles_On_Conflict>;
};

/** on_conflict condition type for table "vehicles" */
export type Vehicles_On_Conflict = {
  constraint: Vehicles_Constraint;
  update_columns?: Array<Vehicles_Update_Column>;
  where?: Maybe<Vehicles_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicles". */
export type Vehicles_Order_By = {
  allow_reservations?: Maybe<Order_By>;
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  day_rate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_end_date?: Maybe<Order_By>;
  insurance_name?: Maybe<Order_By>;
  insurance_number?: Maybe<Order_By>;
  insurance_start_date?: Maybe<Order_By>;
  make?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles?: Maybe<Order_By>;
  model?: Maybe<Order_By>;
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  picture?: Maybe<Order_By>;
  plate?: Maybe<Order_By>;
  rate_type?: Maybe<Order_By>;
  reservations_aggregate?: Maybe<Reservations_Aggregate_Order_By>;
  status?: Maybe<Order_By>;
  tenant?: Maybe<Tenants_Order_By>;
  tenant_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vehicles_status?: Maybe<Vehicles_Status_Order_By>;
  vin?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: vehicles */
export type Vehicles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "vehicles" */
export enum Vehicles_Select_Column {
  /** column name */
  AllowReservations = 'allow_reservations',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayRate = 'day_rate',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceEndDate = 'insurance_end_date',
  /** column name */
  InsuranceName = 'insurance_name',
  /** column name */
  InsuranceNumber = 'insurance_number',
  /** column name */
  InsuranceStartDate = 'insurance_start_date',
  /** column name */
  Make = 'make',
  /** column name */
  MileRate = 'mile_rate',
  /** column name */
  Miles = 'miles',
  /** column name */
  Model = 'model',
  /** column name */
  Picture = 'picture',
  /** column name */
  Plate = 'plate',
  /** column name */
  RateType = 'rate_type',
  /** column name */
  Status = 'status',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Vin = 'vin',
  /** column name */
  Year = 'year'
}

/** select "vehicles_aggregate_bool_exp_avg_arguments_columns" columns of table "vehicles" */
export enum Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Miles = 'miles'
}

/** select "vehicles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "vehicles" */
export enum Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AllowReservations = 'allow_reservations'
}

/** select "vehicles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "vehicles" */
export enum Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AllowReservations = 'allow_reservations'
}

/** select "vehicles_aggregate_bool_exp_corr_arguments_columns" columns of table "vehicles" */
export enum Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Miles = 'miles'
}

/** select "vehicles_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "vehicles" */
export enum Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Miles = 'miles'
}

/** select "vehicles_aggregate_bool_exp_max_arguments_columns" columns of table "vehicles" */
export enum Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Miles = 'miles'
}

/** select "vehicles_aggregate_bool_exp_min_arguments_columns" columns of table "vehicles" */
export enum Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Miles = 'miles'
}

/** select "vehicles_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "vehicles" */
export enum Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Miles = 'miles'
}

/** select "vehicles_aggregate_bool_exp_sum_arguments_columns" columns of table "vehicles" */
export enum Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Miles = 'miles'
}

/** select "vehicles_aggregate_bool_exp_var_samp_arguments_columns" columns of table "vehicles" */
export enum Vehicles_Select_Column_Vehicles_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Miles = 'miles'
}

/** input type for updating data in table "vehicles" */
export type Vehicles_Set_Input = {
  allow_reservations?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_end_date?: Maybe<Scalars['String']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurance_number?: Maybe<Scalars['String']>;
  insurance_start_date?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles?: Maybe<Scalars['float8']>;
  model?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  rate_type?: Maybe<Scalars['String']>;
  status?: Maybe<Vehicles_Status_Enum>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** columns and relationships of "vehicles_status" */
export type Vehicles_Status = {
  value: Scalars['String'];
  /** An array relationship */
  vehicles: Array<Vehicles>;
  /** An aggregate relationship */
  vehicles_aggregate: Vehicles_Aggregate;
};


/** columns and relationships of "vehicles_status" */
export type Vehicles_StatusVehiclesArgs = {
  distinct_on?: Maybe<Array<Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Order_By>>;
  where?: Maybe<Vehicles_Bool_Exp>;
};


/** columns and relationships of "vehicles_status" */
export type Vehicles_StatusVehicles_AggregateArgs = {
  distinct_on?: Maybe<Array<Vehicles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Order_By>>;
  where?: Maybe<Vehicles_Bool_Exp>;
};

/** aggregated selection of "vehicles_status" */
export type Vehicles_Status_Aggregate = {
  aggregate?: Maybe<Vehicles_Status_Aggregate_Fields>;
  nodes: Array<Vehicles_Status>;
};

/** aggregate fields of "vehicles_status" */
export type Vehicles_Status_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Vehicles_Status_Max_Fields>;
  min?: Maybe<Vehicles_Status_Min_Fields>;
};


/** aggregate fields of "vehicles_status" */
export type Vehicles_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vehicles_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "vehicles_status". All fields are combined with a logical 'AND'. */
export type Vehicles_Status_Bool_Exp = {
  _and?: Maybe<Array<Vehicles_Status_Bool_Exp>>;
  _not?: Maybe<Vehicles_Status_Bool_Exp>;
  _or?: Maybe<Array<Vehicles_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
  vehicles?: Maybe<Vehicles_Bool_Exp>;
  vehicles_aggregate?: Maybe<Vehicles_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "vehicles_status" */
export enum Vehicles_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  VehiclesStatusPkey = 'vehicles_status_pkey'
}

export enum Vehicles_Status_Enum {
  Available = 'available',
  Deleted = 'deleted',
  Disabled = 'disabled',
  Rented = 'rented',
  Unavailable = 'unavailable'
}

/** Boolean expression to compare columns of type "vehicles_status_enum". All fields are combined with logical 'AND'. */
export type Vehicles_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Vehicles_Status_Enum>;
  _in?: Maybe<Array<Vehicles_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Vehicles_Status_Enum>;
  _nin?: Maybe<Array<Vehicles_Status_Enum>>;
};

/** input type for inserting data into table "vehicles_status" */
export type Vehicles_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
  vehicles?: Maybe<Vehicles_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vehicles_Status_Max_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Vehicles_Status_Min_Fields = {
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "vehicles_status" */
export type Vehicles_Status_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicles_Status>;
};

/** input type for inserting object relation for remote table "vehicles_status" */
export type Vehicles_Status_Obj_Rel_Insert_Input = {
  data: Vehicles_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Vehicles_Status_On_Conflict>;
};

/** on_conflict condition type for table "vehicles_status" */
export type Vehicles_Status_On_Conflict = {
  constraint: Vehicles_Status_Constraint;
  update_columns?: Array<Vehicles_Status_Update_Column>;
  where?: Maybe<Vehicles_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "vehicles_status". */
export type Vehicles_Status_Order_By = {
  value?: Maybe<Order_By>;
  vehicles_aggregate?: Maybe<Vehicles_Aggregate_Order_By>;
};

/** primary key columns input for table: vehicles_status */
export type Vehicles_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "vehicles_status" */
export enum Vehicles_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "vehicles_status" */
export type Vehicles_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "vehicles_status" */
export type Vehicles_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vehicles_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vehicles_Status_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "vehicles_status" */
export enum Vehicles_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type Vehicles_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vehicles_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicles_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Vehicles_Stddev_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vehicles" */
export type Vehicles_Stddev_Order_By = {
  day_rate?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vehicles_Stddev_Pop_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vehicles" */
export type Vehicles_Stddev_Pop_Order_By = {
  day_rate?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vehicles_Stddev_Samp_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vehicles" */
export type Vehicles_Stddev_Samp_Order_By = {
  day_rate?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles?: Maybe<Order_By>;
};

/** Streaming cursor of the table "vehicles" */
export type Vehicles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vehicles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vehicles_Stream_Cursor_Value_Input = {
  allow_reservations?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day_rate?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_end_date?: Maybe<Scalars['String']>;
  insurance_name?: Maybe<Scalars['String']>;
  insurance_number?: Maybe<Scalars['String']>;
  insurance_start_date?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles?: Maybe<Scalars['float8']>;
  model?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  rate_type?: Maybe<Scalars['String']>;
  status?: Maybe<Vehicles_Status_Enum>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Vehicles_Sum_Fields = {
  day_rate?: Maybe<Scalars['numeric']>;
  mile_rate?: Maybe<Scalars['numeric']>;
  miles?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "vehicles" */
export type Vehicles_Sum_Order_By = {
  day_rate?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles?: Maybe<Order_By>;
};

/** update columns of table "vehicles" */
export enum Vehicles_Update_Column {
  /** column name */
  AllowReservations = 'allow_reservations',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayRate = 'day_rate',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceEndDate = 'insurance_end_date',
  /** column name */
  InsuranceName = 'insurance_name',
  /** column name */
  InsuranceNumber = 'insurance_number',
  /** column name */
  InsuranceStartDate = 'insurance_start_date',
  /** column name */
  Make = 'make',
  /** column name */
  MileRate = 'mile_rate',
  /** column name */
  Miles = 'miles',
  /** column name */
  Model = 'model',
  /** column name */
  Picture = 'picture',
  /** column name */
  Plate = 'plate',
  /** column name */
  RateType = 'rate_type',
  /** column name */
  Status = 'status',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Vin = 'vin',
  /** column name */
  Year = 'year'
}

export type Vehicles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Vehicles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vehicles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vehicles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Vehicles_Var_Pop_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vehicles" */
export type Vehicles_Var_Pop_Order_By = {
  day_rate?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vehicles_Var_Samp_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vehicles" */
export type Vehicles_Var_Samp_Order_By = {
  day_rate?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Vehicles_Variance_Fields = {
  day_rate?: Maybe<Scalars['Float']>;
  mile_rate?: Maybe<Scalars['Float']>;
  miles?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vehicles" */
export type Vehicles_Variance_Order_By = {
  day_rate?: Maybe<Order_By>;
  mile_rate?: Maybe<Order_By>;
  miles?: Maybe<Order_By>;
};

export type ClientFragment = Pick<Users, 'id' | 'tenant_id' | 'status' | 'role' | 'email' | 'first_name' | 'last_name' | 'address' | 'city' | 'state' | 'zip' | 'phone' | 'country' | 'picture' | 'dob' | 'license_number' | 'license_state' | 'license_exp_date' | 'license_image_front' | 'license_image_back' | 'insurance_name' | 'insurance_number' | 'insurance_start_date' | 'insurance_end_date' | 'insurance_image' | 'created_at' | 'updated_at'>;

export type GetClientsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<Users_Select_Column> | Users_Select_Column>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By> | Users_Order_By>;
  where?: Maybe<Users_Bool_Exp>;
}>;


export type GetClientsQuery = { meta: { aggregate?: Maybe<Pick<Users_Aggregate_Fields, 'count'>> }, items: Array<ClientFragment> };

export type GetClientQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetClientQuery = { item?: Maybe<ClientFragment> };

export type CreateClientMutationVariables = Exact<{
  object: Users_Insert_Input;
}>;


export type CreateClientMutation = { item?: Maybe<Pick<Users, 'id'>> };

export type UpdateClientMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: Users_Set_Input;
}>;


export type UpdateClientMutation = { item?: Maybe<ClientFragment> };

export type DeleteClientMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteClientMutation = { item?: Maybe<Pick<Users, 'id'>> };

export type SyncGetClientsMetaSubscriptionVariables = Exact<{
  where?: Maybe<Users_Bool_Exp>;
}>;


export type SyncGetClientsMetaSubscription = { meta: { aggregate?: Maybe<Pick<Users_Aggregate_Fields, 'count'>> } };

export type SyncGetClientsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<Users_Select_Column> | Users_Select_Column>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By> | Users_Order_By>;
  where?: Maybe<Users_Bool_Exp>;
}>;


export type SyncGetClientsSubscription = { items: Array<ClientFragment> };

export type SyncGetClientSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SyncGetClientSubscription = { item?: Maybe<ClientFragment> };

export type OrderFragment = (
  Pick<Orders, 'id' | 'order_number' | 'tenant_id' | 'agent_id' | 'client_id' | 'vehicle_id' | 'state' | 'status' | 'step' | 'created_at' | 'updated_at' | 'start_date' | 'end_date' | 'start_time' | 'end_time' | 'extended_end_date' | 'contract_signed' | 'rental_period' | 'free_miles' | 'miles_in' | 'miles_out' | 'day_rate' | 'discount' | 'mile_rate' | 'sales_tax' | 'sub_total' | 'order_total' | 'fuel_in' | 'fuel_out' | 'contract_id' | 'contract_link' | 'contract_preview' | 'delivery_geo' | 'delivery_images' | 'delivery_notes' | 'delivery_videos' | 'pickup_geo' | 'pickup_images' | 'pickup_notes' | 'pickup_videos' | 'policy_type' | 'terms_accepted' | 'insurance_image' | 'license_images' | 'memo' | 'other_fees' | 'deposit'>
  & { tenant: Pick<Tenants, 'name'>, agent: Pick<Users, 'email' | 'first_name' | 'last_name'>, client?: Maybe<Pick<Users, 'id' | 'tenant_id' | 'status' | 'role' | 'email' | 'first_name' | 'last_name' | 'address' | 'country' | 'city' | 'state' | 'zip' | 'phone' | 'picture' | 'dob' | 'license_number' | 'license_state' | 'license_exp_date' | 'license_image_front' | 'license_image_back' | 'insurance_name' | 'insurance_number' | 'insurance_start_date' | 'insurance_end_date' | 'insurance_image' | 'created_at' | 'updated_at'>>, vehicle?: Maybe<Pick<Vehicles, 'id' | 'tenant_id' | 'status' | 'make' | 'model' | 'year' | 'color' | 'plate' | 'vin' | 'miles' | 'picture' | 'insurance_end_date' | 'insurance_name' | 'insurance_number' | 'insurance_start_date' | 'created_at' | 'updated_at' | 'allow_reservations' | 'day_rate' | 'mile_rate' | 'rate_type'>> }
);

export type GetOrderStatusQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetOrderStatusQuery = { status?: Maybe<Pick<GetOrderStatusOutput, 'state'>> };

export type GetOrdersQueryVariables = Exact<{
  distinct_on?: Maybe<Array<Orders_Select_Column> | Orders_Select_Column>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By> | Orders_Order_By>;
  where?: Maybe<Orders_Bool_Exp>;
}>;


export type GetOrdersQuery = { meta: { aggregate?: Maybe<Pick<Orders_Aggregate_Fields, 'count'>> }, items: Array<OrderFragment> };

export type GetOrderQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetOrderQuery = { item?: Maybe<OrderFragment> };

export type TriggerOrderEventMutationVariables = Exact<{
  event: Scalars['String'];
  order_id: Scalars['String'];
}>;


export type TriggerOrderEventMutation = { order?: Maybe<Pick<OrderTriggerEventOutput, 'id'>> };

export type GenerateContractMutationVariables = Exact<{
  object: GenerateContractInput;
}>;


export type GenerateContractMutation = { order?: Maybe<Pick<GenerateContractOutput, 'id'>> };

export type GenerateContractLinkMutationVariables = Exact<{
  object: GenerateContractInput;
}>;


export type GenerateContractLinkMutation = { order?: Maybe<Pick<GenerateContractOutput, 'id'>> };

export type CreateOrderMutationVariables = Exact<{
  object: Orders_Insert_Input;
}>;


export type CreateOrderMutation = { item?: Maybe<Pick<Orders, 'id'>> };

export type UpdateOrderMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: Orders_Set_Input;
}>;


export type UpdateOrderMutation = { item?: Maybe<OrderFragment> };

export type DeleteOrderMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteOrderMutation = { item?: Maybe<Pick<Orders, 'id'>> };

export type SyncGetOrdersMetaSubscriptionVariables = Exact<{
  where?: Maybe<Orders_Bool_Exp>;
}>;


export type SyncGetOrdersMetaSubscription = { meta: { aggregate?: Maybe<Pick<Orders_Aggregate_Fields, 'count'>> } };

export type SyncGetOrdersSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<Orders_Select_Column> | Orders_Select_Column>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By> | Orders_Order_By>;
  where?: Maybe<Orders_Bool_Exp>;
}>;


export type SyncGetOrdersSubscription = { items: Array<OrderFragment> };

export type SyncGetOrderSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SyncGetOrderSubscription = { item?: Maybe<OrderFragment> };

export type ReservationFragment = (
  Pick<Reservations, 'id' | 'status' | 'tenant_id' | 'vehicle_id' | 'client_id' | 'agent_id' | 'order_id' | 'email' | 'reservation_number' | 'first_name' | 'last_name' | 'phone' | 'dob' | 'address' | 'city' | 'state' | 'zip' | 'country' | 'start_date' | 'end_date' | 'start_time' | 'end_time' | 'rental_period' | 'free_miles' | 'day_rate' | 'mile_rate' | 'discount' | 'sales_tax' | 'sub_total' | 'total' | 'terms_accepted' | 'created_at' | 'updated_at' | 'memo' | 'other_fees' | 'deposit'>
  & { tenant: Pick<Tenants, 'id' | 'slug' | 'name' | 'address' | 'city' | 'state' | 'zip' | 'phone'>, client?: Maybe<Pick<Users, 'id' | 'tenant_id' | 'status' | 'role' | 'email' | 'first_name' | 'last_name' | 'address' | 'country' | 'city' | 'state' | 'zip' | 'phone' | 'picture' | 'dob' | 'license_number' | 'license_state' | 'license_exp_date' | 'license_image_front' | 'license_image_back' | 'insurance_name' | 'insurance_number' | 'insurance_start_date' | 'insurance_end_date' | 'insurance_image' | 'created_at' | 'updated_at'>>, agent?: Maybe<Pick<Users, 'id' | 'tenant_id' | 'status' | 'role' | 'email' | 'first_name' | 'last_name'>>, vehicle: Pick<Vehicles, 'id' | 'tenant_id' | 'status' | 'make' | 'model' | 'year' | 'color' | 'plate' | 'vin' | 'miles' | 'picture' | 'insurance_end_date' | 'insurance_name' | 'insurance_number' | 'insurance_start_date' | 'created_at' | 'updated_at' | 'allow_reservations' | 'day_rate' | 'mile_rate' | 'rate_type'> }
);

export type GetReservationsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<Reservations_Select_Column> | Reservations_Select_Column>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By> | Reservations_Order_By>;
  where?: Maybe<Reservations_Bool_Exp>;
}>;


export type GetReservationsQuery = { meta: { aggregate?: Maybe<Pick<Reservations_Aggregate_Fields, 'count'>> }, items: Array<ReservationFragment> };

export type CheckReservationEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckReservationEmailQuery = { items: Array<ReservationFragment> };

export type GetReservationQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationQuery = { item?: Maybe<ReservationFragment> };

export type CreateReservationMutationVariables = Exact<{
  object: Reservations_Insert_Input;
}>;


export type CreateReservationMutation = { item?: Maybe<Pick<Reservations, 'id'>> };

export type UpdateReservationMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: Reservations_Set_Input;
}>;


export type UpdateReservationMutation = { item?: Maybe<ReservationFragment> };

export type DeleteReservationMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteReservationMutation = { item?: Maybe<Pick<Reservations, 'id'>> };

export type SyncGetReservationsMetaSubscriptionVariables = Exact<{
  where?: Maybe<Reservations_Bool_Exp>;
}>;


export type SyncGetReservationsMetaSubscription = { meta: { aggregate?: Maybe<Pick<Reservations_Aggregate_Fields, 'count'>> } };

export type SyncGetReservationsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<Reservations_Select_Column> | Reservations_Select_Column>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By> | Reservations_Order_By>;
  where?: Maybe<Reservations_Bool_Exp>;
}>;


export type SyncGetReservationsSubscription = { items: Array<ReservationFragment> };

export type SyncGetReservationSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SyncGetReservationSubscription = { item?: Maybe<ReservationFragment> };

export type TenantFragment = (
  Pick<Tenants, 'id' | 'parent_id' | 'type' | 'status' | 'name' | 'slug' | 'address' | 'city' | 'state' | 'zip' | 'phone' | 'vehicle_policy_enabled' | 'latest_terms_accepted' | 'stripe_id' | 'subscription_id' | 'billing_email' | 'created_at' | 'updated_at'>
  & { stats?: Maybe<Pick<Tenant_Stats, 'dealers' | 'admins' | 'managers' | 'agents' | 'clients' | 'total_vehicles' | 'unavailabe_vehicles' | 'available_vehicles' | 'rented_vehicles' | 'total_orders' | 'pending_orders' | 'open_orders' | 'completed_orders'>> }
);

export type TenantBillingFragment = { billing: (
    Pick<StripeCustomer, 'id' | 'email' | 'balance' | 'delinquent'>
    & { subscriptions: { data: Array<(
        Pick<StripeSubscription, 'id' | 'description' | 'billingCycleAnchor' | 'canceledAt' | 'cancelAt' | 'cancelAtPeriodEnd' | 'currentPeriodEnd' | 'currentPeriodStart' | 'daysUntilDue' | 'endedAt' | 'startDate' | 'status' | 'trialEnd' | 'trialStart'>
        & { billingThresholds?: Maybe<Pick<StripeSubscriptionBillingThresholds, 'amountGte' | 'resetBillingCycleAnchor'>>, defaultPaymentMethod?: Maybe<Pick<StripePaymentMethod, 'id'>>, items: { data: Array<(
            Pick<StripeSubscriptionItem, 'created' | 'id' | 'quantity'>
            & { price: (
              Pick<StripePrice, 'active' | 'billingScheme' | 'created' | 'id' | 'nickname' | 'type' | 'unitAmount' | 'unitAmountDecimal'>
              & { product: Pick<StripeProduct, 'active' | 'description' | 'id' | 'images' | 'name' | 'type' | 'unitLabel'> }
            ), plan: Pick<StripePlan, 'usageType'> }
          )> } }
      )> }, paymentMethods: { data: Array<(
        Pick<StripePaymentMethod, 'id' | 'type'>
        & { card?: Maybe<Pick<StripePaymentMethodCard, 'brand' | 'expMonth' | 'expYear' | 'last4'>> }
      )> } }
  ) };

export type CheckSubdomainQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type CheckSubdomainQuery = { subdomain?: Maybe<Pick<CheckSubDomainOutput, 'taken'>> };

export type GetTenantsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<Tenants_Select_Column> | Tenants_Select_Column>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By> | Tenants_Order_By>;
  where?: Maybe<Tenants_Bool_Exp>;
}>;


export type GetTenantsQuery = { meta: { aggregate?: Maybe<Pick<Tenants_Aggregate_Fields, 'count'>> }, items: Array<TenantFragment> };

export type GetTenantBySlugQueryVariables = Exact<{
  tenant_slug: Scalars['String'];
}>;


export type GetTenantBySlugQuery = { tenant?: Maybe<(
    Pick<Tenants, 'id' | 'type' | 'name' | 'slug'>
    & { parent?: Maybe<Pick<Tenants, 'id' | 'type' | 'name' | 'slug'>> }
  )> };

export type GetTenantQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetTenantQuery = { item?: Maybe<TenantFragment> };

export type CreateTenantMutationVariables = Exact<{
  object: CreateTenantInput;
}>;


export type CreateTenantMutation = { item?: Maybe<Pick<CreateTenantOutput, 'id'>> };

export type UpdateTenantMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: Tenants_Set_Input;
}>;


export type UpdateTenantMutation = { item?: Maybe<TenantFragment> };

export type DeleteTenantMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteTenantMutation = { item?: Maybe<Pick<DeleteTenantOutput, 'id'>> };

export type SyncGetTenantsMetaSubscriptionVariables = Exact<{
  where?: Maybe<Tenants_Bool_Exp>;
}>;


export type SyncGetTenantsMetaSubscription = { meta: { aggregate?: Maybe<Pick<Tenants_Aggregate_Fields, 'count'>> } };

export type SyncGetTenantsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<Tenants_Select_Column> | Tenants_Select_Column>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By> | Tenants_Order_By>;
  where?: Maybe<Tenants_Bool_Exp>;
}>;


export type SyncGetTenantsSubscription = { items: Array<TenantFragment> };

export type SyncGetTenantSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SyncGetTenantSubscription = { item?: Maybe<TenantFragment> };

export type GetTenantBillingQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetTenantBillingQuery = { item?: Maybe<TenantBillingFragment> };

export type GetTenantVehiclesQueryVariables = Exact<{
  order_by?: Maybe<Array<Vehicles_Order_By> | Vehicles_Order_By>;
  where?: Maybe<Vehicles_Bool_Exp>;
}>;


export type GetTenantVehiclesQuery = { items: Array<Pick<Vehicles, 'id' | 'color' | 'make' | 'model' | 'picture' | 'status' | 'tenant_id' | 'year' | 'day_rate' | 'mile_rate' | 'rate_type'>> };

export type UserFragment = Pick<Users, 'id' | 'tenant_id' | 'status' | 'role' | 'email' | 'first_name' | 'last_name' | 'address' | 'city' | 'state' | 'zip' | 'phone' | 'created_at' | 'updated_at'>;

export type GetUsersQueryVariables = Exact<{
  distinct_on?: Maybe<Array<Users_Select_Column> | Users_Select_Column>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By> | Users_Order_By>;
  where?: Maybe<Users_Bool_Exp>;
}>;


export type GetUsersQuery = { meta: { aggregate?: Maybe<Pick<Users_Aggregate_Fields, 'count'>> }, items: Array<UserFragment> };

export type CheckUserEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckUserEmailQuery = { items: Array<UserFragment> };

export type GetUserQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserQuery = { item?: Maybe<UserFragment> };

export type CreateUserMutationVariables = Exact<{
  object: Users_Insert_Input;
}>;


export type CreateUserMutation = { item?: Maybe<Pick<Users, 'id'>> };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: Users_Set_Input;
}>;


export type UpdateUserMutation = { item?: Maybe<UserFragment> };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteUserMutation = { item?: Maybe<Pick<Users, 'id'>> };

export type SyncGetUsersMetaSubscriptionVariables = Exact<{
  where?: Maybe<Users_Bool_Exp>;
}>;


export type SyncGetUsersMetaSubscription = { meta: { aggregate?: Maybe<Pick<Users_Aggregate_Fields, 'count'>> } };

export type SyncGetUsersSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<Users_Select_Column> | Users_Select_Column>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By> | Users_Order_By>;
  where?: Maybe<Users_Bool_Exp>;
}>;


export type SyncGetUsersSubscription = { items: Array<UserFragment> };

export type SyncGetUserSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SyncGetUserSubscription = { item?: Maybe<UserFragment> };

export type VehicleFragment = (
  Pick<Vehicles, 'id' | 'tenant_id' | 'status' | 'make' | 'model' | 'year' | 'color' | 'plate' | 'vin' | 'miles' | 'picture' | 'insurance_end_date' | 'insurance_name' | 'insurance_number' | 'insurance_start_date' | 'created_at' | 'updated_at' | 'allow_reservations' | 'day_rate' | 'mile_rate' | 'rate_type'>
  & { open_reservations: Array<(
    Pick<Reservations, 'id' | 'status' | 'tenant_id' | 'vehicle_id' | 'client_id' | 'agent_id' | 'order_id' | 'email' | 'reservation_number' | 'first_name' | 'last_name' | 'start_date' | 'end_date' | 'start_time' | 'end_time' | 'rental_period' | 'created_at' | 'updated_at' | 'memo'>
    & { tenant: Pick<Tenants, 'id' | 'slug' | 'name' | 'address' | 'city' | 'state' | 'zip' | 'phone'>, client?: Maybe<Pick<Users, 'id' | 'tenant_id' | 'status' | 'email' | 'first_name' | 'last_name' | 'country' | 'city' | 'state' | 'phone'>>, agent?: Maybe<Pick<Users, 'id' | 'tenant_id' | 'status' | 'role' | 'email' | 'first_name' | 'last_name'>> }
  )>, open_orders: Array<(
    Pick<Orders, 'id' | 'status' | 'state' | 'step' | 'order_number' | 'rental_period' | 'start_date' | 'end_date' | 'created_at'>
    & { client?: Maybe<Pick<Users, 'id' | 'first_name' | 'last_name' | 'email'>> }
  )>, completed_orders: Array<(
    Pick<Orders, 'id' | 'status' | 'state' | 'step' | 'order_number' | 'rental_period' | 'start_date' | 'end_date' | 'created_at'>
    & { client?: Maybe<Pick<Users, 'id' | 'first_name' | 'last_name' | 'email'>> }
  )> }
);

export type GetVehiclesQueryVariables = Exact<{
  distinct_on?: Maybe<Array<Vehicles_Select_Column> | Vehicles_Select_Column>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Order_By> | Vehicles_Order_By>;
  where?: Maybe<Vehicles_Bool_Exp>;
}>;


export type GetVehiclesQuery = { meta: { aggregate?: Maybe<Pick<Vehicles_Aggregate_Fields, 'count'>> }, items: Array<VehicleFragment> };

export type GetVehicleQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetVehicleQuery = { item?: Maybe<VehicleFragment> };

export type CreateVehicleMutationVariables = Exact<{
  object: Vehicles_Insert_Input;
}>;


export type CreateVehicleMutation = { item?: Maybe<Pick<Vehicles, 'id'>> };

export type UpdateVehicleMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: Vehicles_Set_Input;
}>;


export type UpdateVehicleMutation = { item?: Maybe<VehicleFragment> };

export type DeleteVehicleMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteVehicleMutation = { item?: Maybe<Pick<Vehicles, 'id'>> };

export type SyncGetVehiclesMetaSubscriptionVariables = Exact<{
  where?: Maybe<Vehicles_Bool_Exp>;
}>;


export type SyncGetVehiclesMetaSubscription = { meta: { aggregate?: Maybe<Pick<Vehicles_Aggregate_Fields, 'count'>> } };

export type SyncGetVehiclesSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<Vehicles_Select_Column> | Vehicles_Select_Column>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vehicles_Order_By> | Vehicles_Order_By>;
  where?: Maybe<Vehicles_Bool_Exp>;
}>;


export type SyncGetVehiclesSubscription = { items: Array<VehicleFragment> };

export type SyncGetVehicleSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SyncGetVehicleSubscription = { item?: Maybe<VehicleFragment> };

import React from "react";
import StatsCard from "components/ui/StatsCard";
import { useActions, useAppState } from "state";
import { Grid } from "@mantine/core";

const RootDashboard = () => {

    const info = useAppState(state => state.tenant.info)
    const { getTenantInfo } = useActions().tenants

    React.useEffect(() => {
        getTenantInfo()
    }, [getTenantInfo])

    if (!info) return null;

    return (
        <Grid>
            <Grid.Col span={6} xl={4}>
                <StatsCard
                    label="Tenants"
                    icon="tenants"
                    value={info.stats?.dealers}
                    link="/tenants"
                />
            </Grid.Col>
            <Grid.Col span={6} xl={4}>
                <StatsCard
                    label="Users"
                    icon="agents"
                    value={info.stats?.admins}
                    link="/users"
                />
            </Grid.Col>
        </Grid>
    )

}

export default RootDashboard;
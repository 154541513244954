export type SessionInfoPayload = {
    accessTokenPayload: {
        tenant_id: string,
        user_role: string,
        tenant_slug: string,
        tenant_name: string,
        tenant_type: string,
    };
    sessionHandle: string;
    userId: string;
}

export const getSessionInfo = async (): Promise<SessionInfoPayload|false> => {

    try {

        let res = await fetch(process.env.REACT_APP_AUTH_SESSION_URL||'')
        let data = await res.json()
        return data

    } catch (e) {
        console.error(e)
        return false;
    }
}
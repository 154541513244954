export const decimalToPercentage = (value: number): string => {
    return ( Math.round( ( ( value * 100 ) + Number.EPSILON ) * 100 ) / 100 ).toString() + '%'
}

export const sanitizeNumber = (str: number | undefined): number => {
    if (str===null||typeof str === 'undefined') return str as unknown as number
    const regex = /[^0-9-.,()&@#!?$='"%]+/g; // regex to match all characters except alphanumeric and space
    const trimmedStr = str.toString().trim(); // remove spaces at beginning and end of the string
    const sanitizedStr = trimmedStr.replace(regex, ""); // replace all invalid characters with hyphens
    const singleSpacedStr = sanitizedStr.replace(/\s+/g, ""); // replace multiple spaces with single spaces
    return parseInt(singleSpacedStr); // return sanitized string
}

export const sanitizeDecimal = (str: number | undefined): number => {
    if (str===null||typeof str === 'undefined') return str as unknown as number
    const regex = /[^0-9-.,()&@#!?$='"%]+/g; // regex to match all characters except alphanumeric and space
    const trimmedStr = str.toString().trim(); // remove spaces at beginning and end of the string
    const sanitizedStr = trimmedStr.replace(regex, ""); // replace all invalid characters with hyphens
    const singleSpacedStr = sanitizedStr.replace(/\s+/g, ""); // replace multiple spaces with single spaces
    return parseFloat(singleSpacedStr); // return sanitized string
}
import React from "react"
import { Alert, Box, Card, Container, Stack, MediaQuery, Group, ScrollArea, Center, Button, Text, Menu, Divider } from "@mantine/core"
import { useMachine } from "@xstate/react"
import { State } from "xstate"
import { z } from "zod";
import { Icon } from "@iconify/react"
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import { useActions, useAppState } from "state"
import { OrderFragment, Order_Status_Enum } from "state/gql/_types"
import OrderMachine, { MachineEvent } from "state/orders/OrderMachine"

import { GenericDictionary } from "state/_types"

import { formatSnakeCase } from "utils/string"

import OrderProcessButton from "./components/OrderProcessButton"
import Icons from "components/ui/Icons"
import PageHeader from "components/layout/page/PageHeader"
import { TabDivider } from "components/ui/Tabs"
import Loader from "components/app/Loader";
import ModuleErrors from "components/ui/ModuleErrors";

import OrderSchemas from "./OrderSchemas"

import { SelectVehicleAction } from "./actions/SelectVehicle"
import { SelectClientAction } from "./actions/SelectClient"
import { VerifyClientDetailsAction } from "./actions/VerifyClientDetails";
import { VerifyLicenseAction } from "./actions/VerifyLicense";
import { SelectRentalTermsAction } from "./actions/SelectRentalTerms";
import { VerifyInsuranceAction } from "./actions/VerifyInsurance";
import { SelectRentalDatesAction } from "./actions/SelectRentalDates";
import { GetDeliveryLocationAction } from "./actions/GetDeliveryLocation";
import { GetPickUpLocationAction } from "./actions/GetPickUpLocation";
import { DeliveryInspectionAction } from "./actions/DeliveryInspection";
import { GenerateContractAction } from "./actions/GenerateContract";
import { PickUpInspectionAction } from "./actions/PickUpInspection";
import { SignContractAction } from "./actions/SignContract";

import VehicleInfoView from "./views/VehicleInfo"
import ClientInfoView from "./views/ClientInfo"
import ClientInsuranceInfoView from "./views/ClientInsuranceInfo";
import ClientLicenseInfoView from "./views/ClientLicenseInfo";
import RentalDatesInfoView from "./views/RentalDatesInfo";
import ClientDetailsInfoView from "./views/ClientDetailsInfo";
import RentalTermsInfoView from "./views/RentalTermsInfo";
import GetDeliveryLocation from "./views/GetDeliveryLocation";
import GetPickUpLocation from "./views/GetPickUpLocation";
import DeliveryInspection from "./views/DeliveryInspection";
import GenerateContractInfo from "./views/GenerateContract";
import SignContractInfo from "./views/SignContract";
import RentalActiveInfo from "./views/RentalActive";
import PickUpInspection from "./views/PickUpInspection";
import CompletedInfoView from "./views/CompletedInfo";

import { mergeMeta, orderStateTitleMap } from "./OrderUtils";
import TenantOrderPreview from "./TenantOrderPreview";
import TenantOrderStatus from "./TenantOrderStatus";
import TenantOrderSummary from "./TenantOrderSummary";


const getValidationObject = (state: keyof typeof OrderSchemas) => {

    const error = (message: string = 'Missing', alt: boolean = false) => {
        return !alt ? {
            invalid_type_error: message
        } : {
            message: message
        }
    }

    // const isBeforeToday = (date: string): boolean => {
    //     return dayjs(date).isBefore(dayjs())
    // }

    // const isAfterToday = (date: string): boolean => {
    //     return dayjs(date).isAfter(dayjs())
    // }

    const getPolicyError = (check: 'set' | 'before' | 'after', key: 'insurance_name' | 'insurance_number' | 'insurance_start_date' | 'insurance_end_date'): string => {
        let setErrorMap: { [index in 'insurance_name' | 'insurance_number' | 'insurance_start_date' | 'insurance_end_date']: string } = {
            insurance_name: 'Missing',
            insurance_number: 'Missing',
            insurance_start_date: 'Missing',
            insurance_end_date: 'Missing'
        }
        let beforeErrorMap: { [index in 'insurance_name' | 'insurance_number' | 'insurance_start_date' | 'insurance_end_date']: string } = {
            insurance_name: 'Missing',
            insurance_number: 'Missing',
            insurance_start_date: 'Missing',
            insurance_end_date: 'Missing'
        }
        let afterErrorMap: { [index in 'insurance_name' | 'insurance_number' | 'insurance_start_date' | 'insurance_end_date']: string } = {
            insurance_name: 'Missing',
            insurance_number: 'Missing',
            insurance_start_date: 'Insurance is Inactive',
            insurance_end_date: 'Insurance is Expired'
        }
        if (check === 'set') {
            return setErrorMap[key]
        }
        if (check === 'before') {
            return beforeErrorMap[key]
        }
        if (check === 'after') {
            return afterErrorMap[key]
        }
        return 'Missing'
    }

    const checkAgainstPolicyType = (check: 'set' | 'before' | 'after', type: any, data: { client: any, vehicle: any }, key: 'insurance_name' | 'insurance_number' | 'insurance_start_date' | 'insurance_end_date', returnError: boolean = false): boolean => {
        if (check === 'set') {
            switch (type) {
                case 'renter':
                    return !!data.client
                case 'vehicle':
                    return !!data.vehicle
                default:
                    return false
            }
        }
        if (check === 'before') {
            switch (type) {
                case 'renter':
                    return !!data.client
                case 'vehicle':
                    return !!data.vehicle
                default:
                    return false
            }
        }
        if (check === 'after') {
            switch (type) {
                case 'renter':
                    return !!data.client
                case 'vehicle':
                    return !!data.vehicle
                default:
                    return false
            }
        }
        return false
    }

    const validators: { [index: string]: any } = {
        select_vehicle: z.object({
            vehicle_id: z.string(error("No Vehicle Selected"))
        }),
        select_client: z.object({
            client_id: z.string(error("No Client Selected")),
        }),
        verify_client_details: z.object({
            client: z.object({
                address: z.string(error()),
                country: z.string(error()),
                city: z.string(error()),
                state: z.string(error()),
                zip: z.string(error()),
                phone: z.string(error()),
            })
        }),
        verify_license: z.object({
            client: z.object({
                license_number: z.string(error()),
                license_state: z.string(error()),
                license_image_front: z.string(error()),
                license_image_back: z.string(error()),
                license_exp_date: z.string(error()).refine((val) => dayjs(val).isAfter(dayjs()), error("License is Expired", true)),
                dob: z.string(error()),
            })
        }),
        verify_insurance: z.object({
            policy_type: z.any().refine((val) => { return val !== 'none' }, error('Missing', true)),
            client: z.object({
                insurance_name: z.any(),
                insurance_number: z.any(),
                insurance_start_date: z.any(),
                insurance_end_date: z.any(),
                insurance_image: z.any(),
                // insurance_start_date: z.string(error()).refine((val) => dayjs(val).isBefore(dayjs()), error("Insurance is Inactive", true)),
                // insurance_end_date: z.string(error()).refine((val) => dayjs(val).isAfter(dayjs()), error("Insurance is Expired", true)),
            }),
            vehicle: z.object({
                insurance_name: z.any(),
                insurance_number: z.any(),
                insurance_start_date: z.any(),
                insurance_end_date: z.any(),
            })
        })
            .refine(
                (val) => {
                    return checkAgainstPolicyType('set', val.policy_type, { client: val.client.insurance_name, vehicle: val.vehicle.insurance_name }, 'insurance_name')
                },
                () => ({
                    message: getPolicyError('set', 'insurance_name'),
                    path: ["insurance_name"]
                })
            )
            .refine(
                (val) => {
                    return checkAgainstPolicyType('set', val.policy_type, { client: val.client.insurance_number, vehicle: val.vehicle.insurance_number }, 'insurance_number')
                },
                () => ({
                    message: getPolicyError('set', 'insurance_number'),
                    path: ["insurance_number"]
                })
            )
            .refine(
                (val) => {
                    let isSet = checkAgainstPolicyType('set', val.policy_type, { client: val.client.insurance_start_date, vehicle: val.vehicle.insurance_start_date }, 'insurance_start_date')
                    if (isSet) {
                        return checkAgainstPolicyType('before', val.policy_type, { client: val.client.insurance_start_date, vehicle: val.vehicle.insurance_start_date }, 'insurance_start_date')
                    } else {
                        return false
                    }
                },
                (val) => {
                    let isSet = checkAgainstPolicyType('set', val.policy_type, { client: val.client.insurance_start_date, vehicle: val.vehicle.insurance_start_date }, 'insurance_start_date')
                    if (isSet) {
                        let isBefore = checkAgainstPolicyType('before', val.policy_type, { client: val.client.insurance_start_date, vehicle: val.vehicle.insurance_start_date }, 'insurance_start_date')
                        if (isBefore) {
                            return {
                                message: getPolicyError('before', 'insurance_start_date'),
                                path: ["insurance_start_date"]
                            }
                        }
                    }
                    return {
                        message: getPolicyError('set', 'insurance_start_date'),
                        path: ["insurance_start_date"]
                    }
                }
            )
            .refine(
                (val) => {
                    let isSet = checkAgainstPolicyType('set', val.policy_type, { client: val.client.insurance_end_date, vehicle: val.vehicle.insurance_end_date }, 'insurance_end_date')
                    if (isSet) {
                        return checkAgainstPolicyType('after', val.policy_type, { client: val.client.insurance_end_date, vehicle: val.vehicle.insurance_end_date }, 'insurance_end_date')
                    } else {
                        return false
                    }
                },
                (val) => {
                    let isSet = checkAgainstPolicyType('set', val.policy_type, { client: val.client.insurance_end_date, vehicle: val.vehicle.insurance_end_date }, 'insurance_end_date')
                    if (isSet) {
                        let isBefore = checkAgainstPolicyType('after', val.policy_type, { client: val.client.insurance_end_date, vehicle: val.vehicle.insurance_end_date }, 'insurance_end_date')
                        if (isBefore) {
                            return {
                                message: getPolicyError('after', 'insurance_end_date'),
                                path: ["insurance_end_date"]
                            }
                        }
                    }
                    return {
                        message: getPolicyError('set', 'insurance_end_date'),
                        path: ["insurance_end_date"]
                    }
                }
            )
            .refine(
                (val) => {
                    switch (val.policy_type) {
                        case 'renter':
                            return !!val.client.insurance_image
                        default:
                            return true
                    }
                },
                { message: "Insurance Photo Missing", path: ["insurance_image"] }
            ),
        select_rental_dates: z.object({
            start_date: z.string(error()),
            end_date: z.string(error()),
            start_time: z.string(error()),
            end_time: z.string(error()),
            rental_period: z.number(error()).gte(1, error('Rental Period is Too Short', true)),
        }),
        select_rental_terms: z.object({
            day_rate: z.number(error()),
            discount: z.number(error()),
            sales_tax: z.number(error()),
            order_total: z.number(error()),
        }),
        initiate_delivery: z.object({
            delivery_geo: z.string(error()),
        }),
        start_delivery_inspection: z.object({
            fuel_out: z.string(error()),
            miles_out: z.number(error()),
            delivery_images: z.string(error()),
            delivery_videos: z.string(error()),
        }),
        generate_contract: z.object({
            contract_id: z.string(error()),
            // contract_preview: z.string(error()),
        }),
        sign_contract: z.object({
            contract_link: z.string(error()),
            contract_signed: z.boolean(error()).refine((val) => val === true, error("Contract Has Not Been Signed", true)),
        }),
        rental_active: z.object({

        }),
        extend_rental: z.object({
            extended_end_date: z.string(error()),
        }),
        report_incident: z.object({

        }),
        initiate_pickup: z.object({
            pickup_geo: z.string(error()),
        }),
        start_pickup_inspection: z.object({
            fuel_in: z.string(error()),
            miles_in: z.number(error()),
            pickup_images: z.string(error()),
            pickup_videos: z.string(error()),
        }),
    }

    return validators.hasOwnProperty(state) ? validators[state] : false

}

type OrderWizardProps = {
    order: OrderFragment
}

type OrderWizardMachineProps = {
    order: OrderFragment,
    status: any;
}



const OrderWizardMachine: React.FC<OrderWizardMachineProps> = ({ order, status }) => {

    const { triggerOrderEvent } = useActions().orders

    const OrderMachineInstance = React.useMemo(
        () => OrderMachine.withConfig({
            actions: {
                updateVehicleMeta: (context, event) => { console.log('evento', event) }
            }
        }), [])

    const [state, send] = useMachine(OrderMachineInstance, {
        state: OrderMachine.resolveState(State.create(status))
    })

    const [isStepValid, setStepValid] = React.useState(false)
    const [stepErrors, setStepErrors] = React.useState<string[]>([])
    const [invalidFields, setInvalidFields] = React.useState<Record<string, string[]>>({})

    const triggerEvent = async (event: string) => {
        try {
            await triggerOrderEvent({
                order_id: order.id,
                event: event
            });
            send(event as MachineEvent)
        } catch (e) {
            console.error(e)
        }
    }

    React.useEffect(() => {
        const validateStep = async () => {

            let validator = getValidationObject(state.value as keyof typeof OrderSchemas)

            if (!validator) {
                setStepErrors([])
                return;
            }

            let result = await validator.safeParse(order)

            let invalidFieldsObj: Record<string, string[]> = {}

            if (!result.success) {

                let allErrors: GenericDictionary = result.error.format()

                let stateErrors = Object.keys(allErrors).reduce<string[]>((errors, key) => {

                    let errorObject = allErrors[key];

                    if (key === '_errors') {
                        return [...errors]
                    }

                    let stateSubErrors = Object.keys(errorObject).reduce<string[]>((subErrors, subKey) => {

                        let subErrorObject = errorObject[subKey];

                        let subCriticalErrors: string[] = []

                        if (subKey === '_errors') {

                            subErrorObject.forEach((error: string) => {

                                if (error !== 'Missing') {
                                    subCriticalErrors.push(error)
                                }
                                if (errors.indexOf('Missing Required Information') < 0 || errors.indexOf('Missing Required Information') < 0) {
                                    subCriticalErrors.push('Missing Required Information')
                                }
                                if (!invalidFieldsObj.hasOwnProperty(key)) {
                                    invalidFieldsObj[key] = [error]
                                } else {
                                    invalidFieldsObj[key].push(error)
                                }
                            })

                            return [...subErrors, ...subCriticalErrors]
                        }
                        if (subErrorObject._errors && Array.isArray(subErrorObject._errors)) {

                            subErrorObject._errors.forEach((error: string) => {
                                if (error !== 'Missing') {
                                    subCriticalErrors.push(error)
                                }
                                if (subErrors.indexOf('Missing Required Information') < 0) {
                                    subCriticalErrors.push('Missing Required Information')
                                }
                                if (!invalidFieldsObj.hasOwnProperty(subKey)) {
                                    invalidFieldsObj[subKey] = [error]
                                } else {
                                    invalidFieldsObj[subKey].push(error)
                                }
                            })

                            return [...subErrors, ...subCriticalErrors]
                        }

                        return subErrors;

                    }, [])

                    if (stateSubErrors.length) {
                        return [...errors, ...stateSubErrors]
                    }

                    return errors;

                }, [])

                setInvalidFields(invalidFieldsObj)
                setStepErrors(stateErrors)
                setStepValid(false)

            } else {
                setInvalidFields({})
                setStepErrors([])
                setStepValid(true)
            }
        }
        validateStep()
    }, [order, state.value])

    const renderView = () => {

        switch (state.value) {

            case 'select_vehicle':
                return <VehicleInfoView order={order} invalidFields={invalidFields} />

            case 'select_client':
                return <ClientInfoView order={order} invalidFields={invalidFields} />

            case 'verify_client_details':
                return <ClientDetailsInfoView order={order} invalidFields={invalidFields} />

            case 'verify_license':
                return <ClientLicenseInfoView order={order} invalidFields={invalidFields} />

            case 'verify_insurance':
                return <ClientInsuranceInfoView order={order} invalidFields={invalidFields} />

            case 'select_rental_dates':
                return <RentalDatesInfoView order={order} invalidFields={invalidFields} />

            case 'select_rental_terms':
                return <RentalTermsInfoView order={order} invalidFields={invalidFields} />

            case 'initiate_delivery':
                return <GetDeliveryLocation order={order} invalidFields={invalidFields} />

            case 'start_delivery_inspection':
                return <DeliveryInspection order={order} invalidFields={invalidFields} />

            case 'generate_contract':
                return <GenerateContractInfo order={order} invalidFields={invalidFields} />

            case 'sign_contract':
                return <SignContractInfo order={order} invalidFields={invalidFields} />

            case 'rental_active':
                return <RentalActiveInfo order={order} invalidFields={invalidFields} />

            case 'initiate_pickup':
                return <GetPickUpLocation order={order} invalidFields={invalidFields} />

            case 'start_pickup_inspection':
                return <PickUpInspection order={order} invalidFields={invalidFields} />

            case 'completed':
            case 'finalized':
            case 'cancelled':
                return <CompletedInfoView order={order} />
        }

    }

    const renderAction = () => {

        switch (state.value) {

            case 'select_vehicle':
                return <SelectVehicleAction isValid={isStepValid} />

            case 'select_client':
                return <SelectClientAction isValid={isStepValid} />

            case 'verify_client_details':
                return <VerifyClientDetailsAction isValid={isStepValid} />

            case 'verify_license':
                return <VerifyLicenseAction isValid={isStepValid} />

            case 'verify_insurance':
                return <VerifyInsuranceAction isValid={isStepValid} />

            case 'select_rental_dates':
                return <SelectRentalDatesAction isValid={isStepValid} />

            case 'select_rental_terms':
                return <SelectRentalTermsAction isValid={isStepValid} />

            case 'initiate_delivery':
                return <GetDeliveryLocationAction isValid={isStepValid} />

            case 'start_delivery_inspection':
                return <DeliveryInspectionAction isValid={isStepValid} />

            case 'generate_contract':
                return <GenerateContractAction isValid={isStepValid} />

            case 'sign_contract':
                return <SignContractAction isValid={isStepValid} />

            case 'initiate_pickup':
                return <GetPickUpLocationAction isValid={isStepValid} />

            case 'start_pickup_inspection':
                return <PickUpInspectionAction isValid={isStepValid} />

            case 'completed':
            case 'finalized':
            case 'cancelled':
                return <Center><Icon icon={Icons.successCircle} color="#0ca678" width={30} /></Center>
        }

    }

    const canRenderStepAction = (eventKey: string) => {
        let meta = mergeMeta(state.meta)
        if (meta.hasOwnProperty('settings')) {
            return meta.settings.indexOf(eventKey) < 0
        }
        return true
    }

    const renderProcessButton = (size: 'sm' | 'xl') => {

        if (order.status === 'completed' || order.status === 'cancelled') {

            return (
                <React.Fragment>
                    {state.nextEvents.map((i, k) => {
                        return (
                            <Button
                                component={Link}
                                to={`/orders/${order.id}`}
                                rightIcon={<Icon icon={Icons.forward} color="white" width={18} />}
                                color="blue"
                                variant="filled"
                                size={size}
                            >
                                <Text>Review</Text>
                            </Button>
                        )
                    })}
                </React.Fragment>
            )

        }

        return (
            <React.Fragment>
                {state.nextEvents.map((i, k) => {
                    if (!canRenderStepAction(i)) return null
                    return (
                        <OrderProcessButton
                            key={k}
                            onClick={() => triggerEvent(i)}
                            title={formatSnakeCase(i)}
                            icon={Icons.forward}
                            size={size}
                            disabled={!isStepValid}
                        />
                    )
                })}
            </React.Fragment>
        )

    }

    const renderSettingsMenu = () => {
        let nextEvents: string[] = []
        let dangerEvents: string[] = []
        state.nextEvents.forEach(i => {
            if (i === 'cancelled' && !canRenderStepAction(i)) {
                dangerEvents.push(i)
            } else if (!canRenderStepAction(i)) {
                nextEvents.push(i)
            }
        })
        if (nextEvents.length === 0 && dangerEvents.length === 0) return null
        return (
            <Menu
                withArrow
                control={
                    <Button px={8} variant="outline" color="gray">
                        <Icon icon={Icons.settings} width={20} />
                    </Button>
                }
                sx={{
                    display: 'flex'
                }}
            >
                {nextEvents.length > 0 && <Menu.Label>Modify Order</Menu.Label>}

                {nextEvents.map((i, k) => {
                    return (
                        <React.Fragment key={k}>
                            <Menu.Item
                                onClick={() => triggerEvent(i)}
                            >
                                {formatSnakeCase(i)}
                            </Menu.Item>
                        </React.Fragment>
                    )
                })}

                {nextEvents.length > 0 && dangerEvents.length > 0 && <Divider />}
                {dangerEvents.length > 0 && <Menu.Label>Danger zone</Menu.Label>}

                {dangerEvents.map((i, k) => {
                    let label = i
                    if (i === 'cancelled') {
                        label = 'cancel_order'
                    }
                    return (
                        <React.Fragment key={k}>
                            <Menu.Item
                                onClick={() => triggerEvent(i)}
                                color={i === 'cancelled' ? 'red' : ''}
                            >
                                {formatSnakeCase(label)}
                            </Menu.Item>
                        </React.Fragment>
                    )
                })}

            </Menu>
        )
    }

    return (
        <React.Fragment>

            <Box
                sx={theme => ({
                    height: '100%',
                    display: 'grid',
                    [theme.fn.smallerThan('md')]: {
                        gridTemplateRows: 'auto 1fr auto',
                    },
                    [theme.fn.largerThan('md')]: {
                        gridTemplateRows: 'auto 1fr',
                    },
                })}
            >

                <Box>
                    <Container
                        size="lg"
                        sx={(theme) => ({
                            height: "100%",
                            paddingBottom: 0
                        })}
                    >
                        <PageHeader
                            title={`Order #${order.order_number} - ${orderStateTitleMap[order.state]}`}
                            renderAction={
                                <Group spacing="xs">
                                    {renderSettingsMenu()}
                                    <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                                        <Box>
                                            {renderProcessButton('sm')}
                                        </Box>
                                    </MediaQuery>
                                </Group>
                            }
                        />
                    </Container>
                </Box>

                <Box sx={{ position: 'relative', height: '100%' }}>

                    <ScrollArea
                        style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0
                        }}
                    >

                        <Box sx={(theme) => ({
                            padding: theme.spacing.md,
                            paddingTop: 0
                        })}>

                            <Container size="lg">
                                <TabDivider label="Current Step" />

                                <Stack>

                                    <Card shadow="sm">

                                        <Stack>

                                            <Box>
                                                {renderView()}
                                            </Box>

                                            {stepErrors.length ? (
                                                <Alert icon={<Icon icon={Icons.warningCircle} width="24" />} color="red">
                                                    <strong>
                                                        {stepErrors.join(', ')}
                                                    </strong>
                                                </Alert>
                                            ) : null}

                                            <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                                                <Container
                                                    size="xs"
                                                    px={0}
                                                >
                                                    <Stack spacing="xs">
                                                        {renderAction()}
                                                    </Stack>
                                                </Container>
                                            </MediaQuery>

                                        </Stack>

                                    </Card>

                                </Stack>
                            </Container>

                        </Box>

                    </ScrollArea>

                </Box>

                <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                    <Card
                        sx={theme => ({
                            backgroundColor: theme.colorScheme === 'light' ? theme.white : theme.colors.dark[9],
                            borderRadius: 0
                        })}
                    >
                        <Container
                            size="xs"
                            px={0}
                            sx={theme => ({
                                [theme.fn.smallerThan('sm')]: {
                                    maxWidth: '100%'
                                }
                            })}
                        >
                            <Stack spacing="xs">
                                {renderAction()}
                                {renderProcessButton('xl')}
                            </Stack>
                        </Container>
                    </Card>
                </MediaQuery>

            </Box>

        </React.Fragment>
    )

}

const OrderWizard: React.FC<OrderWizardProps> = ({ order }) => {

    const { getStatus } = useActions().orders
    const errors = useAppState(({ orders }) => orders.errors)
    const [state, setState] = React.useState<any>(null)

    React.useEffect(() => {

        if (order && order.status !== 'completed') {

            let controller: AbortController | null = new AbortController();

            const fetchStatus = async () => {

                if (controller) {
                    try {
                        let data = await getStatus(order.id)
                        setState(data)
                        controller = null;
                    } catch (e: any) {
                        if (e.name !== 'AbortError') {
                            console.error(e)
                        }
                    }
                }

            }

            fetchStatus()

            return () => {
                controller?.abort()
            }

        }

    }, [order, getStatus])

    if (!state && errors.length) {
        return (
            <TenantOrderPreview order={order}>
                <ModuleErrors errors={errors} />
                <TenantOrderStatus currentItem={order} />
                <TenantOrderSummary currentItem={order} />
            </TenantOrderPreview>
        )
    }
    if (!state && !errors.length && (order.status === Order_Status_Enum.Completed || order.status === Order_Status_Enum.Cancelled)) {
        return (
            <TenantOrderPreview order={order}>
                <Card shadow="sm">
                    <CompletedInfoView order={order} />
                </Card>
            </TenantOrderPreview>
        )
    }
    if (!state && !errors.length && order.status !== Order_Status_Enum.Completed) return <Loader />;

    return (
        <OrderWizardMachine order={order} status={state} />
    )

}

export default OrderWizard;
import React from 'react';
import { useAppState } from 'state';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

const DynamicReservationBreadCrumb: React.FC<BreadcrumbComponentProps> = () => {
    const { currentItem } = useAppState().reservations
    if (!currentItem) return <span>Reservation</span>;
    return (
        <span>{`#${currentItem.reservation_number} - ${currentItem.first_name} ${currentItem.last_name}`}</span>
    );
}

export default DynamicReservationBreadCrumb;
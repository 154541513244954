import React from 'react';
import { useAppState } from 'state';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

const DynamicOrderBreadCrumb: React.FC<BreadcrumbComponentProps> = () => {
    const { currentItem } = useAppState().orders
    if (!currentItem) return <span>Order</span>;
    return (
        <span>{`Order #${currentItem.order_number} - ${currentItem.client?.first_name} ${currentItem.client?.last_name}`}</span>
    );
}

export default DynamicOrderBreadCrumb;
import React from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { useActions, useAppState } from 'state';

import Loader from 'components/app/Loader';
import PageHeader from 'components/layout/page/PageHeader';
import ModuleErrors from 'components/ui/ModuleErrors';

import PageLayout from 'components/layout/page/PageLayout';
import { Badge, Box, Button, Card, Grid, Group, MediaQuery, Text } from '@mantine/core';
import { Icon } from '@iconify/react';
import Icons from 'components/ui/Icons';
import { formatDate, formatDuration, formatHour } from 'utils/dates';
import { capitalizeWords, formatAddress } from 'utils/string';
import { decimalToPercentage } from 'utils/numbers';
import { InfoItemPlain, InfoItemTable } from 'components/ui/InfoItem';
import TenantVehicleView from 'components/tenant/vehicle/TenantVehicleView';
import { Reservation_Status_Enum, User_Roles_Enum } from 'state/gql/_types';
import { parseReservationStatusColor } from 'components/tenant/reservations/ReservationUtils';

const TenantReservation: React.FC = () => {

    const { id } = useParams()
    const navigation = useNavigate()
    const { loading, currentItem, currentItemId, errors } = useAppState().reservations
    const { subscribeToItem, unsubscribeFromItem, updateItem } = useActions().reservations
    const { createItem: createOrder } = useActions().orders
    const { createItem: createClient } = useActions().clients
    const agent_id = useAppState(state => state.profile?.id)

    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(() => {
        subscribeToItem({ id });
        return () => {
            unsubscribeFromItem()
        }
    }, [id, subscribeToItem, unsubscribeFromItem])

    const handleEvent = async (event: 'confirm' | 'activate') => {
        setIsLoading(true)
        if (currentItem) {
            switch (event) {
                case 'confirm':
                    await updateItem({ id: currentItemId, _set: { status: Reservation_Status_Enum.Confirmed } })
                    setIsLoading(false)
                    break
                case 'activate':
                    if (currentItem) {
                        let { id, order_id, agent, client, state, city, zip, status, tenant, vehicle, reservation_number, phone, first_name, last_name, email, dob, created_at, updated_at, address, country, total, ...orderPayload } = currentItem

                        let newClientId = null
                        if (!currentItem.client_id) {
                            let client = await createClient({
                                tenant_id: currentItem.tenant_id,
                                role: User_Roles_Enum.Client,
                                email: currentItem.email,
                                first_name: currentItem.first_name,
                                last_name: currentItem.last_name,
                                address: currentItem.address,
                                city: currentItem.city,
                                state: currentItem.state,
                                zip: currentItem.zip,
                                phone: currentItem.phone,
                                country: currentItem.country,
                                dob: currentItem.dob,
                            })
                            if (client) {
                                newClientId = client.id
                            }
                        } else {
                            newClientId = currentItem.client_id
                        }

                        let newOrderId = null
                        if (newClientId) {
                            let order = await createOrder({
                                ...orderPayload,
                                order_total: total,
                                agent_id,
                                client_id: newClientId
                            })
                            if (order) {
                                newOrderId = order.id
                            }
                            console.log(order)
                        }

                        if (newClientId || newOrderId) {
                            console.log(newClientId, newOrderId)
                            await updateItem({
                                id: currentItemId, _set: {
                                    status: Reservation_Status_Enum.Active,
                                    order_id: newOrderId,
                                    client_id: newClientId
                                }
                            })
                        }
                    }
                    setIsLoading(false)

            }
        }
    }

    if (loading || !currentItem) return <Loader />

    const renderPreview = () => {
        return (
            <React.Fragment>

                <Grid gutter="lg">
                    <Grid.Col span={8}>
                        <Text weight={500} mb="md">Rental Summary</Text>
                        <Card shadow="xs" mb="md">
                            <InfoItemTable label="Status">
                                <Badge variant="outline" color={parseReservationStatusColor(currentItem.status)}>{currentItem.status}</Badge>
                            </InfoItemTable>

                            <InfoItemTable label="Start Date" value={`${formatDate(currentItem.start_date)} ${formatHour(currentItem.start_time)}`} />
                            <InfoItemTable label="End Date" value={`${formatDate(currentItem.end_date)} ${formatHour(currentItem.end_time)}`} />
                            <InfoItemTable label="Rental Period" value={formatDuration(currentItem.rental_period)} />
                            <InfoItemTable label="Vehicle">
                                <TenantVehicleView id={currentItem.vehicle_id} />
                            </InfoItemTable>
                            <InfoItemTable label="Memo" value={currentItem.memo} />

                        </Card>
                        <Text weight={500} my="md">Customer Summary</Text>
                        <Card shadow="xs" mb="md">

                            <InfoItemTable label="Name" value={capitalizeWords(`${currentItem.first_name} ${currentItem.last_name}`)} />
                            <InfoItemTable label="Email" value={currentItem.email} />
                            <InfoItemTable label="Phone" value={currentItem.phone} />
                            <InfoItemTable label="Address" value={formatAddress(currentItem.address)} />
                            <InfoItemTable label="Address 2" value={formatAddress(currentItem.city, currentItem.state, currentItem.zip, currentItem.country)} />
                            <InfoItemTable label="Date of Birth" value={formatDate(currentItem.dob, 'M/DD/YY')} />

                        </Card>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text weight={500} mb="md">Charge Summary</Text>

                        <Card shadow="xs" mb="md">
                            <InfoItemTable label="End Date" value={formatDate(currentItem.end_date)} />
                            <InfoItemTable label="Rental Period" value={formatDuration(currentItem.rental_period)} />
                            <InfoItemTable label="Daily Rate" value={currentItem.day_rate} type="currency" />
                            <InfoItemTable label="Mile Rate" value={currentItem.mile_rate} type="currency" />
                            <InfoItemTable label="Free Miles" value={currentItem.free_miles} type="currency" />
                            <InfoItemTable label="Sub Total" value={currentItem.sub_total} type="currency" />
                            <InfoItemTable label="Sales Tax" value={decimalToPercentage(currentItem.sales_tax)} />
                            <InfoItemTable label="Other Fees" value={currentItem.other_fees} type="currency" />
                            <InfoItemTable label="Discount" value={currentItem.discount} type="currency" />
                            <InfoItemTable label="Order Total" value={currentItem.total} type="currency" />
                            <InfoItemTable label="Deposit" value={currentItem.deposit} type="currency" />
                            <Box mt="md">
                                <InfoItemPlain label="Balance" value={currentItem.total - currentItem.deposit} type="currency" color="green" />
                            </Box>
                        </Card>

                    </Grid.Col>
                </Grid>

            </React.Fragment>
        )
    }

    return (
        <PageLayout
            header={<PageHeader
                title={`${currentItem.first_name} ${currentItem.last_name}`}
                renderAction={(
                    <React.Fragment>
                        <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
                            <Group spacing="xs">
                                {(
                                    currentItem.status !== Reservation_Status_Enum.Active &&
                                    currentItem.status !== Reservation_Status_Enum.Completed &&
                                    currentItem.status !== Reservation_Status_Enum.Archived
                                ) && (
                                        <Button
                                            onClick={() => navigation(`/reservations/${currentItemId}/edit`)}
                                            rightIcon={<Icon icon={Icons.edit} width={18} />}
                                            color="blue"
                                            variant="outline"
                                            size="sm"
                                        >
                                            <Text>Edit Reservation</Text>
                                        </Button>
                                    )}
                                {currentItem.status === Reservation_Status_Enum.New && (
                                    <Button
                                        onClick={() => handleEvent('confirm')}
                                        rightIcon={<Icon icon={Icons.successCircle} color="white" width={18} />}
                                        color="green"
                                        variant="filled"
                                        size="sm"
                                        loading={isLoading}
                                    >
                                        <Text>Confirm Reservation</Text>
                                    </Button>
                                )}
                                {currentItem.status === Reservation_Status_Enum.Confirmed && (
                                    <Button
                                        onClick={() => handleEvent('activate')}
                                        rightIcon={<Icon icon={Icons.successCircle} color="white" width={18} />}
                                        color="green"
                                        variant="filled"
                                        size="sm"
                                        loading={isLoading}
                                    >
                                        <Text>Start Rental</Text>
                                    </Button>
                                )}
                                {currentItem.status === Reservation_Status_Enum.Active && currentItem.order_id && (
                                    <Button
                                        onClick={() => navigation(`/orders/${currentItem.order_id }`)}
                                        rightIcon={<Icon icon={Icons.successCircle} color="white" width={18} />}
                                        color="green"
                                        variant="filled"
                                        size="sm"
                                        loading={isLoading}
                                    >
                                        <Text>View Order</Text>
                                    </Button>
                                )}
                            </Group>
                        </MediaQuery>

                        <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                            <Group spacing="xs">
                                {(
                                    currentItem.status !== Reservation_Status_Enum.Active &&
                                    currentItem.status !== Reservation_Status_Enum.Completed &&
                                    currentItem.status !== Reservation_Status_Enum.Archived
                                ) && (
                                        <Button
                                            onClick={() => navigation(`/reservations/${currentItemId}/edit`)}
                                            color="blue"
                                            variant="outline"
                                            size="sm"
                                            px={8}
                                        >
                                            <Icon icon={Icons.edit} width={19} />
                                        </Button>
                                    )}
                                {currentItem.status === Reservation_Status_Enum.New && (
                                    <Button
                                        onClick={() => handleEvent('confirm')}
                                        color="green"
                                        variant="filled"
                                        size="sm"
                                        px={8}
                                        loading={isLoading}
                                    >
                                        <Icon icon={Icons.successCircle} color="white" width={19} />
                                    </Button>
                                )}
                                {currentItem.status === Reservation_Status_Enum.Confirmed && (
                                    <Button
                                        onClick={() => handleEvent('activate')}
                                        color="green"
                                        variant="filled"
                                        size="sm"
                                        px={8}
                                        loading={isLoading}
                                    >
                                        <Icon icon={Icons.successCircle} color="white" width={19} />
                                    </Button>
                                )}
                            </Group>
                        </MediaQuery>
                    </React.Fragment>
                )}
            />}
        >

            <ModuleErrors errors={errors} />

            {renderPreview()}

        </PageLayout>
    )
}

export default TenantReservation;
import React from 'react';

import PageDrawerAction, { DrawerActionProps } from 'components/layout/page/PageDrawerAction';

import { extractCurrentItemData } from "utils/forms";

import FormSchema from './RootUserFormSchema.json';
import { UserFragment, Users_Insert_Input, User_Roles_Enum } from 'state/gql/_types';
import FormDrawer from 'components/forms/FormDrawer';
import { useActions, useAppState } from 'state';
import Icons from 'components/ui/Icons';

type FormPayloadType = Omit<UserFragment,
    | 'id'
    | 'tenant_id'
    | 'created_at'
    | 'updated_at'
    | 'role'
    | 'status'
>

const initialState: FormPayloadType = {
    email: '',
    first_name: '',
    last_name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: ''
};

const RootUserDrawer: React.FC<DrawerActionProps<UserFragment>> = ({ currentItem, onClose }) => {

    const tenant_id = useAppState(state=>state.profile?.tenant_id)
    const { createItem, updateItem, deleteItem, checkUserEmail } = useActions().users;
    const { loading, errors } = useAppState().users;

    let currentItemId = null;
    let currentItemData = null;
    if (currentItem) {
        currentItemId = currentItem.id;
        currentItemData = extractCurrentItemData<FormPayloadType>(initialState, currentItem);
    }

    const handleCreate = (payload: Users_Insert_Input) => {
        if (tenant_id) {
            return createItem({ ...payload, tenant_id: tenant_id, role: User_Roles_Enum.Admin })
        }
        return Promise.reject('No Active Tenant Loaded')
    }

    return (
        <>
            <FormDrawer<FormPayloadType, UserFragment, string>
                autoOpen={false}
                formSchema={FormSchema}
                validators={{
                    email_available: checkUserEmail
                }}
                createAction={handleCreate}
                updateAction={updateItem}
                deleteAction={deleteItem}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
                redirectPath="/users"
                deleteRedirect={true}
            />

        </>
    )
}

export const NewRootUserAction = () => {
    return (
        <PageDrawerAction<UserFragment>
            title='New User'
            action={RootUserDrawer}
            props={{}}
        />
    )
}

export const EditRootUserAction = () => {
    const { currentItem } = useAppState().users
    return (
        <PageDrawerAction<UserFragment>
            title='Edit User'
            action={RootUserDrawer}
            icon={Icons.edit}
            props={{ currentItem }}
            color="dark"
        />
    )
}
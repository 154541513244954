import React from 'react';

import { useActions, useAppState } from 'state';
import { OrderFragment } from 'state/gql/_types';

import { extractCurrentItemData } from "utils/forms";
import FormDrawer from 'components/forms/FormDrawer';

import { DrawerActionProps } from 'components/layout/page/PageDrawerAction';

import FormSchema from 'components/tenant/order/schemas/delivery_inspection.json';
import Icons from 'components/ui/Icons';
import OrderProcessAction from '../components/OrderProcessAction';
import { OrderActionProps } from './_types';

type FormPayloadType = Pick<OrderFragment,
    | 'fuel_out'
    | 'miles_out'
    | 'delivery_notes'
    | 'delivery_images'
    | 'delivery_videos'
>

const initialState: FormPayloadType = {
    fuel_out: '',
    miles_out: 0,
    delivery_notes: '',
    delivery_images: '',
    delivery_videos: '',
};

const DeliveryInspection: React.FC<DrawerActionProps<OrderFragment>> = ({ currentItem, onClose }) => {

    const { updateItem } = useActions().orders;
    const { loading, errors } = useAppState().orders;

    let currentItemId = null;
    let currentItemData = null;
    if (currentItem) {
        currentItemId = currentItem.id;
        currentItemData = extractCurrentItemData<FormPayloadType>(initialState, currentItem);
    }

    return (
        <>
           <FormDrawer<FormPayloadType, OrderFragment, string>
                autoOpen={false}
                formSchema={FormSchema}
                updateAction={updateItem}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
            />
        </>
    )
}

export const DeliveryInspectionAction: React.FC<OrderActionProps> = ({isValid}) => {

    const currentItem = useAppState(state=>state.orders.currentItem)

    if (!currentItem) return null;

    return (
        <OrderProcessAction<DrawerActionProps<OrderFragment>>
            title={isValid?'Updatate Inspection Details':'Start Delivery Inspection'}
            action={DeliveryInspection}
            icon={Icons.edit}
            props={{currentItem}}
            variant={isValid?"outline":"filled"}
            color={isValid?"gray":"blue"}
        />
    )
}
import { Box, Grid, Alert } from "@mantine/core";
import { capitalizeWords } from "utils/string";
import { InfoItemPlain } from "components/ui/InfoItem";
import { Icon } from "@iconify/react";
import Icons from "components/ui/Icons";
import { formatDate, formatDuration } from "utils/dates";
import { OrderFragment } from "state/gql/_types";

const CompletedInfoView: React.FC<{order: OrderFragment}> = ({ order }) => {
    return (
        <Box>

            <Grid grow gutter="xs">

                <Grid.Col span={12}>
                    <Alert icon={<Icon icon={Icons.successCircle} />} title="All Done!" color="teal">
                        Order completed on <strong>{formatDate(order.updated_at)}</strong> by <strong>{order.agent.first_name} {order.agent.last_name}</strong>
                    </Alert>
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Client" value={`${order.client?.first_name} ${order.client?.last_name}`} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Email" value={order.client?.email} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Phone" value={order.client?.phone} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItemPlain label="Vehicle" value={capitalizeWords(`${order.vehicle?.year} ${order.vehicle?.make} ${order.vehicle?.model}`)} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItemPlain label="Start Date" value={formatDate(order.start_date)} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItemPlain label="End Date" value={formatDate(order.end_date)} />
                </Grid.Col>

                <Grid.Col xs={6} lg={4}>
                    <InfoItemPlain label="Rental Period" value={formatDuration(order.rental_period)} />
                </Grid.Col>

            </Grid>

        </Box>
    )
}

export default CompletedInfoView;
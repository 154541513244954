import { AsyncAction } from "state/_types";

export const getProfile: AsyncAction = async ({state,actions}) => {
    let id = state.auth.user_id
    if (id) {
        let profile = await actions.users.getItem({id});
        if (profile) {
            state.auth.session_role = profile.role
            state.profile = profile
        }
    }
}
export const mapArrayToStateObject = (array: any, key: string) => {
    return array.reduce((map: any, item: any) => {
        let index = item[key];
        return { ...map, [index]: item };
    }, {});
}

export const isMobile = (): boolean => {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
}

export const getDefaultUIView = (): 'list' | 'cards' => {
    return isMobile() ? 'cards' : 'list'
}
const Icons = {
    'home': 'ph:house-duotone',
    'tenants': 'ph:suitcase-duotone',
    'vehicles': 'ph:car-duotone',
    'managers': 'ph:user-gear-duotone',
    'orders': 'ph:clipboard-text-duotone',
    'settings': 'ph:gear-duotone',
    'clients': 'ph:identification-card-duotone',
    'agents': 'ph:users-three-duotone',
    'add': 'ph:plus-bold',
    'edit': 'ph:note-pencil-bold',
    'dark': 'ph:moon-stars-duotone',
    'light': 'ph:sun-duotone',
    'successCircle': 'ph:check-circle-duotone',
    'failCircle': 'ph:x-circle-duotone',
    'warningCircle': 'ph:warning-circle-duotone',
    'search': 'ph:magnifying-glass-duotone',
    'go': 'ph:arrow-circle-right-light',
    'cancel': 'ph:x-circle-light',
    'clientDetails': 'ph:address-book',
    'clientLicense': 'ph:identification-card',
    'clientInsurance': 'ph:keyboard',
    'back': 'ph:arrow-left-bold',
    'user': 'ph:user-circle-duotone',
    'out': 'ph:sign-out-bold',
    'forward': 'ph:arrow-right-bold',
    'image': 'ion:image-outline',
    'images': 'ion:images-outline',
    'files': 'ph:files-duotone',
    'download': 'ph:download-simple',
    'billing': 'ph:notebook-duotone',
    'delete': 'ph:trash-duotone',
    'disable': 'ph:pause-circle-duotone',
    'enable': 'ph:play-circle-duotone',
    'reservations': 'ph:calendar-blank-duotone',
    'list': 'ph:list-light',
    'cards': 'ph:grid-four-light'
}

export type IconNames = keyof typeof Icons

export default Icons;
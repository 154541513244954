import AgentDashboard from 'components/agent/AgentDashboard';
import PageHeader from 'components/layout/page/PageHeader';
import PageLayout from 'components/layout/page/PageLayout';
import { TenantBillingWarnings } from 'components/root/tenant/TenantBillingInfo';
import React from 'react';
import { useAppState } from 'state';

const AgentHome: React.FC = () => {
    const { userName } = useAppState()

    return (
        <PageLayout
            header={
                <PageHeader
                    title={`Welcome, ${userName}`}
                />}
        >
            <TenantBillingWarnings showButtons={true} />
            <AgentDashboard />

        </PageLayout>
    )
}

export default AgentHome;
import { ReservationFragment } from "state/gql/_types"
import { FormSchema } from "utils/forms"


const TenantReservationCreateSchema: FormSchema<Omit<ReservationFragment, 'id' | 'status' | 'tenant_id' | 'created_at' | 'updated_at' | 'tenant' | 'vehicle' >> = {
    id: "reservations",
    title: "Reservation",
    groups: {
        reservation_details: {
            label: 'Reservation Details' 
        },
        customer_details: {
            label: 'Customer Details' 
        },
        rental_details: {
            label: 'Rental Details' 
        }
    },
    fields: {
        vehicle_id: {
            group: 'reservation_details',
            label: "Vehicle",
            type: "guest-vehicle-table",
            grid: 12,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                }
            },
            divider: true
        },
        start_date: {
            group: 'reservation_details',
            label: "Rental Start Date",
            type: "date-picker-future",
            grid: 4,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                }
            }
        },
        start_time: {
            group: 'reservation_details',
            label: "Delivery Time",
            type: "time-picker",
            grid: 2,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                }
            }
        },
        end_date: {
            group: 'reservation_details',
            label: "Rental End Date",
            type: "date-picker-future",
            grid: 4,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                }
            }
        },
        end_time: {
            group: 'reservation_details',
            label: "PickUp Time",
            type: "time-picker",
            grid: 2, 
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                }
            }
        },
        rental_period: {
            group: 'reservation_details',
            label: "Rental Period",
            type: "rental-period",
            grid: 12,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                }
            }
        },
        memo: {
            group: 'reservation_details',
            label: "Memo / Notes",
            type: "textarea",
            grid: 12,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: false,
                    message: "{field} is required"
                }
            }
        },
        client_id: {
            group: 'customer_details',
            label: "Client",
            type: "client-select-reservation",
            grid: 12,
            validation: {
                required: {
                    value: false,
                    message: "{field} is required"
                }
            }
        },           
        first_name: {
            group: 'customer_details',
            label: "First Name",
            type: "text",
            grid: 6,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                }
            }
        },
        last_name: {
            group: 'customer_details',
            label: "Last Name",
            type: "text",
            grid: 6,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                }
            }
        },
        email: {
            group: 'customer_details',
            label: "Email Address",
            type: "email",
            grid: 4,
            editable: false,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                },
                email: {
                    message: "{field} is invalid"
                }
            }
        },
        phone: {
            group: 'customer_details',
            label: "Contact Phone",
            type: "phone",
            grid: 4,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: false,
                    message: "{field} is required"
                }
            }
        },    
        dob: {
            group: 'customer_details',
            label: "Birth Date",
            type: "date-picker",
            grid: 4,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: false,
                    message: "{field} is required"
                }
            }
        },
        address: {
            group: 'customer_details',
            label: "Address",
            type: "text",
            grid: 12,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: false,
                    message: "{field} is required"
                }
            }
        },
        city: {
            group: 'customer_details',
            label: "City",
            type: "text",
            grid: 3,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: false,
                    message: "{field} is required"
                }
            }
        },
        state: {
            group: 'customer_details',
            label: "State/Region",
            type: "text",
            grid: 3,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: false,
                    message: "{field} is required"
                }
            }
        },
        zip: {
            group: 'customer_details',
            label: "Postal Code",
            type: "zip",
            grid: 3,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: false,
                    message: "{field} is required"
                }
            }
        },
        country: {
            group: 'customer_details',
            label: "Country",
            type: "country",
            grid: 3,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: false,
                    message: "{field} is required"
                }
            }
        },
        day_rate: {
            group: 'rental_details',
            label: "Day Rate",
            type: "currency",
            grid: 4,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                }
            }
        },
        mile_rate: {
            group: 'rental_details',
            label: "Mile Rate",
            type: "currency",
            grid: 4,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                }
            }
        },
        free_miles: {
            group: 'rental_details',
            label: "Free Miles",
            type: "number",
            grid: 4,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                }
            }
        },
        sub_total: {
            group: 'rental_details',
            label: "Sub Total",
            type: "order-subtotal",
            grid: 12,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                }
            }
        },
        sales_tax: {
            group: 'rental_details',
            label: "Sales Tax",
            type: "decimal",
            grid: 4,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                }
            }
        },
        other_fees: {
            group: 'rental_details',
            label: "Other Fees",
            type: "currency",
            grid: 4,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                }
            }
        },
        discount: {
            group: 'rental_details',
            label: "Discount",
            type: "currency",
            grid: 4,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                }
            }
        },
        total: {
            group: 'rental_details',
            label: "Order Total",
            type: "order-total",
            grid: 12,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                }
            }
        },        
        deposit: {
            group: 'rental_details',
            label: "Deposit",
            type: "order-balance",
            grid: 12,
            validation: {
                type: {
                    value: "string",
                    message: "{field} must be a string"
                },
                required: {
                    value: true,
                    message: "{field} is required"
                }
            }
        }
    }
}

export default TenantReservationCreateSchema
import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Card, Grid, Text, Badge, Group } from '@mantine/core';

import { useActions, useAppState } from 'state';
import PageHeader from 'components/layout/page/PageHeader';
import ModuleErrors from 'components/ui/ModuleErrors';
import { PageFilters, usePageFilters } from 'components/ui/PageFilters';
import { VehicleFragment, Vehicles_Bool_Exp, Vehicles_Status_Enum } from 'state/gql/_types';
import { parseVehicleStatusColor } from 'components/tenant/vehicle/TenantVehicleUtils';
import VehicleColorBadge from 'components/tenant/vehicle/VehicleColorBadge';
import PageLayout from 'components/layout/page/PageLayout';
import { EmptyPageComponent } from 'components/ui/Misc';
import LoadMoreButton from 'components/ui/LoadMoreButton';
import { TenantVehiclesDrawerAction } from 'components/tenant/vehicle/TenantVehicleDrawer';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'utils/dates';
import { SetMetaInput } from 'state/Module';
import DataTable from 'components/ui/DataTable';

const Vehicles: React.FC = () => {

    const tenant_id = useAppState(state => state.profile?.tenant_id)
    const navigation = useNavigate()
    const { itemsList, errors, loading, meta, loadingMore, meta: { limit, offset, sortKey, sortOrder } } = useAppState(state => state.vehicles)
    const { subscribeToItems, unsubscribeFromItems, getMoreItems, resetModule, setMeta } = useActions().vehicles
    const { view } = useAppState(state => state.ui)

    const {
        filters,
        filterParams
    } = usePageFilters<Vehicles_Bool_Exp['_and'], VehicleFragment>({
        baseFilters: [{ tenant_id: { _eq: tenant_id } }],
        clickFilters: [{ status: { _eq: Vehicles_Status_Enum.Available } }],
        searchFilterFields: ['make', 'model', 'color', 'plate'],
        clickFilterField: 'status',
        clickFilterDefault: 'available',
        clickFiltersDisableDefaultAll: true,
        clickFilterValues: {
            available: { label: 'Available', filter: [{ status: { _eq: Vehicles_Status_Enum.Available } }] },
            rented: { label: 'Rented', filter: [{ status: { _eq: Vehicles_Status_Enum.Rented } }] },
            reserved: { label: 'Reserved', filter: [{ status: { _eq: Vehicles_Status_Enum.Unavailable } }] },
            all: { label: 'All', filter: [] }
        }
    })

    React.useEffect(() => {
        subscribeToItems(filters);
        return () => {
            unsubscribeFromItems()
        }
    }, [filters, limit, offset, sortKey, sortOrder, subscribeToItems, unsubscribeFromItems, resetModule])

    const columnHelper = createColumnHelper<VehicleFragment>()

    const columns = React.useMemo(() => [
        columnHelper.accessor('id', {
            header: 'ID'
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: row => <Badge variant="outline" color={parseVehicleStatusColor(row.getValue())}>{row.getValue()}</Badge>
        }),
        columnHelper.accessor(row => row.year, {
            header: 'Year'
        }),
        columnHelper.accessor(row => row.make, {
            header: 'Make'
        }),
        columnHelper.accessor(row => row.model, {
            header: 'Model'
        }),
        columnHelper.accessor(row => row.color, {
            header: 'Color'
        }),
        columnHelper.accessor('plate', {
            header: 'Plate'
        }),
        columnHelper.accessor('created_at', {
            header: 'Created',
            cell: row => formatDate(row.getValue(), 'MM/DD/YY')
        }),
    ], [columnHelper])

    const initialState = {
        columnVisibility: {
            id: false
        }
    }

    const handleMetaChange = (meta: SetMetaInput<VehicleFragment>) => {
        setMeta(meta)
    }

    const handleSelect = (id: string) => {
        navigation(`/vehicles/${id}`)
    }

    const renderList = () => {
        return (
            <DataTable<VehicleFragment>
                columns={columns}
                data={itemsList}
                loading={loading}
                meta={meta}
                onMetaChange={handleMetaChange}
                initialState={initialState}
                onSelectItem={handleSelect}
            />
        )
    }

    const renderCards = () => {
        return (
            <React.Fragment>
                <Grid>
                    {itemsList.map((i, k) => {
                        return (
                            <Grid.Col md={6} key={k}>
                                <Card
                                    component={Link}
                                    to={`/vehicles/${i.id}`}
                                    shadow="xs"
                                    sx={(theme) => ({
                                        '&:hover': {
                                            backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[9],
                                        },
                                    })}
                                >
                                    <Group position='apart'>
                                        <Text weight={500}>{i.year} {i.make} {i.model}</Text>
                                        <Badge variant="outline" color={parseVehicleStatusColor(i.status)}>{i.status}</Badge>
                                    </Group>
                                    <Group spacing="xs">
                                        <VehicleColorBadge color={i.color} size="xs" />
                                        <Group>
                                            <Text>[{i.plate.toUpperCase()}]</Text>
                                        </Group>
                                    </Group>
                                </Card>
                            </Grid.Col>
                        )
                    })}
                </Grid>
                <LoadMoreButton meta={meta} onLoadMore={getMoreItems} loading={loadingMore} />
            </React.Fragment>
        )
    }

    return (
        <PageLayout
            header={<PageHeader
                title='Vehicles'
                renderAction={<TenantVehiclesDrawerAction />}
            />} 
        >

            <PageFilters options={filterParams} />

            <ModuleErrors errors={errors} />

            {itemsList.length ? (<> {view === 'list' ? renderList() : renderCards()} </>) : (<EmptyPageComponent label='Vehicles' />)}

        </PageLayout>
    )
}

export default Vehicles;
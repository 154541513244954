import { Box, Grid } from "@mantine/core";
import { OrderInfoViewProps } from "./_types";
import { InfoItemPlain } from "components/ui/InfoItem";
import { decimalToPercentage } from "utils/numbers";
import { checkItemValidity } from "./_utils";


const RentalTermsInfoView: React.FC<OrderInfoViewProps> = ({ order, invalidFields }) => {
    return (
        <Box>

            <Grid grow gutter="xs">

                <Grid.Col span={12}>
                    <InfoItemPlain label="Daily Rate" value={order.day_rate} type="currency" validation={checkItemValidity('day_rate', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Sub Total" value={order.sub_total} type="currency" validation={checkItemValidity('sub_total', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Other Fees" value={order.other_fees} type="currency" validation={checkItemValidity('other_fees', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Discount" value={order.discount} type="currency" validation={checkItemValidity('discount', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Sales Tax" value={decimalToPercentage(order.sales_tax)} validation={checkItemValidity('sales_tax', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Deposit" value={order.deposit} type="currency" validation={checkItemValidity('deposit', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Total" value={order.order_total} type="currency" color="green" validation={checkItemValidity('order_total', invalidFields)}/>
                </Grid.Col>

            </Grid>

        </Box>
    )
}

export default RentalTermsInfoView; 
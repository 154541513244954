import { Loader, Select } from "@mantine/core";
import React from "react";
import { GenericFieldProps } from "utils/forms";
import { capitalizeWords } from "utils/string";

type ModelInfo = {
    Make_ID: number;
    Model_ID: number;
    Model_Name: string;
    Make_Name: string;
}

type ModelLookupResults = {
    Count: number;
    Message: string;
    SearchCriteria: string;
    Results: ModelInfo[]
}


const ModelSelect: React.FC<GenericFieldProps> = ({ id, name, label, onChange, onBlur, value, error, required, description, disabled, getValues, classNames }) => {

    const [items, setItems] = React.useState<string[]>([])
    const [loading, setLoading] = React.useState(false)
    const [lastMake, setLastMake] = React.useState('')
    const [lastYear, setLastYear] = React.useState('')

    React.useEffect(() => {
        if (!!getValues && !!getValues.year && !!getValues.make) {
            if (getValues.make !== lastMake || getValues.year !== lastYear) {
                setLastMake(getValues.make)
                setLastYear(getValues.year)
            }
        } else {
            if (items.length) {
                setItems([])
            }
        }
    }, [items, lastMake, lastYear, getValues])

    React.useEffect(() => {

        let controller: AbortController | null = new AbortController();

        const loadModels = async () => {

            if (controller) {
                try {
                    setLoading(true)
                    let res = await fetch(`https://vpic.nhtsa.dot.gov/api/vehicles/getmodelsformakeyear/make/${lastMake}/modelyear/${lastYear}?format=json`, { signal: controller.signal })
                    let data: ModelLookupResults = await res.json()
                    let sucess = data.Results && data.Results.length
                    if (sucess) {
                        setItems(data.Results.map(i => capitalizeWords(i.Model_Name.toLocaleLowerCase())))
                    }
                    setLoading(false)
                    controller = null;
                } catch (e: any) {
                    if (e.name !== 'AbortError') {
                        console.error(e)
                    }
                    setLoading(false)
                }
            }

        }

        if (lastMake.length && lastYear.length) {
            loadModels()
        }

        return () => controller?.abort()

    }, [lastMake, lastYear])

    return (
        <Select
            label={label}
            id={id}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={error}
            required={required}
            disabled={disabled}
            description={description}
            rightSection={loading ? <Loader size="xs" /> : null}
            placeholder="Select..."
            searchable
            clearable
            creatable
            getCreateLabel={(value) => `+ Add ${value}`}
            onCreate={(query) => {
                const item = { value: query, label: query };
                onChange(value);
                return item;
              }}
            data={items}
            selectOnBlur
            autoComplete="off"
            classNames={classNames}
        />
    )

}

export default ModelSelect
import { Box, Grid } from "@mantine/core";
import { OrderInfoViewProps } from "./_types";
import { InfoItemPlain } from "components/ui/InfoItem";
import { formatDate } from "utils/dates";
import { checkItemValidity } from "./_utils";
import { capitalizeWord } from "utils/string";
import React from "react";
import { ClientFragment } from "state/gql/_types";
import { EditableVehicleFragment } from "state/vehicles";

const ClientInsuranceInfoView: React.FC<OrderInfoViewProps> = ({ order, invalidFields }) => {

    if (!order.client) return null;

    const renderGenericFields = (i: EditableVehicleFragment | ClientFragment) => (
        <React.Fragment>
            <Grid.Col span={12}>
                <InfoItemPlain label="Provider" value={i.insurance_name} validation={checkItemValidity('insurance_name', invalidFields)} />
            </Grid.Col>

            <Grid.Col span={12}>
                <InfoItemPlain label="Policy Number" value={i.insurance_number} validation={checkItemValidity('insurance_number', invalidFields)} />
            </Grid.Col>

            <Grid.Col span={1}>
                <InfoItemPlain label="Effective" value={formatDate(i.insurance_start_date)} validation={checkItemValidity('insurance_start_date', invalidFields)} />
            </Grid.Col>

            <Grid.Col span={12}>
                <InfoItemPlain label="Expires" value={formatDate(i.insurance_end_date)} validation={checkItemValidity('insurance_end_date', invalidFields)} />
            </Grid.Col>
        </React.Fragment>
    )

    const renderFields = () => {
        if (order.policy_type === 'renter' && order.client) {
            return (
                <React.Fragment>
                    {renderGenericFields(order.client)}
                    <Grid.Col span={12}>
                        <InfoItemPlain label="Insurace Photo" value={order.client.insurance_image ? 'Uploaded' : 'Missing'} validation={checkItemValidity('insurance_image', invalidFields)} />
                    </Grid.Col>
                </React.Fragment>
            )
        }
        if (order.policy_type === 'vehicle' && order.vehicle) {
            return (
                <React.Fragment>
                    {renderGenericFields(order.vehicle)}
                </React.Fragment>
            )
        }
    }

    return (
        <Box>

            <Grid grow gutter="xs">

                <Grid.Col span={12}>
                    <InfoItemPlain label="Policy Type" value={capitalizeWord(order.policy_type)} validation={checkItemValidity('policy_type', invalidFields)} />
                </Grid.Col>

                {renderFields()}

            </Grid>

        </Box>
    )
}

export default ClientInsuranceInfoView;
import React from 'react';


import { useActions, useAppState } from 'state';
import { OrderFragment } from 'state/gql/_types';

import { extractCurrentItemData } from "utils/forms";
import FormDrawer from 'components/forms/FormDrawer';

import { DrawerActionProps } from 'components/layout/page/PageDrawerAction';

import FormSchema from 'components/tenant/order/schemas/select_rental_dates.json';
import Icons from 'components/ui/Icons';
import OrderProcessAction from '../components/OrderProcessAction';
import { OrderActionProps } from './_types';

type FormPayloadType = Pick<OrderFragment,
    | 'start_date'
    | 'end_date'
    | 'start_time'
    | 'end_time'
>

const initialState: FormPayloadType = {
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: ''
};

const SelectRentalDatesDrawer: React.FC<DrawerActionProps<OrderFragment>> = ({ currentItem, onClose }) => {

    const { updateItem } = useActions().orders;
    const { loading, errors } = useAppState().orders;

    let currentItemId = null;
    let currentItemData = null;
    if (currentItem) {
        currentItemId = currentItem.id;
        currentItemData = extractCurrentItemData<FormPayloadType>(initialState, currentItem);
    }

    return (
        <>
            <FormDrawer<FormPayloadType, OrderFragment, string>
                autoOpen={false}
                formSchema={FormSchema}
                updateAction={updateItem}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
            />
        </>
    )
}

export const SelectRentalDatesAction: React.FC<OrderActionProps> = ({isValid}) => {

    const currentItem = useAppState(state=>state.orders.currentItem)

    if (!currentItem) return null;

    return (
        <OrderProcessAction<DrawerActionProps<OrderFragment>>
            title='Select Rental Dates'
            action={SelectRentalDatesDrawer}
            icon={Icons.edit}
            props={{currentItem}}
            variant={isValid?"outline":"filled"}
            color={isValid?"gray":"blue"}
        />
    )
}
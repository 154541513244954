import React from "react";
import StatsCard from "components/ui/StatsCard";
import { useActions, useAppState } from "state";
import { Grid } from "@mantine/core";

const TenantDashboard = () => {

    const info = useAppState(state => state.tenant.info)
    const { getTenantInfo } = useActions().tenants

    React.useEffect(() => {
        getTenantInfo()
    }, [getTenantInfo])

    if (!info) return null;

    return (
        <Grid>
            <Grid.Col span={6} xl={4}>
                <StatsCard
                    label="Pending Orders"
                    icon="orders"
                    value={info.stats?.pending_orders}
                    link="/orders?status=pending"
                />
            </Grid.Col>
            <Grid.Col span={6} xl={4}>
                <StatsCard
                    label="Open Orders"
                    icon="orders"
                    value={info.stats?.open_orders}
                    link="/orders?status=open"
                />
            </Grid.Col>
            <Grid.Col span={6} xl={4}>
                <StatsCard
                    label="Vehicles"
                    icon="vehicles"
                    value={info.stats?.total_vehicles}
                    link="/vehicles"
                />
            </Grid.Col>
            <Grid.Col span={6} xl={4}>
                <StatsCard
                    label="Available Vehicles"
                    icon="vehicles"
                    value={info.stats?.available_vehicles}
                    link="/vehicles?status=available"
                    iconColor="green"
                />
            </Grid.Col>
            <Grid.Col span={6} xl={4}>
                <StatsCard
                    label="Rented Vehicles"
                    icon="vehicles"
                    value={info.stats?.rented_vehicles}
                    link="/vehicles?status=rented"
                    iconColor="yellow"
                />
            </Grid.Col>
            <Grid.Col span={6} xl={4}>
                <StatsCard
                    label="Clients"
                    icon="clients"
                    value={info.stats?.clients}
                    link="/clients"
                />
            </Grid.Col>
            <Grid.Col span={6} xl={4}>
                <StatsCard
                    label="Agents"
                    icon="agents"
                    value={info.stats?.agents}
                    link="/users?role=agent"
                />
            </Grid.Col>
            <Grid.Col span={6} xl={4}>
                <StatsCard
                    label="Managers"
                    icon="managers"
                    value={info.stats?.managers}
                    link="/users?role=manager"
                />
            </Grid.Col>
        </Grid>
    )

}

export default TenantDashboard;
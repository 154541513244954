import { User_Roles_Enum, User_Status_Enum } from "state/gql/_types";

export const parseUserStatusColor = (status: User_Status_Enum): string => {
    switch (status) {

        case 'active':
            return 'green';

        default:
            return 'gray'

    }
}

export const parseUserRoleColor = (role: User_Roles_Enum): string => {
    switch (role) {

        case 'admin':
            return 'grape';

        case 'manager':
            return 'blue';

        case 'agent':
            return 'teal';

        default:
            return 'gray'

    }
}
import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Card, Grid, Text, Group, Badge, Box } from '@mantine/core';

import { useActions, useAppState } from 'state';
import PageHeader from 'components/layout/page/PageHeader';
import ModuleErrors from 'components/ui/ModuleErrors';
import { PageFilters, usePageFilters } from 'components/ui/PageFilters';
import { ClientFragment, OrderFragment, Orders_Bool_Exp, Order_Status_Enum, TenantFragment, UserFragment, VehicleFragment } from 'state/gql/_types';
import PageLayout from 'components/layout/page/PageLayout';
import { EmptyPageComponent } from 'components/ui/Misc';
import { NewTenantOrderAction } from 'components/tenant/order/TenantOrderCreateActions';
import { capitalizeWords, formatSnakeCase } from 'utils/string';
import { formatDate, formatDuration } from 'utils/dates';
import LoadMoreButton from 'components/ui/LoadMoreButton';
import { parseOrderStatusColor } from 'components/tenant/order/OrderUtils';
import { motion, AnimatePresence } from 'framer-motion'
import VehicleColorBadge from 'components/tenant/vehicle/VehicleColorBadge';
import { createColumnHelper } from '@tanstack/react-table';
import { SetMetaInput } from 'state/Module';
import DataTable from 'components/ui/DataTable';

const TenantOrders: React.FC = () => {

    const tenant_id = useAppState(state => state.profile?.tenant_id)
    const navigation = useNavigate()
    const { itemsList, errors, meta, loadingMore, loading, meta: { limit, offset, sortKey, sortOrder } } = useAppState(state => state.orders)
    const { subscribeToItems, unsubscribeFromItems, getMoreItems, resetModule, setMeta } = useActions().orders
    const { view } = useAppState(state => state.ui)

    const {
        filters,
        filterParams
    } = usePageFilters<Orders_Bool_Exp['_and'], OrderFragment, { client: ClientFragment, tenant: TenantFragment, vehicle: VehicleFragment, agent: UserFragment }>({
        baseFilters: [{ tenant_id: { _eq: tenant_id } }],
        clickFilters: [{ status: { _eq: Order_Status_Enum.Pending } }],
        searchFilterFields: [],
        searchFilterRelations: {
            tenant: ['name'],
            agent: ['first_name', 'last_name', 'email'],
            client: ['first_name', 'last_name', 'email'],
            vehicle: ['year', 'make', 'model', 'plate', 'color']
        },
        clickFilterField: 'status',
        clickFilterDefault: 'pending',
        clickFiltersDisableDefaultAll: true,
        clickFilterValues: {
            pending: { label: 'Pending', filter: [{ status: { _eq: Order_Status_Enum.Pending } }] },
            open: { label: 'Open', filter: [{ status: { _eq: Order_Status_Enum.Open } }] },
            completed: { label: 'Completed', filter: [{ status: { _eq: Order_Status_Enum.Completed } }] },
            all: { label: 'All', filter: [] }
        }
    })

    React.useEffect(() => {
        subscribeToItems(filters);
        return () => {
            unsubscribeFromItems()
        }
    }, [filters, limit, offset, sortKey, sortOrder, subscribeToItems, unsubscribeFromItems, resetModule])

    const columnHelper = createColumnHelper<OrderFragment>()

    const columns = React.useMemo(() => [
        columnHelper.accessor('id', {
            header: 'ID'
        }),
        columnHelper.accessor('order_number', {
            header: '#'
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: row => <Badge variant="outline" color={parseOrderStatusColor(row.getValue())}>{row.getValue()}</Badge>
        }),
        columnHelper.accessor('step', {
            header: 'Step',
            cell: row => <Badge variant="outline" color="gray">{row.getValue()}</Badge>
        }),
        columnHelper.accessor('state', {
            header: 'State',
            cell: row => <Badge variant="outline" color="gray">{row.getValue()}</Badge>
        }),
        columnHelper.accessor(row => row.vehicle?.year, {
            header: 'Year'
        }),
        columnHelper.accessor(row => row.vehicle?.make, {
            header: 'Make'
        }),
        columnHelper.accessor(row => row.vehicle?.model, {
            header: 'Model'
        }),
        columnHelper.accessor(row => row.vehicle?.color, {
            header: 'Color'
        }),
        columnHelper.accessor(row => row.client?.first_name, {
            header: 'First Name'
        }),
        columnHelper.accessor(row => row.client?.last_name, {
            header: 'Last Name'
        }),
        columnHelper.accessor('start_date', {
            header: 'Start Date',
            cell: row => formatDate(row.getValue(), 'MM/DD/YY')
        }),
        columnHelper.accessor('end_date', {
            header: 'End Date',
            cell: row => formatDate(row.getValue(), 'MM/DD/YY')
        }),
    ], [columnHelper])

    const initialState = {
        columnVisibility: {
            id: false
        }
    }

    const handleMetaChange = (meta: SetMetaInput<OrderFragment>) => {
        setMeta(meta)
    }

    const handleSelect = (id: string) => {
        navigation(`/orders/${id}`)
    }

    const renderList = () => {
        return (
            <DataTable<OrderFragment>
                columns={columns}
                data={itemsList}
                loading={loading}
                meta={meta}
                onMetaChange={handleMetaChange}
                initialState={initialState}
                onSelectItem={handleSelect}
            />
        )
    }

    const renderCards = () => {
        return (
            <React.Fragment>
                <AnimatePresence>
                    <motion.div
                        variants={{
                            hidden: { opacity: 0, transition },
                            show: { opacity: 1, transition },
                            close: {
                                opacity: 0,
                                transition
                            }
                        }}
                        initial="hidden"
                        animate="show"
                        exit="close"
                    >
                        <Grid>
                            {itemsList.map((i, k) => {
                                return (
                                    <Grid.Col md={6} key={k}>
                                        <motion.div
                                            variants={{
                                                hidden: { opacity: 0, transition },
                                                show: { opacity: 1, transition },
                                                close: {
                                                    opacity: 0,
                                                    transition
                                                }
                                            }}
                                        >
                                            <Box>
                                                <Card
                                                    component={Link}
                                                    to={`/orders/${i.id}`}
                                                    shadow="xs"
                                                    sx={(theme) => ({
                                                        '&:hover': {
                                                            backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[9],
                                                        },
                                                    })}
                                                >
                                                    <Group position='apart'>
                                                        <Text weight={500}>{`Order #${i.order_number} - ${i.client?.first_name} ${i.client?.last_name}`}</Text>
                                                        <Badge size="sm" variant="outline" color={parseOrderStatusColor(i.status)}>{formatSnakeCase(i.status)}</Badge>
                                                    </Group>
                                                    <Group spacing="xs">
                                                        <Box><Text size="sm">{capitalizeWords(`${i.vehicle?.year} ${i.vehicle?.make} ${i.vehicle?.model}`)}</Text></Box>
                                                        <VehicleColorBadge color={i.vehicle?.color || 'white'} size="xs" />
                                                        <Group>
                                                            <Text size="sm">[{i.vehicle?.plate.toUpperCase()}]</Text>
                                                        </Group>
                                                    </Group>
                                                    <Box><Text size="sm">{formatDate(i.start_date, 'MM/DD')} - {formatDate(i.end_date, 'MM/DD')} ({formatDuration(i.rental_period)})</Text></Box>
                                                    {/* <Grid grow gutter="xs">

                                                            <Grid.Col span={12}>
                                                                <InfoItemPlain label="Status">
                                                                    <InfoItemBadge variant="outline" color="green" body={formatSnakeCase(i.step)} />
                                                                </InfoItemPlain>
                                                            </Grid.Col>

                                                            <Grid.Col span={12}>
                                                                <InfoItemPlain label="Next Action">
                                                                    <InfoItemBadge variant="outline" color="green" body={formatSnakeCase(i.state)} />
                                                                </InfoItemPlain>
                                                            </Grid.Col>

                                                        </Grid> */}

                                                </Card>
                                            </Box>
                                        </motion.div>
                                    </Grid.Col>
                                )
                            })}
                        </Grid>
                    </motion.div>
                </AnimatePresence>
                <LoadMoreButton meta={meta} onLoadMore={getMoreItems} loading={loadingMore} />
            </React.Fragment>
        )
    }

    const transition = { duration: 0.2, staggerChildren: 0.1 };

    return (
        <PageLayout
            header={<PageHeader
                title='Orders'
                renderAction={<NewTenantOrderAction />}
            />}
        >

            <PageFilters options={filterParams} />

            <ModuleErrors errors={errors} />

            {itemsList.length ? (<> {view === 'list' ? renderList() : renderCards()} </>) : (<EmptyPageComponent label='Orders' />)}

        </PageLayout>
    )
}

export default TenantOrders;
import { AsyncAction, Context } from "state/_types";
import { pipe, debounce } from 'overmind'

export const checkUserEmail: AsyncAction<string|undefined,boolean> = pipe(
    debounce(250),
    async ({effects}: Context, email) => {
        if (!email) return true;
        let { items } = await effects.gql.queries.CheckUserEmail({email})
        return !!!items.length
    }
)
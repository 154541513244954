
import { pipe } from 'overmind'
import LogRocket from 'logrocket'
import { AsyncAction, Context } from "state/_types"

import SuperTokens from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";

import logger from 'utils/logger';
import { getTenantSlug } from 'utils/urls';
import { BaseOverrides, PaletteOverrides, StyleOverrides, VerificationOverrides } from 'components/ui/AuthOverrides';
import { effects } from '..';
import { getDefaultUIView } from 'utils/state';

export const onInitializeOvermind: AsyncAction = pipe(
    async ({state,actions}: Context) => {
        LogRocket.init('jizvus/clutch360');
        state.tenant.slug = getTenantSlug()
        state.ui.view = getDefaultUIView()
        state.loading = true;
        logger(`Initializing App`)
        actions.loadSettings()
    },
    async ({state,actions}: Context) => {
 
        logger(`Initializing Auth`)

        let authColors = PaletteOverrides(state.settings.theme)
        let authStyles = StyleOverrides(state.settings.theme)

        SuperTokens.init({
            appInfo: { 
                appName: "DT",
                apiDomain: process.env.REACT_APP_AUTH_API_URL || '',
                websiteDomain: window.location.hostname + (process.env.NODE_ENV==='development'?':3000':'')
            },
            recipeList: [
                EmailPassword.init({
                    palette: authColors,
                    style: authStyles,
                    override: {
                        components: BaseOverrides,
                        emailVerification: {
                            components: VerificationOverrides
                        }
                    },
                    useShadowDom: false,
                    emailVerificationFeature: {
                        mode: "REQUIRED"
                    },
                    signInAndUpFeature: {
                        signUpForm: {
                            formFields: [
                                {
                                    id: "first_name",
                                    label: "First Name",
                                    placeholder: "First Name"
                                },
                                {
                                    id: "last_name",
                                    label: "Last Name",
                                    placeholder: "Last Name"
                                }
                            ],
                            style: {
                                container: {
                                    fontFamily: "Euclid Circular B"
                                }
                            },
                        }
                    },
                    onHandleEvent: async (context) => {
                        logger('EmailPassword.onHandleEvent', context, 'info')
                        if ( context.action === 'EMAIL_VERIFIED_SUCCESSFUL') {
                            let success =await effects.refreshTokenPayload()
                            if (success) {
                                await actions.refreshSession()
                                await actions.initializeApi()
                                await actions.getProfile()
                                await actions.tenants.getTenantInfo()
                                await actions.tenants.loadTenantBilling()
                                await actions.initializeSubscriptions()
                            }
                        }
                    },
                    preAPIHook: async (context) => {

                        let url = context.url;
                        let requestInit = context.requestInit;
                        let action = context.action;

                        if (action === "EMAIL_PASSWORD_SIGN_UP") {

                            if (requestInit.body) {
                                let body = JSON.parse(requestInit.body as string);
                                body.formFields.push({
                                    id: 'tenant_slug',
                                    value: state.tenant.slug
                                })
                                requestInit.body = JSON.stringify(body)
                            } 

                        }

                        return {
                            requestInit, url
                        }
                    } 
                }),
                Session.init({
                    onHandleEvent: async (context) => {
                        logger('Session.onHandleEvent', context.action, 'info')
                        if ( context.action === 'SESSION_CREATED' || context.action === 'REFRESH_SESSION' ) {
                            await actions.refreshSession()
                            await actions.initializeApi()
                            await actions.getProfile()
                            await actions.tenants.getTenantInfo()
                            await actions.tenants.loadTenantBilling()
                            await actions.initializeSubscriptions()
                        }
                    }
                })
            ]
        });
    
    },
    async ({actions}: Context) => {
        logger(`Application Initialized`)
        await actions.refreshSession();
        await actions.initializeApi();
    },
    async ({state,actions}: Context) => {
        await actions.lookUpTenant()
        if(state.auth.session_id) {
            await actions.getProfile()
            await actions.tenants.getTenantInfo()
            await actions.tenants.loadTenantBilling()
        }
        if(state.tenant.id) {
            await actions.initializeApi();
        }
    },
    async ({state,actions}: Context) => {
        state.loading = false;
        if (state.profile) {
            await actions.initializeSubscriptions()
            LogRocket.identify(state.profile.id, {
                name: `${state.profile.first_name} ${state.profile.last_name}`,
                email: state.profile.email, 
                tenant_id: state.profile.tenant_id,
                tenant: state.tenant.name || 'Unknown'
            });
        }
    }
)
import React from 'react';
import { Box, Stack } from '@mantine/core';

import { IconNames } from 'components/ui/Icons';

import DrawerActionButtons, {DrawerActions, DrawerTrigger, DrawerTriggerButtons } from 'components/ui/DrawerActions';
import { DrawerActionProps } from 'components/layout/page/PageDrawerAction';
import { GenericModel } from 'state/Module';

export type DrawerActionItem<A extends string, M extends GenericModel> = {
    element: React.FC<DrawerActionProps<M,A>>;
    icon: IconNames;
    label: string;
    valid: boolean;
    postAction?: A;
}

export type DrawerActionItems<A extends string, M extends GenericModel> = {
    valid: DrawerActionItem<A,M>[];
    invalid: DrawerActionItem<A,M>[];
}

export type ActionsDrawerProps<M extends GenericModel, A extends string = 'none'> = {
    header(): JSX.Element | null,
    currentItemId?: string;
    currentItem: M | null;
    defaultAction?: A;
    onClose(item?: Pick<M,'id'>): void;
    subscribe(payload: { id: string }): void;
    unsubscribe(): void;
    create: {
        element: any,
        postAction: A
    };
    actions?: {
        [index: string]: DrawerActionItem<A, M>
    };
    triggers?: {
        [index: string]: DrawerTrigger
    };
}

function ActionsDrawer<M extends GenericModel, A extends string = 'none'>({
    header,
    subscribe,
    unsubscribe,
    create,
    actions,
    triggers = {},
    currentItemId,
    currentItem,
    onClose,
    defaultAction
}: React.PropsWithChildren<ActionsDrawerProps<M,A>>
): React.ReactElement {

    const [itemId, setItemId] = React.useState<string | undefined>(currentItemId)
    const [action, setAction] = React.useState<A | 'none'>(defaultAction||'none')

    React.useEffect(() => { 
        if (itemId) {
            subscribe({ id: itemId });
            return () => {
                unsubscribe()
            }
        }
    }, [itemId, subscribe, unsubscribe])

    const handleCreateActionClose = (item: Pick<M,'id'>) => {
        if (item && item.id) {
            setItemId(item.id)
            setAction(create.postAction)
        } else {
            onClose()
        }
    }

    const handleActionClose = (item: Pick<M,'id'> | undefined, postAction?: A) => {
        if(postAction && item && item.id) {
            setAction(postAction)
        } else {
            setAction('none')
        }
    }

    const validateActions = (): DrawerActions => {
        let mappedActions = (!!!actions) ? [] : Object.keys(actions).map(actionKey=>{
            let action = actions[actionKey];
            return {
                action: actionKey,
                ...action
            }
        })
        return {
            valid: Object.values(mappedActions).filter(i => i.valid),
            invalid: Object.values(mappedActions).filter(i => !i.valid)
        }
    }
    

    if (!currentItem) {
        let CreateAction = create.element
        return (
            <CreateAction onClose={handleCreateActionClose} />
        )
    }

    if (currentItem) {
        if(!!actions) {
            let currentAction = actions[action]
            if(currentAction) {
                let CurrentActionElement = currentAction.element
                return (
                    <CurrentActionElement onClose={(item)=>handleActionClose(item,currentAction.postAction)} currentItem={currentItem}/>
                )
            }
        }
    }

    const handleActionSelect = (action: A) => {
        setAction(action)
    }

    let validatedActions = validateActions()
    let validatedTriggers = (!!!triggers) ? [] : Object.values(triggers)

    return (
        <Box
            p="xl"
            sx={theme=>({
                height: "calc(100% - 77px)",
                backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[1] : theme.colors.dark[7]
            })}
        >

            {header()}

            <Stack>
                <DrawerActionButtons actions={validatedActions} onSelect={handleActionSelect}/>
                <DrawerTriggerButtons triggers={validatedTriggers}/>
            </Stack>

        </Box >
    )

}

export default ActionsDrawer
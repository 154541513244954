import * as Types from '../gql/_types';

import { ReservationModule } from "./init";
import { mapArrayToStateObject } from "utils/state";
import { Action, AsyncAction, GraphQLSubscriptionResultsPayload } from 'state/_types';
import { subscriptions } from 'state/gql/_operations';
import { handleGraphqQLErrorResponse, handleGraphqQLSubscriptionErrorResponse } from 'state/gql/utils';
import { SetMetaInput } from 'state/Module';

const Module = ReservationModule;

export const state = Module;

export const getItems: AsyncAction<Types.GetReservationsQueryVariables> = async ({state, effects}, payload) => {
    let finalPayload = Module.mergeQueryPayload(payload);
    state.reservations.errors = [];
    state.reservations.loading = true;
    try {
        let { limit, offset, sortKey, sortOrder } = state.reservations.meta;
        let originalPayload = { limit, offset, order_by: { [sortKey]: sortOrder } };
        let { meta, items } = await effects.gql.queries.GetReservations({...originalPayload, ...finalPayload})
        state.reservations.meta.total = meta.aggregate?.count || 0;
        state.reservations.items = mapArrayToStateObject(items, 'id');
    } catch (e: any) {
        state.reservations.errors = handleGraphqQLErrorResponse(e);
    }
    state.reservations.loading = false;
}

export const resetState: Action = ({state}) => {
    state.reservations.errors = []
    state.reservations.loading = false
    state.reservations.items = {}
    state.reservations.currentItemId = null
}

export const resetMeta: Action = ({state}) => {
    state.reservations.meta = {
        limit: Module.defaultLimit,
        sortOrder: Module.defaultSortOrder,
        sortKey: Module.defaultSortKey,
        page: 1,
        total: 0,
        offset: 0,
        orderBy: {}
    }
}

export const resetModule: AsyncAction = async ({actions}) => {
    actions.reservations.resetMeta()
    actions.reservations.resetState()
}

export const getMoreItems: AsyncAction = async ({state, effects}) => {
    let finalPayload = Module.mergeQueryPayload({});
    state.reservations.errors = [];
    state.reservations.loadingMore = true;
    try {
        let { limit, sortKey, sortOrder, page } = state.reservations.meta;
        let newPage = ++page;
        let newOffset = ( limit * newPage ) - limit
        let originalPayload = { limit, offset: newOffset, order_by: { [sortKey]: sortOrder } };
        let { meta, items } = await effects.gql.queries.GetReservations({...originalPayload, ...finalPayload})
        state.reservations.meta.total = meta.aggregate?.count || 0;
        state.reservations.meta.page = newPage;
        state.reservations.meta.offset = newOffset;
        state.reservations.items = { ...state.reservations.items, ...mapArrayToStateObject(items, 'id') };
    } catch (e: any) {
        state.reservations.errors = handleGraphqQLErrorResponse(e);
    }
    state.reservations.loadingMore = false;
}

export const getAllItems: AsyncAction<Types.GetReservationsQueryVariables> = async ({state, effects}, payload) => {
    state.reservations.errors = [];
    state.reservations.loading = true;
    try {
        let { meta, items } = await effects.gql.queries.GetReservations(payload)
        state.reservations.meta.total = meta.aggregate?.count || 0;
        state.reservations.items = mapArrayToStateObject(items, 'id');
    } catch (e: any) {
        state.reservations.errors = handleGraphqQLErrorResponse(e);
    }
    state.reservations.loading = false;
}

export const getItem: AsyncAction<Types.GetReservationQueryVariables,Types.GetReservationQuery['item']> = async ({state, effects}, payload) => {
    try {
        let { item } = await effects.gql.queries.GetReservation(payload);
        return item;
    } catch (e: any) {
        state.reservations.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const loadItem: AsyncAction<Types.GetReservationQueryVariables> = async ({state, actions}, payload) => {
    state.reservations.loading = true;
    try {
        let item = await actions.reservations.getItem(payload);
        if (item) {
            state.reservations.currentItemId = item.id;
            state.reservations.items[item.id] = item;
        }
    } catch (e: any) {
        state.reservations.errors = handleGraphqQLErrorResponse(e);
    }
    state.reservations.loading = false;
}

export const selectItem: Action<string> = async ({state}, payload) => {
    state.reservations.currentItemId = payload
}

export const setMeta: Action<SetMetaInput<Types.ReservationFragment>> = async ({state}, meta) => {
    state.reservations.meta = {
        ...state.reservations.meta,
        ...meta
    }
}


export const createItem: AsyncAction<Types.CreateReservationMutationVariables['object'],Types.CreateReservationMutation['item']> = async ({state, effects}, payload) => {
    let finalPayload = Module.mergeCreatePayload<Types.CreateReservationMutationVariables['object']>(payload);
    state.reservations.errors = [];
    try {
        let { item } = await effects.gql.mutations.CreateReservation({object:finalPayload});
        return item;
    } catch (e: any) {
        state.reservations.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const updateItem: AsyncAction<Types.UpdateReservationMutationVariables,Types.UpdateReservationMutation['item']> = async ({state, effects}, payload) => {
    state.reservations.errors = [];
    try {
        let { item } = await effects.gql.mutations.UpdateReservation(payload);
        return item;
    } catch (e: any) {
        state.reservations.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const deleteItem: AsyncAction<Types.DeleteReservationMutationVariables,Types.DeleteReservationMutation['item']> = async ({state, effects}, payload) => {
    state.reservations.errors = [];
    try {
        let { item } = await effects.gql.mutations.DeleteReservation(payload);
        return item;
    } catch (e: any) {
        state.reservations.errors = handleGraphqQLErrorResponse(e);
        return null;
    }
}

export const syncGetItemsMeta: Action<Types.SyncGetReservationsMetaSubscriptionVariables> = async ({state, actions, effects}, {where}) => {
    effects.subscriptions.subscribe<Types.SyncGetReservationsMetaSubscription, Types.SyncGetReservationsMetaSubscriptionVariables>(
        'syncGetReservationsMeta', 
        subscriptions.SyncGetReservationsMeta, 
        { where }, 
        actions.reservations.onItemsMeta
    );
}

export const unsubItemsMeta: Action = ({effects}) => {
    effects.subscriptions.unsubscribe('syncGetReservationsMeta');
}

export const onItemsMeta: Action<GraphQLSubscriptionResultsPayload<Types.SyncGetReservationsMetaSubscription>> = async ({state}, payload) => {
    if (payload.error) {
        state.reservations.errors = [ ...state.reservations.errors, ...handleGraphqQLSubscriptionErrorResponse(payload.error) ];
    }
    if (payload.data) {
        state.reservations.meta.total = payload.data.meta.aggregate?.count || 0;
    }    
}

export const syncGetItems: Action<Types.SyncGetReservationsSubscriptionVariables> = async ({state, actions, effects}, payload) => {
    let { limit, offset, sortKey, sortOrder } = state.reservations.meta;
    let originalPayload = { limit, offset, order_by: { [sortKey]: sortOrder } };
    state.reservations.loading = true;
    effects.subscriptions.subscribe<Types.SyncGetReservationsSubscription, Types.SyncGetReservationsSubscriptionVariables>(
        'syncGetReservations', 
        subscriptions.SyncGetReservations, 
        {...originalPayload, ...payload}, 
        actions.reservations.onItemsData,
        () => { state.reservations.loading = false }
    );
}

export const unsubItemsData: Action = ({state,effects}) => {
    effects.subscriptions.unsubscribe('syncGetReservations');
    state.reservations.items = {};
}

export const onItemsData: Action<GraphQLSubscriptionResultsPayload<Types.SyncGetReservationsSubscription>> = async ({state}, payload) => {
    if (payload.error) {
        state.reservations.errors = [ ...state.reservations.errors, ...handleGraphqQLSubscriptionErrorResponse(payload.error) ];
    }
    if (payload.data&&payload.data.items) {
        state.reservations.items = mapArrayToStateObject(payload.data.items, 'id');
    }  
}

export const subscribeToItems: Action<Types.SyncGetReservationsSubscriptionVariables> = async ({actions}, payload) => {
    let finalPayload = Module.mergeQueryPayload(payload);
    actions.reservations.syncGetItems(finalPayload);
    actions.reservations.syncGetItemsMeta(finalPayload);
}

export const unsubscribeFromItems: Action = ({actions}) => {
    actions.reservations.unsubItemsData();
    actions.reservations.unsubItemsMeta();
}

export const syncGetItem: Action<Types.SyncGetReservationSubscriptionVariables> = async ({state, actions, effects}, payload) => {
    state.reservations.loading = true;
    effects.subscriptions.subscribe<Types.SyncGetReservationSubscription, Types.SyncGetReservationSubscriptionVariables>(
        'syncGetReservation', 
        subscriptions.SyncGetReservation, 
        payload, 
        actions.reservations.onItemData,
        () => { state.reservations.loading = false }
    );
}

export const unsubItemData: Action = ({state,effects}) => {
    effects.subscriptions.unsubscribe('syncGetReservation');
    state.reservations.currentItemId = null;
}

export const onItemData: Action<GraphQLSubscriptionResultsPayload<Types.SyncGetReservationSubscription>> = async ({state}, payload) => {
    if (payload.error) {
        state.reservations.errors = [ ...state.reservations.errors, ...handleGraphqQLSubscriptionErrorResponse(payload.error) ];
    }
    if (payload.data&&payload.data.item) {
        state.reservations.currentItemId = payload.data.item.id;
        state.reservations.items[payload.data.item.id] = payload.data.item;
    }
}

export const subscribeToItem: Action<Types.SyncGetReservationSubscriptionVariables> = async ({actions}, payload) => {
    actions.reservations.syncGetItem(payload);
}

export const unsubscribeFromItem: Action = ({actions}) => {
    actions.reservations.unsubItemData();
}

export const actions = {
    getItems,
    getAllItems,
    resetState,
    resetMeta,
    getMoreItems,
    resetModule,
    getItem,
    loadItem,
    selectItem,
    setMeta,
    createItem,
    updateItem,
    deleteItem,
    syncGetItemsMeta,
    unsubItemsMeta,
    onItemsMeta,
    syncGetItems,
    unsubItemsData,
    onItemsData,
    subscribeToItems,
    unsubscribeFromItems,
    syncGetItem,
    unsubItemData,
    onItemData,
    subscribeToItem,
    unsubscribeFromItem
};
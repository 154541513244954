import React from 'react';
import { useAppState } from 'state';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

const DynamicUserBreadCrumb: React.FC<BreadcrumbComponentProps> = () => {
    const { currentItem } = useAppState().users
    if (!currentItem) return <span>User</span>;
    return (
        <span>{currentItem.first_name} {currentItem.last_name}</span>
    );
}

export default DynamicUserBreadCrumb;
import { VehicleFragment } from "state/gql/_types";
import { Badge, Grid } from '@mantine/core';
import { capitalizeWord } from "utils/string";
import InfoItem from "components/ui/InfoItem";
import { parseVehicleStatusColor } from "./TenantVehicleUtils";
import VehicleColorBadge from "./VehicleColorBadge";
import { formatDate } from "utils/dates";

export const TenantVehicleInfo: React.FC<{ vehicle: VehicleFragment }> = ({ vehicle }) => {
    return (
        <Grid grow gutter="xs">

            <Grid.Col span={12}>
                <InfoItem label="Status">
                    <Badge size="lg" variant="outline" color={parseVehicleStatusColor(vehicle.status)}>{vehicle.status}</Badge>
                </InfoItem>
            </Grid.Col>

            <Grid.Col xs={6} lg={3}>
                <InfoItem label="Make" value={vehicle.make} />
            </Grid.Col>
            <Grid.Col xs={6} lg={3}>
                <InfoItem label="Model" value={capitalizeWord(vehicle.model)} />
            </Grid.Col>
            <Grid.Col xs={6} lg={3}>
                <InfoItem label="Year" value={vehicle.year} />
            </Grid.Col>
            <Grid.Col xs={6} lg={3}>
                <InfoItem label="Color">
                    <VehicleColorBadge color={vehicle.color}/>
                </InfoItem>
            </Grid.Col>

            <Grid.Col xs={12} lg={6}>
                <InfoItem label="Vin" value={vehicle.vin} />
            </Grid.Col>

            <Grid.Col xs={6} lg={3}>
                <InfoItem label="Plate" value={vehicle.plate} />
            </Grid.Col>

            <Grid.Col xs={6} lg={3}>
                <InfoItem label="Miles" value={vehicle.miles} />
            </Grid.Col>

            <Grid.Col xs={6}>
                <InfoItem label="Created" value={formatDate(vehicle.created_at)} />
            </Grid.Col>
            <Grid.Col xs={6}>
                <InfoItem label="Updated" value={formatDate(vehicle.updated_at)} />
            </Grid.Col>

        </Grid>
    )
}
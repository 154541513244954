import { Box, Grid } from "@mantine/core";
import { OrderInfoViewProps } from "./_types";
import { InfoItemPlain } from "components/ui/InfoItem";
import { checkItemValidity } from "./_utils";

const GenerateContractInfo: React.FC<OrderInfoViewProps> = ({ order, invalidFields }) => {
    return (
        <Box>

            <Grid grow gutter="xs">

                <Grid.Col span={12}>
                    <InfoItemPlain label="Contract" value={!!order.contract_id?'Generated':'Not Generated'} validation={checkItemValidity('contract_id', invalidFields)} />
                </Grid.Col>

            </Grid>

        </Box>
    )
}

export default GenerateContractInfo;
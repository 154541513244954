import { Icon } from '@iconify/react';
import { Box, Button, Divider, Stack } from '@mantine/core';

import Icons, { IconNames } from 'components/ui/Icons';
import { TabDividerStatus } from 'components/ui/Tabs';
import React from 'react';

export type DrawerAction = {
    action: string;
    icon: IconNames;
    label: string;
    valid: boolean;
}

export type DrawerActions = {
    valid: DrawerAction[],
    invalid: DrawerAction[],
}

type DrawerActionsProps = {
    actions: DrawerActions
    onSelect(action: string): void
}

function DrawerActionButtons({
    actions,
    onSelect
}: React.PropsWithChildren<DrawerActionsProps>
): React.ReactElement {
    return (
        <React.Fragment>

            {!!actions.invalid.length && (
                <Box>
                    <TabDividerStatus label="Pending Actions" valid={false} />
                    <Stack>
                        {actions.invalid.map((i, key) => (
                            <Button
                                key={`invalid-action-${key}`}
                                onClick={() => onSelect(i.action)}
                                rightIcon={<Icon icon={Icons[i.icon]} width={22} />}
                                size="md"
                                color="red"
                                styles={{
                                    inner: {
                                        justifyContent: 'space-between'
                                    }
                                }}
                            >
                                {i.label}
                            </Button>
                        ))}
                    </Stack>
                </Box>
            )}

            <Box>
                <Divider label="Item Actions" my="xs" mt={0} mb="md" variant="dashed" labelPosition="center" />
                <Stack>
                    {actions.valid.map((i, key) => (
                        <Button
                            key={`valid-action-${key}`}
                            rightIcon={<Icon icon={Icons[i.icon]} width={22} />}
                            onClick={() => onSelect(i.action)}
                            size="md"
                            variant="default"
                            color="gray"
                            styles={{
                                inner: {
                                    justifyContent: 'space-between'
                                }
                            }}
                        >
                            {i.label}
                        </Button>
                    ))}
                </Stack>
            </Box>
        </React.Fragment>
    )
}

export type DrawerTrigger = {
    icon: IconNames;
    label: string;
    color: "red" | "gray" | "green";
    onSelect(): void;
}

type DrawerTriggersProps = {
    triggers: DrawerTrigger[];
}

export function DrawerTriggerButtons({
    triggers
}: React.PropsWithChildren<DrawerTriggersProps>
): React.ReactElement {

    return (
        <Box>
            <Stack>
                {triggers.map((i, key) => (
                    <Button
                        key={`trigger-${key}`}
                        rightIcon={<Icon icon={Icons[i.icon]} width={22} />}
                        onClick={() => i.onSelect()}
                        size="md"
                        variant={i.color!=='gray'?"filled":"default"}
                        color={i.color}
                        styles={{
                            inner: {
                                justifyContent: 'space-between'
                            }
                        }}
                    >
                        {i.label}
                    </Button>
                ))}
            </Stack>
        </Box>
    )
}

export default DrawerActionButtons

import { PageTabs } from "components/ui/Tabs";

import { useAppState } from "state";

const RootTenantTabs = () => {

    const { currentItemId } = useAppState(state=>state.tenants)

    return ( 
        <PageTabs
            parentId={currentItemId}
            urlRoot='/tenants'
            tabs={{
                details: {
                    label: 'Details',
                    icon: 'tenants',
                    route: ''
                },
                users: {
                    label: 'Users',
                    icon: 'agents',
                    route: 'users'
                }
            }}
        />
    )

}

export default RootTenantTabs;
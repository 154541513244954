import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Card, Grid, Text, Badge, Group } from '@mantine/core';

import { useActions, useAppState } from 'state';
import { UserFragment, Users_Bool_Exp, User_Roles_Enum } from 'state/gql/_types';

import PageHeader from 'components/layout/page/PageHeader';
import ModuleErrors from 'components/ui/ModuleErrors';
import { NewTenantUserAction } from 'components/tenant/user/TenantUserActions';
import { PageFilters, usePageFilters } from 'components/ui/PageFilters';
import { parseUserRoleColor } from 'components/root/user/UserUtils';
import PageLayout from 'components/layout/page/PageLayout';
import { EmptyPageComponent } from 'components/ui/Misc';
import LoadMoreButton from 'components/ui/LoadMoreButton';
import { createColumnHelper } from '@tanstack/react-table';
import { SetMetaInput } from 'state/Module';
import DataTable from 'components/ui/DataTable';
import { formatDate } from 'utils/dates';

const TenantUsers: React.FC = () => {

    const tenant_id = useAppState(state => state.profile?.tenant_id)
    const navigation = useNavigate()
    const { itemsList, errors, meta, loadingMore, loading, meta: { limit, offset, sortKey, sortOrder } } = useAppState(state => state.users)
    const { subscribeToItems, unsubscribeFromItems, getMoreItems, resetModule, setMeta } = useActions().users
    const { view } = useAppState(state => state.ui)

    const {
        filters,
        filterParams
    } = usePageFilters<Users_Bool_Exp['_and'], UserFragment>({
        baseFilters: [{ tenant_id: { _eq: tenant_id } }],
        clickFilters: [
            {
                _or: [
                    { role: { _eq: User_Roles_Enum.Manager } },
                    { role: { _eq: User_Roles_Enum.Agent } }
                ]
            }
        ],
        searchFilterFields: ['email', 'first_name', 'last_name', 'phone', 'address', 'city'],
        clickFilterField: 'role',
        clickFilterValues: {
            manager: { label: 'Managers', filter: [{ role: { _eq: User_Roles_Enum.Manager } }] },
            agent: { label: 'Agents', filter: [{ role: { _eq: User_Roles_Enum.Agent } }] }
        }
    })

    React.useEffect(() => {
        subscribeToItems(filters);
        return () => {
            unsubscribeFromItems()
        }
    }, [filters, limit, offset, sortKey, sortOrder, subscribeToItems, unsubscribeFromItems, resetModule])

    const columnHelper = createColumnHelper<UserFragment>()

    const columns = React.useMemo(() => [
        columnHelper.accessor('id', {
            header: 'ID'
        }),
        columnHelper.accessor('role', {
            header: 'Role',
            cell: row => <Badge variant="outline" color={parseUserRoleColor(row.getValue())}>{row.getValue()}</Badge>
        }),
        columnHelper.accessor('first_name', {
            header: 'First Name'
        }),
        columnHelper.accessor('last_name', {
            header: 'Last Name'
        }),  
        columnHelper.accessor('email', {
            header: 'Email'
        }), 
        columnHelper.accessor('phone', {
            header: 'Phone'
        }),
        columnHelper.accessor('created_at', {
            header: 'Created',
            cell: row => formatDate(row.getValue(),'MM/DD/YY')
        }),  
    ], [columnHelper])

    const initialState = { 
        columnVisibility: {
            id: false
        }
    }

    const handleMetaChange = (meta: SetMetaInput<UserFragment>) => {
        setMeta(meta)
    }

    const handleSelect = (id: string) => {
        navigation(`/users/${id}`)
    }

    const renderList = () => {
        return (
            <DataTable<UserFragment>
                columns={columns}
                data={itemsList}
                loading={loading}
                meta={meta}
                onMetaChange={handleMetaChange}
                initialState={initialState}
                onSelectItem={handleSelect}
            />
        )
    }

    const renderCards = () => {
        return (
            <React.Fragment>
                <Grid>
                        {itemsList.map((i, k) => {
                            return (
                                <Grid.Col md={6} key={k}>
                                    <Card
                                        component={Link}
                                        to={`/users/${i.id}`}
                                        shadow="xs"
                                        sx={(theme) => ({
                                            '&:hover': {
                                                backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[9],
                                            },
                                        })}
                                    >
                                        <Group position='apart'>
                                            <Text weight={500}>{i.first_name} {i.last_name}</Text>
                                            <Badge variant="outline" color={parseUserRoleColor(i.role)}>{i.role}</Badge>
                                        </Group>
                                        <Text size="sm">{i.email}</Text>
                                    </Card>
                                </Grid.Col>
                            )
                        })}
                    </Grid>
                    <LoadMoreButton meta={meta} onLoadMore={getMoreItems} loading={loadingMore} />
            </React.Fragment>
        )
    }

    return (
        <PageLayout
            header={<PageHeader
                title='Users'
                renderAction={<NewTenantUserAction />}
            />}
        >

            <PageFilters options={filterParams} />

            <ModuleErrors errors={errors} />

            {itemsList.length ? (<> {view === 'list' ? renderList() : renderCards()} </>) : (<EmptyPageComponent label='Users' />)}

        </PageLayout>
    )
}

export default TenantUsers;
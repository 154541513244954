import { Box, Grid } from "@mantine/core";
import { OrderInfoViewProps } from "./_types";
import { InfoItemPlain } from "components/ui/InfoItem";
import { checkItemValidity } from "./_utils";

const ClientDetailsInfoView: React.FC<OrderInfoViewProps> = ({ order, invalidFields }) => {
    if (!order.client) return null;
    let i = order.client
    return (
        <Box>

            <Grid grow gutter="xs">

                <Grid.Col span={12}>
                    <InfoItemPlain label="Address" value={i.address} validation={checkItemValidity('address',invalidFields)}/>
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Country" value={i.country} validation={checkItemValidity('country',invalidFields)}/>
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="City" value={i.city} validation={checkItemValidity('city',invalidFields)}/>
                </Grid.Col>

                <Grid.Col span={6}>
                    <InfoItemPlain label="State/Region" value={i.state} validation={checkItemValidity('state',invalidFields)}/>
                </Grid.Col>

                <Grid.Col span={6}>
                    <InfoItemPlain label="Zip" value={i.zip} validation={checkItemValidity('zip',invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Phone" value={i.phone} validation={checkItemValidity('phone',invalidFields)} />
                </Grid.Col>

            </Grid>

        </Box>
    )
}

export default ClientDetailsInfoView;
import { Loader, Select } from "@mantine/core";
import React from "react";
import { useActions, useAppState } from "state";
import { Order_By, User_Roles_Enum } from "state/gql/_types";
import { GenericFieldProps } from "utils/forms";
import { capitalizeWords } from "utils/string";

const ClientSelect: React.FC<GenericFieldProps> = ({ id, name, label, onChange, onBlur, value, error, required, description, disabled, classNames, setValues, setValueKeys }) => {

    const selectRef = React.useRef<HTMLInputElement|null>(null);
    const tenant_id = useAppState(state => state.profile?.tenant_id)
    const { items, itemsList, loading } = useAppState(state => state.clients)
    const { getAllItems } = useActions().clients

    React.useEffect(() => {

        let controller: AbortController | null = new AbortController();

        const loadItems = async () => {

            if (controller) {
                try {
                    await getAllItems({
                        where: {
                            tenant_id: {
                                _eq: tenant_id
                            },
                            role: {
                                _eq: User_Roles_Enum.Client
                            }
                        },
                        order_by: {
                            first_name: Order_By.Asc
                        }
                    })
                    controller = null;
                } catch (e: any) {
                    if (e.name !== 'AbortError') {
                        console.error(e)
                    }
                }
            }

        }

        if (tenant_id) {
            loadItems()
        }

        return () => controller?.abort()

    }, [tenant_id,getAllItems])

    const handleChange = (value: string | null) => {
        let info: Record<string, string | undefined | null> = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            dob: '',
            address: '',
            country: '',
            city: '',
            state: '',
            zip: '',
        }
        if(value) {
            let client = items[value]
            info = {
                first_name: client?.first_name,
                last_name: client?.last_name,
                email: client?.email,
                phone: client?.phone,
                dob: client?.dob,
                address: client?.address,
                country: client?.country,
                city: client?.city,
                state: client?.state,
                zip: client?.zip,
            }
        }
        if (setValues&&setValueKeys&&setValueKeys.length) {
            setValueKeys.forEach(field=>setValues(field, info[field]))
        }
        onChange(value)
        console.log(selectRef.current)
        if (selectRef.current) {
            selectRef.current.blur()
        }
    }

    return (
        <Select 
            ref={selectRef}
            label={label}
            id={id}
            name={name}
            onChange={handleChange}
            onBlur={onBlur}
            value={value as 'string' | 'null' | 'undefined'}
            error={error}
            required={required}
            disabled={disabled}
            description={description}
            rightSection={loading ? <Loader size="xs" /> : null}
            placeholder="Select..."
            searchable
            clearable
            data={itemsList.map(user=>({
                label: capitalizeWords(`${user.first_name} ${user.last_name}`),
                value: user.id
            }))}
            selectOnBlur
            autoComplete="off"
            classNames={classNames}
            onInput={onBlur}
        />
    )

}

export default ClientSelect;
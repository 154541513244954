import React from 'react';

import PageDrawerAction, { DrawerActionProps } from 'components/layout/page/PageDrawerAction';

import { extractCurrentItemData } from "utils/forms";

import FormSchema from './TenantOrderCreateFormSchema.json';
import { OrderFragment, Orders_Insert_Input } from 'state/gql/_types';
import FormDrawer from 'components/forms/FormDrawer';
import { useActions, useAppState } from 'state';

type FormPayloadType = Pick<OrderFragment,
    | 'client_id'
    | 'vehicle_id'
>

const initialState: FormPayloadType = {
    client_id: '',
    vehicle_id: ''
};

const TenantOrderDrawer: React.FC<DrawerActionProps<OrderFragment>> = ({ currentItem, onClose }) => {

    const tenant_id = useAppState(state=>state.profile?.tenant_id)
    const agent_id = useAppState(state=>state.profile?.id)
    const { createItem, updateItem, deleteItem } = useActions().orders;
    const { loading, errors } = useAppState().orders;

    let currentItemId = null;
    let currentItemData = null;
    if (currentItem) {
        currentItemId = currentItem.id;
        currentItemData = extractCurrentItemData<FormPayloadType>(initialState, currentItem);
    }

    const handleCreate = (payload: Orders_Insert_Input) => {
        if (tenant_id) {
            return createItem({ ...payload, tenant_id: tenant_id, agent_id: agent_id })
        }
        return Promise.reject('No Active Tenant Loaded')
    }

    return (
        <>
            <FormDrawer<FormPayloadType, OrderFragment, string>
                autoOpen={false}
                formSchema={FormSchema}
                createAction={handleCreate}
                updateAction={updateItem}
                deleteAction={deleteItem}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
                redirectPath="/orders"
                createRedirect={true}
                deleteRedirect={true}
            />

        </>
    )
}

export const NewTenantOrderAction = () => {
    return (
        <PageDrawerAction<OrderFragment>
            title='New Order' 
            action={TenantOrderDrawer}
            props={{}}
        />
    )
}
import { ClientFragment } from "state/gql/_types";
import { Badge, Grid } from '@mantine/core';
import InfoItem from "components/ui/InfoItem";
import { parseUserStatusColor } from "components/root/user/UserUtils";
import { formatDate } from "utils/dates";
import { formatAddress } from "utils/string";

export const TenantClientDetailsInfo: React.FC<{ client: ClientFragment }> = ({ client }) => {
    return (
        <Grid grow gutter="xs">

            <Grid.Col span={12}>
                <InfoItem label="Status">
                    <Badge size="lg" variant="outline" color={parseUserStatusColor(client.status)}>{client.status}</Badge>
                </InfoItem>
            </Grid.Col>

            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Name" value={`${client.first_name} ${client.last_name}`} />
            </Grid.Col>

            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Email" value={client.email} />
            </Grid.Col>

            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Phone" value={client.phone} />
            </Grid.Col>

            <Grid.Col span={12}>
                <InfoItem label="Address" value={formatAddress(client.address, client.city, client.state, client.zip)} />
            </Grid.Col>

            <Grid.Col span={12}>
                <InfoItem label="Country" value={client.country} />
            </Grid.Col>

            <Grid.Col xs={6}>
                <InfoItem label="Created" value={formatDate(client.created_at)} />
            </Grid.Col>
            <Grid.Col xs={6}>
                <InfoItem label="Updated" value={formatDate(client.updated_at)} />
            </Grid.Col>

        </Grid>
    )
}

export const TenantClientLicenseInfo: React.FC<{ client: ClientFragment }> = ({ client }) => {
    return (
        <Grid grow gutter="xs">

            <Grid.Col span={12}>
                <InfoItem label="License Number" value={client.license_number} />
            </Grid.Col>
            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Location Issued" value={client.license_state} />
            </Grid.Col>
            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Date of Birth" value={formatDate(client.dob)} />
            </Grid.Col>
            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Expiration Date" value={formatDate(client.license_exp_date)} />
            </Grid.Col>

        </Grid>
    )
}

export const TenantClientInsuranceInfo: React.FC<{ client: ClientFragment }> = ({ client }) => {
    return (
        <Grid grow gutter="xs">

            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Policy Provider" value={client.insurance_name} />
            </Grid.Col>
            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Policy Number" value={client.insurance_number} />
            </Grid.Col>
            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Effective Date" value={formatDate(client.insurance_start_date)} />
            </Grid.Col>
            <Grid.Col xs={6} lg={4}>
                <InfoItem label="Expiration Date" value={formatDate(client.insurance_end_date)} />
            </Grid.Col>

        </Grid>
    )
}
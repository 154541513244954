import React from 'react';

import { useActions, useAppState } from 'state';
import { VehicleFragment } from 'state/gql/_types';

import { DrawerActionProps } from 'components/layout/page/PageDrawerAction';
import FormDrawer from 'components/forms/FormDrawer';

import FormSchema from './schemas/edit-vehicle-picture.json';
import { extractCurrentItemData } from 'utils/forms';
import { VehicleDrawerActions } from '../TenantVehicleDrawer';

type FormPayloadType = Pick<VehicleFragment,
    | 'picture'
>

const initialState: FormPayloadType = {
    picture: ''
};

const EditVehiclePictureAction: React.FC<DrawerActionProps<VehicleFragment,VehicleDrawerActions>> = ({ currentItem, onClose }) => {

    const { updateItem } = useActions().vehicles;
    const { loading, errors } = useAppState().vehicles;

    let currentItemId = null;
    let currentItemData = null;
    if (currentItem) {
        currentItemId = currentItem.id;
        currentItemData = extractCurrentItemData<FormPayloadType>(initialState, currentItem);
    }

    return (
        <>
            <FormDrawer<FormPayloadType, VehicleFragment,string>
                autoOpen={false}
                formSchema={FormSchema}
                updateAction={updateItem}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                onClose={onClose}
                hideDelete={true}
            />

        </>
    )
}

export default EditVehiclePictureAction
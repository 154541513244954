import { AppShell, Box, Burger, Button, Divider, Group, Header, MediaQuery, Navbar, Text, ThemeIcon, useMantineTheme, Loader, Container } from "@mantine/core"
import React from "react"
import { NavLink, Outlet, useRoutes } from "react-router-dom"
import { Icon } from '@iconify/react';
import { EmailPasswordAuth } from "supertokens-auth-react/recipe/emailpassword";
import NavContext from "components/navigation/NavContext";
import { useAppState } from "state";
import ProfileMenu from "components/ui/ProfileMenu";
import { AnimatePresence } from 'framer-motion'
import { TenantTermsModal } from "components/app/TenantTermsModal";
import { TenantSubscriptionBadge } from "components/root/tenant/TenantBillingInfo";

type SideBarNavButtonProps = {
    to: string;
    label: string;
    icon: string;
    onClick?(): void;
}

const SideBarNavButton: React.FC<SideBarNavButtonProps> = ({ to, label, icon, onClick }) => {
    const theme = useMantineTheme();
    return (
        <Button
            variant="subtle"
            color="dark"
            to={to}
            component={NavLink}
            size="lg"
            leftIcon={<ThemeIcon color="dark" variant="outline" size="lg"><Icon icon={icon} width="20" color={theme.colors.red[9]} /></ThemeIcon>}
            onClick={onClick}
            styles={{
                inner: {
                    justifyContent: 'flex-start',

                },
                label: {
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.dark[4]
                }
            }}
            sx={{
                '&.active': {
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[3],
                }
            }}
        >
            {label}
        </Button>
    )
}

const UserInfo: React.FC = () => {
    const { name } = useAppState().tenant
    const user = useAppState().profile
    return (
        <React.Fragment>
            <Text
                weight={600}
                sx={(theme) => ({
                    color: theme.colorScheme === 'light' ? theme.colors.gray[7] : theme.white,
                    fontSize: 18
                })}
            >
                {name}
            </Text>
            <Group align="center" position="left" spacing="xs">
                <Text
                    component="span"
                    sx={(theme) => ({
                        color: theme.colorScheme === 'light' ? theme.colors.dark[9] : theme.white,
                        fontSize: 13,
                        lineHeight: '14px'
                    })}
                >
                    {user ? `${user.first_name} ${user.last_name}` : ''}
                </Text>
                <Text
                    component="span"
                    sx={(theme) => ({
                        color: theme.colors.red[9],
                        fontSize: 11,
                        lineHeight: '14px'
                    })}
                >
                    {user ? `(${user.email})` : ''}
                </Text>
            </Group>
        </React.Fragment>
    )
}

const PageLoaderComponent = () => (
    <Group p={30} py={100} position="center">
        <Loader color="dark" size="lg" variant="bars" />
    </Group>
)

const TopBarNavButton: React.FC<SideBarNavButtonProps> = ({ to, label, icon, onClick }) => {
    const theme = useMantineTheme();
    return (
        <Button
            variant="subtle"
            color="dark"
            to={to}
            component={NavLink}
            onClick={onClick}
            styles={theme => ({
                inner: {
                    justifyContent: 'flex-start',

                },
                label: {
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[6],

                }
            })}
            sx={{
                '&.active': {
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[3],
                    '.mantine-Button-label': {
                        color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
                    }
                },
                '&:hover .mantine-Button-label': {
                    color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
                }
            }}
        >
            {label}
        </Button>
    )
}

type RenderNavigatorProps = {
    online: boolean;
    navigator: React.ReactElement | null
}


export const Portal = () => {

    return (
        <AnimatePresence exitBeforeEnter initial={true} presenceAffectsLayout={false}>
            <Outlet />
        </AnimatePresence>
    )

}

const RenderNavigator: React.FC<RenderNavigatorProps> = ({ online, navigator }) => {

    return (
        <AnimatePresence exitBeforeEnter initial={true} presenceAffectsLayout={false}>
            {online ? navigator : <PageLoaderComponent />}
        </AnimatePresence>
    )
}

const AppShellLayout: React.FC = () => {

    const online = useAppState(state => state.online)
    const { routes, menu } = React.useContext(NavContext);
    const navigator = useRoutes(routes);
    const [opened, setOpened] = React.useState(false);
    const theme = useMantineTheme();

    return (
        <AppShell
            navbarOffsetBreakpoint="md"
            fixed
            padding={0}
            navbar={
                <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                    <Navbar
                        p="md"
                        mt={1}
                        hiddenBreakpoint="md"
                        hidden={!opened}
                    >
                        <Navbar.Section>
                            <Box px='xl' pt="xs">
                                <UserInfo />
                                <Divider mt="lg" mb="md" variant="dashed" />
                            </Box>
                        </Navbar.Section>
                        <Navbar.Section grow>
                            <Group direction="column" spacing="xs" grow>
                                {menu.map((i, k) => {
                                    return <SideBarNavButton key={`nav-item-${k}`} to={i.to} label={i.label} icon={i.icon} onClick={() => setOpened((o) => !o)} />
                                })}
                            </Group>
                        </Navbar.Section>
                    </Navbar>
                </MediaQuery>
            }
            styles={{
                root: {
                    height: '100%'
                },
                body: {
                    height: '100%'
                },
                main: {
                    height: '100%',
                    minHeight: '100%',
                    maxHeight: '100%'
                }
            }}
        >

            <TenantTermsModal />

            <Box
                sx={(theme) => ({
                    height: "100%",
                    [theme.fn.smallerThan('md')]: {
                        padding: 0,
                    }
                })}
            >

                <Header
                    height={70}
                    sx={theme => ({
                        borderImageSlice: 2,
                        borderBottomWidth: 5,
                        borderImageSource: 'linear-gradient(45deg, #c92a2a, #c92a2a)',
                        backgroundColor: theme.colorScheme === 'light' ? theme.colors.dark[8] : theme.white,
                    })}
                >

                    <Container
                        size="lg"
                        sx={(theme) => ({
                            height: "100%"
                        })}
                    >

                        <Box
                            sx={theme => ({      
                                height: "100%",                      
                                display: "flex",
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                [theme.fn.smallerThan('md')]: {
                                    alignItems: 'flex-start',
                                },
                            })}
                        >

                            <Box
                                sx={(theme) => ({
                                    display: 'grid',
                                    gridTemplateColumns: '1fr auto 1fr',
                                    width: '100%',
                                    [theme.fn.smallerThan('md')]: {
                                        gridTemplateColumns: '1fr auto auto'
                                    }
                                })}
                            >

                                <Group>

                                    <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                                        <Burger
                                            opened={opened}
                                            onClick={() => setOpened((o) => !o)}
                                            size="sm"
                                            color={theme.colors.gray[6]}
                                        />
                                    </MediaQuery>
                                    <Box>
                                        <Text
                                            weight={700}
                                            sx={(theme) => ({
                                                display: "inline-block",
                                                color: theme.colorScheme === 'light' ? theme.white : theme.black,
                                                fontSize: 25
                                            })}
                                        >
                                            Clutch
                                        </Text>
                                        <Text
                                            weight={700}
                                            sx={(theme) => ({
                                                display: "inline-block",
                                                color: theme.colors.red[9],
                                                fontSize: 25
                                            })}
                                        >
                                            360
                                        </Text>
                                    </Box>

                                </Group>

                                <Box>

                                    <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
                                        <Group
                                            spacing="xs"
                                            grow
                                        >
                                            {menu.map((i, k) => {
                                                return <TopBarNavButton key={`nav-item-${k}`} to={i.to} label={i.label} icon={i.icon} onClick={() => setOpened((o) => !o)} />
                                            })}
                                        </Group>
                                    </MediaQuery>

                                </Box>

                                <Group position="right" spacing={2}>
                                    <TenantSubscriptionBadge />
                                    <ProfileMenu />
                                </Group>

                            </Box>

                        </Box>



                    </Container>

                </Header>

                <EmailPasswordAuth>
                    <Box
                        className="page-shell-wrapper"
                        sx={{ height: '100%', overflow: 'hidden' }}
                    >
                        <RenderNavigator online={online} navigator={navigator} />
                    </Box>
                </EmailPasswordAuth>

            </Box>

        </AppShell >
    )
}

export default AppShellLayout
import { Card, Stepper, Box } from '@mantine/core';
import { TabDivider } from 'components/ui/Tabs';
import { orderStatusIndex } from 'components/tenant/order/OrderUtils';
import { OrderFragment } from 'state/gql/_types';

export const TenantOrderStatus: React.FC<{currentItem: OrderFragment}> = ({currentItem}) => {

    return (
        <Box>
            <TabDivider label="Order status" />
            <Card 
                shadow="sm"
                sx={theme=>({
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: currentItem.status === 'pending' ? theme.colors.yellow[3] : currentItem.status === 'open' ? theme.colors.blue[3] : theme.colors.green[3]
                })}
            >
                <Stepper
                    active={orderStatusIndex(currentItem.status)}
                    breakpoint="xs"
                    color="green"
                    size="sm"
                    styles={theme => ({
                        steps: {
                            [theme.fn.smallerThan('xs')]: {
                                alignItems: 'center',
                                flexDirection: 'row'
                            }
                        },
                        step: {
                            flexDirection: 'column',
                            '&:first-of-type .mantine-Stepper-stepLabel': {
                                textAlign: 'left'
                            },
                            '&:nth-of-type(2) .mantine-Stepper-stepLabel': {
                                textAlign: 'center'
                            },
                            '&:last-of-type .mantine-Stepper-stepLabel': {
                                textAlign: 'right'
                            }
                        },
                        stepCompleted: {
                            '& .mantine-Stepper-stepLabel': {
                                color: theme.colors.green[6]
                            }
                        },
                        stepProgress: {
                            '&:first-of-type .mantine-Stepper-stepLabel': {
                                color: theme.colors.yellow[7]
                            },
                            '&:nth-of-type(2) .mantine-Stepper-stepLabel': {
                                color: theme.colors.blue[6]
                            },
                            '&:last-of-type .mantine-Stepper-stepLabel': {
                                color: theme.colors.green[6]
                            }
                        },
                        stepBody: {
                            marginLeft: 0,
                        },
                        stepLabel: {
                            textAlign: 'inherit',
                            color: theme.colors.gray[6]
                        },
                        stepDescription: {
                            textAlign: 'center',
                            [theme.fn.smallerThan('xs')]: {
                                display: 'none'
                            }
                        },
                        separator: {
                            [theme.fn.smallerThan('xs')]: {
                                width: 2,
                                minHeight: 2,
                                marginLeft: 10,
                                marginRight: 10,
                                marginTop: 0,
                                marginBottom: 0,
                            }
                        },
                        stepIcon: {
                            display: 'none'
                        }
                    })}
                >
                    <Stepper.Step label="Pending" description="Preparing Rental" />
                    <Stepper.Step label="Open" description="Rental is Active" />
                    <Stepper.Step label="Completed" description="Rental Completed" />
                </Stepper>
            </Card>

        </Box>
    )
    
}

export default TenantOrderStatus
export type ItemValidation = {
    valid: boolean
    message: string | null
}

export const checkItemValidity = (key: string, fields: Record<string,string[]>, showMessage: boolean = false): ItemValidation => {
    if(!!fields[key]) {
        let errors: string[] = fields[key]
        return {
            valid: false,
            message: !showMessage ? null : errors.length ? errors[0] : null
        }
    }
    return {
        valid: true,
        message: null
    }
}
import { AsyncAction } from "state/_types";
import logger from 'utils/logger';

export const initializeApi: AsyncAction = async ({state,effects}) => {
    logger('Initializing GraphQL API')
    effects.gql.initialize({
        endpoint: process.env.REACT_APP_GQL_API_URL || '',
        headers: () => ({
            'X-Hasura-Role': state.auth.session_role || '',
            'X-Hasura-User-Id': state.auth.user_id || '',
            'X-Hasura-Session-Id': state.auth.session_id || '',
            'X-Hasura-Tenant-Id': state.tenant.id || '',
        })
    });
}
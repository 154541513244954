import { Outlet } from "react-router-dom"

import Layout from "components/navigation/Layout";
import DynamicTenantBreadCrumb from "components/root/tenant/DynamicTenantBreadCrumb";
import DynamicUserBreadCrumb from "components/root/user/DynamicUserBreadCrumb";
import Icons from "components/ui/Icons";

import NotFound from "screens/global/NotFound";
import RootHome from "screens/root/RootHome";
import Tenant from "screens/root/tenant/Tenant";
import Tenants from "screens/root/tenant/Tenants";
import User from "screens/root/user/User";
import Users from "screens/root/user/Users";
import TenantUsers from "screens/root/tenant/users/TenantUsers";

let rootRoutes = {
    routes: [
        {
            path: '/',
            element: <Layout />,
            breadcrumb: 'Layout',
            children: [
                { index: true, element: <RootHome />, breadcrumb: 'Home' },
                {
                    path: "tenants",
                    element: <Outlet />,
                    breadcrumb: 'Tenants',
                    icon: 'home',
                    children: [
                        { index: true, element: <Tenants /> },
                        { path: ":id", element: <Tenant />, breadcrumb: DynamicTenantBreadCrumb },
                        { 
                            path: ":id", 
                            element: <Outlet />,
                            children: [
                                { index: true, element: <Tenant />, breadcrumb: DynamicTenantBreadCrumb },
                                { path: "users", element: <TenantUsers />, breadcrumb: 'Users' }
                            ]
                        }
                    ]
                },
                {
                    path: "users",
                    element: <Outlet />,
                    breadcrumb: 'Users',
                    icon: 'users',
                    children: [
                        { index: true, element: <Users /> },
                        { path: ":id", element: <User />, breadcrumb: DynamicUserBreadCrumb }
                    ]
                },
                { path: "*", element: <NotFound />, breadcrumb: 'Page Not Found', }
            ]
        }
    ],
    menu: [
        {
            to: '/',
            label: "Home",
            icon: Icons.home
        },
        {
            to: '/tenants',
            label: "Tenants",
            icon: Icons.tenants
        },
        {
            to: '/users',
            label: "Users",
            icon: Icons.agents
        }
    ],
    mobile: [
        {
            to: '/',
            label: "Home",
            icon: Icons.home
        },
        {
            to: '/tenants',
            label: "Tenants",
            icon: Icons.tenants
        },
        {
            to: '/users',
            label: "Users",
            icon: Icons.agents
        }
    ]
}

export default rootRoutes;
import { Box, Grid } from "@mantine/core";
import { OrderInfoViewProps } from "./_types";
import { InfoItemPlain } from "components/ui/InfoItem";
import { checkItemValidity } from "./_utils";

const SignContractInfo: React.FC<OrderInfoViewProps> = ({ order, invalidFields }) => {
    return (
        <Box>

            <Grid grow gutter="xs">

                <Grid.Col span={12}>
                    <InfoItemPlain label="Contract" value={order.contract_id?'Generated':'Not Generated'} validation={checkItemValidity('contract_id', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Link" value={order.contract_link?'Generated':'Not Generated'} validation={checkItemValidity('contract_link', invalidFields)} />
                </Grid.Col>

                <Grid.Col span={12}>
                    <InfoItemPlain label="Signed" value={order.contract_signed?'Yes':'No'} validation={checkItemValidity('contract_signed', invalidFields)} />
                </Grid.Col>

            </Grid>

        </Box>
    )
}

export default SignContractInfo;
import { AsyncAction } from "state/_types";

import Session from "supertokens-auth-react/recipe/session";

export const refreshSession: AsyncAction = async ({state,effects}) => {
    if (await Session.doesSessionExist()) {
        let session = await effects.getSessionInfo();
        if(session) {
            state.auth = {
                user_id: session.userId,
                session_id: session.sessionHandle,
                session_role: 'user'
            }
            if(Object.keys(session.accessTokenPayload).length) {                
                state.tenant.id = session.accessTokenPayload.tenant_id
                state.tenant.slug = session.accessTokenPayload.tenant_slug
                state.tenant.name = session.accessTokenPayload.tenant_name
                state.tenant.type = session.accessTokenPayload.tenant_type
            }
        }
    }
}
import { Module } from "state/Module"
import * as Types from "state/gql/_types"

export const UserModule = new Module<Types.UserFragment, Types.GetUsersQueryVariables, Types.Users_Insert_Input>({
    sortKey: 'first_name',
    filter: {
        where: {
            role: {
                _neq: Types.User_Roles_Enum.Client
            }
        }
    },
    default: {
        status: Types.User_Status_Enum.Inactive
    }
})
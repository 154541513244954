import { Menu, Button, useMantineTheme, Divider, useMantineColorScheme } from "@mantine/core";
import { Icon } from "@iconify/react";
import Icons from "./Icons";
import { signOut } from "supertokens-website";
import { useNavigate } from "react-router-dom";
import { useAppState } from "state";
import React from "react";

async function onLogout() {
    await signOut();
    window.location.href = "/";
}

const ProfileMenu = () => {
    const profile = useAppState(state => state.profile)
    const theme = useMantineTheme();
    const navigate = useNavigate()
    const { toggleColorScheme } = useMantineColorScheme();
    return (
        <Menu
            withArrow
            control={
                <Button
                    px={7}
                    variant="subtle"
                    color={theme.colorScheme === 'light' ? 'dark' : 'dark'}

                >
                    <Icon icon={Icons.user} color={theme.colorScheme === 'light' ? theme.colors.red[9] : theme.colors.dark[5]} width={28} />
                </Button>
            }
            sx={{
                display: 'flex'
            }}
        >
            {profile && profile.role === 'manager' && (
                <React.Fragment>
                    <Menu.Item
                        icon={<Icon icon={Icons.billing} />}
                        onClick={() => navigate('/billing')}
                    >
                        Billing
                    </Menu.Item>
                    <Divider />
                </React.Fragment>
            )}
            <Menu.Item
                icon={<Icon icon={theme.colorScheme === 'light' ? Icons.dark : Icons.light} />}
                onClick={() => toggleColorScheme()}
            >
                Toggle {theme.colorScheme === 'light' ? 'Dark' : 'Light'} Theme
            </Menu.Item>
            <Divider />
            <Menu.Item icon={<Icon icon={Icons.out} />} onClick={onLogout}>Sign Out</Menu.Item>
        </Menu>
    )
}

export default ProfileMenu
import { GenerateContractLinkMutation, GenerateContractLinkMutationVariables, GenerateContractMutation, GenerateContractMutationVariables, TriggerOrderEventMutation, TriggerOrderEventMutationVariables } from "state/gql/_types";
import { AsyncAction, Context } from "state/_types";

export const getStatus: AsyncAction<string,any> = async ({state,effects}: Context, id): Promise<any> => {
    try {
        let { status } = await effects.gql.queries.GetOrderStatus({id})
        if (status) {
            return JSON.parse(status.state) 
        }
    } catch (e: any) {
        state.orders.errors = [{ code: 'Temporal01', text: 'Failed To Fetch Process Status' }]
    }
}

export const triggerOrderEvent: AsyncAction<TriggerOrderEventMutationVariables,TriggerOrderEventMutation> = async ({state,effects}: Context, payload): Promise<any> => {
    let { order } = await effects.gql.mutations.TriggerOrderEvent(payload)
    if (order) {
        return order
    }
}

export const generateContract: AsyncAction<GenerateContractMutationVariables,GenerateContractMutation> = async ({state,effects}: Context, payload): Promise<any> => {
    let { order } = await effects.gql.mutations.GenerateContract(payload)
    if (order) {
        return order
    }
}

export const generateContractLink: AsyncAction<GenerateContractLinkMutationVariables,GenerateContractLinkMutation> = async ({state,effects}: Context, payload): Promise<any> => {
    let { order } = await effects.gql.mutations.GenerateContractLink(payload)
    if (order) {
        return order
    }
}
export type BillingUsageRecordSummary = {
    id: string;
    object: 'usage_record_summary';
    invoice: string | null;
    livemode: boolean;
    period: {
        end: number | null;
        start: number | null;
    };
    subscription_item: string;
    total_usage: number;
  }

export const getBillingUsage = async (sub_item_id: string | undefined): Promise<BillingUsageRecordSummary[]> => {

    try {

        let res = await fetch(`${process.env.REACT_APP_OPS_API_URL||''}/tenant/get-subscription-usage`,{
            method:'POST',
            body: JSON.stringify({sub_item_id}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        let data = await res.json()
        return data

    } catch (e) {
        console.error(e)
        return [];
    }
    
}
import { Tenant_Status_Enum } from "state/gql/_types";

export const parseTenantStatusColor = (status: Tenant_Status_Enum): string => {
    switch (status) {

        case 'active':
            return 'green';

        default:
            return 'gray'

    }
}
import PageHeader from 'components/layout/page/PageHeader';
import PageLayout from 'components/layout/page/PageLayout';
import { TenantBillingWarnings } from 'components/root/tenant/TenantBillingInfo';
import TenantDashboard from 'components/tenant/TenantDashboard';
import React from 'react';
import { useAppState } from 'state';

const TenantHome: React.FC = () => {
    const { userName } = useAppState()

    return (
        <PageLayout
            header={<PageHeader
                title={`Welcome, ${userName}`}
            />}
        >

            <TenantBillingWarnings showButtons={true} />
            <TenantDashboard />
            
        </PageLayout>
    )
}

export default TenantHome;
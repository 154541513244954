import React from 'react';
import { useParams } from "react-router-dom";

import Loader from 'components/app/Loader';
import { useActions, useAppState } from 'state';
import PageHeader from 'components/layout/page/PageHeader';
import { EditTenantAction } from 'components/root/tenant/NewTenantAction';
import ModuleErrors from 'components/ui/ModuleErrors';
import { TenantInfo, TenantSettingsInfo } from 'components/root/tenant/TenantInfo';
import { TabDivider } from 'components/ui/Tabs';
import RootTenantTabs from 'components/root/tenant/RootTenantTabs';
import PageLayout from 'components/layout/page/PageLayout';
import { Box, Group, Stack } from '@mantine/core';
import { TenantBillingInfoParentDetails } from 'components/root/tenant/TenantBillingInfo';

const Tenant: React.FC = () => {

    const { id } = useParams()
    const { loading, currentItem, errors } = useAppState(state => state.tenants)
    const { subscribeToItem, unsubscribeFromItem } = useActions().tenants

    React.useEffect(() => {
        subscribeToItem({ id });
        return () => {
            unsubscribeFromItem()
        }
    }, [id, subscribeToItem, unsubscribeFromItem])

    if (loading || !currentItem) return <Loader />

    return (
        <PageLayout
            header={<PageHeader
                title={currentItem.name}
                renderAction={(
                    <Group position="right" spacing="xs">
                        <EditTenantAction />
                    </Group>
                )}
            />}
        >

            <RootTenantTabs />

            <ModuleErrors errors={errors} />

            <Stack>

                <Box>
                    <TabDivider label="Subscription Details" />
                    <TenantBillingInfoParentDetails tenant={currentItem}/>
                </Box>

                <Box>
                    <TabDivider label="Tenant Details" />
                    <TenantInfo tenant={currentItem} />
                </Box>

                <Box>
                    <TabDivider label="Tenant Settings" />
                    <TenantSettingsInfo tenant={currentItem} />
                </Box>


            </Stack>

        </PageLayout>
    )
}

export default Tenant;
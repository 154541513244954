import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Card, Grid, Text, Badge, Group } from '@mantine/core';

import { useActions, useAppState } from 'state';
import { UserFragment, Users_Bool_Exp, User_Status_Enum, ClientFragment } from 'state/gql/_types';

import PageHeader from 'components/layout/page/PageHeader';
import ModuleErrors from 'components/ui/ModuleErrors';
import { PageFilters, usePageFilters } from 'components/ui/PageFilters';
import { parseUserStatusColor } from 'components/root/user/UserUtils';
import PageLayout from 'components/layout/page/PageLayout';
import { EmptyPageComponent } from 'components/ui/Misc';
import LoadMoreButton from 'components/ui/LoadMoreButton';
import { TenantClientsDrawerAction } from 'components/tenant/client/TenantClientDrawer';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'utils/dates';
import { SetMetaInput } from 'state/Module';
import DataTable from 'components/ui/DataTable';

const TenantClients: React.FC = () => {

    const tenant_id = useAppState(state => state.profile?.tenant_id)
    const navigation = useNavigate()
    const { itemsList, errors, meta, loadingMore, loading, meta: { limit, offset, sortKey, sortOrder } } = useAppState(state => state.clients)
    const { subscribeToItems, unsubscribeFromItems, getMoreItems, resetModule, setMeta } = useActions().clients
    const { view } = useAppState(state => state.ui)

    const {
        filters,
        filterParams
    } = usePageFilters<Users_Bool_Exp['_and'], UserFragment>({
        baseFilters: [{ tenant_id: { _eq: tenant_id } }],
        clickFilters: [
            {
                _or: [
                    { status: { _eq: User_Status_Enum.Active } },
                    { status: { _eq: User_Status_Enum.Inactive } }
                ]
            }
        ],
        searchFilterFields: ['email', 'first_name', 'last_name', 'phone', 'address', 'city'],
        clickFilterField: 'status',
        clickFilterValues: {
            active: { label: 'Active', filter: [{ status: { _eq: User_Status_Enum.Active } }] },
            inactive: { label: 'Inactive', filter: [{ status: { _eq: User_Status_Enum.Inactive } }] }
        }
    })

    React.useEffect(() => {
        subscribeToItems(filters);
        return () => {
            unsubscribeFromItems()
        }
    }, [filters, limit, offset, sortKey, sortOrder, subscribeToItems, unsubscribeFromItems, resetModule])

    const columnHelper = createColumnHelper<ClientFragment>()

    const columns = React.useMemo(() => [
        columnHelper.accessor('id', {
            header: 'ID'
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: row => <Badge variant="outline" color={parseUserStatusColor(row.getValue())}>{row.getValue()}</Badge>
        }),
        columnHelper.accessor('first_name', {
            header: 'First Name'
        }),
        columnHelper.accessor('last_name', {
            header: 'Last Name'
        }),
        columnHelper.accessor('email', {
            header: 'Email'
        }),
        columnHelper.accessor('state', {
            header: 'State'
        }),
        columnHelper.accessor('country', {
            header: 'Country'
        }),
        columnHelper.accessor('created_at', {
            header: 'Created',
            cell: row => formatDate(row.getValue(), 'MM/DD/YY')
        }),
    ], [columnHelper])

    const initialState = {
        columnVisibility: {
            id: false
        }
    }

    const handleMetaChange = (meta: SetMetaInput<ClientFragment>) => {
        setMeta(meta)
    }

    const handleSelect = (id: string) => {
        navigation(`/clients/${id}`)
    }

    const renderList = () => {
        return (
            <DataTable<ClientFragment>
                columns={columns}
                data={itemsList}
                loading={loading}
                meta={meta}
                onMetaChange={handleMetaChange}
                initialState={initialState}
                onSelectItem={handleSelect}
            />
        )
    }

    const renderCards = () => {
        return (
            <React.Fragment>
                <Grid>
                    {itemsList.map((i, k) => {
                        return (
                            <Grid.Col md={6} key={k}>
                                <Card
                                    component={Link}
                                    to={`/clients/${i.id}`}
                                    shadow="xs"
                                    sx={(theme) => ({
                                        '&:hover': {
                                            backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[9],
                                        },
                                    })}
                                >
                                    <Group position='apart'>
                                        <Text weight={500}>{i.first_name} {i.last_name}</Text>
                                        <Badge variant="outline" color={parseUserStatusColor(i.status)}>{i.status}</Badge>
                                    </Group>
                                    <Text size="sm">{i.email}</Text>
                                </Card>
                            </Grid.Col>
                        )
                    })}
                </Grid>
                <LoadMoreButton meta={meta} onLoadMore={getMoreItems} loading={loadingMore} />
            </React.Fragment>
        )
    }

    return (
        <PageLayout
            header={<PageHeader
                title='Clients'
                renderAction={<TenantClientsDrawerAction />}
            />}
        >

            <PageFilters options={filterParams} />

            <ModuleErrors errors={errors} />

            {itemsList.length ? (<> {view === 'list' ? renderList() : renderCards()} </>) : (<EmptyPageComponent label='Clients' />)}

        </PageLayout>
    )
}

export default TenantClients;
import { GenericError, GraphQLError, GraphQLErrorResponse } from "state/_types";
import { capitalizeWord, capitalizeWords } from "utils/string";

const cleanUpErrorCode = (code: string): string => {
    let codeArray = code.split('-');
    let cleanCodeArray = codeArray.map((str: string)=>{
        return capitalizeWord(str);
    });
    return cleanCodeArray.join(' '); 
}

const parseGenericError = (error: GraphQLError) => {
    switch (error.extensions.code) {
        case 'UsernameExistsException':
            return { code: 'USR01', text: 'A User Already Exists With The Provided Email' }
        default:
            return { code: 'GQL01', text: cleanUpErrorCode(error.extensions.code) };
    }
    
}

const parseValidationError = (error: GraphQLError): GenericError => {
    if (error.message.indexOf("not found in type: 'mutation_root'") > -1 || error.message.indexOf("not found in type: 'query_root'") > -1) {
        return { code: 'AUTH01', text: 'Not Authorized To Perform Current Action' };
    } else {
        if ( error.message ) {
            return { code: 'GQL', text: capitalizeWords(error.message) };
        }
        return { code: 'GQL', text: 'Server Validation Failed' };
    }
}

const parseConstraintError = (error: GraphQLError): GenericError => {
    console.log('parseConstraintError',error)
    if (error.message.indexOf("not found in type: 'mutation_root'") > -1 || error.message.indexOf("not found in type: 'query_root'") > -1) {
        return { code: 'AUTH01', text: 'Not Authorized To Perform Current Action' };
    } else {
        if ( error.message ) {
            return { code: 'GQL', text: capitalizeWords(error.message) };
        }
        return { code: 'GQL', text: 'Server Validation Failed' };
    }
}

export const handleGraphqQLErrorResponse = (transaction: GraphQLErrorResponse): GenericError[] => {   
    if (transaction.hasOwnProperty('response')&&transaction.response.hasOwnProperty('errors')) {
        return transaction.response.errors.map(error=>{
            console.error(error.extensions.code,error)
            switch(error.extensions.code) {
                case 'validation-failed':
                    return parseValidationError(error);
                case 'constraint-violation':
                    return parseConstraintError(error);
                default:
                    return parseGenericError(error);
            }
        })
    } else {
        // @ts-ignore
        return [{ code: 'UNKNOWN', text: transaction.error }]
    }
}

export const handleGraphqQLSubscriptionErrorResponse = (payload: GraphQLError | GraphQLError[]): GenericError[] => {  
    const errors = Array.isArray(payload) ? payload : [payload]
    return errors.map(error=>{
        console.error(error.extensions.code,error)
        switch(error.extensions.code) {
            case 'validation-failed':
                return parseValidationError(error);
            case 'constraint-violation':
                return parseConstraintError(error);
            default:
                return parseGenericError(error);
        }
    })
}
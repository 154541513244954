import { useActions, useAppState } from "state";
import { ReservationFragment, Reservations_Insert_Input } from "state/gql/_types";
import FormInline from "components/forms/FormInline";
import TenantReservationCreateSchema from "../schemas/reservation-create-grouped";
import { Box, Card, Grid, Text } from "@mantine/core";
import { InfoItemPlain, InfoItemTable } from "components/ui/InfoItem";
import { decimalToPercentage } from "utils/numbers";
import { capitalizeWords, formatAddress } from "utils/string";
import { formatDate, formatDuration, formatHour } from "utils/dates";
import React from "react";
import TenantVehicleView from "components/tenant/vehicle/TenantVehicleView";

type FormPayloadType = Omit<ReservationFragment,
    | 'id'
    | 'tenant_id'
    | 'created_at'
    | 'updated_at'
    | 'status'
    | 'tenant'
    | 'vehicle'
    | 'terms_accepted'
>

const initialState: FormPayloadType = {
    "end_date": '',
    "start_date": '',
    "free_miles": 0,
    "rental_period": 0,
    "day_rate": 0,
    "discount": 0,
    "mile_rate": 0,
    "sales_tax": 0.07,
    "sub_total": 0,
    "total": 0,
    "deposit": 0,
    "other_fees": 0,
    "address": '',
    "city": '',
    "country": '',
    "dob": '',
    "email": '',
    "first_name": '',
    "last_name": '',
    "phone": '',
    "state": '',
    "zip": '',
    "end_time": '12:00:00',
    "start_time": '12:00:00',
    "vehicle_id": '',
    "client_id": '',
    "memo": ''
};

const CreateReservationAction: React.FC = () => {

    //const { checkUserEmail } = useActions().users;
    const tenant_id = useAppState(state => state.profile?.tenant_id)
    const agent_id = useAppState(state => state.profile?.id)
    const { createItem, updateItem, deleteItem } = useActions().reservations;
    const { loading, errors } = useAppState().reservations;

    let currentItemId = null;
    let currentItemData = null;
    // if (currentItem) {
    //     currentItemId = currentItem.id;
    //     currentItemData = extractCurrentItemData<FormPayloadType>(initialState, currentItem);
    // }

    const handleCreate = (payload: Reservations_Insert_Input) => {
        if (tenant_id) {
            return createItem({ 
                ...payload, 
                rental_period: parseInt( payload.rental_period && typeof payload.rental_period === 'number' ? payload.rental_period.toString() : '0'),
                tenant_id: tenant_id,
                agent_id
            })
        }
        return Promise.reject('No Active Tenant Loaded')
    }

    const renderPreview = (values: FormPayloadType) => {
        return (
            <React.Fragment>
                <Grid>
                    <Grid.Col span={9}>
                        <Text weight={500} mb="md">Rental Summary</Text>
                        <Card shadow="xs" mb="md">
                            <InfoItemTable label="Start Date" value={`${formatDate(values.start_date)} ${formatHour(values.start_time)}`} />
                            <InfoItemTable label="End Date" value={`${formatDate(values.end_date)} ${formatHour(values.end_time)}`} />
                            <InfoItemTable label="Rental Period" value={formatDuration(values.rental_period)} />
                            <InfoItemTable label="Vehicle">
                                <TenantVehicleView id={values.vehicle_id} />
                            </InfoItemTable>
                            <InfoItemTable label="Memo" value={values.memo} />
                        </Card>
                        <Text weight={500} mb="md">Customer Summary</Text>
                        <Card shadow="xs">
                            <InfoItemTable label="Name" value={capitalizeWords(`${values.first_name} ${values.last_name}`)} />
                            <InfoItemTable label="Email" value={values.email} />
                            <InfoItemTable label="Phone" value={values.phone} />
                            <InfoItemTable label="Address" value={formatAddress(values.address)} />
                            <InfoItemTable label="Address 2" value={formatAddress(values.city, values.state, values.zip, values.country)} />
                            <InfoItemTable label="Date of Birth" value={formatDate(values.dob, 'M/DD/YY')} />

                        </Card>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <Text weight={500} mb="md">Charge Summary</Text>
                        <Card shadow="xs">

                            <InfoItemTable label="Rental Period" value={formatDuration(values.rental_period)} />

                            <InfoItemTable label="Daily Rate" value={values.day_rate} type="currency" />
                            <InfoItemTable label="Mile Rate" value={values.mile_rate} type="currency" />
                            <InfoItemTable label="Free Miles" value={values.free_miles} type="currency" />

                            <InfoItemTable label="Sub Total" value={values.sub_total} type="currency" />
                            <InfoItemTable label="Discount" value={values.discount} type="currency" />

                            <InfoItemTable label="Sales Tax" value={decimalToPercentage(values.sales_tax)} />
                            <Box mt="md">
                                <InfoItemPlain label="Total" value={values.total} type="currency" color="green" />
                            </Box>

                        </Card>
                    </Grid.Col>
                </Grid>
            </React.Fragment>
        )
    }

    return (
        <>
            <FormInline<FormPayloadType, ReservationFragment, string>
                autoOpen={false}
                formSchema={TenantReservationCreateSchema}
                createAction={handleCreate}
                updateAction={updateItem}
                deleteAction={deleteItem}
                isLoading={loading}
                errors={errors}
                currentItemId={currentItemId}
                initialData={initialState}
                currentItemData={currentItemData}
                redirectPath="/reservations"
                deleteRedirect={true}
                cancelRedirectPath="/reservations"
                createRedirect={true}
                cancelRedirect={true}
                updateRedirect={true}
                renderReviewComponent={renderPreview}
            />

        </>
    )
}

export default CreateReservationAction
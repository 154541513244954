import React from 'react';
import Loader from 'components/app/Loader';
import { useActions, useAppState } from 'state';
import { Text, useMantineTheme } from '@mantine/core';
import { capitalizeWords } from 'utils/string';

const TenantVehicleView: React.FC<{ id: string }> = ({ id }) => {

    const theme = useMantineTheme();
    const { currentItem, loading } = useAppState().vehicles
    const { loadItem } = useActions().vehicles

    React.useEffect(() => {
        if (id.length) {
            loadItem({ id });
        }
    }, [id,loadItem])

    if (loading || !currentItem) return <Loader />

    return (
        <Text weight={600} color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black} sx={theme => ({ fontSize: 13, [theme.fn.smallerThan('md')]: { fontSize: 13 } })}>
            {capitalizeWords(`${currentItem.year} ${currentItem.make} ${currentItem.model} ${currentItem.color} [${currentItem.plate} ]`)}
        </Text>
    )
}

export default TenantVehicleView;